import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmptyState from "../../../../components/EmptyState";
import { SectionLoader } from "../../../../components/PageLoader";
// import { getAnnualEmployees } from "../../../../redux/actions/businessActions";
// import { ButtonLoader } from "../../../../components/ButtonLoader";
import QualityCard from "./QualityCard";
import { getAnnualEmployees } from "../../../../redux/actions/businessActions";

function QualityOfHire() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [count, setCount] = useState(0);

  const { annualEmployees } = useSelector(({ business }) => business);
  const { userData } = useSelector((state) => state.auth);

  const company_id = userData?.company?.id;

  // console.log("CompaID", company_id);
  // console.log("Annual Employees", annualEmployees);

  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getAnnualEmployees(dispatch, company_id)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch]);

  const refreshList = () => {
    getAnnualEmployees(dispatch, company_id);
  };

  return (
    <div className="mt-2">
      {loading && !annualEmployees && <SectionLoader />}
      {!loading && !annualEmployees && (
        <EmptyState text="There are no pending request" />
      )}
      {annualEmployees && (
        <>
          {annualEmployees.map((employee, index) => (
            <QualityCard
              key={index}
              user={employee}
              company_id={company_id}
              company_name={userData.company.company_name}
              refreshList={refreshList}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default QualityOfHire;
