import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../../../components/CustomSearchInput";
import RecruiterWrapper from "../RecruiterWrapper";
import icons from "../../../../assets/icons/icon-collection.svg";
import Card from "../../../../components/Card";
import CustomButton from "../../../../components/CustomButton";
import {
  acceptOrDeclineJob,
  getRecruiterJobs,
} from "../../../../redux/actions/recruiterAction";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import { Divider } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import HomeModal from "./Modal";

const Requests = () => {
  const dispatch = useDispatch();
  const { recruiterJobs } = useSelector(({ recruiter }) => recruiter);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [section, setSection] = useState("details");

  const { jobId } = useParams();

  useEffect(() => {
    setLoading(true);
    Promise.resolve(getRecruiterJobs(dispatch)).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);

  const changeJobStatus = (job_id, acceptOrDecline) => {
    acceptOrDeclineJob(
      dispatch,
      { job_id: parseInt(job_id), acceptOrDecline },
      () => {
        getRecruiterJobs(dispatch);
      }
    );
  };

  const { userData } = useSelector((store) => store.auth);
  return (
    <RecruiterWrapper>
      {jobId && (
        <HomeModal
          setSection={setSection}
          section={section}
          open={true}
          handleClose={() => history.push("/recruiter/requests")}
          isBusiness={false}
          isFromCompany={false}
          saveForLater={() => {}}
        />
      )}
      <div className="row m-4">
        <h3 className="mb-5">
          <b>Requests</b>
        </h3>
        <SearchInput />
        <div className="my-4 w-100 position-relative">
          {loading && <SectionLoader />}
          {!loading && !recruiterJobs[0] && (
            <EmptyState text="You have no requests" />
          )}
          {!loading && recruiterJobs[0] && (
            <>
              {recruiterJobs.map(
                (job) =>
                  (job.isAccepted === "0" ||
                    job.accepted_by === userData?.id) && (
                    <Card
                      key={job.id}
                      noPadding
                      additionalClass="w-100 p-0 mb-4"
                    >
                      <div className="d-flex align-items-center status-recruiter px-3 py-2">
                        <span className="mr-2">
                          <svg width="13" height="13">
                            <use href={`${icons}#elipse`}></use>
                          </svg>
                        </span>
                      </div>

                      <div
                        className="d-flex mb-2 align-items-center justify-content-between px-4"
                        style={{
                          paddingTop: 10,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span className="d-flex flex-column">
                            <h6 className="mb-0">{job.campany_name}</h6>
                            <span className="co-gray">
                              {job.created_by_profile.first_name}{" "}
                              {job.created_by_profile.last_name}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          paddingBottom: 10,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                        className="d-flex justify-content-start"
                      >
                        <span className="mr-2">
                          <svg width="14" height="14" className="mr-1">
                            <use href={`${icons}#mappin`}></use>
                          </svg>
                          {job.location}
                        </span>
                      </div>
                      <Divider />
                      <div
                        style={{
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <b className="d-block my-3 co-primary">
                          {job.role_title}
                        </b>
                        <span>
                          {job.about_company?.substring(0, 25)}...
                          <button
                            type="button"
                            onClick={() =>
                              history.push(`/recruiter/requests/${job.id}`)
                            }
                            className="co-primary bg-white borderless"
                          >
                            <b>See More</b>
                          </button>
                        </span>
                      </div>

                      <div className="w-100 d-flex justify-content-end">
                        <div className="w-50 pb-3 d-flex align-items-center">
                          {job.isAccepted === "0" && (
                            <>
                              <button
                                onClick={() => changeJobStatus(job.id, 0)}
                                className="co-lightred borderless bg-white mx-3"
                              >
                                Reject
                              </button>
                              <span className="w-100 mx-3">
                                <CustomButton
                                  handleClick={() => changeJobStatus(job.id, 1)}
                                  text="Accept"
                                  color="#fff"
                                  backgroundColor="#3A6ED4"
                                />
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </Card>
                  )
              )}
            </>
          )}
        </div>
      </div>
    </RecruiterWrapper>
  );
};

export default Requests;
