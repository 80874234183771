import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  array as arrayPropType,
  bool,
  object as objectPropType,
} from "prop-types";
import EditCard from "../EditCard";
import {
  EDIT_PROFILE,
  GET_STATES,
  PROFILE_LOADING,
  STOP_PROFILE_LOADING,
} from "../../../../../redux/actionTypes";
import { ternaryResolver } from "../../../../../utils/helpers";
import { getStateAction } from "../../../../../redux/actions/stateActions";
import { updateProfileAction } from "../../../../../redux/actions/profileActions";
import SearchLocationInput from "../../../../../components/LocationComponent";

const BasicInfo = ({
  // industries,
  profileData,
  states,
  countries,
  profileLoading,
}) => {
  const [isOnEditLocation, setIsOnEditLocation] = useState(true);
  const [title, setTitle] = useState(profileData.title || "");
  const dispatch = useDispatch();
  const handleChange = ({ target: { name, value } }) => {
     if (name === "title") {
      setTitle(value); // Update the local title state
    }
    if (name === "country") {
      dispatch({ type: GET_STATES, payload: [] });
      const selectedCountry = countries.find(({ id }) => value === id);
      dispatch({ type: EDIT_PROFILE, payload: { name, value } });
      getStateAction(selectedCountry.id, dispatch);
    } else {
      dispatch({ type: EDIT_PROFILE, payload: { name, value } });
    }
  };
 useEffect(() => {
    const storedTitle = localStorage.getItem("title");
    if (storedTitle) setTitle(storedTitle);
  }, []);
  const handleLocationChange = (place) => {
    dispatch({
      type: EDIT_PROFILE,
      payload: { name: "location", value: place },
    });
  };
  
  const handleSubmit = () => {
  const payload = {
    first_name: profileData.first_name,
    last_name: profileData.last_name,
    title: title,
    gender: profileData.gender,
    dob: profileData.dob,
    state: profileData.state,
    country: profileData.country,
    industry: profileData.industry,
    image_url: profileData.image_url,
    resume: profileData.resume,
    phone: profileData.phone,
    links: profileData.links,
    isShowOnProfile: 1,
    about: profileData.about,
    location: profileData.location || "",
  };

  dispatch({ type: PROFILE_LOADING });

 
  updateProfileAction(payload, dispatch)
    .then(response => {
      console.log("Profile updated successfully:", response);
      localStorage.setItem("title", response.payload.title)
     
    })
    .catch(error => {
      console.error("Error updating profile:", error);
    })
    .finally(() => {
      dispatch({ type: STOP_PROFILE_LOADING });
    });
};

  return (
    <EditCard
      handleSubmit={handleSubmit}
      allRequired
      loading={profileLoading}
      btnText="Update"
      disable={[
        !profileData.first_name,
        !profileData.last_name,
        !title,
        !profileData.phone,
        !profileData.country,
        !profileData.state,
        !profileData.location,
        !profileData.dob,
        !profileData.gender,
        //profileData?.industry?.length === 0,
      ].includes(true)}
    >
      <TextField
        onChange={handleChange}
        name="first_name"
        value={profileData.first_name}
        className="w-100 mb-4"
        variant="outlined"
        label="First name"
      />
      <TextField
        onChange={handleChange}
        name="last_name"
        value={profileData.last_name}
        className="w-100 mb-4"
        variant="outlined"
        label="Last name"
      />
      <TextField
        onChange={handleChange}
        name="title"
        value={title}
        className="w-100 mb-4"
        variant="outlined"
        label="Title"
        helperText="E.g Software Developer or Customer Service Rep."
      />
      <TextField
        name="email"
        disabled
        value={profileData.email}
        className="w-100 mb-4"
        variant="outlined"
        label={ternaryResolver(profileData.email, "", "Email Address")}
      />
      <TextField
        onChange={handleChange}
        value={profileData.phone}
        name="phone"
        className="w-100 mb-4"
        variant="outlined"
        label="Phone Number"
      />

      <FormControl variant="outlined" className="w-100 mb-4">
        <InputLabel name="country-label">Country</InputLabel>
        <Select
          labelId="country-label"
          name="country"
          label="country"
          value={profileData.country}
          onChange={handleChange}
        >
          <MenuItem value="">Select an country</MenuItem>
          {countries.map(({ name, id }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className="w-100 mb-4">
        <InputLabel name="state-label">State</InputLabel>
        <Select
          labelId="state-label"
          name="state"
          label="state"
          value={profileData.state}
          onChange={handleChange}
          disabled={states.length === 0}
        >
          <MenuItem value="">Select an state</MenuItem>
          {states.map(({ name, id }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isOnEditLocation ? (
        <FormControl variant="outlined" className="w-100 mb-4">
          <SearchLocationInput
            inputStyle="TextField"
            value={profileData.location}
            handleLocationChange={handleLocationChange}
          />
        </FormControl>
      ) : (
        <>
          <span
            onChange={() => {
              setIsOnEditLocation(true);
              alert("true");
            }}
            style={{ marginLeft: "80%" }}
          >
            Change Location
          </span>
          <TextField
            onChange={handleChange}
            value={profileData.location}
            name="location"
            className="w-100 mb-4"
            variant="outlined"
            label="Location"
          />
        </>
      )}

      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChange}
        value={profileData.dob}
        name="dob"
        type="date"
        className="w-100 mb-4"
        variant="outlined"
        label="Date of birth"
      />

      <FormControl variant="outlined" className="w-100 mb-4">
        <InputLabel name="gender-label">Gender</InputLabel>
        <Select
          labelId="gender-label"
          name="gender"
          label="gender"
          onChange={handleChange}
          value={profileData.gender}
        >
          <MenuItem value="">Select a gender</MenuItem>
          <MenuItem value="2">Female</MenuItem>
          <MenuItem value="1">Male</MenuItem>
          <MenuItem value="3">Others</MenuItem>
        </Select>
      </FormControl>

      {/* <FormControl variant="outlined" className="w-100 mb-4">
        <InputLabel name="industry-label">Industry</InputLabel>
        <Select
          labelId="industry-label"
          name="industry"
          label="industry"
          value={profileData.industry}
          onChange={handleChange}
        >
          <MenuItem value="">Select an industry</MenuItem>
          {industries.map(({ title, id, description }) => (
            <MenuItem key={id} value={title}>
              {description}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </EditCard>
  );
};

BasicInfo.propTypes = {
  // industries: arrayPropType.isRequired,
  countries: arrayPropType.isRequired,
  states: arrayPropType.isRequired,
  profileData: objectPropType.isRequired,
  profileLoading: bool.isRequired,
};

export default BasicInfo;
