import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../../../../../components/EmptyState";
import AdminJobCard from "../../../AdminJobCard";
import { SectionLoader } from "../../../../../components/PageLoader";
import { resetAllJobs } from "../../../../../redux/actions/jobActions";
import { getAdminJobs } from "../../../../../redux/actions/businessActions";
import CustomButton from "../../../../../components/CustomButton";
import { ButtonLoader } from "../../../../../components/ButtonLoader";
import { useHistory, useParams } from "react-router";
import HomeModal from "../../../../General/Home/Modal";
import { saveBusinessRef } from "../../../../../redux/actions/businessActions";

function General() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [count, setCount] = useState(0);
  const [section, setSection] = useState("details");
  const history = useHistory();
  const { myJobs } = useSelector(({ business }) => business);
  const adminJobs = myJobs ? myJobs : [];
  const { jobId, refLink } = useParams();
  // console.log("Admin Jobs", adminJobs);
  useEffect(() => {
    setLoading(true);
    page === 1 ? setLoading(true) : setLoadingMore(true);
    resetAllJobs(dispatch);
    Promise.resolve(getAdminJobs(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);

  useEffect(() => {
    const count = adminJobs ? adminJobs.length : 0;
    setCount(count);
  }, [adminJobs]);

  const saveForLater = () => {
    const payload = {
      job_id: jobId,
      status: 1,
      refCode: refLink ? refLink : null,
    };
    const saveLater = async () => {
      await saveBusinessRef(dispatch, page, payload);
    };
    saveLater();
  };

  return (
    <div className="mt-2">
      {loading && <SectionLoader />}
      {!loading && !adminJobs && <EmptyState text="There are no admin job" />}
      {jobId && (
        <HomeModal
          setSection={setSection}
          section={section}
          open={jobId}
          handleClose={() => history.push("/admin/jobs")}
          isBusiness={true}
          saveForLater={saveForLater}
        />
      )}
      {adminJobs && adminJobs && (
        <>
          {adminJobs.map((job, index) => (
            <AdminJobCard
              key={index}
              job={job}
              isBusiness={true}
              isMine={true}
            />
          ))}
        </>
      )}
      {count > 10 * page && (
        <div className="mx-auto mt-3 w-75">
          <CustomButton
            handleClick={() => setPage(page + 1)}
            text={loadingMore ? <ButtonLoader /> : "Load More"}
          />
        </div>
      )}
    </div>
  );
}

export default General;
