import React, { useRef, useState, useEffect } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import icons from "../../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import ReactStars from "react-rating-stars-component";
import {getThankYouCardsAction} 
from "../../../../redux/actions/thankYouAction";  
import { SectionLoader } from "../../../../components/PageLoader";

import { useHistory } from "react-router";
import CustomTextArea from "../../../../components/CustomTextArea";
import CustomInput from "../../../../components/CustomInput";
import cogoToast from "cogo-toast";
import { uploadFile } from "../../../../utils/helpers";
import Grid from '@material-ui/core/Grid';
import './thankyou.css';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
 

const Cards = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [isAdding, setisAdding] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const {thankyou_data} = useSelector((store) => store);
  const [loading, setLoading] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(true);

  const uploadInputRef = useRef(0);
  const uniqueURL = `${process.env.REACT_APP_WEB_URL}`;
  const title = ``;
  const hashtags = ["workbrook"];
 
 

  useEffect(() => {
    Promise.resolve(getThankYouCardsAction(dispatch, 1)).finally(() =>  {
      setTimeout( ()=>{
        setSectionLoading(false);
      }, 500)
 
    }
    );
  }, [dispatch, 1]);

  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
     setAnchorEl(null);
    setOpen(false);
  };


  const handleRefClick = () => {
    uploadInputRef.current.click();
  };
  return (
     <div additionalClass="mt-4 p-4 maxh">
     <h5>Thank You Cards</h5>
      <p>Thank you cards from your colleagues</p>
     
      {sectionLoading && <SectionLoader />}


      <div
                
                style={{
                  padding: "10px",
                  maxHeight: "280px",
                 }}
                className="col-12 col-md-12 list-achievements"
              >

  
                  {thankyou_data 
                  &&
                  thankyou_data.thankyou_data 
                  &&
                  thankyou_data.thankyou_data.map( (item, index)=>{
                    return(
                      <Card
                      additionalClass="mx-md-2x px-4x mb-4x thankyoubg"
                      additionalStyle={{
                        height: "60%",
                        display: "flex",
                        flexFlow: "column",
                        alignItems: "stretch",
                        justifyContent: "space-between",
                      }}
                    >

                
                

                 {/* <Divider/> */}

                      <br/>

                   
                  <div className="d-flex mb-2 align-items-center justify-content-between">


                  <div className="d-flex align-items-center">


                  <span className="d-flex flex-column ml-3x">
                  <h6 className="mb-0 fontbold"></h6> 
                  <span className="co-gray content-width font-b" >{item.comment} </span>
                
                  <br/>
     

                  <Grid container spacing={0}
                  style={{
                    //background:'rgba(58, 110, 212,0.2)',
                    borderRadius:20
                  }}
                  >
                        <Grid item xs={1}>
                          <br/>
                        <img src={item.image_url} 
                      data-demo-src="assets/img/avatars/dan.jpg" 
                      data-user-popover="1" 
                      className="md-dd img-circle"
                      alt="" 
                      data-target="webuiPopover67" />
                        </Grid>
                        <Grid item xs={11}>
                        <br/>
                         
                        <span className="large-font white-txtx">{item.first_name} {item.last_name}(CEO Caroi limited)</span><br/>
                       <span className="co-grayj">{item.date_created}</span>
                      
                       <IconButton  onClick={(e) => handleClick(e)}>
                        <svg width="20" height="15" color="#fff">
                        <use href={`${icons}#dots`} color="#fff"></use>
                        </svg>
                        </IconButton> 
                       
                        </Grid>

                  </Grid>

                 
                  <span className="width-100-white" style={{visibility:'hidden'}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce pretium ultricies elit, non scelerisque lorem malesuada vitae. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;
                  </span>
                  </span>

 
                              
                  </div>
 


                  </div> 


         
                 {/* <Divider/> */}


                  </Card>
                    )
                  })
                      
                                      }
 
              
            <div>
            <br/><br/><br/><br/><br/><br/>
            </div>
              

<Menu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: "15rem",
                        marginTop: "0.2rem",
                        borderRadius: "1rem",
                        boxShadow: "0px 0px 5px -3px rgba(0,0,0,0.2)",
                      },
                    }}
                  >
                    <MenuItem
                      className="px-4 py-2 medium-text"
                    
                  
                    >
                     <FacebookShareButton
                      url={uniqueURL}
                      quote={title}
                      className="mr-2"
                      hashtag="#workbrook #job"
                    >
                    <FacebookIcon size={32} round /> Share on Facebook
                    </FacebookShareButton>
                    </MenuItem>
                    

                    <MenuItem
                      className="px-4 py-2 medium-text"
                    
                  
                    >
                    <TwitterShareButton
                      url={uniqueURL}
                      title={title}
                      className="mr-2"
                      hashtags={hashtags}
                    >
                      <TwitterIcon size={32} round />Share on Twitter
                    </TwitterShareButton>
                    </MenuItem>

                    <MenuItem
                      className="px-4 py-2 medium-text"
                      onClick={handleClose}
                    >
                    <LinkedinShareButton url={uniqueURL} className="mr-2">
                    <LinkedinIcon size={32} round /> Share on LinkedIn
                  </LinkedinShareButton>
                    </MenuItem>
 
                    <Divider />
                    
                  </Menu>
                 
                 
      </div>

 
     </div>
 
   );
};

export default Cards;
