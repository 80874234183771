import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import SubMenu from "../../../../components/SubMenu";
import Layout from "../../../../layout";
import BasicInfo from "./BasicInfo";
import ProfileLayout from "../Layout";
import About from "./About";
import Education from "./Education";
import Skills from "./Skills";
import Resume from "./Resume";
import { getIndustryAction } from "../../../../redux/actions/industryActions";
import { getProfileAction } from "../../../../redux/actions/profileActions";
import { getCountryAction } from "../../../../redux/actions/countryActions";
import { getStateAction } from "../../../../redux/actions/stateActions";
import { PageLoader } from "../../../../components/PageLoader";
import {
  getAllSkillsAction,
  getMySkillsAction,
} from "../../../../redux/actions/skillsAction";
import {
  getAllEducationAction,
  getSingleEducationAction,
} from "../../../../redux/actions/educationActions";
import Experience from "./Experience";

const subMenuOptions = [
  ["basic info", "/profile/edit/basic"],
  ["about", "/profile/edit/about"],
  ["education", "/profile/edit/education"],
  ["skills", "/profile/edit/skills"],
  ["experience", "/profile/edit/experience"],
  ["resume", "/profile/edit/resume"],
];

const EditProfile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { educationId } = useParams();
  const { industry, profile, country, state, education, skill } = useSelector(
    (store) => store
  );
  const { industries } = industry;
  const { profileData, profileLoading } = profile;
  const { educationData, educationLoading } = education;
  const { countries } = country;
  const { states } = state;
  const { allSkills, skillLoading, mySkills } = skill;

  const basicInfoProps = {
    industries,
    profileData,
    countries,
    states,
    profileLoading,
  };

  const aboutProps = {
    profileData,
    profileLoading,
  };

  const educationProps = {
    educationData,
    educationLoading,
    isEdit: !!educationId,
    educationId: educationId ?? "",
  };

  const skillProps = {
    allSkills,
    skillLoading,
    mySkills,
  };

  useEffect(() => {
    Promise.resolve(getProfileAction(dispatch)).then(({ payload }) => {
      const countryId = payload?.country;
      const promises = [
        getIndustryAction(dispatch),
        getCountryAction(dispatch),
        getAllSkillsAction(dispatch),
        getMySkillsAction(dispatch),
        getAllEducationAction(dispatch),
      ];
      if (countryId) {
        promises.push(getStateAction(countryId, dispatch));
      }
      Promise.all(promises).finally(() => {
        setIsLoading(false);
        console.log(" =====educationDataValue::: " + educationId);
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (educationId) {
      Promise.resolve(getSingleEducationAction(educationId, dispatch)).finally(
        () => {
          setIsLoading(false);
        }
      );
    }
  }, [dispatch, educationId]);

  return (
    <Layout>
      {isLoading ? (
        <PageLoader />
      ) : (
        <ProfileLayout>
          <div className="section-header mt-5">
            <p className="mb-0">Edit Profile</p>
          </div>
          <div className="d-flex mt-3">
            <div className="w-25">
              <SubMenu menuOptions={subMenuOptions} />
            </div>
            <div className="w-75">
              {pathname.includes("basic") && <BasicInfo {...basicInfoProps} />}
              {pathname.includes("about") && <About {...aboutProps} />}
              {pathname.includes("education") && (
                <Education {...educationProps} />
              )}
              {pathname.includes("skills") && <Skills {...skillProps} />}
              {pathname.includes("resume") && <Resume />}
              {pathname.includes("experience") && <Experience />}
            </div>
          </div>
        </ProfileLayout>
      )}
    </Layout>
  );
};

export default EditProfile;
