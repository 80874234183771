import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmptyState from "../../../../components/EmptyState";
import { SectionLoader } from "../../../../components/PageLoader";
import { getPendingAdminRequests } from "../../../../redux/actions/profileActions";
// import { ButtonLoader } from "../../../../components/ButtonLoader";
import RequestCard from "./RequestCard";

function Requests() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [count, setCount] = useState(0);

  const { requests } = useSelector(({ profile }) => profile);

  // console.log("REQUESTS", requests);

  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getPendingAdminRequests(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);

  const refreshList = () => {
    getPendingAdminRequests(dispatch, page);
  };

  return (
    <div className="mt-2">
      {loading && !requests && <SectionLoader />}
      {!loading && !requests && (
        <EmptyState text="There are no pending request" />
      )}
      {requests && (
        <>
          {requests.map((request, index) => (
            <RequestCard
              key={index}
              profile={request}
              refreshList={refreshList}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default Requests;
