import httpRequest from "../../ajax";
import {
  GET_ALL_SKILLS,
  CREATE_SKILL,
  GET_MY_SKILLS,
  GET_SKILL_BY_ID,
  ERROR,
} from "../actionTypes";

/**
 * @description get all skills action
 * @function getAllSkillsAction
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const getAllSkillsAction = (dispatch) => {
  return httpRequest("get", "/settings/profile/skills/list/1/5000")
    .then(({ data: { data } }) => {
      // console.log("DDData", data);
      const action = { type: GET_ALL_SKILLS, payload: data[0] };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

/**
 * @description create skill action
 * @function createSkillAction
 * @param {object} requestObject - request data
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const createSkillAction = (requestObject, dispatch) => {
  return httpRequest("post", "/settings/profile/skills/create", requestObject)
    .then(({ data: { data } }) => {
      const action = { type: CREATE_SKILL, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

/**
 * @description get my skill action
 * @function getMySkillsAction
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const getMySkillsAction = (dispatch) => {
  return httpRequest("get", "/settings/profile/skills")
    .then(({ data: { data } }) => {
      const action = {
        type: GET_MY_SKILLS,
        payload: Array.isArray(data) ? data[0] : data,
      };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

/**
 * @description get skill by ID action
 * @function getSkillByIdAction
 * @param {string} skillId - skill id
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const getSkillByIdAction = (skillId, dispatch) => {
  return httpRequest("get", `/settings/profile/skills/${skillId}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_SKILL_BY_ID, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};
