import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Divider, IconButton } from "@material-ui/core";
import CustomButton from "../../../../components/CustomButton";
import icons from "../../../../assets/icons/icon-collection.svg";
import moment from "moment";
import Card from "../../../../components/Card";
import ViewReferral from "../ViewReferral";
// import JobDetails from "../../../../layout/NewPost/JobDetails";
import ViewDetails from "../../../General/Home/Modal/ViewDetails";
import EmptyState from "../../../../components/EmptyState";
import { SectionLoader } from "../../../../components/PageLoader";

import { getReferralJobs } from "../../../../redux/actions/jobActions";
import { getSentReferralJobs } from "../../../../redux/actions/businessActions";

const Sent = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [, setLoadingMore] = useState(false);
  const [openReferral, setOpenReferral] = useState(false);
  const [openJobDetail, setOpenJobDetail] = useState(false);
  const [section] = useState("details");
  const [jobId] = useState(null);
  const [applicants, setApplicants] = useState([]);
  const [page, setPage] = useState(1);

  const [job, setJob] = useState();

  const { referralJobs } = useSelector(({ job }) => job);
  // const { jobs, count } = jobsData;

  // console.log("Job", jobs);

  const handleViewReferral = (applicants) => {
    setApplicants(applicants);
    setOpenReferral(true);
    // console.log("clicked...");
  };

  const closeViewReferral = () => {
    setOpenReferral(false);
  };

  const handleViewJobDetails = (jobdetails) => {
    setJob(jobdetails);
    setOpenJobDetail(true);
  };

  const closeJobDetail = () => {
    // setJob(null);
    setOpenJobDetail(false);
  };

  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getSentReferralJobs(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);

  useEffect(() => {
    // console.log("referralJobs", referralJobs);
  }, [referralJobs]);

  useEffect(() => {
    // console.log("jobId", jobId);
  }, [jobId]);

  return (
    <div className="mt-2">
      {loading && !referralJobs?.[0] && <SectionLoader />}
      {!loading && !referralJobs?.[0] && (
        <EmptyState text="There are no sent referrals" />
      )}
      {referralJobs?.length > 0 && referralJobs.map((referral, index) => (
        <Card noPadding additionalClass="pt-3 mt-3" key={index}>
          <div className="d-flex justify-content-between w-100 px-4">
            <svg width="57" height="55">
              <use href={`${icons}#pr`}></use>
            </svg>
            <div className="inner">
              <div className="w-100 d-flex justify-content-between">
                <span className="d-flex flex-column mb-2">
                  <b>{referral.role_title}</b>
                  <span>{referral.job_level.title}</span>
                </span>
                <span className="co-primary thintext">
                  {moment(referral.date_created).fromNow()}
                </span>{" "}
              </div>
              <div className="d-flex mb-2">
                <span>
                  <svg width="17" height="16" className="mr-2">
                    <use href={`${icons}#briefcase`}></use>
                  </svg>
                  {referral.job_type}
                </span>
                <span>
                  <svg width="14" height="14" className="mr-2">
                    <use href={`${icons}#mappin`}></use>
                  </svg>
                  {referral.location}
                </span>
              </div>
              <div className="mb-3">
                <svg width="17" height="17" className="mr-2">
                  <use href={`${icons}#filetext`}></use>
                </svg>
                {referral.about_company}
              </div>
            </div>
          </div>
          <Divider />
          <div className="d-flex">
            <CustomButton
              additionalClass="py-3"
              text="Job Details"
              borderColor="#fff"
              backgroundColor="#fff"
              color="#3a6ed4"
              handleClick={() => handleViewJobDetails(referral)}
            />
            <Divider orientation="vertical" flexItem />
            <CustomButton
              additionalClass="py-3"
              text="View Referrals"
              borderColor="#fff"
              backgroundColor="#fff"
              color="#3a6ed4"
              handleClick={() => handleViewReferral(referral.applicants)}
            />
          </div>
        </Card>
      ))}
      <ViewReferral
        open={openReferral}
        handleClose={closeViewReferral}
        applicants={applicants}
      />
      <Dialog
        open={openJobDetail}
        onClose={closeJobDetail}
        aria-labelledby="View details"
        PaperProps={{
          style: {
            width: "30rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        {job && (
          <>
            <div className="d-flex justify-content-between align-items-centter">
              <DialogTitle id="details-dialog-title">
                <div>
                  <h2 className="mb-0">{job.role_title}</h2>
                  <p className="mb-0 co-darkgray">{job.campany_name}</p>
                </div>
              </DialogTitle>
              <div className="close-button">
                <IconButton>
                  <svg width="30" height="30" className="rounded-circle">
                    <use href={`${icons}#close`}></use>
                  </svg>
                </IconButton>
              </div>
            </div>
            <div className="position-relative sticky">
              <div className="bg-white w-100 position-absolute">
                <div className="d-flex px-4 justify-content-between">
                  <button className="co-white px-4 py-2 fw-bold border-0 bg-primary rounded">
                    Job Description
                  </button>
                  <button className="co-white px-4 py-2 fw-bold border-0 bg-darkgrey rounded">
                    Additional Documents
                  </button>
                </div>
                <div className="d-flex justify-content-center my-3">
                  <span className="mr-2">
                    <svg width="17" height="16" className="mr-1">
                      <use href={`${icons}#briefcase`}></use>
                    </svg>
                    {job.job_level.title}
                  </span>
                  <span className="mr-2">
                    <svg width="14" height="14" className="mr-1">
                      <use href={`${icons}#mappin`}></use>
                    </svg>
                    {job.location}
                  </span>
                  <span className="mr-2">
                    <svg width="24" height="17" className="mr-1">
                      <use href={`${icons}#persons`}></use>
                    </svg>
                    {job.no_of_staff} workers
                  </span>
                </div>
                <Divider />
              </div>
            </div>
          </>
        )}
        <DialogContent>
          <ViewDetails job={job} section={section} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Sent;
