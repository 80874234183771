/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import icons from "../../../../assets/icons/icon-collection.svg";
import Card from "../../../../components/Card";
// import Placeholder from "../../../../assets/icons/placeholder.svg";
import CustomButton from "../../../../components/CustomButton";
import { getRecruiterJobsShortlisted } from "../../../../redux/actions/recruiterAction";
import EmptyState from "../../../../components/EmptyState";
import { SectionLoader } from "../../../../components/PageLoader";

const General = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { recruiterJobsShortlisted } = useSelector(
    ({ recruiter }) => recruiter
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.resolve(getRecruiterJobsShortlisted(dispatch)).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);

  const showApplicants = (jobid) => {
    const url = `/recruiter/shortlist/applicants/${jobid}`;
    history.push(url);
  };

  return (
    <div className="my-4 w-100">
      {loading && <SectionLoader />}
      {!loading &&
        recruiterJobsShortlisted &&
        recruiterJobsShortlisted.length == 0 && (
          <EmptyState text="No shortlist found" />
        )}
      {!loading &&
        recruiterJobsShortlisted &&
        recruiterJobsShortlisted.map((shortlist) => (
          <Card
            key={shortlist.job_id}
            noPadding
            additionalClass="w-100 p-0 mb-4"
          >
            <div className="d-flex align-items-center status-considered px-3 py-2">
              <span className="mr-2">
                <svg width="13" height="13">
                  <use href={`${icons}#elipse`}></use>
                </svg>
              </span>
              {shortlist.campany_name}
            </div>
            <div className="d-flex flex-column mt-2 mx-4">
              {shortlist.role_title}
              <span className="smalltext co-darkgray">
                {shortlist.campany_name}
              </span>
            </div>
            {/* <div className="mx-4 py-3">
              <div>
                <p className="small-text thin-text mb-2">
                  Shortlisted Applicants
                </p>
                <img
                  className="pr-1"
                  width="30px"
                  src={Placeholder}
                  alt="avatar"
                />
              </div>
            </div> */}
            <div className="w-100 d-flex justify-content-end">
              <div className="w-25 pb-3 d-flex align-items-center mr-3">
                <span className="w-100">
                  <CustomButton
                    text="View"
                    color="#fff"
                    backgroundColor="#3A6ED4"
                    handleClick={() => showApplicants(shortlist.job_id)}
                  />
                </span>
              </div>
            </div>
          </Card>
        ))}
    </div>
  );
};

export default General;
