import httpRequest from "../../ajax";
import {
  GET_EXPERIENCE,
  CREATE_EXPERIENCE,
  GET_EXPERIENCES,
  ERROR,
  UPDATE_EXPERIENCE,
} from "../actionTypes";

export const getAllExperienceAction = (dispatch) => {
  return httpRequest("get", "/settings/profile/experience")
    .then(({ data: { data } }) => {
      const action = { type: GET_EXPERIENCES, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const getSingleExperienceAction = (experienceId, dispatch) => {
  return httpRequest("get", `/settings/profile/experience/user/${experienceId}`)
    .then(({ data }) => {
      const action = { type: GET_EXPERIENCE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const updateExperienceAction = (experienceId, requestData, dispatch) => {
  return httpRequest(
    "post",
    `/settings/profile/experience/${experienceId}/update`,
    requestData
  )
    .then(({ data: { data } }) => {
      getAllExperienceAction(dispatch);
      const action = { type: UPDATE_EXPERIENCE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const createExperienceAction = (requestData, dispatch) =>
  httpRequest("post", "/settings/profile/experience/create", requestData)
    .then(({ data: { data } }) => {
      const action = { type: CREATE_EXPERIENCE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
