/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { resetAllJobs } from "../../../redux/actions/jobActions";
import { getBusinessJobsAction } from "../../../redux/actions/businessActions";
import HomeModal from "../../General/Home/Modal";
import { SectionLoader } from "../../../components/PageLoader";
import EmptyState from "../../../components/EmptyState";
import CustomButton from "../../../components/CustomButton";
import AdminJobCard from "../AdminJobCard";
import "../../General/Home/index.scss";
import { BUSINESS_SEARCH_JOBS } from "../../../redux/actionTypes";
import { saveBusinessRef } from "../../../redux/actions/businessActions";

export default function SearchResult() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [page] = useState(1);
  const [loading, setLoading] = useState(false);
  const [setLoadingMore] = useState(false);
  const [section, setSection] = useState("details");
  const { jobs, searchedTerm } = useSelector(({ business }) => business);
  const { userData } = useSelector(({ auth }) => auth);
  const refCode = userData.refCode;
  const company_id = userData.company_id ? userData.company.id : "";
  const { jobId, refLink } = useParams();
  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    resetAllJobs(dispatch);
    Promise.resolve(getBusinessJobsAction(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);

  const handleClose = () => {
    setSection("details");
    setAnchorEl(null);
  };

  const saveForLater = () => {
    const payload = {
      job_id: jobId,
      status: 1,
      refCode: refLink ? refLink : null,
    };
    const saveLater = async () => {
      await saveBusinessRef(dispatch, page, payload);
    };
    saveLater();
  };
  return (
    <>
      {jobs && jobs[0] && (
        <div
          style={{
            display: "flex",
            justifyContent: "between",
            alignItems: "center",
            flexFlow: "row nowrap",
          }}
        >
          <p
            style={{
              textAlign: "left",
              padding: "20px",
              fontSize: "20px",
              minWidth: "80%",
            }}
          >{`Results for ${searchedTerm}`}</p>
          <CustomButton
            backgroundColor="#3A6ED4"
            color="#fff"
            alt="add post"
            text="Back"
            additionalClass="my-3"
            handleClick={(e) => {
              e.preventDefault();
              dispatch({
                type: BUSINESS_SEARCH_JOBS,
                payload: { data: [], term: "" },
              });
            }}
          />
        </div>
      )}
      <div className="row mt-3 d-flex">
        {jobId && (
          <HomeModal
            setSection={setSection}
            section={section}
            open={jobId}
            handleClose={() => history.push("/home")}
            isBusiness={true}
            saveForLater={saveForLater}
          />
        )}
        {loading && <SectionLoader />}
        {!loading && !jobs[0] && (
          <div className="d-flex align-items-center justify-content-center">
            <EmptyState text={`There are no jobs for ${searchedTerm}`} />
          </div>
        )}
        {jobs && jobs[0] && (
          <>
            {jobs.map((job, index) => (
              <div className="col-12 col-md-6">
                <AdminJobCard
                  key={index}
                  job={job}
                  handleClose={handleClose}
                  anchorEl={anchorEl}
                  setSection={setSection}
                  companyId={company_id}
                  refCode={refCode}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}
