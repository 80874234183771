import thunk from "redux-thunk";
import logger from "redux-logger";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers";

/**
 * @function createAppStore
 * @description create app store
 * @returns {Object} - store
 */
export default () => {
  const middlewares = [thunk];

  const initialState = {};

  if (process.env.NODE_ENV === "development") {
    middlewares.push(logger);
  }

  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
};
