import React, { useRef,useEffect, useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import icons from "../../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import ReactStars from "react-rating-stars-component";

import { SectionLoader } from "../../../../components/PageLoader";

import { useHistory } from "react-router";
import CustomTextArea from "../../../../components/CustomTextArea";
import CustomInput from "../../../../components/CustomInput";
import cogoToast from "cogo-toast";
import { uploadFile } from "../../../../utils/helpers";
import Grid from '@material-ui/core/Grid';
import './vote.css';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
 
import {
  getActivePollsAction,
  voteNominationAction
} from "../../../../redux/actions/nominationAction";

const Vote = () => {
  const history = useHistory();
  const dispatch = useDispatch(); 
  const [sectionLoading, setSectionLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [stateRecognition, setStateRecognition] = useState({
    "poll_id" : "",
    "option_id" : ""
   });

  const {get_active_poll} = useSelector((store) => store);

   
  useEffect(() => {
      Promise.resolve(getActivePollsAction(dispatch, null)).finally(() => {
      
        setTimeout( ()=>{
          setSectionLoading(false);
        }, 1000)
      
      } 
      );
    }, [dispatch, 1]);

   

   const submitPollVote = ()=>
   {

    setLoading(true)
     const promise =  voteNominationAction(dispatch, {
      ...stateRecognition,
    });
    Promise.resolve(promise).finally(() => {
          setLoading(false)
          setTimeout( ()=>{
            //window.location.reload(false);
          },2000)
         
    });
    
   }

 

  return (
     <div additionalClass="mt-4 p-4 maxh">
     <h5>Ongoing  Polls</h5>
      
       <div
                
                style={{
                  padding: "10px",
                  maxHeight: "280px",
                }}
                className="col-12 col-md-12 list-achievements"
              >

   {/* <button className="btnc" 
        onClick={
          ()=>{
            setisAdding(true)
          }
        }
        >Add New Achievement</button> */}
      <div className="row">
        
      {sectionLoading && <SectionLoader />}
    {
      get_active_poll && get_active_poll.get_active_poll 
      &&
      get_active_poll.get_active_poll.map( (item, index)=>{
        return(

      <Card
      additionalClass="col-md-12"
      additionalStyle={{
      height: "100%",
      display: "flex",
      marginBottom:'5%',
      flexFlow: "column",
      alignItems: "stretch",
      justifyContent: "space-between",
       }}>

               
    <div className="d-flex mb-2 align-items-center justify-content-between">
    <div className="d-flex align-items-center">
     <div className="panel panel-primary">
     <div className="panel-heading">
        <h6 className="panel-title fontbold">
            <span className="glyphicon glyphicon-hand-right"></span>
         {item.title}
        
          <br/>

            </h6>
            <span className="co-gray">&nbsp;Voting Ends: {item.end_date}</span>
    </div>
    <div className="panel-body">
        <ul className="list-group">
           
        {
         item.options.map((item2, index2)=>{
                   return(
            <li className="list-group-item" key={index2}>
                <div className="checkbox">
                    <label className="co-gray">
                       <input type="radio" name={'emp1_'+item.id} value=""
                       onClick={()=>{
                        setStateRecognition({ ...stateRecognition, option_id: item2.id , poll_id:item.id});
                       }}
                       />
                        &nbsp;&nbsp; &nbsp;
                        {
                          item.option_type == "others" ?
                        <img src={item2.image_url} 
                          className="md-dd img-circle"
                        data-demo-src={item2.image_url}  
                        data-user-popover="5" alt="" 
                        data-target="webuiPopover20" />
                        :
                          <></>
                          }
                        {
                          item2.option_type == "others" ?
                        <span className="co-gray">{item2.option} </span> 
                          :
                       <span>  {item2.last_name} {item2.first_name} </span> 

                        }
            
                    </label>
                </div>
            </li>
                   )
         })
                 
          }
           
        </ul>
    </div>
    <div className="panel-footer text-center">
      {
        item.has_voted?
        <button type="button" 
         disabled
       className="btn btn-primary btn-block btn-sm">You have voted. Result will be out on {item.end_date}</button>
        :
        <button type="button" 
        onClick={
         ()=>{
           submitPollVote();
         }
       }
       className="btn btn-primary btn-block btn-sm">Submit Vote</button>

        
      }
    </div>
    </div>

              
      </div>
      </div> 
       
      
    </Card>

        )
      })
   

      

        }
 
      </div>

       
      </div>





     
     </div>
 
   );
};

export default Vote;
