import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import "./organogram.css";

const Organogram = ({ roles, companyStaff }) => {
  console.log("Roles:", roles);
  console.log("Company Staff:", companyStaff);

  const organizeRoles = (roles) => {
    const roleMap = {};
    const hierarchy = [];

    // Ensure roles is an array before processing
    if (!Array.isArray(roles)) {
      console.warn("Expected roles to be an array, but got:", roles);
      return hierarchy; // Return an empty array if roles is not an array
    }

    roles.forEach((role) => {
      roleMap[role.id] = { ...role, children: [] };
    });

    roles.forEach((role) => {
      if (role.parent_role_id) {
        if (roleMap[role.parent_role_id]) {
          roleMap[role.parent_role_id].children.push(roleMap[role.id]);
        }
      } else {
        hierarchy.push(roleMap[role.id]);
      }
    });

    return hierarchy;
  };

  const getStaffNameByOccupantId = (occupantid) => {
    if (!companyStaff || companyStaff.length === 0) return null;

    console.log("Looking for occupant ID:", occupantid);

    const staff = companyStaff.find((staffMember) => {
      console.log("Comparing with staff member ID:", staffMember.id);
      return Number(staffMember.id) === Number(occupantid);
    });

    return staff ? staff.username : null;
  };

  const renderTreeNodes = (role) => {
    const staffName = getStaffNameByOccupantId(role.occupant_id);
    console.log("Staff name found:", staffName);
    return (
      <TreeNode
        label={
          <div className="my-label">
            {staffName ? (
              <div className="staff-name">{staffName}</div>
            ) : (
              <div className="staff-name">Vacant</div>
            )}
            <div className="role-name">{role.name}</div>
          </div>
        }
        key={role.id}
      >
        {role.children.length > 0 &&
          role.children.map((childRole) => renderTreeNodes(childRole))}
      </TreeNode>
    );
  };

  const hierarchicalRoles = organizeRoles(roles);

  return (
    <div>
      <h1>Organizational Chart</h1>

      {hierarchicalRoles.length > 0 ? (
        <Tree
          lineWidth={"2px"}
          lineColor={"#3A6ED4"}
          lineBorderRadius={"10px"}
          label={<div style={{ fontWeight: "bold", fontSize: "14px" }}>Company Structure</div>}
        >
          {hierarchicalRoles.map((role) => renderTreeNodes(role))}
        </Tree>
      ) : (
        <p>No roles available</p>
      )}
    </div>
  );
};

export default Organogram;
