import React, { useRef, useState } from "react";
// import { Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import CustomButton from "../../../components/CustomButton";
import { uploadFile } from "../../../utils/helpers";
import { array, func, object } from "prop-types";
import CustomInput from "../../../components/CustomInput";

const AddDocuments = ({ addDocLink, job, addDocLinkObject, linksObject }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [link, setLink] = useState("");
  const uploadInputRef = useRef(0);

  const addLink = () => {
    addDocLink(link);
    addDocLinkObject({ link, name: null });
    setLink("");
  };

  const handleChangeUpload = ({ target: { files } }) => {
    if (files && files[0]) {
      setIsUploading(true);

      Promise.resolve(
        uploadFile(
          files[0],
          "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
        )
      )
        .then(({ data, success }) => {
          if (success) {
            addDocLink(data);
            addDocLinkObject({ link: data, name: files[0].name });
          } else {
            cogoToast.error("Unsuccessful document upload", {
              hideAfter: 4,
              position: "top-center",
            });
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  const handleRefClick = () => {
    uploadInputRef.current.click();
  };
  return (
    <div>
       <div className="rowx">
 
 
        <div className="w-50  d-flex align-items-end mb-3">
          <span className="w-75 mr-2">
            <CustomInput
              id="link"
              label="Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              placeholder="http://www.example.com"
            />
          </span>
          <span className="w-25" style={{ marginBottom: "13px" }}>
            <CustomButton
              handleClick={addLink}
              color="#3A6ED4"
              borderColor="#3A6ED4"
              backgroundColor="#fff"
              text="Add Link"
            />
          </span>
        </div>
          <div className="col-12 col-md-6">
          <input
            ref={uploadInputRef}
            type="file"
            onChange={handleChangeUpload}
            style={{ display: "none" }}
          />

          <CustomButton
            backgroundColor="#FFF"
            color="#3A6ED4"
            text="Click to Select Document"
            handleClick={handleRefClick}
            loading={isUploading}
          />
          
          <div className="pb-5">
        {job.additional_files && job.additional_files[0] ? (
          <div className="d-flex flex-column">
            {/* {job.additional_files.map((fileLink, index) => (
              <a
                className="doc-link bg-primary mid-radius py-3 my-1 w-100 text-left p-2"
                key={index.toString()}
                href={fileLink}
                target="_blank"
                rel="noreferrer"
              >
                <b>{fileLink}</b>
              </a>
            ))} */}
            {linksObject.map((fileLink, index) => (
              <a
                className="doc-link bg-primary mid-radius py-3 my-1 w-100 text-left p-2"
                key={index.toString()}
                href={fileLink.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>{fileLink.name ? fileLink.name : fileLink.link}</b>
              </a>
            ))}
          </div>
        ) : (
          <span>No document added</span>
        )}
      </div>
     
 
          </div>
           
  

      </div>
    </div>
  );
};

AddDocuments.propTypes = {
  addDocLink: func.isRequired,
  job: object.isRequired,
  addDocLinkObject: func.isRequired,
  linksObject: array,
};

export default AddDocuments;
