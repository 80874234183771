import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import Card from "../../../components/Card";
import CustomButton from "../../../components/CustomButton";
import SearchInput from "../../../components/CustomSearchInput";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import { getInviteAction } from "../../../redux/actions/inviteAction";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../../../components/EmptyState";
import { ButtonLoader } from "../../../components/ButtonLoader";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

const subMenuOptions = [
  ["Unique Invite Link", "/invite/link"],
  ["invitees", "/invite/invitees"],
];

const Invite = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [copied, setCopied] = useState(false);
  const [page, setPage] = useState(1);
  const [inviteCode, setInviteCode] = useState("");
  const textAreaRef = useRef(null);
  const { inviteesData } = useSelector(({ invite }) => invite);
  const { invitees, count } = inviteesData;
  const [loadingMore, setLoadingMore] = useState(false);
  const [search, setSearch] = useState("");
  // const userData = sessionStorage.getItem("userData");
  const {
    userData: { first_name, last_name, invitation_code },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    setLoadingMore(true);
    Promise.resolve(getInviteAction(dispatch, page)).then(() =>
      setLoadingMore(false)
    );
  }, [dispatch, page]);

  // useEffect(() => {
  //   const { invitation_code } = JSON.parse(userData);
  //   setInviteCode(invitation_code);
  // }, [userData]);

  useEffect(() => {
    // const { invitation_code } = JSON.parse(userData);
    setInviteCode(invitation_code);
  }, [invitation_code]);
  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand("copy");
    setCopied(true);
  };

  const uniqueURL = `${process.env.REACT_APP_WEB_URL}/signup/${inviteCode}`;
  const title = `${first_name} ${last_name} will like you to join workbrook. Please follow the link to download workbrook`;
  const hashtags = ["workbrook"];

  const displaySection = () => {
    if (pathname.includes("invitees")) {
      return (
        <div className="m-3">
          {!invitees[0] && <EmptyState text="you have not invited anyone" />}
          {invitees && invitees[0] && (
            <div>
              <SearchInput
                handleChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              {invitees
                .filter((invitee) => {
                  if (
                    search &&
                    !JSON.stringify(invitee)
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((invitee) => (
                  <span
                    key={invitee.id}
                    className="d-flex my-4 align-items-center"
                  >
                    <img src={invitee.image_url} width="50" alt="avatar" />
                    <span className="d-flex flex-column ml-3">
                      <h6 className="mb-0">
                        {invitee.first_name} {invitee.last_name}
                      </h6>
                      <span className="co-gray">@{invitee.username}</span>
                    </span>
                  </span>
                ))}
              {count > 10 * page && (
                <div className="mx-auto mt-3 w-75">
                  <CustomButton
                    handleClick={() => setPage(page + 1)}
                    text={loadingMore ? <ButtonLoader /> : "Load More"}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h5 className="text-center mt-5">YOUR UNIQUE URL</h5>
          <p className="mx-auto w-75 text-center my-5">
            <textarea
              ref={textAreaRef}
              value={uniqueURL}
              className="borderless w-100 copy-link"
            />
          </p>
          {!copied && (
            <div className="w-75 my-3 d-flex flex-column flex-md-row mx-auto">
              <CustomButton
                backgroundColor="#3A6ED4"
                color="#fff"
                text="Copy Link"
                handleClick={copyToClipboard}
                additionalClass="m-2"
              />
              {/* <CustomButton
                backgroundColor="#fff"
                color="#808498"
                text="Share"
                additionalClass="m-2"
                borderColor="#808498"
              /> */}
            </div>
          )}
          {copied && (
            <div className="w-75 my-3 d-flex flex-column flex-md-row mx-auto">
              <CustomButton
                borderColor="#3A6ED4"
                backgroundColor="#fff"
                color="#3A6ED4"
                text="Link Copied"
                additionalClass="m-2"
              />
              {/* <CustomButton
                backgroundColor="#69CA87"
                color="#fff"
                text="Share"
                additionalClass="m-2"
                borderColor="#69CA87"
              /> */}
            </div>
          )}
          <div className="text-center mb-4 mt-4">
            <h5 className="mb-2">Click to share your Unique URL</h5>
            <div>
              <FacebookShareButton
                url={uniqueURL}
                quote={title}
                className="mr-2"
                hashtag="#workbrook #job"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <TwitterShareButton
                url={uniqueURL}
                title={title}
                className="mr-2"
                hashtags={hashtags}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <LinkedinShareButton url={uniqueURL} className="mr-2">
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>

              <EmailShareButton
                url={uniqueURL}
                subject="You have been invited to join workbrook"
                body={title}
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div>
       
       
        </div>
      );
    }
  };

  return (
    <Layout>
      <div className="d-flex flex-column flex-md-row mt-3">
        <div className="sub-menu-container">
          <BackButton />
          <SubMenu menuOptions={subMenuOptions} includeSVG />
        </div>
        <div className="main-content ml-md-4">
          <Card className="mt-5">{displaySection()}</Card>
        </div>
      </div>
    </Layout>
  );
};

export default Invite;
