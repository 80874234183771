import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  // useHistory
} from "react-router-dom";
import Card from "../../components/Card";
import {
  getAllExpertCategoryAction
} from "../../redux/actions/expertCategoryAction";

import blueWBlogo from "../../assets/icons/blueWBlogo.svg";
import Illustration from "../../assets/images/workbrook-league.png";
import CustomBadge from "../../components/CustomBadge";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { getLeagueAction } from "../../redux/actions/leagueAction";
import { getMyRecruiterData } from "../../redux/actions/recruiterAction";
import { openRecruiterModal } from "../../redux/actions/demoActions";
import icons from "../../assets/icons/icon-collection.svg";
import {
  getAllExpertAction
} from "../../redux/actions/expertCategoryAction";


import SlidingPane from "react-sliding-pane";

import './expert.css';

const ExpertBoard = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const [canPost, setCanPost] = useState(true);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [expertCategory, setExpertCategory] = useState("");
  const { league } = useSelector(({ league }) => league);

  const [expertdetails, setExpertDetails] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const {
    userData: { account_type, isRecruiter },
  } = useSelector((store) => store.auth);

  const {get_all_expert_categories} = useSelector((store) => store);
  const {get_all_expert} = useSelector((store) => store);

  const { myRecruiterData } = useSelector(({ recruiter }) => recruiter);

  useEffect(() => {
    if (isRecruiter === 0 && account_type === "business") {
      setCanPost(false);
    }
  }, [dispatch]);

    
  useEffect(() => {
    getAllExpertAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getAllExpertCategoryAction(dispatch);
  }, [dispatch]);
  
 
  useEffect(() => {
    getLeagueAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getMyRecruiterData(dispatch);
  }, [dispatch]);

  return (
    <>
    { !canPost ?
    <Card>
      {!pathname.includes("league/list") && (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <span className="d-flex align-items-center">
               <h6 className="mb-0 ml-2">Talk to an Expert</h6>
            </span>
           </div>

          
           {
            get_all_expert_categories &&

            get_all_expert_categories.get_all_expert_categories
            &&
            get_all_expert_categories.get_all_expert_categories.map( (item, index)=>{
              return(
                    <div
                    onClick={()=>{
                      setExpertDetails({ isPaneOpen: true });
                      setExpertCategory(item.title)
                    }}
                      className="d-flex border-list-link justify-content-between align-items-center mt-3">
                     <span className="d-flex align-items-center">
                        <img src={item.icon} className="icon-img" />
                       <span className="d-flex flex-column ml-3 small-text">
                         <h6 className="mb-0">
                           <a href="#">
                           {item.title}
                           </a>
                          
                         </h6>
                       </span>
                     </span>
                     
                   </div>
              )
            }
            )
        }
                   

 
        </div>
      )}


      
    <SlidingPane
          className="sliderx"
          overlayClassName="some-custom-overlay-class"
          isOpen={expertdetails.isPaneOpen}
          title={expertCategory}
          subtitle={"Talk to an Expert"}
          width="100%"
          from={'bottom'}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setExpertDetails({ isPaneOpen: false });
          }}
        >
      
          <div className="row"> 
   
         {   get_all_expert &&

            get_all_expert.get_all_expert
            &&
            get_all_expert.get_all_expert.map( (item, index)=>{
              return(
              <div className="col-sm-3">
                    <div className="business-card">
                        <div className="media">
                            <div className="media-left">
                                {/*<img className="media-object img-circle profile-img" 
                                src="" /> */}
                            </div>
                            <div className="media-body">
                                <h2 className="media-heading">{item.fullname}</h2>
                                <div className="mail"><a href={"mailto:"+item.email}>{item.email}</a> </div>
 
                            </div>
                        </div>
                    </div>
                </div>
              )
            }
            )
          }
          </div>
         

    </SlidingPane>

      
 
    </Card>
   
    :
    <></>
    }
         </>
  );
};

export default ExpertBoard;
