import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import BackButton from "../../../components/BackButton";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import Cards from "./Cards";
import ThankAColleague from "./ThankAColleague"; 

import "./index.scss";
 

 
const subMenuOptions = [
  ["My Thank You Cards", "/thankyou/cards"],
  ["Thank a Colleague", "/thankyou/thank_a_colleague"]
];

  const ThankYou = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  
  const displaySection = () => {
    if (pathname.includes("cards")) {
      return <Cards />;
    } 
   else if (pathname.includes("thank_a_colleague")) {
        return <ThankAColleague />;
      } 
     
    else {
      return <Cards />;
    }
  };
  return (
    <Layout>
      <div className="referrals d-flex mt-3">
        <div className="w-25">
          <BackButton />
          <SubMenu menuOptions={subMenuOptions} />
        </div>
        <div className="w-75 ml-4 d-flex align-items-center justify-content-center">
          {displaySection()}
        </div>
      </div>
    </Layout>
  );
};

export default ThankYou;
