import React from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import Insights from "./Insights";
import Jobs from "./Jobs";
import "../Referrals/index.scss";
import Requests from "./Requests";
import QualityOfHire from "./QualityOfHire";

const subMenuOptions = [
  ["My Jobs", "/admin/jobs"],
  ["Insights", "/admin/insights"],
  ["Admin Requests", "/admin/requests"],
  ["Quality of Hire", "/admin/qualityofhire"],
];
const Admin = () => {
  const { pathname } = useLocation();
  const displaySection = () => {
    if (pathname.includes("insight")) {
      return <Insights />;
    } else if (pathname.includes("request")) {
      return <Requests />;
    } else if (pathname.includes("qualityofhire")) {
      return <QualityOfHire />;
    } else {
      return <Jobs />;
    }
  };
  return (
    <Layout>
      <div className="referrals d-flex mt-3">
        <div className="w-25">
          <BackButton />
          <SubMenu menuOptions={subMenuOptions} />
        </div>
        <div className="w-75 ml-4">{displaySection()}</div>
      </div>
    </Layout>
  );
};

export default Admin;
