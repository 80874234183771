import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { IconButton } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import axios from "axios";

function Confirmation({ open, handleClose, icons, closeDialog }) {
  const [review, setReview] = useState({
    companyName: "",
    companyTax: "",
    companyWebsite: "",
    role: [],
  });

  const handleForm = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      if (checked) {
        setReview({ ...review, role: [...review.role, value] });
      } else {
        setReview({
          ...review,
          role: review.role.filter((role) => role !== value),
        });
      }
    } else {
      setReview({ ...review, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      companyName: review.companyName,
      companyTax: review.companyTax,
      companyWebsite: review.companyWebsite,
      role: review.role,
    };

    try {
      const response = await axios.post(
        "https://workbrook.us/api/v1/verification/status",
        data
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <h2 className="text-center mt-4 pt-3">Job Post Review Questionnaire</h2>
      </DialogTitle>
      <div className="close-button">
        <IconButton onClick={handleClose}>
          <svg width="30" height="30" className="rounded-circle">
            <use href={`${icons}#close`}></use>
          </svg>
        </IconButton>
      </div>

      <div className="container shadow-md border border-none mt-4">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title text-center">
              Ensure to fill in all details appropriately
            </h5>
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="companyName">Hiring company name</label>
                <CustomInput
                  type="text"
                  className="form-control"
                  id="companyName"
                  value={review.companyName}
                  onChange={handleForm}
                  name="companyName"
                  placeholder="Enter Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="companyTax">Hiring company Tax ID</label>
                <CustomInput
                  type="text"
                  className="form-control"
                  value={review.companyTax}
                  onChange={handleForm}
                  id="companyTax"
                  name="companyTax"
                  placeholder="Enter Tax ID"
                />
              </div>
              <div className="form-group">
                <label htmlFor="companyWebsite">Hiring company website</label>
                <CustomInput
                  type="text"
                  value={review.companyWebsite}
                  onChange={handleForm}
                  className="form-control"
                  id="companyWebsite"
                  name="companyWebsite"
                  placeholder="Enter Website"
                />
              </div>
              <div>
                <label>What is your role in the hiring company?</label>
                <div className="mt-3 d-flex gap-5">
                  <input
                    type="checkbox"
                    name="role"
                    checked={review.role.includes("Hiring Manager")}
                    onChange={handleForm}
                    value="Hiring Manager"
                  />
                  <label className="mt-2 mx-1">Hiring Manager</label>
                </div>
                <div className="mt-3 d-flex gap-5">
                  <input
                    type="checkbox"
                    name="role"
                    checked={review.role.includes("Business Owner")}
                    onChange={handleForm}
                    value="Business Owner"
                  />
                  <label className="mt-2 mx-1">Business Owner</label>
                </div>
                <div className="mt-3 d-flex gap-5">
                  <input
                    type="checkbox"
                    name="role"
                    checked={review.role.includes("Recruiter")}
                    onChange={handleForm}
                    value="Recruiter"
                  />
                  <label className="mt-2 mx-1">Recruiter/HR Manager</label>
                </div>
                <div className="mt-3 d-flex gap-5">
                  <input
                    type="checkbox"
                    name="role"
                    checked={review.role.includes(
                      "Not affiliated with hiring company"
                    )}
                    onChange={handleForm}
                    value="Not affiliated with hiring company"
                  />
                  <label className="mt-2 mx-1">
                    Not affiliated with hiring company
                  </label>
                </div>
              </div>
              <div className="mt-3">
                <CustomButton
                  text="Submit"
                  type="submit"
                  backgroundColor="#3A6ED4"
                  color="#fff"
                />
              </div>
              <div className="mt-3">
                <CustomButton text="Exit" color="#fff" backgroundColor="green" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default Confirmation;
