import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import Applied from "../../../../assets/icons/applied.svg";
import Successful from "../../../../assets/icons/successful.svg";
import Interested from "../../../../assets/icons/interested.svg";
import SearchInput from "../../../../components/CustomSearchInput";
import CustomButton from "../../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getReferralStats,
  getReferrers,
} from "../../../../redux/actions/insightActions";
import EmptyState from "../../../../components/EmptyState";

const Referrals = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { referrers, referralStats } = useSelector(({ insight }) => insight);

  useEffect(() => {
    Promise.all([getReferralStats(dispatch), getReferrers(dispatch)]);
  }, [dispatch]);
  return (
    <div>
      <div className="d-flex justify-content-between mx-5 mb-3">
        <div className="count co-primary">
          <div className="figure">
            <img src={Interested} alt="Interested" />
            <b>{referralStats?.clicksByReferral || 0}</b>
          </div>
          <p className="my-1 text-center">
            Interested <br /> Candidates
          </p>
        </div>
        <div className="count co-primary">
          <div className="figure">
            <b>{referralStats?.appliedForJob || 0}</b>
            <img src={Applied} alt="Applied" />
          </div>
          <p className="my-1 text-center">
            Your <br /> Applicants
          </p>
        </div>
        <div className="count co-primary">
          <div className="figure">
            <b>{referralStats?.gotEmployed || 0}</b>
            <img src={Successful} alt="Successful" />
          </div>
          <p className="my-1 text-center">
            Successful <br /> Referral
          </p>
        </div>
      </div>
      <Divider />
      <div className="row m-4">
        <SearchInput
          handleChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        {!referrers?.referreds && <EmptyState text="you have no referrals" />}
        {referrers?.referreds && (
          <>
            {referrers?.referreds
              .filter((referrer) => {
                if (
                  search &&
                  !JSON.stringify(referrer)
                    .toLowerCase()
                    .includes(search.toLowerCase())
                ) {
                  return false;
                }
                return true;
              })
              .map((referrer) => (
                <div
                  key={referrer.id}
                  className="w-100 mt-2 d-flex align-items-center justify-content-between"
                >
                  <div className="d-flex align-items-center">
                    <img width="50" src={referrer.image_url} alt="avatar" />
                    <span className="d-flex flex-column ml-3">
                      <h6 className="mb-0">
                        {referrer.first_name} {referrer.last_name}
                      </h6>
                      <span className="co-gray">{referrer.username}</span>
                    </span>
                  </div>
                  <div className="view">
                    <CustomButton
                      backgroundColor="#fff"
                      text="view"
                      color="#3A6ED4"
                      borderColor="#3A6ED4"
                      additionalClass="px-3"
                    />
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Referrals;
