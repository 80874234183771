import React from 'react';
import { bool, func, string } from 'prop-types';
import { anonymousFunc } from '../../utils/helpers';

const TermsAndConditions = ({  }) => {
  return (
     <div>

        <h5><strong>1. Introduction</strong></h5>
        <p>
        This User Agreement (this “Agreement” or “Terms” or “Terms of Use”) is a contract between you (“you” or “User” refers to the user or intended user) and workbrook LLC (referred to as “workbrook”, “we,” or “us”). You must read, agree to, and accept all of the Terms of Use contained in this Agreement to be a User of our mobile applications, web application (referred to as “workbrook”, “referral marketplace”, “software”, “platform”, “application” or “app”) or any of the services on workbrook (referred to as “Services”).
        </p>

        <p>
        If you reside in the Nigeria, you are entering into this Agreement with workbrook Limited and workbrook Limited will be the controller of your personal data provided to, or collected by or for, or processed in connection with our Services as well as all payments between you and the platform.        
        </p>

        <p>
        These Terms of Use are effective immediately for all users. These Terms govern the use of this website, software, electronic messages, mobile or web applications or any other services from workbrook. The use of this platform will only be for the intended purposes which includes but not limited to ensuring people are informed of job or work opportunities, people share information about job or work opportunities, people share information on individuals or organizations that may possess required skills, capabilities or knowledge required for these opportunities.
        </p>

        <p>
        By accessing or using workbrook and our services, you agree to accept these Terms as well as our Privacy Policy. If our services include, are used in connection with, or are integrated in the services of third parties, the Terms and/or privacy and cookie policies of those third parties may apply in addition to these Terms. If you use workbrook on behalf of a company or other legal entity, then "you" also means such company or legal entity and you agree to be bound by these Terms even if we have a separate agreement with you.
        </p>

        <p>
        You must be at least 16 years of age to use workbrook. If there is an older age requirement in your jurisdiction, then that age limit automatically applies.
        </p>

        <p>
        You may use your workbrook account for any or all of the following roles at any time:
        </p>

        <ul>
          <li>
         - Job Owner (Job Poster)- anyone who creates a job advertisement on workbrook with the intention of placing on either the referral or recruiter portals.
          </li>
          <li>
         - Applicant Referrer (Referrer)- anyone who sees a job advertise on workbook and makes a decision to share this job opportunity with anyone in their network of contacts through any medium enabled by the referral portal.
          </li>
          <li>
         - Job Seeker (Applicant)- anyone who sees or is informed about a job opportunity advertised on workbrook and decides to put his/her profile forward to be considered by the job poster i.e., apply for the job opportunity through workbrook. 
          </li>
          <li>
         - Recruiter- anyone who creates a recruiter profile on the recruiter portal of workbrook and puts his or her talent acquisition services up for hire. This role requires additional profile information and registration which will have to be approved by us before you can use your recruiter account on workbrook
          </li>


        </ul>

        <p>
        To use workbrook as a recruiter (sign up on the recruiter platform on workbrook) you must have 2 years recruitment experience.
        </p>

        <p>

        In order to create an account on workbrook, you will be required to provide a valid email address that you own and have access to. This is a prerequisite; you may not be permitted to operate a workbrook account without a valid email address on which we can reach you. You will also be required to provide a password and username to access and operate your account. In addition to this, you will create and update your personal and professional profile by providing some information required by the platform. You must treat all information you provide as confidential and you must not share or disclose your password, username or any other security information with any person or entity. You agree to notify us immediately of any unauthorized access to your account or security details. 
        </p>

        <p>
        You may be able to sign up and subsequently access workbrook through some social networking sites provided on the platform. If you opt for this option, you agree that we may access any information or content that you have in your Social Networking Site account of choice so that it is available on and through workbrook via your account and your Profile page. Depending on the privacy settings and agreement you may have with the Social Networking Site account you use to access workbrook, personally identifiable information that you provide to that Social Networking Site may be displayed on workbrook. workbrook disclaims any liability for personally identifiable information that may be provided to us by any Social Networking Site through which you choose to access our platform in violation of any privacy settings or agreement that you may have with such Social Networking Site.
        </p>

        <p>
        During your use of workbrook, you may need to access information on your personal or professional contacts (including personally identifiable information) on your device or Social Networking Sites in order to share certain information from workbrook with such contacts. You agree that we may access and use such information that you have in any device or Social Networking Site. workbrook disclaims any liability for personally identifiable information that may be provided to us by any Social Networking Site or via any device in violation of any privacy settings or agreement that you may have with such Social Networking Site, device manufacturer or network service operator
        </p>
        <p>
        You may invite people to the workbrook through the “invite a friend” functionality.
        </p>
        <p>
        The English version of these Terms shall control over any translations.
        </p>

        <h5><strong>2. Copyright notice</strong></h5>
        <p>
        (c)2020, workbrook LLC <br/>
          All rights reserved 
        </p>


        <h5><strong>3. License to use website</strong></h5>
        <p>
        workbrook contains content that may or may not be confidential in nature or may or may not be treated as confidential by the owner of the information, whether such information is or has been conveyed verbally or in written or other tangible form, and whether such information is acquired directly or indirectly during your interaction with workbrook, including, but not limited to photography, graphic design, portraits, logos, trademarks, trade names, domain names, copyrights, patents, resumes, contact information, personal information, professional information, personal preferences, business or technology ideas, intellectual property, products, product plans and roadmaps, product specifications, hardware designs, computer programs, business and operational plans and materials, marketing plans and materials, financial statements, financial information, corporate agreements, employment agreements, salaries, organization performance data, shareholder customer lists, customer agreements, market assessments, projections, reports, studies, statistics, demonstrations and any other data, in oral, written, graphic, electronic, or any other form or medium. The Content may be owned by workbrook and our licensors, however workbrook owns all property rights on workbrook either directly or through license. 
        </p>

        <p>
        As an authorized user, we grant you a limited, revocable, non-sublicensable license under the intellectual property rights licensable by us to download, view, copy and print Content from workbrook only for your personal use based on your specific and pre-defined role as a user. Asides from rights provided in the foregoing, you agree not to reproduce, modify, publish, transmit, record, make notes of, distribute, redistribute, publicly perform or display, sell, adapt or create derivative works based on workbrook or the Content (excluding Your Content); or transfer, share, rent, lease, loan, or sell access to workbrook or the Content. You agree not to reverse engineer, decompile or disassemble any Information or Content on this site.
        </p>

        <h5><strong>4. Acceptable use</strong></h5>
        <p>
        To access workbrook including any resources or services we offer, you may be asked to provide certain details and information. It is a condition of your use of workbrook that all the information you provide is correct, current and complete.
        By using the functionalities of this website and app, you agree to give workbrook the right to use and share your information with companies or individuals for the proper functioning of workbrook. You are also responsible for ensuring that all persons who access workbrook through your internet connection or device are aware of these Terms of Use and comply with them.
      
        By posting job and company information on this site and app, you agree to workbrook sharing such information with people or organizations as we deem necessary for the proper functioning of workbrook.
        By applying for job opportunities, you agree to allow workbrook share your personal and professional information with job posters, recruiters or companies you show interest in or apply to
        You agree not to:
        </p>

        <p>
        Access or use workbrook under false pretenses nor create multiple user accounts; apply to the same job or organization using multiple profiles, create multiple recruiter accounts to increase your chances of being hired;
        Give anyone your access to or allow anyone to use your account with workbrook;
        Misrepresent your identity, personal information, work experience or association with any person, organization or entity;
        </p>

        <p>
        Request, manipulate or connive with anyone to use their access to the platform to violate any part of these Terms of use or in order to facilitate any gain off workbrook which is not in alignment with the purpose intended by us;
        Use your access to, account or Content on workbrook for any commercial activity including but not limited to sales, advertising, sublicensing, barter or any form of quid pro quo that results in personal gain except that which has been expressly approved by us. This excludes approved recruiters putting up their services for hire through workbrook;
        Put up plagiarized content;
         </p>

         <p>
         Put up or post Content that is discriminatory, abusive, profane, racist, indecent, offensive, defamatory or harmful in any way to any other user;
         Put up or post any pornographic or sexually explicit or Content;
         Harass, bully, threaten, stalk or infringe on the rights or privacy of any user or entity in any way or manner;
         </p>
         <p>
         Constitute any form of danger to workbrook or its users or behave in any manner deemed inappropriate or objectionable by workbrook;
         Use workbrook in any way that breaches any confidentiality or contractual agreement between you and any person or entity;
         Use workbrook or any of its features and/or services to further any intention or action violating any applicable anti-SPAM, anti-phishing or non-solicitation laws or provisions;
         </p>
         <p>
         Violate any applicable law, order or regulation and/or infringe on any type of right including proprietary rights;
         Access any part of workbrook besides your personal account or Content or information available only to you through your personal account without our permission;
         Edit or modify any information or Content which you did not put on workbrook;
         </p>

         <p>
         Copy, save, capture or use any information or Content on workbrook which is not available to you through your personal account or access without our permission;
         Be involved in or conduct any action that compromises workbrook or any of its features or services;
         Use any means whatsoever to gain access to any non-public part of workbrook or any information or Content that has not been made available to the public;
         </p>
         <p>
         Introduce viruses, spyware, firmware, log or time bombs, web shell or any form of malicious software or corrupt file to workbrook;
         Interfere with, hijack, reverse engineer, decompile or constitute a threat in any way to any part of workbrook or its functionalities or Services; including any third-party service or functionality on or available through workbrook;
         Attempt to or bypass normal authentication or encryption or any security feature on workbrook;
         
         Attempt or conduct any cyberattack intended to redirect workbrook's traffic to another website, application or platform.
         </p>

         <h5><strong>5.Use on behalf of organization:</strong> use on behalf of another</h5>
          <p>
          workbrook is for personal use, except you have an agreement with workbrook or its affiliate organizations to use our services for commercial use. If you are an individual using workbrook on behalf of any employer, organization or any legal entity, by using workbrook, you affirm that you have the authorization by such legal entity to use or conduct business on workbrook on its behalf.
          <br/>
          <p>
          <u>Posting a Job on Behalf of an Organization or legal entity:</u>
            </p>        
           
           </p>
          <p>
          By posting a job/position on workbrook you confirm you have the authority to represent the organization or entity where this position sits or the organization or entity that owns this position;
           
          You agree not to include any business confidential information on the job board;
          
          You attest that all information about the company or position advertised have been carefully considered and does not violate any information management policy of the organization or legal entity you or this position represent.
          </p>
 
 
          <p>
          <u>Hiring a Recruiter on behalf of an Organization or Legal entity:</u>
          </p>   
          By posting a job on recruiter platform of workbrook in order to hire a recruiter, you are affirming that you have the authority to act on behalf the organization or entity where this position domicile (i.e., that owns this position) as a decision maker to hire a recruiter (and his/her services). 
         

        <p>
        <p>
        <u>Recruiting on behalf of a Recruitment Agency:</u>
        </p> 
        By displaying the name of a recruitment agency on your recruiter profile indicating you recruit on behalf of such Agency, you confirm that you have the authorization to act on behalf the Agency and all decisions to make or actions you take on workbrook has been approved by the management of the Agency.       
         </p>

         <h5><strong>6. Job poster registration, accounts and services</strong></h5>
          <p>
          Any user on workbrook can post a job on the platform with his or her user account.
          You represent that any job or position you post on workbrook is real, present and does not violate any applicable law. You also present that all hiring actions or activities you conduct on workbrook conforms with all applicable laws.
          </p>

          <p>
          By advertising details of a job or position on workbrook, you give workbrook and anyone on the workbrook platform the right to share these details with whomever they deem fit in order to help promote the awareness of this job as advertised.
          You agree to give consideration to all job seekers that apply to your position (applicants) and who meet the minimum requirements of the position. You also agree to communicate with any applicant first on this platform at every stage of the process of considering or assessing the applicant(s) for this role before communicating through any other means. workbrook encourages equal opportunity recruitment processes and standards and does not accommodate any form of discrimination on any basis. 
          </p>

          <p>
          workbrook does not accept any liability due to errors or omission in the content of the position advertised or the details of the applicants provided through this platform
          You agree not to use the information of an applicant for any other purpose or in any other manner except that which it is intended by workbrook and/or the applicant.
          </p>

          <p>
          workbrook will not reward any referral made by the Job Poster to a job he/she posted on the platform. Please note that you may make a referral to a job you posted on workbrook however you will be doing so without expectation or anticipation of any benefit from the referral.  
          Please refer to the Client Agreement if you intend to hire the services of a Recruiter on workbrook.
          </p>

          <h5><strong>7. Job referrer registration, accounts and services</strong></h5>
          <p>
          Any user on workbrook can make a referral on the platform with his or her user account. A referral will be credited to you when the candidate you referred applies through your referral link. A referral is successful only if the candidate you referred is hired into the job you referred for.
          By clicking on the “refer” button on any position advertised/posted on workbrook you agree:
        To refer only people that you know meet the minimum requirements of the job advertised;
          </p>
        <p>
        To give workbrook access to any media/platform through which you intend to share this opportunity with your contact(s);
        To give workbrook access to the contact information of such contact(s);
        That workbrook can use the information of your contact to reach them with your intended purpose of sharing such an opportunity with them;
        </p>

        <p>
        Not to use the information about a job or an organization you see on workbrook for any other purpose or in any other manner except that which is intended by workbrook and/or the job poster.
    
        </p>

        <h5><strong>8. Jobseeker registration, accounts and services</strong></h5>
        <p>
        Any user on workbrook can apply to a job posted on the platform with his or her user account so long he/she meets the minimum requirement of the job advertised.
        By applying for a job posted on workbrook, you agree:
        </p>
        <p>
        To share your information (including your resume) on the platform with the Job poster and in turn the Job owner in order to be considered for the job;
        That the information you have provided on this platform is true and not misleading or misrepresenting your capabilities, skills, knowledge or experience;
        </p>

        <p>
        Not to use the information of a job or organization for any other purpose or in any other manner except that which is intended by workbrook and/or the Job Poster.
        You may withdraw your application to any job on workbrook at will.
        </p>


        <h5><strong>9. Recruiter registration, accounts and services</strong></h5>
        <p>
        To become a recruiter on workbrook, you will need to create a separate profile on the recruiter portal. You must have at least 2 years of recruitment experience.
        Please refer to the Recruiter Agreement.
        </p>

        <h5><strong>10. Cancellation and suspension of account</strong></h5>
        <p>
        You attest that all information you provide on this site is true and accurate, Workbrook reserves the right to deny you access to this site if such information is found to be inaccurate or if you are found to have misrepresented information. Workbrook also reserve the right to take legal actions were deemed necessary
        </p>

        <p>
        We reserve the right to ban you or terminate your usership, account or access to this platform for any or no reason and without notice. If you have been banned or your account has been terminated by us, you shall not use workbrook any longer.
        Please remember that you have the right not to use workbrook if you so choose. You also have the right to discontinue usage of workbrook and cancel your account and all paid services.
        </p>

        <h5><strong>11. Fees</strong></h5>
        <p>
        Services on workbrook may be free or paid with associated fees. We may change paid Service fees at any time and free Services may become paid Services as we deem necessary. We will inform you of such changes prior to the effective date via a message displayed on workbrook and/or via a message to the email associated with your account.
        </p>

        <p>
        <u>Paid Service:</u><br/>
        Paid Services on workbrook may be on a one-time payment, pay per view/reaction or subscription payment basis. Fees associated with the Services are indicated on workbrook. All fees displayed on workbrook are excluding taxes; you are responsible for all applicable taxes and we will charge tax when required to do so. You may pay for the Services through Third-Party payment method providers available on workbrook or through earn points money equivalent in your workbook wallet. You are responsible for providing complete and accurate billing and contact information on payment method provider’s platforms. You may be required to pay a handling fee by the Third-Party payment method provider, we advise you read all Third-Party payment method provider Terms of service.
        </p>

    <p>
    You agree to pay fees associated with the Services on workbrook before you are granted access to use such Services and that workbrook may charge such fees to your payment method via our Third-Party payment method provider. You may cancel your subscription for paid services at any time but you will not be refunded any payments already made for such Services.
    </p>

    <p>
    We reserve the right to withdraw your access to paid Services if and when you do not pay the fees associated with such Services or your subscription for such Services expires. In the event of reasonable payment disputes, we will be open to review our decisions while you cooperate with us in resolving the dispute. 
    </p>
    <p>
      <u>Recruiter Services:</u><br/>
      We partner with individual users who qualify to be recruiters on workbrook in order to provide recruiting services. You may hire the Services of recruiters on workbrook to meet your individual recruitment needs. Please see “hiring a recruiter’ section of the Terms (Section 6) for more details on hiring a recruiter and refer to the Recruiter Agreement. 

    </p>

    <h5><strong>12. Gamification and Points</strong></h5>
    <p>
      <u>Earning Points</u><br/>
      You may earn points for certain functions and services of workbrook:
      <br/>
      <ul>
        <li>Sign up to use workbrook- 5pts;</li>
        <li>Completing your profile to 100%- 5pts;</li>
        <li>Inviting a friend to workbrook- 5pts;</li>
        <li>Making a successful referral to any of the job levels (executives- 30pts; senior managers- 20pts; managers-15pts; non-manager and graduates- 10pts)</li>

      </ul>


    </p>
    <p>
    The points you earn are not transferrable.
    You may not earn points on "invite a friend" until the friend posts a job or applies for a job and is successful or makes a successful referral
    You may earn points through signing up only once.
    Points earned through the referral system may be used in the future as part of a reward system. We reserve the right and the sole discretion to make changes to how points are earned and rewarded at any time.
    </p>

    <h5><strong>13. Your content: license</strong></h5>
    <p>
    You own your information and we respect that. All information and content (in any format) on workbrook are used with uttermost confidentiality. By putting your content or information into workbrook, you agree to give workbrook free, unlimited and transferrable license to use, remove, copy, move, display, analyze, modify and distribute such information and content in any form, anywhere and at any time to anyone or any company or organization. This license also includes the right for workbrook to sublicense to anyone or any company or organization as we deem necessary, which may include the use, display or publication of the information and content on other media or platform.
    </p>

    <h5><strong>14. Report abuse</strong></h5>
      <p>
      You shall:
      </p>

      <p>
      Disclose in writing to workbrook any conflict of interest or appearance of any conflict of interest that may occur during your interaction with or use of workbrook. Disclosure could also be through any feature made available for such cases on the platform;
      </p>
      <p>
      Inform workbrook immediately of your knowledge of any attempt to compromise any part of the system, including potential risk or attempt by any other person or entity to gain access through your account.
      </p>

      <p>
      You may report any abuse of the use of workbrook in writing to us immediately. You may also report through the comment and/or feedback functionality on the platform.
      </p>
        
      <h5><strong>15. Limited warranties</strong></h5>
      <p>
      We reserve the right and the sole discretion to withdraw or amend workbrook, and any service or material we provide on this Platform, without notice. We will not be liable for all or any part of workbrook being unavailable at any time or for any period and for any reason. We have the right to restrict access to any or all parts of workbrook to some or all users.
      </p>

      <h5><strong>16. Indemnity</strong></h5>
      <p>
      You agree to indemnify and hold us (workbrook company), the workbrook app, our subsidiaries, partners, regents, officers, employees, board members, directors or agents harmless from any liabilities, loss, damage, claim or causes of action whatsoever, including but not limited to reasonable attorneys' fees and costs, made by any third party due to or otherwise arising from your use of or interaction with workbrook, including due to or arising from your breach of any provision of these Terms.
      </p>

      <h5><strong>17. Limitations and exclusions of liability: </strong></h5>
      <p>
      workbrook is provided "as-is" and as available.
      </p>
      <p>
      workbrook assumes no responsibility, and disclaims all liability for the content, accuracy, completeness, legality, reliability, or availability of any job posting, referral to any job posting, personal or professional information of any user (including resume information), communication details or information between any 2 or more users or lack of communication thereof, audio, video or pictorial information or message you post, send or receive from or through workbrook. We make no warranty that workbrook or your use of workbook and its features and services will meet your needs or requirements; nor do we make warranty that workbrook will be available to you, any user or entity uninterrupted, timely, secure, or error-free.  
      </p>
      <p>
      workbrook products and services may provide integration with other third-party products and services, you are solely responsible for your interactions with third-party products and services including advertisers and other users and we are not responsible for the activities, omissions, or other conduct, whether online or offline, of any advertiser or user of workbrook. workbrook assumes no responsibilities and disclaims all liabilities for business losses, loss of profit, loss of data, information or software, consequential losses or any direct or indirect damages resulting from your use of workbrook or interaction with any one, user or entity on wokrbrook. 
      </p>

      <p>
      All candidate introductions, referrals or applications on or facilitated by workbrook are made on a confidential basis and the job poster or owner or company shall hold workbrook harmless from any liability resulting from any unauthorized disclosure or misuse of information regarding any individual. 
      </p>

      <p>
      workbrook does not guarantee the performance of any individual or the accuracy of information provided regarding any individual or persons, and disclaims any responsibility for claim, loss, or liability as a result of such person’s acts or omissions. As a job owner, you agree to conduct investigations, as you deem necessary to verify candidate information or to obtain such other information with the candidate’s consent, as you may deem relevant.
      </p>

      <p>
      workbrook does not guarantee accuracy of information provided by the job poster or job owner or company displaying job opportunities on this website or application and disclaims any responsibility for claim, loss, or liability as a result of a such information. As an applicant you agree to conduct investigations as you deem necessary to verify job or company information or to obtain such other information, as you may deem relevant.
      </p>

      <p>
      In no event shall workbrook be responsible or liable for any failure or delay in the performance of its obligations hereunder arising out of or caused by, directly or indirectly, forces beyond its control, including, without limitation, strikes, work stoppages, accidents, acts of war or terrorism, civil or military disturbances, nuclear or natural catastrophes or acts of God, and interruptions, loss or malfunctions of utilities, communications or computer (software and hardware) services; it being understood that the workbrook shall use reasonable efforts which are consistent with accepted practices in the industry to resume performance as soon as practicable under the circumstances.
      </p>

      <p>
        <u>
          <strong>
          These Terms of Use of limitations of liability are not made with the intention to deny you of your rights under applicable laws and jurisdictions. Notwithstanding any provision contained in this Terms to the contrary, in the event workbrook is liable for damage under mandatory law, you agree that workbrook’s aggregate liability to you for any and all claims arising out of or in connection with your use of workbrook will in no event exceed one hundred dollars ($100).
          </strong>

        </u>
      </p>
 

      <h5><strong>18. Breaches of these Terms of Use </strong></h5>
        <p>
        You acknowledge and agree that any breach of the Terms of Use of this App/site will result in significant damage to workbrook or its users, which is not compensable monetarily, and agree that workbrook shall be entitled to receive injunctive relief in a court of appropriate jurisdiction in the event of the breach or threatened or anticipated breach of any of the Terms of this Agreement. Please be reminded that workbrook reserves the right to ban you or terminate your usership, account or access to this platform. If you have been banned or your account has been terminated by us, you shall not use workbrook anymore
        </p>

        <p>
        Neither the failure nor any delay on our part to insist upon the strict and full performance of this Terms of Use or to exercise or enforce any right, remedy, power or privilege consequent upon a breach of the provisions of this Terms, shall constitute waiver of any such breach of this Terms of Use of use.
        </p>


        <h5><strong>19. Third party content and websites </strong></h5>
        <p>
        Content owned or provided by a third party on workbrook may not be controlled by us, hence you agree not to hold workbrook responsible for any third-party Content; we do not guarantee the accuracy or veracity of such Content. We assume no responsibility whatsoever for unintended, objectionable, inaccurate, misleading, or unlawful Content made available by any user or entity (authorized or non-authorized), or any other third-party. While you are using the features or Content on Workbrook, you may be directed to third-party websites, applications, software or any other platform through links or any other feature placed on or available on workbrook. Your decision to access these links or any feature directing you to a third-party websites, applications, software or platform is solely yours and you do so at your own risk. workbrook does not take any responsibility for the content or your experience on such third-party platforms. If these links or features are placed my us, we do so only to facilitate access to information you choose to pursue. This does not in any way mean we endorse, approve of or encourage the use of these third-party platforms; hence you agree not to hold workbrook responsible for your experience or interactions with any third-party platform you access through workbrook.
        </p>

        <h5><strong>20. Trade marks </strong></h5>
        <p>
        The workbrook name, logo and all related names, logos, product and service names, designs and slogans are trademarks of workbrook or its affiliates or licensors. You must not use such marks without the prior written permission of workbrook or its owners.

        </p>

        <h5><strong>21. Variation</strong></h5>
        <p>
        Workbrook may revise these Terms as deemed necessary. A revision will be done through uploading an updated version on workbrook. All users will be notified through the platform and/or through any other means deemed necessary when and if there is a revised version. For new users who registered after a revision of the Terms, the new Terms will be effective immediately and at the point of creating an account. For existing users, the revised Terms will be effective immediately the notice has been placed on the platform or we have notified you through any other means adopted, except specifically indicated in the notice. We advise you to keep up to date with the details of the Terms of Use at all times. By using workbrook at any time you agree to the most recent Terms of Use. In the event that we make material changes, we will notify you through any of the contact detail you provided or through a message on the platform before such changes take effect.

        </p>

        <h5><strong>22. Assignment</strong></h5>
        <p>
        You may not assign your rights or obligations under these Terms. Workbrook will have the right at all times to assign any of its rights or obligations under these Terms to a direct or indirect wholly owned Subsidiary of the workbrook or to any successor in interest of any business associated with our services without any limitations or without your consent.
        </p>

        <h5><strong>23. Severability</strong></h5>
        <p>
        In case any provision in these Terms shall be invalid, illegal or unenforceable, such provision will be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If a modification is not possible, such provision will be removed to the minimum extent necessary so that the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby and such provision shall be ineffective only to the extent of such invalidity, illegality or unenforceability.
        </p>

        <h5><strong>24. Entire agreement</strong></h5>
        <p>
        This Terms constitute the entire agreement between you and workbrook with respect to your use of and interaction with workbrook and any of its features and/or services and supersedes all other prior agreements and understandings, both written and oral, between you and anyone or entity regarding your use of and interaction with workbrook.
        </p>

        <h5><strong>25. Law and jurisdiction</strong></h5>
        <p>
        YOU AND WORKBROOK AGREE THAT THESE TERMS (INCLUDING ALL CLAIMS, DISPUTES, OR OTHER LEGAL PROCEEDINGS) WILL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE STATE OF TEXAS, IRRESPECTIVE OF THE CONFLICT OF LAWS RULES. THESE TERMS WILL NOT LIMIT ANY CONSUMER PROTECTION RIGHTS THAT YOU MAY BE ENTITLED TO UNDER THE MANDATORY LAWS OF YOUR COUNTRY OF RESIDENCE. FURTHERMORE, BECAUSE YOU AND WORKBROOK AGREE THAT THE TEXAS COURTS ARE THE EXCLUSIVE FORUM FOR RESOLVING ANY DISPUTES ARISING OUT OF THESE TERMS, THE PARTIES SUBMIT THEMSELVES TO THE PERSONAL JURISDICTION OF THE TEXAS COURTS.
        </p>

        <p>
        If a dispute arises from or relates to these Terms or the breach thereof, and if the dispute cannot be settled through direct discussions, you and workbrook agree to endeavor first to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration. We further agree that any unresolved controversy or claim arising out of or relating to these Terms, or breach thereof, shall be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules and judgment on the award rendered by the arbitrator(s) may be entered in the jurisdiction of the federal or state courts in Fort Bend County, Texas.

        </p>
        <p>
        IF YOU LIVE IN NIGERIA, YOU AND WORKBROOK AGREE THAT THESE TERMS (INCLUDING ALL CLAIMS, DISPUTES, OR OTHER LEGAL PROCEEDINGS) WILL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE FEDERAL REPUBLIC OF NIGERIA, IRRESPECTIVE OF THE CONFLICT OF LAWS RULES.

        </p>

        <h5><strong>26. Our details</strong></h5>
        <p>
        The workbrook application is operated by workbrook LLC, a registered Limited Liability Company.
        </p>
        <p>
        Registered address: 26403 Cloverbank LN, Richmond, Texas, 77406, United States of America. 
        </p>
        <p>
        Contact email: infor@workbrook.com
        </p>
        <p>
        Website: www.workbrook.com 
        </p>

        <h5><strong>27. Miscellaneous</strong></h5>
        <p>
        You agree that any part of your interaction with the workbrook application not covered in these Terms of Use of use will be addressed by workbrook and the decision on how to address such situations is the sole responsibility of workbrook. The section titles in the Terms are for convenience only and have no legal or contractual effect; as used in the Terms, the word "including" means "including but not limited to."
        </p>
        <p>
        You hereby freely agree that you fully assume the risks associated with your use of workbrook. You fully and forever release from all liability and discharge yourself, your heirs, executors, administrators, legal representatives, assignees, and successors (collectively referred to as “Successors”), any and all rights and claims, demands, or damages, present or future, whether the same be known or unknown, anticipated or unanticipated against workbrook, and its agents, board members, officials, and employees, through or by which any of the aforementioned parties may be held for any and all damages which may be sustained whether caused by the negligence of the above mentioned parties or by you directly or indirectly in connection with or arising out of your use of workbrook. 
        </p>
        <p>
        You agree, for yourself and Successors, that the above representations in these Terms of Use are contractually binding, and are not mere recitals, and that should you or my successors assert any claim in contravention of this agreement, you or your Successors shall be liable for the expenses (including legal fees) incurred by the other party or parties in defending such claim unless the other party or parties are finally adjudged liable on such claim for willful and wanton negligence. These Terms may not be modified orally. A written waiver of any provision of this agreement shall not be construed as a modification of any other provision, or as a consent to any other provision, or as a consent to any subsequent waiver of modification.
        </p>
     </div>
  );
};

TermsAndConditions.propTypes = {
  onClick: func.isRequired, 
  label: string.isRequired, 
  additionalClass: string,
  loading: bool
};

TermsAndConditions.defaultProps = {
  additionalClass: ""
};

export default TermsAndConditions;
