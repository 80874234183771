import React, { useState } from "react";
import cogoToast from "cogo-toast";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import placeholder from "../../../../assets/icons/placeholder.svg";
import { node } from "prop-types";
import { updateProfileAction } from "../../../../redux/actions/profileActions";
import { uploadFile } from "../../../../utils/helpers";
import { ButtonLoader } from "../../../../components/ButtonLoader";

const ProfileLayout = ({ children }) => {
  const { pathname } = useLocation();
  const [isUploading, setIsUploading] = useState(false);
  const { userData } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const { first_name, last_name, image_url, title } = userData;

  const [profileImage, setProfileImage] = useState("");

  const handleChange = ({ target: { files } }) => {
    setIsUploading(true);
    
    // Check if files are selected
    if (files && files.length > 0) {
      const file = files[0]; // Get the selected file
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        // Set the profile image to display immediately
        setProfileImage(reader.result);
      });

      reader.readAsDataURL(file); // Read file as Data URL

      // Proceed with the upload
      Promise.resolve(uploadFile(file)).then(({ data, success }) => {
        if (success) {
          const payload = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          title: userData.title,
          gender: userData.gender,
          dob: userData.dob,
          state: userData.state,
          country: userData.country,
          industry: userData.industry,
          image_url: data,
          resume: userData.resume,
          phone: userData.phone,
          links: userData.links,
          isShowOnProfile: 1,
          about: userData.about,
        };
          const promise = updateProfileAction(payload, dispatch);
          Promise.resolve(promise).finally(() => {
            setIsUploading(false);
          });
        } else {
          cogoToast.error("Unsuccessful image upload", {
            hideAfter: 4,
            position: "top-center",
          });
          setIsUploading(false);
        }
      });
    } else {
      cogoToast.error("No file selected", {
        hideAfter: 4,
        position: "top-center",
      });
      setIsUploading(false);
    }
  };

  return (
    <div className="mt-3 px-2">
      <div className="profile-header d-flex">
        {isUploading ? (
          <div
            className="mr-5"
            style={{
              width: "150px",
              height: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonLoader color="#3a6ed4" width="80" height="80" />
          </div>
        ) : (
          <img
            height="150"
            width="150"
            className="mr-5"
            src={profileImage || image_url || placeholder}
            style={{
              borderRadius: "100px",
            }}
            onError={({ target }) => {
              target.src = placeholder;
            }}
            alt="Profile"
          />
        )}
        <div className="d-flex flex-column mt-2">
          <h4 className="text-dark">
            {first_name ?? "Hello"} <b>{last_name ?? "User"}</b>
          </h4>
          <span className="co-gray">{title}</span>
          
          <input
            disabled={isUploading}
            onChange={handleChange}
            type="file"
            id="upload-profile-img"
            accept=".png,.jpg,.jpeg,.gif"
            style={{ display: "none" }}
          />
          
          <label
            htmlFor="upload-profile-img"
            className="underlined co-primary py-1 my-1"
            style={{
              cursor: isUploading ? "not-allowed" : "pointer",
            }}
          >
            <b>
              {isUploading ? "Uploading image" : "Change Avatar"}
            </b>
          </label>

          <Link
            to={pathname.includes("edit") ? "/profile" : "/profile/edit/basic"}
            className="edit-profile-button text-center my-2 py-2"
          >
            {pathname.includes("edit") ? "View Profile" : "Edit Profile"}
          </Link>
        </div>
      </div>
      {children}
    </div>
  );
};

ProfileLayout.propTypes = {
  children: node.isRequired,
};

export default ProfileLayout;
