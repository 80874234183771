/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch } from "react-redux";
import { Divider } from "@material-ui/core";
import Card from "../../../../components/Card";
import CustomButton from "../../../../components/CustomButton";
import Placeholder from "../../../../assets/icons/placeholder.svg";
import {
  approveAdminRequests,
  declineAdminRequest,
} from "../../../../redux/actions/profileActions";

const RequestCard = ({ profile, refreshList }) => {
  const dispatch = useDispatch();

  // console.log("Req", profile);

  const approveRequest = (id) => {
    Promise.resolve(approveAdminRequests(dispatch, id)).finally(() => {
      refreshList();
    });
  };

  const rejectRequest = (id) => {
    Promise.resolve(declineAdminRequest(dispatch, id)).finally(() => {
      refreshList();
    });
  };

  return (
    <Card noPadding additionalClass="pt-3 mt-3">
      <div className="d-flex justify-content-between w-100 px-4">
        <img src={Placeholder} alt="placeholder" />
        <div className="w-100 d-flex flex-column mt-3 ml-3 mb-4">
          <b>
            {profile.details.first_name} {profile.details.last_name}
          </b>
          <span>@{profile.details.username}</span>
        </div>
      </div>
      <Divider />
      <div className="d-flex">
        <CustomButton
          additionalClass="py-3"
          text="Accept"
          borderColor="#fff"
          backgroundColor="#fff"
          color="#3a6ed4"
          handleClick={() => approveRequest(profile.id)}
        />
        <Divider orientation="vertical" flexItem />
        <CustomButton
          additionalClass="py-3"
          text="Reject"
          borderColor="#fff"
          backgroundColor="#fff"
          color="#E8323E"
          handleClick={() => rejectRequest(profile.id)}
        />
      </div>
    </Card>
  );
};

export default RequestCard;
