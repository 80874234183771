import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton";

import { IoPersonSharp } from "react-icons/io5";
import cogoToast from "cogo-toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ButtonLoader } from "../../../../components/ButtonLoader";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomTextArea from "../../../../components/CustomTextArea";
import { getCompanyStaff } from "../../../../redux/actions/businessActions";
import { BsPeople, BsGraphUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import {
  getEmployee,
  getPerformance,
  postperformances,
  postsendback,
} from "../../../../redux/actions/jobActions";

function RewardPerformance() {
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [activeTab, setActiveTab] = useState("Leadership");
  const [employees, setEmployees] = useState([]);
  const [formData, setFormData] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);
  const [employeeAppraisal, setEmployeeAppraisal] = useState([]);

  const dispatch = useDispatch();
  const [sendEmployee, setSendEmployee] = useState({
    employee_id: "",
    employee_name: "",
    employee_goals: [],
    employee_appraisal: [],
  });
  const [hrleadercomment, setHrLeaderComment] = useState({
    Leadership: "",
  });
  const [hrperformcomment, setHrPerformComment] = useState({
    Performance: "",
  });
  const [hrdevelopcomment, setHrDevelopComment] = useState({
    Development: "",
  });
const {
    userData: {  company },
  } = useSelector((store) => store.auth);
   const company_id = company ? Number(company.id) : "";
    useEffect(() => {
    const getCompany = async () => {
        const parsedCompanyId = parseFloat(company_id); 
        console.log("company_id:", company_id, "Type:", typeof parsedCompanyId);
        
        const data = {
            company_id: Math.floor(parsedCompanyId) 
        };
        
        try {
            console.log("Making request with company_id:", data);
            const response = await getCompanyStaff(dispatch, data);
            console.log(response.payload);
          setEmployees(response.payload);
          
        } catch (error) {
            console.log(error);
        }
    };
    getCompany();
  }, [company_id]);
  const handleEmployeeChange = (e) => {
    const value = e.target.value;
    const employee = employees.find((all) => all.username === value);
    if (employee) {
      setSelectedEmployee(employee);
      setSendEmployee((prevSendEmployee) => ({
        ...prevSendEmployee,
        employee_id: employee.id,
        employee_name: employee.username,
      }));

      setTimeout(() => {
        getPerformance(dispatch, employee.id)
          .then((action) => {
            console.log(action.payload.data);

            const performanceData = JSON.parse(
              action.payload.data.employee_goals
            );
            const employeeAppraisal = JSON.parse(
              action.payload.data.employee_appraisal
            );
            setEmployeeAppraisal(employeeAppraisal);
            setPerformanceData(performanceData);
            console.log(performanceData);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setHrLeaderComment((prevHrLeaderComment) => ({
      ...prevHrLeaderComment,
      [name]: value,
    }));
  };
  const handleSendBack = async () => {
    const sendData = {
      employee_id: sendEmployee.employee_id,
      // employee_name: sendEmployee.employee_name,
    };

    console.log(sendData);

  try {
    const response =  await postsendback(dispatch, sendData);
  } catch (error) {
    console.log(error)
  }
  };
  const handleChangee = (e) => {
    const { name, value } = e.target;
    setHrPerformComment((prevHrPerformComment) => ({
      ...prevHrPerformComment,
      [name]: value,
    }));
  };
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setHrDevelopComment((prevHrDevelopComment) => ({
      ...prevHrDevelopComment,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    const formDataReview = {
      Leadership: [performanceData.Leadership.map((goal) => {
        const goalKey = Object.keys(goal)[0];
        return {
          ...goal[goalKey],
        HR_comment: hrleadercomment.Leadership,
       }
      }),
    ],
      Performance: [performanceData.Performance.map((goal) => {
        const goalKey = Object.keys(goal)[0];
        return {
          ...goal[goalKey],
        HR_comment: hrperformcomment.Performance,
       }
      }),
    ],
      Development: [performanceData.Development.map((goal) => {
        const goalKey = Object.keys(goal)[0];
        return{
          ...goal[goalKey],
        HR_comment: hrdevelopcomment.Development,
       }
      }),
    ],
    };

    localStorage.setItem("formD", JSON.stringify(formDataReview));
    cogoToast.success("Saved Successfully")

    console.log(formDataReview );
  };
   const handleSubmitt = () => {
    const formDataReview = {
      Leadership: [performanceData.Leadership.map((goal) => {
        const goalKey = Object.keys(goal)[0];
        return {
          ...goal[goalKey],
        HR_comment: hrleadercomment.Leadership,
       }
      }),
    ],
      Performance: [performanceData.Performance.map((goal) => {
        const goalKey = Object.keys(goal)[0];
        return {
          ...goal[goalKey],
        HR_comment: hrperformcomment.Performance,
       }
      }),
    ],
      Development: [performanceData.Development.map((goal) => {
        const goalKey = Object.keys(goal)[0];
        return{
          ...goal[goalKey],
        HR_comment: hrdevelopcomment.Development,
       }
      }),
    ],
    };
setFormData(true)
    localStorage.setItem("formD", JSON.stringify(formDataReview));
    cogoToast.success("Saved Successfully")

    console.log(formDataReview );
  };
  const handleAll = async () => {
    const superv = JSON.parse(localStorage.getItem("formD")) || {};
    const appraisalData = JSON.parse(localStorage.getItem("appraisalData"));
    const sendData = {
      employee_id: sendEmployee.employee_id,
      employee_name: sendEmployee.employee_name,
      employee_appraisal: JSON.stringify(appraisalData),

      employee_goals: JSON.stringify(superv),
    };
    console.log(superv);
    console.log(sendData);

  try {
    const response = await postperformances(dispatch, sendData);
  } catch (error) {
    console.log(error)
  }
  };
  return (
    <div className="card-height" additionalClass="mt-4 p-4">
      <h5 style={{ fontWeight: "bold" }} className="co-grayx">
        HR Approval/Rejection of Performance
      </h5>

      <div
        style={{ padding: "10px", maxHeight: "680px" }}
        className="col-12 col-md-12 list-achievements"
      >
        <div className="select-container mt-3">
          <strong>Select Employee to view</strong>
          <select
            style={{ border: "1px solid #e8e9eb" }}
            onChange={handleEmployeeChange}
          >
            <option value="">Select an Option</option>
           {Array.isArray(employees) && employees.length > 0 && (
              employees.map((employee) => (
                <option key={employee.id} value={employee.username }>
                  {employee.username}
                </option>
              ))
            )}
          </select>
        </div>

        <br />

        {selectedEmployee && (
          <>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Leadership" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Leadership")}
                  style={{
                    backgroundColor:
                      activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                  }}
                >
                  <BsPeople className="mb-1" /> Leadership
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Performance" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Performance")}
                  style={{
                    backgroundColor:
                      activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <BsGraphUp className="mb-1" /> Performance
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Development" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Development")}
                  style={{
                    backgroundColor:
                      activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <IoPersonSharp className="mb-1" /> Development
                </button>
              </li>
            </ul>
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  activeTab === "Leadership" ? "show active" : ""
                }`}
                id="leadership-tab"
                style={{ marginTop: "7px" }}
              >
                 {performanceData &&  (
                  <div>
                    {Array.isArray(performanceData.Leadership) &&performanceData.Leadership.flat().map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Leadership
                          Goals/Objectives
                        </strong>
                       <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                
                {employeeAppraisal
                  .filter((item) => item.type === "Leadership")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                             <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                    </div>
                  ))}
                <>
                  <br />
                  {performanceData && performanceData.Leadership && performanceData.Leadership.map((performanceItemArray, index) => (
  performanceItemArray.map((item, subIndex) => (
    (item.Improve_Performance || item.Succession) && ( 
      <div key={`${index}-${subIndex}`}>
        {item.Succession && ( 
          <>
            <strong>Supervisor Comment for {item.goal_title}</strong>
            <CKEditor
              editor={ClassicEditor}
              config={{}}
              data={item.Succession|| ""} 
              onReady={(editor) => {
                // Editor is ready
              }}
              
            />
          </>
        )}
        
      </div>
    )
  ))
))}

                    {performanceData && performanceData.Leadership && performanceData.Leadership.map((performanceItemArray, index) => (
  performanceItemArray.map((item, subIndex) => (
    (item.Improve_Performance || item.Succession) && ( 
      <div key={`${index}-${subIndex}`}>
        {item.Improve_Performance && (
          <>
            <strong>Supervisor Comment for {item.goal_title}</strong>
            <CKEditor
              editor={ClassicEditor}
              config={{}}
              data={item.Improve_Performance || ""} 
              onReady={(editor) => {
                // Editor is ready
              }}
             
            />
          </>
        )}
       
      </div>
    )
  ))
))}
                  <br />
                  
                  {!performanceData?.Leadership?.some(arr => arr.some(item => item.HR_comment)) && (
                    <div>
                       <strong>HR Comment (Free text)</strong>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{}}
                      data={hrleadercomment.Leadership || ""}
                      onReady={(editor) => {
   
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
    
                        setHrLeaderComment((prev) => ({ ...prev, Leadership: data }));
                      }}
                    />
                   </div>
                  )}
                  <br />
                  {performanceData && performanceData.Leadership && performanceData.Leadership.map((performanceItemArray, index) => (
  performanceItemArray.map((item, subIndex) => (
    (item.HR_comment) && ( 
      <div key={`${index}-${subIndex}`}>
        {item.HR_comment && ( 
          <>
            <strong>HR Comment for {item.goal_title}</strong>
            <CKEditor
              editor={ClassicEditor}
              config={{}}
              data={item.HR_comment || ""} 
              onReady={(editor) => {
                // Editor is ready
              }}
             
            />
          </>
        )}
      
      </div>
    )
  ))
))}
                  <>
                    <br />
                  </>
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "25%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSubmit}
                  >
                    Confirm Submission
                  </button>
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "20%",
                      color: "#fff",
                      height: "6vh",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      backgroundColor: "red",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSendBack}
                  >
                    Send back
                  </button>
                </>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Performance" ? "show active" : ""
                }`}
                id="performance-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData &&  (
                  <div>
                    {Array.isArray(performanceData.Performance) &&performanceData.Performance.flat().map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Perfomance
                          Goals/Objectives
                        </strong>
                        <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Performance")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                            <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                    </div>
                  ))}
                <>
                 {performanceData && performanceData.Performance && performanceData.Performance.map((performanceItemArray, index) => (
                    performanceItemArray.map((item, subIndex) => (
        (item.Improve_Performance || item.Succession) && (
                        <div key={`${index}-${subIndex}`}>
                          {item.Improve_Performance && (
          <>
          <strong>Supervisor Comment for {item.goal_title}</strong>
          <CKEditor
            editor={ClassicEditor}
            config={{}}
            data={item.Improve_Performance|| ""} 
            onReady={(editor) => {
            
            }}
           
           />
                </>
                          )}
          
                        </div>
        )
      ))
    ))}
                  {performanceData && performanceData.Performance && performanceData.Performance.map((performanceItemArray, index) => (
                     performanceItemArray.map((item, subIndex) => (
          (item.Improve_Performance || item.Succession) && ( 
        <div key={`${index}-${subIndex}`}>
        {item.Succession && ( 
          <>
          <strong>Supervisor Comment for {item.goal_title}</strong>
          <CKEditor
            editor={ClassicEditor}
            config={{}}
            data={item.Succession || ""}
            onReady={(editor) => {
              // Editor is ready
            }}
           
            />
          </>
          )}
          
        
        </div>
      )
      ))
    ))}
                  <br />

                 
                  {!performanceData?.Performance?.some(arr => arr.some(item => item.HR_comment)) && (
                    <div>
                      <strong>HR Comment (Free text)</strong>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{}}
                      data={hrperformcomment.Performance || ""}
                      onReady={(editor) => {
   
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
    
                        setHrPerformComment((prev) => ({ ...prev, Performance: data }));
                      }}
                    />
                     </div>
                  )}
                 
                  <br />
                    {performanceData && performanceData.Performance && performanceData.Performance.map((performanceItemArray, index) => (
                    performanceItemArray.map((item, subIndex) => (
        (item.HR_comment) && (
                        <div key={`${index}-${subIndex}`}>
                          {item.HR_comment && ( 
          <>
          <strong>Supervisor Comment for {item.goal_title}</strong>
          <CKEditor
            editor={ClassicEditor}
            config={{}}
            data={item.HR_comment|| ""} 
            onReady={(editor) => {
            
            }}
            
           />
                </>
                          )}
          {!item.HR_comment && (
            <div>No Comment data available.</div>
          )}
                        </div>
        )
      ))
    ))}
                <br />
                <br />
                <br />

                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "25%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSubmit}
                  >
                    Confirm Submission
                  </button>
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "20%",
                      color: "#fff",
                      height: "6vh",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      backgroundColor: "red",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSendBack}
                  >
                    Send back
                  </button>
                </>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Development" ? "show active" : ""
                }`}
                id="development-tab"
                style={{ marginTop: "7px" }}
              >
                 {performanceData &&  (
                  <div>
                    {Array.isArray(performanceData.Development) &&performanceData.Development.flat().map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Development
                          Goals/Objectives
                        </strong>
                      <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Development")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                              <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
                      />
                      
                    </div>
                  ))}
                <br />
                <br />
                <br />

                {performanceData && performanceData.Development && performanceData.Development.map((performanceItemArray, index) => (
                     performanceItemArray.map((item, subIndex) => (
          (item.Improve_Performance || item.Succession) && ( 
        <div key={`${index}-${subIndex}`}>
        {item.Succession && ( 
          <>
          <strong>Supervisor Comment for {item.goal_title}</strong>
          <CKEditor
            editor={ClassicEditor}
            config={{}}
            data={item.Succession || ""} 
            onReady={(editor) => {
              // Editor is ready
            }}
           
            />
          </>
          )}
          
        </div>
      )
      ))
    ))}

                 {performanceData && performanceData.Development && performanceData.Development.map((performanceItemArray, index) => (
                    performanceItemArray.map((item, subIndex) => (
        (item.Improve_Performance || item.Succession) && (
                        <div key={`${index}-${subIndex}`}>
                          {item.Improve_Performance && ( 
          <>
          <strong>Supervisor Comment for {item.goal_title}</strong>
          <CKEditor
            editor={ClassicEditor}
            config={{}}
            data={item.Improve_Performance|| ""} 
            onReady={(editor) => {
            
            }}
            
           />
                </>
                          )}
          
                        </div>
        )
      ))
    ))}
              
                {!performanceData?.Development?.some(arr => arr.some(item => item.HR_comment)) && (
                  <div>
                     <strong>HR Comment (Free text)</strong>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{}}
                    data={hrdevelopcomment.Development || ""}
                    onReady={(editor) => {
   
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
    
                      setHrDevelopComment((prev) => ({ ...prev, Development: data }));
                    }}
                  />
                   </div>
                )}
                {performanceData && performanceData.Development && performanceData.Development.map((performanceItemArray, index) => (
                    performanceItemArray.map((item, subIndex) => (
        (item.HR_comment) && (
                        <div key={`${index}-${subIndex}`}>
                          {item.HR_comment&& ( 
          <>
          <strong>Supervisor Comment for {item.goal_title}</strong>
          <CKEditor
            editor={ClassicEditor}
            config={{}}
            data={item.HR_comment|| ""} 
            onReady={(editor) => {
            
            }}
           
           />
                </>
                          )}
          {!item.HR_comment && (
            <div>No Comment data available.</div>
          )}
                        </div>
        )
      ))
    ))}
                <br />
                <br />
                <br />
                <>
                  <br />
                </>
                {!formData ? (
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSubmitt}
                  >
                    Save Review
                  </button>
                ) : (
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "25%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleAll}
                  >
                    Confirm Submission
                  </button>
                )}

                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    backgroundColor: "red",
                  }}
                  color="#fff"
                  text="Submit"
                  onClick={handleSendBack}
                >
                  Send back
                </button>
              </div>
            </div>
          </>
        )}

        <br />
        <br />
      </div>
    </div>
  );
}

export default RewardPerformance;
