import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import Checkbox from "@material-ui/core/Checkbox";
import { getCountryAction } from "../../../../redux/actions/countryActions";
import { FormControlLabel , Select, MenuItem} from "@material-ui/core";

import AuthWrapper from "../AuthWrapper";
// import AuthWrapper from "../../../General/Authentication/AuthWrapper";
import WBlogo from "../../../../assets/icons/WBlogo.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import { isValidEmail } from "../../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  activateUserAction,
  createUserAction,
} from "../../../../redux/actions/authActions";
import {
  AUTH_LOADING,
  CLEAR_CREATE_USER,
  STOP_AUTH_LOADING,
} from "../../../../redux/actionTypes";

const BusinessSignUp = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
   const [selectedCountry, setSelectedCountry] = useState();
  const [code, setCode] = useState("");
  const { inviteCode } = useParams();
  const [state, setState] = useState({
    username: "",
    email: "",
    password: "",
    department: "none",
    account_type: "business",
    country_id: selectedCountry || 0,
    invited_by_code: inviteCode || "",
    isAdmin: false,
  });
   const { country, profile } = useSelector((store) => store);
  const { loading, userCreated, isAuthenticated } = useSelector(
    (store) => store.auth
  );
    const { countries } = country;

  // console.log("inviteCode", inviteCode);

  useEffect(() => {
    dispatch({ type: CLEAR_CREATE_USER });
     const promises = [getCountryAction(dispatch)];
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) history.push("/");
  }, [isAuthenticated, history]);

  const switchToReferral = () => {
    localStorage.setItem("accountType", "referral");
   
    window.location.reload();
  };

  const handleChange = ({ target: { id, value } }) => {
    // console.log("id", id, "val", value);
    const newValue = {};
    newValue[id] = value;
    setState({ ...state, ...newValue });
  };

  const handleCheckbox = (event) => {
    setState({ ...state, isAdmin: event.target.checked });
  };

  const handleSelect = ({ target: { value, name } }) => {
    const changed = {};
    changed[name] = value;
    setState({ ...state, ...changed });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: AUTH_LOADING });
    Promise.resolve(createUserAction(state, dispatch))
      .then(() =>
        setState({
          username: "",
          email: "",
          password: "",
          department: "none",
          country_id: selectedCountry,
           
          account_type: "business",
          isAdmin: false,
        })
      )
      .finally(() => {
        dispatch({ type: STOP_AUTH_LOADING });
      });
  };

  const handleActivate = (e) => {
    e.preventDefault();
    dispatch({ type: AUTH_LOADING });
    Promise.resolve(activateUserAction(dispatch, code))
      .then(() =>
        setState({
          username: "",
          email: "",
          password: "",
           country_id: selectedCountry,
          invited_by_code: inviteCode || "",
        })
      )
      .catch(() => {
        // console.log("Error in activate: ", err);
      })
      .finally(() => {
        dispatch({ type: STOP_AUTH_LOADING });
      });
  };
   const handleChangeCountry = ({ target: { value } }) => {
    const countryID = value;
    setSelectedCountry(countryID);
    setState({ ...state, country_id: countryID });
  };

  const departmentOptions = {
    none: "None",
    engineering: "Engineering",
    customer_service: "Customer Service",
    information_technology: "Information and Technology",
    human_resources: "Human Resources",
    marketing: "Marketing",
    operations: "Operations",
    finance: "Finance",
    public_relations: "Public Relations",
    general_management: "General Management",
  };

  return (
    <AuthWrapper>
      <div className="form-container d-flex justify-content-center ">
        {(userCreated || pathname.includes("activate")) && (
          <div className="auth-form ml-md-4">
            <h4 className="text-center mt-4">Enter Activation Code</h4>
            <p>
              (<small>Check you mail inbox/spam for the activation code</small>)
            </p>
            <form onSubmit={handleActivate} className="pt-2 pb-5">
              <CustomInput
                onChange={(e) => setCode(e.target.value)}
                type="text"
                label="Invite Code"
                id="icode"
                value={state.invited_by_code}
                isRequired
              />

              <CustomButton
                type="submit"
                backgroundColor="#3A6ED4"
                color="#fff"
                text="Activate"
                loading={loading}
                additionalClass="my-5"
              />

              <div className="d-flex justify-content-center">
                <Link to="/" className="align-self-center">
                  Back to Home
                </Link>
              </div>
            </form>
          </div>
        )}
        {!userCreated && !pathname.includes("activate") && (
          <div className="auth-form ml-md-4">
            <h4 className="text-center mt-4">
              Sign Up to your Business Account
            </h4>
            <CustomButton
              icon={WBlogo}
              alt="logo"
              backgroundColor="#3A6ED4"
              color="#fff"
              text="Switch to workbrook Referral"
              additionalClass="mt-3 mb-5"
              handleClick={() => switchToReferral()}
            />
            <form onSubmit={handleSubmit}>
              <CustomInput
                onChange={handleChange}
                type="text"
                label="Choose a username"
                id="username"
                value={state.username}
              />
              <CustomInput
                onChange={handleChange}
                type="text"
                label="Company Email"
                id="email"
                value={state.email}
              />
              

             <p>Country</p>
              <Select
                labelId="country_id"
                name="country"
                label="country"
                value={state.country_id}
                onChange={handleChangeCountry}
                className="fullwidth-select"
              >
                <MenuItem value="">Select an country</MenuItem>
                {countries.map(({ name, id }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <CustomInput
                tooltipText="Password should be alphanumeric and have one uppercase character"
                onChange={handleChange}
                type="password"
                label="Password"
                id="password"
                value={state.password}
                icon={EyeIcon}
              />

              <CustomSelect
                handleChange={handleSelect}
                id="department"
                name="department"
                label="Department"
                value={state.department}
                options={departmentOptions}
                currentValue={state.department}
              />

              <div className="d-flex justify-content-between align-items-center mt-3 small-text">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      id="isAdmin"
                      name="isAdmin"
                      // value={state.isAdmin}
                      onChange={handleCheckbox}
                      checked={state.isAdmin}
                    />
                  }
                  label="Request admin access - post oppotunities to the referral timeline, view job analytics and shortlist applicants."
                />
              </div>

              <p className="pt-1 pb-1 mb-0 small-text text-center">
                By Signing up, I agree to the{" "}
                <a
                  href="https://workbrook.com/apps-term-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                of this application
              </p>

              <CustomButton
                type="submit"
                backgroundColor="black"
                color="#fff"
                text="Sign Up"
                loading={loading}
                disabled={[
                  !isValidEmail(state.email),
                  !state.password,
                ].includes(true)}
                additionalClass="my-5"
              />
            </form>
            <p className="pt-4 pb-5 mb-0 small-text text-center">
              Already have an account? <Link to="/signin">Sign In</Link>{" "}
            </p>
          </div>
        )}
      </div>
    </AuthWrapper>
  );
};

export default BusinessSignUp;
