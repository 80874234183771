import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import BackButton from "../../../components/BackButton";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import Create from "./Create"; 
import CategoryList from "./CategoryList"; 
import CreateExpert from './CreateExpert';
import ExpertList from './ExpertList';
import "./index.scss"; 
const subMenuOptions = [
  ["Create Category", "/experts/category"],
  ["List Categories", "/experts/list"],
  ["Create Experts", "/experts/create"],
  ["List Experts", "/experts/all"]

];

  const ExpertiseCategory = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  
  const displaySection = () => {
    if (pathname.includes("category")) 
    {
      return <Create />;
    } 
   else if (pathname.includes("list")) 
    {
       return <CategoryList/>;
    } 
    else if (pathname.includes("create")) 
    {
       return <CreateExpert/>;
    }   
   else if (pathname.includes("all")) 
    {
       return <ExpertList/>;
    } 
     
    
    else {
      return <Create />;
    }
  };
  return (
    <Layout>
      <div className="referrals d-flex mt-3">
        <div className="w-25">
          <BackButton />
          <SubMenu menuOptions={subMenuOptions} />
        </div>
        <div className="w-75 ml-4 d-flex align-items-center justify-content-center">
          {displaySection()}
        </div>
      </div>
    </Layout>
  );
};

export default ExpertiseCategory;
