import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecruiterWrapper from "../RecruiterWrapper";
import SendJob from "../SendJob";
import placeholder from "../../../../assets/icons/placeholder.svg";
import Doughnut from "../../../../assets/icons/doughnut.svg";
import Chart from "../../../../assets/icons/chart.svg";
import Card from "../../../../components/Card";
// import icons from "../../../../assets/icons/icon-collection.svg";
import { useHistory, useParams } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import { getRecruiter } from "../../../../redux/actions/recruiterAction";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import "../index.scss";

const RecruiterProfile = () => {
  const { id, jobId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("view");
  const [recruiterName, setRecruiterName] = useState("");
  const { currentRecruiter } = useSelector(({ recruiter }) => recruiter);
  const [job, setJob] = useState({});
  useEffect(() => {
    setLoading(true);
    Promise.resolve(getRecruiter(dispatch, id)).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);

  useEffect(() => {
    if (history.location?.state?.job) {
      setJob(history.location?.state?.job);
    }
  }, [history.location.state]);
  // console.log("currentRecruiter", currentRecruiter);

  useEffect(() => {
    if (currentRecruiter) {
      const fullname = currentRecruiter.fullname;
      const nameArr = fullname.split(" ");
      let newName = `${nameArr[0]}`;
      if (nameArr.length > 1) {
        newName = `${nameArr[0]} ${nameArr[1].charAt(0)}.`;
      }

      setRecruiterName(newName);
    }
  }, [currentRecruiter]);

  return (
    <RecruiterWrapper>
      <div className="m-2 position-relative">
        {page === "send" && (
          <SendJob
            recruiterId={currentRecruiter.id}
            name={currentRecruiter.fullname}
            jobId={jobId}
            job={job}
          />
        )}
        {page === "view" && (
          <>
            {loading && <SectionLoader />}
            {!loading && !currentRecruiter && (
              <EmptyState text="Recruiter not found" />
            )}
            {!loading && currentRecruiter && (
              <div>
                <div className="mt-3 px-2">
                  <div className="profile-header d-flex flex-column align-items-center">
                    <img
                      height="150"
                      className="mb-2 rounded-circle"
                      src={currentRecruiter.profilePicture}
                      onError={({ target }) => {
                        target.src = placeholder;
                      }}
                      alt="profile"
                    />
                    <h4>
                      <b>{recruiterName}</b>
                    </h4>
                    <span className="co-gray">{currentRecruiter.location}</span>
                  </div>
                </div>

                <div className="tracker d-flex flex-column mt-3">
                  <button
                    onClick={() =>
                      history.push(`/recruiter/activity/${currentRecruiter.id}`)
                    }
                    className="d-flex justify-content-between align-items-center mb-2"
                  >
                    View Activity Tracker
                    <img alt="chart" src={Chart} />
                  </button>
                  <button
                    onClick={() =>
                      history.push(
                        `/recruiter/performance/${currentRecruiter.id}`
                      )
                    }
                    className="d-flex justify-content-between align-items-center mb-2"
                  >
                    View Performance Tracker
                    <img alt="chart" src={Doughnut} />
                  </button>

                  <Card backgroundColor="#F7F7F7" additionalClass="mt-3 p-4">
                    <div className="mt-1">
                      <h6>About</h6>
                      <p className="co-darkgray">{currentRecruiter.summary}</p>
                    </div>
                    <div className="mt-4">
                      <h6>Recruitment and Experience</h6>
                      <ul className="d-flex flex-wrap mt-3">
                        <li className="mr-2 py-1 px-2 outlined">
                          {currentRecruiter.industryRecruitementExperience}
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4">
                      <h6>Placement Fee</h6>
                      <ul className="d-flex flex-wrap mt-3">
                        <li className="mr-2 py-1 px-2 outlined">
                          {currentRecruiter.placementPercentage}% of annual
                          salary
                        </li>
                      </ul>
                    </div>
                    <div className="mt-4">
                      <h6>Industry</h6>
                      <ul className="d-flex flex-wrap mt-3">
                        {currentRecruiter.industry.map((item, index) => (
                          <li
                            key={index.toString()}
                            className="mr-2 py-1 px-2 outlined"
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* <div className="d-flex my-4">
                      <svg width="22" height="22" className="mr-2">
                        <use href={`${icons}#link`}></use>
                      </svg>
                      {currentRecruiter.linkdnUrl && (
                        <Link
                          className="underlined co-primary mr-2"
                          to={currentRecruiter.linkdnUrl}
                        >
                          LinkedIn
                        </Link>
                      )}
                    </div> */}
                  </Card>
                </div>
                <CustomButton
                  handleClick={() => setPage("send")}
                  additionalClass="mt-4"
                  backgroundColor="#3a6ed4"
                  color="#fff"
                  text="SEND JOB DETAILS"
                />
              </div>
            )}
          </>
        )}
      </div>
    </RecruiterWrapper>
  );
};

export default RecruiterProfile;
