import React from 'react';
import ErrorComponent from '../../../components/ErrorBoundary/ErrorComponent';

const NotFound = () => {
  return (
    <ErrorComponent heading="Page Not Found" />
  );
};

export default NotFound;
