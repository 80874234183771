import React from "react";
import {
  HomeIcon,
  InsightsIcon,
  WalletIcon,
  SavedIcon,
  LeagueIcon,
  InviteIcon,
  SubIcon,
  SettingsIcon,
  RecruiterIcon,
  MyJobsIcon,
} from "../../components/CustomIcons";
import WBtext from "../../assets/icons/WBtext.svg";
import WBlogo from "../../assets/icons/WBlogo.svg";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { bool, string } from "prop-types";
import { NavLink } from "react-router-dom";
import { openRecruiterModal } from "../../redux/actions/demoActions";
import { useDispatch } from "react-redux";
import ReactTooltip from 'react-tooltip';
import HelpIcon from '@material-ui/icons/Help';

import './menu.css'
const Menu = ({ toolbar, open }) => {
  const dispatch = useDispatch();
  return (
    <div className="menu">
      <div className={toolbar}>
        <img className="w-75 pl-2" src={open ? WBtext : WBlogo} alt="logo" />
      </div>
      <ReactTooltip/>
       
      <List>
        <NavLink to="/home">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <HomeIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </NavLink>

        <NavLink to="/insights/applications">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <InsightsIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Insights" />
            <span className="text-white-custom" data-tip="See data and act on your jobs, your application, your referrals… a bird-eye view of your activities on the platform">
              <HelpIcon/>
            </span>

          </ListItem>
        </NavLink>

        <NavLink to="/myjobs">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <MyJobsIcon viewBox="0 0 40 7" />
            </ListItemIcon>
            <ListItemText primary="My Jobs"/>
            <span className="text-white-custom" data-tip="Here you can see all jobs you published and you can invite your contacts to refer">
              <HelpIcon/>
            </span>

          </ListItem>
        </NavLink>
        <NavLink to="/wallet/add">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <WalletIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="My Wallet" />
            <span className="text-white-custom" data-tip="We created a virtual wallet just for you! Start earning!">
              <HelpIcon/>
            </span>

          </ListItem>
        </NavLink>
        <NavLink to="/saved">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <SavedIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Saved" />
            <span className="text-white-custom" data-tip="Here’s where you see job you saved for later">
              <HelpIcon/>
            </span>

          </ListItem>
        </NavLink>
        <NavLink to="/league">
          <ListItem button>
            <ListItemIcon className="ml-1 ">
              <LeagueIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Community Feeds" />
            <span className="text-white-custom" data-tip="Now this is the pride of all your efforts. See where you stand and take on your city! Time to brag!">
              <HelpIcon/>
            </span>
            
          </ListItem>

        </NavLink>
        <NavLink to="/invite/link">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <InviteIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Invite a friend" />
            <span className="text-white-custom" data-tip="You really don’t want to enjoy all these alone, do you?">
              <HelpIcon/>
            </span>


          </ListItem>
        </NavLink>
        <NavLink to="/subscriptions/post-job">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <SubIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Subscriptions" />
            <span className="text-white-custom" data-tip="Here you’ll see all paid services and you can track yours">
              <HelpIcon/>
            </span>

          </ListItem>
        </NavLink>
        <NavLink to="/settings/notifications">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <SettingsIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Settings" />
            <span className="text-white-custom" data-tip="Customize the platform to look more like you’ll like it to.">
              <HelpIcon/>
            </span>
          </ListItem>
        </NavLink>
        {/* <NavLink to="/become-recruiter">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <RecruiterIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Become a Recruiter" />
          </ListItem>
        </NavLink> */}
        {/* <NavLink
          to="/recruiter"
          onClick={(e) => {
            e.preventDefault();
            openRecruiterModal(dispatch);
          }}
        >
          <ListItem button>
            <ListItemIcon className="ml-1">
              <RecruiterIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Recruiter" />
            <span className="text-white-custom" data-tip="Home of professional recruiters- see your analysis, reviews and hire some more!">
              <HelpIcon/>
            </span>

          </ListItem>
        </NavLink> */}
      </List>
    </div>
  );
};

Menu.propTypes = {
  toolbar: string,
  open: bool,
};

Menu.defaultProps = {
  open: false,
};

export default Menu;
