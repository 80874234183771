import httpRequest from "../../ajax";
import {
  GET_PROFILE,
  UPDATE_PROFILE,
  ERROR,
  SET_RESUME,
  GET_OTHER_USER,
  GET_ADMIN_REQUESTS,
  APPROVE_ADMIN_REQUEST,
  DECLINE_ADMIN_REQUEST,
} from "../actionTypes";

/**
 * @description set profile to local storage
 * @function persistUpdatedProfile
 * @param {object} data - profile
 * @returns {object} - returns the action
 */
const persistUpdatedProfile = (data) => {
  const rememberMe = localStorage.getItem("rememberMe");

  if (rememberMe && rememberMe === "true") {
    const prevProfile = JSON.parse(localStorage.getItem("userData"));
    const newProfile = {
      ...prevProfile,
      ...data,
    };
    localStorage.setItem("userData", JSON.stringify(newProfile));
    return newProfile;
  } else {
    const prevProfile = JSON.parse(sessionStorage.getItem("userData"));
    const newProfile = {
      ...prevProfile,
      ...data,
    };
    sessionStorage.setItem("userData", JSON.stringify(newProfile));
    return newProfile;
  }
};

/**
 * @description get profile action
 * @function getProfileAction
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const getProfileAction = (dispatch) =>
  httpRequest("get", "/settings/profile")
    .then(({ data: { data } }) => {
      const newProfile = persistUpdatedProfile(data);
      const action = { type: GET_PROFILE, payload: newProfile };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

/**
 * @description update profile action
 * @function updateProfileAction
 * @param {object} requestData - request data
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const updateProfileAction = (requestData, dispatch) =>
  httpRequest("post", "/settings/profile/update", requestData)
    .then(({ data: { data } }) => {
      const newProfile = persistUpdatedProfile(data);
      const action = { type: UPDATE_PROFILE, payload: newProfile };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

// export const setResume = (dispatch, link) => {
//   dispatch({
//     type: SET_RESUME,
//     payload: link,
//   });
// };
export const setResume = (dispatch, resumeData) => {
  dispatch({
    type: SET_RESUME,
    payload: resumeData,
  });
};

export const getOtherUserAction = (dispatch, id) =>
  httpRequest("get", `/settings/profile/${id}/others`)
    .then(({ data: { data } }) => {
      const action = { type: GET_OTHER_USER, payload: data[0] };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getPendingAdminRequests = (dispatch) =>
  httpRequest("get", `/admin/requests/pending`)
    .then(({ data: { data } }) => {
      const action = { type: GET_ADMIN_REQUESTS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const approveAdminRequests = (dispatch, id) =>
  httpRequest("post", `/admin/requests/${id}/approve`)
    .then(({ data: { data } }) => {
      const action = { type: APPROVE_ADMIN_REQUEST, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const declineAdminRequest = (dispatch, id) =>
  httpRequest("post", `/admin/requests/${id}/decline`)
    .then(({ data: { data } }) => {
      const action = { type: DECLINE_ADMIN_REQUEST, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
