import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import Card from "../../../components/Card";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import CustomButton from "../../../components/CustomButton";
import Checked from "../../../assets/icons/Checked.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionsConfig } from "../../../redux/actions/subscriptionActions";
import { SectionLoader } from "../../../components/PageLoader";
import EmptyState from "../../../components/EmptyState";
import {
  createSubscription,
  getMySubscriptions,
} from "../../../redux/actions/subscriptionActions";

const subMenuOptions = [
  ["Post-A-Job", "/subscriptions/post-job"],
  ["Recruiters", "/subscriptions/recruiters"],
  ["Active Subscriptions", "/subscriptions/active"],
];

const NewlineText = ({ text }) => {
  return text.split("\n").map((str, index) => (
    <p key={index.toString()}>
      <span className="mr-2">
        <img src={Checked} alt="checked" />
      </span>
      {str}
    </p>
  ));
};

const Subscriptions = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const { availableSubsreiptions } = useSelector(
    ({ subscription }) => subscription
  );
  const { mySubscriptions } = useSelector((store) => store.subscription);

  const [recruiterSubscriptions, setRecruiterSubscriptions] = useState([]);
  const [jobSubscriptions, setJobSubscriptions] = useState([]);

  // console.log("SUBS:", availableSubsreiptions);
  // console.log("MYSUB:", mySubscriptions);

  useEffect(() => {
    setLoading(true);
    Promise.resolve(
      getSubscriptionsConfig(dispatch),
      getMySubscriptions(dispatch)
    ).then(() => setLoading(false));
  }, [dispatch]);

  const handleSubscribe = (configId) => {
    const payload = {
      subscription_config_id: parseInt(configId),
      isPaymentFromWallet: 1,
      card_id: 0,
    };

    setLoading(true);

    Promise.resolve(createSubscription(dispatch, { ...payload })).then(() =>
      setLoading(false)
    );
  };

  useEffect(() => {
    const jobArray = [];
    const recruiterArray = [];
    if (availableSubsreiptions && availableSubsreiptions[0]) {
      availableSubsreiptions.forEach((subscription) => {
        if (subscription.category === "Recruiter") {
          recruiterArray.push(subscription);
        } else {
          jobArray.push(subscription);
        }
      });

      setJobSubscriptions(jobArray);
      setRecruiterSubscriptions(recruiterArray);
    }
  }, [availableSubsreiptions]);

  const displaySection = () => {
    if (pathname.includes("active")) {
      return (
        <div className="">
          <EmptyState text="you have no active Subscriptions" />
          {/* <Card noPadding additionalClass="p-0 mb-4">
            <div className="d-flex align-items-center status-sent px-3 py-2">
                Post-A-Job (Annual)
            </div>
            <div className="px-4 bg-gray bottom-mid-radius py-3 d-flex justify-content-end">
                Expires 26 April 2024
            </div>
          </Card> */}
        </div>
      );
    } else if (pathname.includes("recruiters")) {
      return (
        <div className="m-3">
          {/* Commented till when we activate payments */}
          {/* {recruiterSubscriptions.map((sub) => (
            <Card key={sub.id} noPadding additionalClass="p-0 mb-4">
              <div className="bg-gray mid-radius p-2 d-flex justify-content-between align-items-center">
                <div className="mx-4 py-3">
                  {sub.name} ({sub.type})
                </div>
                <span className="w-25">
                  <CustomButton
                    additionalClass="p-3"
                    text={`PAY ${sub.amount}`}
                    backgroundColor="#3A6ED4"
                    color="#fff"
                  />
                </span>
              </div>
            </Card>
          ))} */}

          {recruiterSubscriptions.length > 1 && (
            <Card
              key={recruiterSubscriptions[0].id}
              noPadding
              additionalClass="p-0 mb-4 mt-4"
            >
              <div className="bg-gray mid-radius p-2 d-flex justify-content-between align-items-center">
                <div className="mx-4 py-3">
                  {recruiterSubscriptions[0].description}
                  {/* ({recruiterSubscriptions[0].type}) */}
                </div>
                <span className="w-25">
                  <CustomButton
                    additionalClass="p-3"
                    text={`PAY ${recruiterSubscriptions[0].amount}`}
                    backgroundColor="#3A6ED4"
                    color="#fff"
                    handleClick={() =>
                      handleSubscribe(recruiterSubscriptions[0].id)
                    }
                    loading={loading}
                  />
                </span>
              </div>
            </Card>
          )}

          <p className="d-flex mt-3">
            <img src={Checked} alt="checked" />
            You can only send requests to 2 recruiters per recruiter access. If
            a recruiter declines you get refunded $99 back into your wallet.
          </p>
        </div>
      );
    } else {
      return (
        <div className="m-3">
          <Card noPadding additionalClass="p-0 mb-4">
            <div className="d-flex align-items-center status-sent px-3 py-2">
              Free For now
            </div>
            <div className="bg-gray bottom-mid-radius px-4 py-3">
              Posting a job on the referral timeline is free for now
            </div>
          </Card>
          {/* {jobSubscriptions && jobSubscriptions[0] && (
            <>
              {jobSubscriptions.map((sub) => (
                <Card key={sub.id} noPadding additionalClass="p-0 mb-4">
                  <div className="d-flex align-items-center status-sent px-3 py-2">
                    {sub.name} ({sub.type})
                  </div>
                  <div className="bg-gray bottom-mid-radius px-4 py-3">
                    {<NewlineText text={sub.description} />}
                    <div className="d-flex justify-content-end">
                      <span className="d-none d-md-block w-25">
                        <CustomButton
                          additionalClass="p-3"
                          text={`PAY ${sub.amount}`}
                          backgroundColor="#3A6ED4"
                          color="#fff"
                        />
                      </span>
                      <span className="d-md-none w-75">
                        <CustomButton
                          additionalClass="p-3"
                          text={`PAY ${sub.amount}`}
                          backgroundColor="#3A6ED4"
                          color="#fff"
                        />
                      </span>
                    </div>
                  </div>
                </Card>
              ))}
            </>
          )} */}
        </div>
      );
    }
  };
  return (
    <Layout>
      <div className="position-relative d-flex flex-column flex-md-row mt-3">
        <div className="sub-menu-container">
          <BackButton />
          <SubMenu menuOptions={subMenuOptions} />
        </div>
        {loading && <SectionLoader />}
        {!loading && (
          <div className="main-content ml-md-4">
            <Card additionalClass="position-relative py-4">
              {displaySection()}
            </Card>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Subscriptions;
