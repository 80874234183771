import React from 'react';
import { bool, func, string } from 'prop-types';
import { anonymousFunc } from '../../utils/helpers';

const LinkButton = ({ loading, onClick, label, additionalClass }) => {
  return (
    <button onClick={loading ? anonymousFunc : onClick}
      type="button"
      className={`co-primary bg-white borderless ${additionalClass}`}>
      <b>{label}</b>
    </button>
  );
};

LinkButton.propTypes = {
  onClick: func.isRequired, 
  label: string.isRequired, 
  additionalClass: string,
  loading: bool
};

LinkButton.defaultProps = {
  additionalClass: ""
};

export default LinkButton;
