/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecruiterWrapper from "../RecruiterWrapper";
// import SearchInput from "../../../../components/CustomSearchInput";
import icons from "../../../../assets/icons/icon-collection.svg";
import Card from "../../../../components/Card";
import CustomButton from "../../../../components/CustomButton";
import { useHistory, useLocation } from "react-router-dom";
import Applicants from "./Applicants";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Menu, MenuItem, Divider, IconButton } from "@material-ui/core";
import copy from "copy-to-clipboard";
import cogoToast from "cogo-toast";
import { getRecruiterInsight } from "../../../../redux/actions/recruiterAction";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";

const RecruiterInsights = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { insight } = useSelector(({ recruiter }) => recruiter);
  const { userData } = useSelector(({ auth }) => auth);

  const recrJobs = insight?.jobs ? insight.jobs : [];
  const refCode = userData.refCode;

  // console.log("JOBS", recrJobs);

  const { pathname } = useLocation();

  useEffect(() => {
    setLoading(true);
    Promise.resolve(getRecruiterInsight(dispatch)).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);
  const getView = () => {
    if (pathname.includes("applicants")) {
      return (
        <div className="w-100 mt-3">
          <Applicants />
        </div>
      );
    } else {
      if (loading) {
        return <SectionLoader />;
      } else if (!loading && recrJobs.length == 0) {
        return <EmptyState text="You have no job applicant" />;
      } else if (!loading && recrJobs && recrJobs[0]) {
        return (
          <>
            {recrJobs &&
              recrJobs.map((job, index) => (
                <JobCard key={index} job={job} refCode={refCode} />
              ))}
          </>
        );
      }
      // );
    }
  };

  return (
    <RecruiterWrapper>
      <div className="row m-4">
        <h3 className="mb-5">
          <b>Insights</b>
        </h3>
        {/* <SearchInput /> */}
        {getView()}
      </div>
    </RecruiterWrapper>
  );
};

const JobCard = ({ job, refCode }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [copied, setCopied] = useState(false);
  const [jobURL, setJobURL] = useState("");
  const [openShare, setOpenShare] = useState(false);
  const textAreaRef = useRef(null);

  // const openDialog = () => {
  //   setOpen(true);
  // };

  // const closeDialog = () => {
  //   setOpen(false);
  // };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (copied) {
      cogoToast.success("Link copied");
      setCopied(false);
    }
  }, [copied]);

  const handleCopy = (job_id, recruiterId) => {
    // console.log("JOBID", job_id);
    const url = `${process.env.REACT_APP_WEB_URL}/home/${job_id}/apply/${recruiterId}/${refCode}`;
    setJobURL(url);
    copy(url);
    setOpenShare(true);
  };

  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand("copy");
    setCopied(true);
  };

  const title = `Job Opening for the role of ${job.role_title} at ${job.campany_name}`;
  const hashtags = ["workbrook", "job", "workbrookjobs", "jobopening"];

  return (
    <div className="my-4 w-100">
      <>
        <Card noPadding additionalClass="w-100 p-0 mb-4">
          <div className="d-flex align-items-center status-recruiter px-3 py-2">
            <span className="mr-2">
              <svg width="13" height="13">
                <use href={`${icons}#elipse`}></use>
              </svg>
            </span>
            {job.applicants.length} Applicants
          </div>

          <div className="d-flex justify-content-between  mt-2 mx-4">
            <div className="d-flex flex-column">
              {job.role_title}
              <span className="smalltext co-darkgray">{job.campany_name}</span>
            </div>
            <IconButton onClick={handleClick}>
              <svg width="20" height="15">
                <use href={`${icons}#dots`}></use>
              </svg>
            </IconButton>
          </div>
          {/* <div className="mx-4 py-3">
            <div>
              <p className="small-text thin-text mb-2">Top Applicants</p>
              <img src={Placeholder} alt="avatar" />
              <img src={Placeholder} alt="avatar" />
              <img src={Placeholder} alt="avatar" />
            </div>
          </div> */}
          <div className="w-100 d-flex justify-content-end">
            <div className="w-50 pb-3 d-flex align-items-center">
              <span className="w-100 mx-3">
                <CustomButton
                  handleClick={() =>
                    history.push(`/recruiter/insights/applicants/${job.job_id}`)
                  }
                  text=" Applicants"
                  color="#fff"
                  backgroundColor="#3A6ED4"
                />
              </span>
            </div>
          </div>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "15rem",
                marginTop: "0.2rem",
                borderRadius: "1rem",
                boxShadow: "0px 0px 5px -3px rgba(0,0,0,0.2)",
              },
            }}
          >
            <MenuItem
              className="px-4 py-2 medium-text"
              onClick={() => handleCopy(job.job_id, job.recruiterId)}
            >
              Copy Link
            </MenuItem>
            {/* <MenuItem
              className="px-4 py-2 medium-text co-lightred"
              onClick={openDialog}
            >
              Edit Shortlist
            </MenuItem> */}
            <Divider />
            <MenuItem
              className="px-4 py-3 co-lightred medium-text"
              onClick={handleClose}
            >
              Cancel
            </MenuItem>
          </Menu>
        </Card>
      </>

      {openShare && (
        <Dialog
          open={openShare}
          onClose={() => setOpenShare(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <div className="py-4 mt-4">
              <h5 className="co-primary text-center">
                Refer people using your unique link
              </h5>
              <p className="mx-auto w-75 my-5 w-100">
                <textarea
                  ref={textAreaRef}
                  value={`${jobURL}`}
                  className="borderless w-100 copy-link"
                  onChange={() => {}}
                  rows="3"
                />
              </p>
              <div className="d-flex px-4 mt-3 justify-content-between">
                <CustomButton
                  backgroundColor="#41B883"
                  color="#fff"
                  text="Copy Link"
                  handleClick={copyToClipboard}
                  additionalClass="mr-3 bg-primary"
                />
              </div>
              <div
                className="mx-auto w-75 w-100 mt-4"
                style={{ textAlign: "center" }}
              >
                <h6>Share to Social Network</h6>
                <div>
                  <FacebookShareButton
                    url={`${jobURL}`}
                    quote={title}
                    className="mr-2"
                    hashtag="#workbrook #job"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={`${jobURL}`}
                    title={title}
                    className="mr-2"
                    hashtags={hashtags}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>

                  <LinkedinShareButton url={`${jobURL}`} className="mr-2">
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default RecruiterInsights;
