import React, { useEffect, useState } from "react";
import { FormControlLabel } from "@material-ui/core";
import CustomSwitch from "../../../../components/CustomSwitch";
import CustomButton from "../../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../../../../redux/actions/settingsActions";
import { ButtonLoader } from "../../../../components/ButtonLoader";

const Notificationsd = () => {
  const dispatch = useDispatch();
  const { notificationSettings } = useSelector(({ settings }) => settings);
  const [saving, setSaving] = useState();
  const [state, setState] = useState({
    all_notification: "0",
    email_notification: "0",
    sms_notification: "0",
    workbrook_updates: "0",
  });

  console.log("notifications", notificationSettings);

  useEffect(() => {
    getNotificationSettings(dispatch);
  }, [dispatch]);

  useEffect(() => {
    setState({
      all_notification: notificationSettings.all_notification,
      email_notification: notificationSettings.email_notification,
      sms_notification: notificationSettings.sms_notification,
      workbrook_updates: notificationSettings.workbrook_updates,
    });
  }, [notificationSettings]);

  const handleChange = (event) => {
    const checked = event.target.checked;
    const name = event.target.name;

    console.log("Chkd", checked);

    setState({ ...state, [event.target.name]: checked ? "1" : "0" });
  };

  const handleAllNotifications = (event) => {
    const checked = event.target.checked;

    if (checked) {
      setState({
        ...state,
        ...{
          all_notification: "1",
          email_notification: "1",
          sms_notification: "1",
          workbrook_updates: "1",
        },
      });
    } else {
      setState({
        ...state,
        ...{
          all_notification: "0",
          email_notification: "0",
          sms_notification: "0",
          workbrook_updates: "0",
        },
      });
    }
  };

  const handleClick = () => {
    setSaving(true);
    const data = {};
    // Object.entries(state).forEach(
    //   ([notification, value]) => (data[notification] = +value)
    // );

    if (state.all_notification == "1") {
      setState({
        ...state,
        ...{
          all_notification: "1",
          email_notification: "1",
          sms_notification: "1",
          workbrook_updates: "1",
        },
      });
    } else {
      setState({
        ...state,
        ...{
          all_notification: "0",
          email_notification: "0",
          sms_notification: "0",
          workbrook_updates: "0",
        },
      });
    }

    console.log("State", state);

    // if (
    //   !state.email_notification ||
    //   !state.sms_notification ||
    //   !state.workbrook_updates
    // ) {
    //   data["all_notification"] = "0";
    // } else if (
    //   state.email_notification &&
    //   state.sms_notification &&
    //   state.workbrook_updates
    // ) {
    //   data["all_notification"] = "1";
    // }

    Promise.resolve(updateNotificationSettings(dispatch, state)).then(() =>
      setSaving(false)
    );
  };

  return (
    <div>
      <h6 className="bolder-text">Push Notifications</h6>
      <p className="small-text co-darkgray mt-3">
        We advice that you turn on push notifications to receive the latest
        opportunities and updates on your applications.
      </p>
      <div className="d-flex align-items-center justify-content-between">
        Turn Off All Notifications
        <FormControlLabel
          control={
            <CustomSwitch
              checked={!!parseInt(state.all_notification)}
              onChange={handleAllNotifications}
              name="all_notification"
            />
          }
        />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        Email
        <FormControlLabel
          control={
            <CustomSwitch
              checked={!!parseInt(state.email_notification)}
              onChange={handleChange}
              name="email_notification"
            />
          }
        />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        SMS
        <FormControlLabel
          control={
            <CustomSwitch
              checked={!!parseInt(state.sms_notification)}
              onChange={handleChange}
              name="sms_notification"
            />
          }
        />
      </div>
      <div className="d-flex align-items-center justify-content-between mb-5">
        Update from workbrook
        <FormControlLabel
          control={
            <CustomSwitch
              checked={!!parseInt(state.workbrook_updates)}
              onChange={handleChange}
              name="workbrook_updates"
            />
          }
        />
      </div>
      <div className="d-flex mt-5 justify-content-end">
        <span className="w-25">
          <CustomButton
            handleClick={handleClick}
            text={saving ? <ButtonLoader /> : "Save"}
            backgroundColor="#3a6ed4"
            color="#fff"
          />
        </span>
      </div>
    </div>
  );
};

export default Notificationsd;
