import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import { changePassword } from "../../../../redux/actions/settingsActions";

const Security = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const handleChange = ({ target: { value, name } }) => {
    const newValue = {};
    newValue[name] = value;
    setState({ ...state, ...newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword(dispatch, state);
  };

  return (
    <div>
      <h6 className="bolder-text">Change My Password</h6>
      <form className="mt-4" onSubmit={handleSubmit}>
        <CustomInput
          name="current_password"
          onChange={handleChange}
          type="password"
          label="Current Password"
          id="role"
          isRequired
        />
        <CustomInput
          type="password"
          name="new_password"
          label="New Password"
          id="name"
          onChange={handleChange}
          isRequired
        />
        <CustomInput
          name="confirm_new_password"
          onChange={handleChange}
          type="password"
          label="Confirm Password"
          id="role"
          isRequired
        />
        <CustomButton
          type="submit"
          additionalClass="my-5"
          text="Save"
          backgroundColor="#3A6ED4"
          color="#fff"
        />
      </form>
    </div>
  );
};

export default Security;
