import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { setResume } from "../../../../../redux/actions/profileActions";
import icons from "../../../assets/icons/icon-collection.svg";
import { PageLoader } from "../../../components/PageLoader";
import Layout from "../../../layout";
import { getAllEducationAction } from "../../../redux/actions/educationActions";
import { getAllExperienceAction } from "../../../redux/actions/experienceAction";
import { getProfileAction } from "../../../redux/actions/profileActions";
import { getMySkillsAction } from "../../../redux/actions/skillsAction";
import { ternaryResolver } from "../../../utils/helpers";
import BackButton from "../../../components/BackButton";
import "./index.scss";
import ProfileLayout from "./Layout";

const Profile = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const dispatch = useDispatch();
  const { profile, education, skill } = useSelector((store) => store);
  const [experiences, setExperiences] = useState([]);
  const { profileData } = profile;
  const { myEducations } = education;
  const { mySkills } = skill;

  useEffect(() => {
    setPageLoading(true);
    Promise.all([
      getProfileAction(dispatch),
      getAllEducationAction(dispatch),
      getMySkillsAction(dispatch),
    ]).finally(() => setPageLoading(false));
  }, [dispatch]);
  useEffect(() => {
    handleGetExperience();
  }, []);
  const handleGetExperience = async () => {
    try {
      const response = await getAllExperienceAction(dispatch);
      if (response && response.payload) {
        setExperiences(response.payload);
      } else {
        setExperiences([]);
      }
    } catch (error) {
      console.error(error);
      setExperiences([]);
    }
  };
  const stripHtmlTags = (str) => {
  return str.replace(/<[^>]*>/g, ''); 
};

  return (
    <Layout>
      <BackButton />
      {pageLoading ? (
        <PageLoader />
      ) : (
          
        <ProfileLayout>
          <div className="profile-about">
            <div className="section-header mt-5">
              <p className="mb-0">About</p>
            </div>
            <p className="co-darkgray mt-4">
              {profileData.about || "Nothing to display"}
            </p>
          </div>
          <div className="profile-education">
            <div className="section-header mt-5">
              <p className="mb-0">EDUCATION/CERTIFICATIONS</p>
            </div>
            {myEducations.length ? (
              myEducations?.map((data, index) => (
                <Link
                  className={`education edu_link${index + 1}`}
                  key={data.id}
                  to={`/profile/edit/education/${data.id}`}
                >
                  <p className="co-darkgray medium-text">{data.school}</p>
                  <p>{`${data.degree} ${data.field_of_study} (${ternaryResolver(
                    `${data.end_date}`.toLowerCase() === "present",
                    data.end_date,
                    new Date(data?.end_date).getFullYear()
                  )})`}</p>
                </Link>
              ))
            ) : (
              <div className="mt-4">
                <p className="co-darkgray">Nothing to display</p>
              </div>
            )}
          </div>
          <div className="profile-skills">
            <div className="section-header mt-5">
              <p className="mb-0">SKILLS/INTERESTS</p>
            </div>
            <ul className="d-flex flex-wrap mt-3">
              {mySkills?.skills?.length ? (
                mySkills?.skills?.map((skill) => (
                  <li
                    key={skill}
                    className="mr-2 py-1 px-2 outlined"
                    style={{ marginTop: "10px" }}
                  >
                    {skill}
                  </li>
                ))
              ) : (
                <li className="mr-2 py-1 px-2 co-darkgray">No skill added</li>
              )}
            </ul>
          </div>
          <div className="profile-documents">
            <div className="section-header mt-5">
              <p className="mb-0">RESUME/DOCUMENTS</p>
            </div>
            <div className="d-flex flex-column mt-3">
              {profileData?.resume && (
                <a className="d-flex my-2" href={profileData.resume} download>
                  <svg width="20" height="24" className="mr-2">
                    <use href={`${icons}#file`}></use>
                  </svg>
                  {profileData.resumeName ?? "Rèsumé"}
                  <span className="flag mx-3 co-primary small-text px-1">
                    Default
                  </span>
                </a>
              )}
              {/* <span className="d-flex my-2">
              <svg width="20" height="24" className="mr-2">
                <use href={`${icons}#file`}></use>
              </svg>
              Jane_Doe_Cover_Letter.pdf
            </span> */}
              {profileData?.links && (
                <span className="d-flex my-2">
                  <svg width="22" height="22" className="mr-2">
                    <use href={`${icons}#link`}></use>
                  </svg>
                  {profileData?.links[0]?.facebook && (
                    <a
                      className="underlined co-primary mr-2"
                      href={profileData.links[0].facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  )}
                  {profileData?.links[0]?.linkdn && (
                    <a
                      className="underlined co-primary mr-2"
                      href={profileData.links[0].linkdn}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </a>
                  )}
                  {profileData?.links[0]?.twitter && (
                    <a
                      className="underlined co-primary mr-2"
                      href={profileData.links[0].twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                  )}
                  {profileData?.links[0]?.potfolio && (
                    <a
                      className="underlined co-primary"
                      href={profileData.links[0].potfolio}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Portfolio
                    </a>
                  )}
                  {[
                    !profileData?.resume,
                    !profileData?.links[0]?.facebook,
                    !profileData?.links[0]?.linkdn,
                    !profileData?.links[0]?.twitter,
                    !profileData?.links[0]?.potfolio,
                  ].every((val) => val === true) && (
                    <p className="mr-2 py-1 px-2 co-darkgray">
                      Nothing to display
                    </p>
                  )}
                </span>
              )}
            </div>
            <div className="profile-education">
              <div className="section-header mt-5">
                <p className="mb-0">EXPERIENCES</p>
              </div>
              {Array.isArray(experiences) && experiences?.length ? (
                experiences.map((data, index) => (
                  <div
                    className={`education edu_link${index + 1}`}
                    key={data.id}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <p style={{ color: "#3A6ED4" }}>COMPANY NAME</p>
                        <p className="co-darkgray medium-text">
                          {data.campany_name}
                        </p>
                      </div>
                      <div>
                        <p style={{ color: "#3A6ED4" }}>JOB POSITION</p>
                        <p>{data.title}</p>
                      </div>
                      <div>
                        <p style={{ color: "#3A6ED4" }}>START DATE</p>
                        <p>{data.start_date}</p>
                      </div>
                      <div>
                        <p style={{ color: "#3A6ED4" }}>END DATE</p>
                        <p>{data.end_date}</p>
                      </div>
                    </div>
                    <div>
                      <p style={{ color: "#3A6ED4" }}>ACHIEVEMENTS</p>
                      <p>{stripHtmlTags(data.achievements)}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-4">
                  <p className="co-darkgray">Nothing to display</p>
                </div>
              )}
            </div>
          </div>
        </ProfileLayout>
      )}
    </Layout>
  );
};

export default Profile;
