import React, { useEffect, useState } from "react";
import { bool, object as objectPropType, string } from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import EditCard from "../EditCard";
import { Checkbox, TextField } from "@material-ui/core";
import {
  EDIT_EDUCATION,
  EDUCATION_LOADING,
  ERROR,
  RESET_EDUCATION_FORM,
  STOP_EDUCATION_LOADING,
} from "../../../../../redux/actionTypes";
import {
  createEducationAction,
  getAllEducationAction,
  updateEducationAction,
} from "../../../../../redux/actions/educationActions";

const Education = ({
  educationData,
  educationLoading,
  isEdit,
  educationId,
}) => {
  const [education, setEducation] = useState([]);
  const { push } = useHistory();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const dispatch = useDispatch();
const [isLoading, setIsLoading] = useState(false); 

  
  const [currentEducation, setCurrentEducation] = useState({
    school: "",
    degree: "",
    field_of_study: "",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    const getAll = async () => {
      try {
        const response = await getAllEducationAction(dispatch);
        console.log(response.payload);
        if (Array.isArray(response.payload) && response.payload.length > 0) {
          setEducation(response.payload);
          setIsUpdateMode(true);
        }
         else {
        console.log("No education data found");
        setEducation([]); 
        setIsUpdateMode(false);
      }
      } catch (error) {
        console.log(error);
        setEducation([])
      }
    };
    getAll();
  }, [dispatch]);


  const handleChange = (index, { target: { name, value, checked } }) => {
    const updatedEducation = [...education];
    const entry = updatedEducation[index];

    if (name === "stillInSchool") {
      entry.end_date = checked ? "Present" : "";
      entry.stillInSchool = checked;
    } else {
      entry[name] = value;
    }
    
    setEducation(updatedEducation);
  };

  
  const handleAddEducation = () => {
   
     if (education.length === 0 && currentEducation.school !== "") {
      setEducation([currentEducation]);
    } else {
      setEducation([
        ...education,
        {
           school: "",
           degree: "",
           field_of_study: "",
           start_date: "",
           end_date: "",
        },
      ]);
    }

   
    setCurrentEducation({
      school: "",
    degree: "",
    field_of_study: "",
    start_date: "",
    end_date: "",
    });
  };
const handleSubmit = async () => {
  try {
    setIsLoading(true)
    if (education.length === 0) {
      const data = {
        school: currentEducation.school,
        degree: currentEducation.degree,
        field_of_study: currentEducation.field_of_study,
        start_date: currentEducation.start_date,
        end_date: currentEducation.end_date,
      };
      console.log("Submitting data:", data);
      await createEducationAction(dispatch, data);
    } else {
      for (const edu of education) {
        if (!edu.school && !edu.degree && !edu.field_of_study && !edu.start_date && !edu.end_date) {
       
        continue;
      }

     
      if (!edu.school || !edu.degree || !edu.field_of_study || !edu.start_date || !edu.end_date) {
        alert("Please ensure all fields are filled out in the added education entry before submitting.");
        setIsLoading(false);
        return; 
      }
       
        const data = {
          school: edu.school,
          degree: edu.degree,
          field_of_study: edu.field_of_study,
          start_date: edu.start_date,
          end_date: edu.end_date,
        };
        console.log("Submitting data:", data);  
        if (edu.id) {
          await updateEducationAction(edu.id, data, dispatch);
        } else {
          await createEducationAction(dispatch, data);
        }
      }
    }
  } catch (error) {
    console.error("Error submitting data:", error);
  } finally {
    setIsLoading(false)
  }
};


 

  return (
    <div>
  
      {education.length > 0 ? (
        education.map((edu, index) => (
          <EditCard key={index} includeDelete={false} btnText="" btnType="">
            <h6 style={{ color: "black", fontWeight: "bold" }}>
              Education Details ({index + 1})
            </h6>
            <TextField
              name="school"
              value={edu.school}
              onChange={(e) => handleChange(index, e)}
              className="w-100 mb-4"
              variant="outlined"
              label="School/Institution/University"
            />
            <TextField
              name="degree"
              value={edu.degree}
              onChange={(e) => handleChange(index, e)}
              className="w-100 mb-4"
              variant="outlined"
              label="Degree"
            />
            <TextField
              name="field_of_study"
              value={edu.field_of_study}
              onChange={(e) => handleChange(index, e)}
              className="w-100 mb-4"
              variant="outlined"
              label="Field Of Study"
            />
            <TextField
              name="start_date"
              value={edu.start_date}
              onChange={(e) => handleChange(index, e)}
              type="date"
            
              className="w-100 mb-4"
              variant="outlined"
              label="Start date"
            />
            <TextField
              name="end_date"
              value={edu.end_date === "Present" ? "Present" : edu.end_date}
              onChange={(e) => handleChange(index, e)}
              type="date"
              
              className="w-100 mb-4"
              variant="outlined"
              label="End date"
              disabled={edu.end_date === "Present"}
            />
            <div className="w-100 position-relative">
              <Checkbox
                name="stillInSchool"
                onChange={(e) => handleChange(index, e)}
                color="primary"
                checked={edu.end_date === "Present"}
              />
              <label>Present</label>
            </div>
          </EditCard>
        ))
      ) : (
        <EditCard includeDelete={false} btnText="" btnType="">
        
          <TextField
            onChange={(e) => setCurrentEducation({ ...currentEducation, school: e.target.value })}
            name="school"
            value={currentEducation.school}
            className="w-100 mb-4"
            variant="outlined"
            label="School/Institution/University"
            />
             <TextField
              name="degree"
              value={currentEducation.degree}
              onChange={(e) => setCurrentEducation({ ...currentEducation, degree: e.target.value })}
              className="w-100 mb-4"
              variant="outlined"
              label="Degree"
            />
            <TextField
              name="field_of_study"
              value={currentEducation.field_of_study}
              onChange={(e) => setCurrentEducation({ ...currentEducation, field_of_study: e.target.value })}
              className="w-100 mb-4"
              variant="outlined"
              label="Field Of Study"
            />
             <TextField
              name="start_date"
              value={currentEducation.start_date}
              onChange={(e) => setCurrentEducation({ ...currentEducation, start_date: e.target.value })}
              className="w-100 mb-4"
              variant="outlined"
              type="date"
              label="start_date"
            />
             <TextField
              name=" end_date"
              value={currentEducation.end_date}
              onChange={(e) => setCurrentEducation({ ...currentEducation, end_date: e.target.value })}
              className="w-100 mb-4"
              type="date"
              variant="outlined"
              label="end_date"
            />
        
        </EditCard>
      )}

      <CustomButton
        handleClick={handleAddEducation}
        backgroundColor="#3A6ED4"
        color="#fff"
        text="Add Education"
        additionalClass="m-3"
        type="button"
      />

     
      <CustomButton
        backgroundColor="#3A6ED4"
        color="#fff"
        text={isUpdateMode ? "Update All" : "Submit All"}
        additionalClass="m-3"
        type="button"
         loading={isLoading}
        handleClick={handleSubmit}
      />
    </div>
  );
};

export default Education;
