import cogoToast from "cogo-toast";
import httpRequest from "../../ajax";
import {
    CREATE_EXPERT_CATEGORY, 
    CREATE_EXPERT, 
    GET_ALL_EXPERT_CATEGORY,
    GET_ALL_EXPERT,
    UPDATE_ALL_EXPERT_CATEGORY,
    DELETE_ALL_EXPERT_CATEGORY,
    ERROR
} from "../actionTypes";


  
export const createExpertCategoryAction = (dispatch, data) =>
{
    httpRequest("post", "/experts/category/create", data)
  .then(({ data: { data } }) => {
    const action = { type: CREATE_EXPERT_CATEGORY, payload: data };
    dispatch(action);
     return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

}

export const createExpertAction = (dispatch, data) =>
{
    console.log("<<<createExpertAction>>>"+JSON.stringify(data));
    httpRequest("post", "/experts/create", data)
  .then(({ data: { data } }) => {
    const action = { type: CREATE_EXPERT, payload: data };
    dispatch(action);
     return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

}

 
export const getAllExpertCategoryAction = (dispatch, page) =>{

    httpRequest("get", `/experts/category/all`)
     .then(({ data: { data } }) => {
    const action = { type: GET_ALL_EXPERT_CATEGORY, payload: data };
       dispatch(action);
       return action;
     })
     .catch(({ response }) => ({
       payload: response?.data?.message,
       type: ERROR,
     }));
   }
  
  
   export const getAllExpertAction = (dispatch, page) =>{
    httpRequest("get", `/experts`)
     .then(({ data: { data } }) => {
       console.log("<<<getAllExpertAction>>>"+JSON.stringify(data));
    const action = { type: GET_ALL_EXPERT, payload: data };
       dispatch(action);
       return action;
     })
     .catch(({ response }) => ({
       payload: response?.data?.message,
       type: ERROR,
     }));
   }
     
   export const updateExpertCategoryAction = (dispatch, data) =>{

    httpRequest("put", `/experts/category/`+data.category_id,data)
     .then(({ data: { data } }) => {
     console.log("<<<updateExpertCategoryAction>>>"+JSON.stringify(data));
    const action = { type: UPDATE_ALL_EXPERT_CATEGORY, payload: data };
       dispatch(action);
       return action;
     })
     .catch(({ response }) => ({
       payload: response?.data?.message,
       type: ERROR,
     }));
   }
      
   export const deleteExpertCategoryAction = (dispatch, data) =>{

 
    httpRequest("delete", `/experts/category/`+data.item_id)
     .then(({ data: { data } }) => {
     console.log("<<<deleteExpertCategoryAction>>>"+JSON.stringify(data));
    const action = { type: DELETE_ALL_EXPERT_CATEGORY, payload: data };
       dispatch(action);
       return action;
     })
     .catch(({ response }) => ({
       payload: response?.data?.message,
       type: ERROR,
     }));
   }
  