import {
  GET_INDUSTRIES,
} from '../actionTypes';
    
const initialState = {
  industries: []
};
    
export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_INDUSTRIES:
    return {
      ...state,
      industries: payload?.map(data => ({
        ...data,
        description: data.description?.replace('&amp;', '&'),
      })),
    };
  default:
    return state;
  }
};
    