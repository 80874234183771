import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from '../../../../components/CustomInput';
import CustomSwitch from "../../../../components/CustomSwitch";
import CustomTextArea from "../../../../components/CustomTextArea";
import icons from "../../../../assets/icons/icon-collection.svg";
import { deleteAccount } from "../../../../redux/actions/settingsActions";
import { getProfileAction } from "../../../../redux/actions/profileActions";

const Account = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch()
  const [reason, setReason] = useState(false);
  const [state, setState] = useState({
    checkedB: true,
  });

  const { profile } = useSelector((store) => store);
  const { profileData } = profile;

  useEffect(() => {
    getProfileAction(dispatch);
  }, [dispatch]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleDelete = () => {
    deleteAccount({ reason });
  };

  const gender = {
    "1": "Male",
    "2": "Female",
    "3": "Others"
  }

  return (
    <div>
      <h6 className="bolder-text">Personal Information</h6>
      <p className="small-text co-darkgray mt-3">
        Kindly provide your personal information. Note that this information
        will not be visible publicly unless you choose to make it public.{" "}
      </p>

      <form className="mt-4">
        <CustomInput
          type="text" label="Email Address" id="role" value={profileData.email} 
          isDisabled
        />
        <CustomInput
          type="text" label="Phone" id="name" value={profileData.phone}
          isDisabled
        />
        <CustomInput
          type="text" label="Gender" id="role" value={gender[profileData.gender]}
          isDisabled
        />
        <CustomInput
          type="text" label="Birthday" id="name" value={profileData.dob}
          isDisabled
        />
        <div className="my-4 d-flex align-items-center justify-content-between">
          Turn Off All Notifications
          <FormControlLabel
            control={
              <CustomSwitch
                checked={state.checkedB}
                onChange={handleChange}
                name="checkedB"
              />
            }
          />
        </div>
        <div className="d-flex mt-5 justify-content-end">
          <span className="w-25">
            <CustomButton
              handleClick={() => setOpen(true)}
              text="Delete my account"
              backgroundColor="#E24329"
              color="#fff"
            />
          </span>
        </div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="confirm-delete"
          PaperProps={{
            style: {
              width: "35rem",
              borderRadius: "1rem",
              padding: "0.75rem",
            },
          }}
        >
          <div className="d-flex justify-content-between align-items-centter">
            <DialogTitle>Delete Account</DialogTitle>{" "}
            <div className="close-button">
              <IconButton onClick={() => setOpen(false)}>
                <svg width="30" height="30" className="rounded-circle">
                  <use href={`${icons}#close`}></use>
                </svg>
              </IconButton>
            </div>
          </div>
          <DialogContent>
            <strong className="co-lightred">
              Are you sure you want to delete your account?
            </strong>
            <CustomTextArea
              placeholder="why do you want to delete your account?"
              label="Reason"
              onChange={(e) => setReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <span className="w-50 mr-3">
              <CustomButton
                handleClick={handleDelete}
                text="Delete"
                backgroundColor="#E24329"
                color="#fff"
              />
            </span>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default Account;
