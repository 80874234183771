/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import EmptyState from "../../../../components/EmptyState";
import { SectionLoader } from "../../../../components/PageLoader";
import CustomButton from "../../../../components/CustomButton";
import {
  getRecruiterShortlistedApplicants,
  sendShortlist,
} from "../../../../redux/actions/recruiterAction";

const ShortlistedApplicants = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const { shortlistedApplicants } = useSelector((state) => state.recruiter);
  const jobId = id;

  useEffect(() => {
    setLoading(true);
    Promise.resolve(getRecruiterShortlistedApplicants(dispatch, jobId)).finally(
      () => setLoading(false)
    );
  }, [dispatch]);

  const handleSendShortlist = () => {
    const sendList = async () => {
      setSending(true);
      await sendShortlist(dispatch, jobId);
      setSending(false);
    };
    sendList();
  };

  return (
    <div>
      {loading && !shortlistedApplicants && <SectionLoader />}
      {!loading && shortlistedApplicants && !shortlistedApplicants[0] && (
        <EmptyState text="There are no applicant for this job" />
      )}
      {shortlistedApplicants && shortlistedApplicants[0] && (
        <>
          {shortlistedApplicants.map((applicant, index) => (
            <Applicant applicant={applicant} key={index} job_id={id} />
          ))}
          <div className="w-100 d-flex justify-content-end mt-4">
            <div className="w-50 pb-3 d-flex align-items-center">
              <span className="d-flex flex-row w-100">
                {/* <CustomButton
                  text="Edit Shortlist"
                  alt="Edit Shortlist"
                  type="button"
                  backgroundColor="white"
                  borderColor="var(--primary-color)"
                  additionalClass={"px-4 mr-3"}
                  loading={loading}
                ></CustomButton> */}
                <CustomButton
                  text="Send Shortlist"
                  alt="Send Shortlist"
                  type="button"
                  backgroundColor="var(--primary-color)"
                  color="white"
                  borderColor="var(--primary-color)"
                  additionalClass={"px-4"}
                  loading={sending}
                  handleClick={handleSendShortlist}
                ></CustomButton>
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShortlistedApplicants;

const Applicant = ({ applicant }) => {
  const history = useHistory();

  return (
    <React.Fragment>
      <div
        key={applicant.id}
        className="d-flex align-items-center cursor-pointer justify-content-between mb-3"
      >
        <span
          className="d-flex align-items-center"
          onClick={() => history.push(`/profile/${applicant.profile.user_id}`)}
        >
          <img width="50" src={applicant.profile.image_url} alt="avatar" />
          <span className="d-flex flex-column ml-3">
            <h6 className="mb-0">
              {applicant.profile.first_name} {applicant.profile.last_name}
            </h6>
            <span className="co-gray small-text">
              {applicant.profile.title ?? `@${applicant.profile.username}`}
            </span>
          </span>
        </span>
        <span>
          {/* <CustomButton
              text="Shortlist"
              alt="Move"
              type="button"
              backgroundColor="white"
              borderColor="var(--primary-color)"
              additionalClass={"px-4"}
              loading={loading}
            ></CustomButton> */}
        </span>
      </div>
    </React.Fragment>
  );
};
