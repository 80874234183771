import {
  GET_EDUCATIONS,
  CREATE_EDUCATION,
  EDUCATION_LOADING,
  STOP_EDUCATION_LOADING,
  EDIT_EDUCATION,
  GET_EDUCATION,
  RESET_EDUCATION_FORM,
} from '../actionTypes';

const initialState = {
  educationData: {
    school: '',
    degree: '',
    field_of_study: '',
    start_date: '',
    end_date: '',
    description: '',
    stillInSchool: false,
  },
  myEducations: [],
  educationLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_EDUCATIONS:
    return {
      ...state,
      myEducations: payload
    };
  case RESET_EDUCATION_FORM:
    return {
      ...state,
      educationData: initialState.educationData,
    };
  case GET_EDUCATION:
    return {
      ...state,
      educationData: {
        ...state.educationData,
        ...payload,
        stillInSchool: String(payload.end_date).toLowerCase() === 'present',
      }
    };
  case CREATE_EDUCATION:
    return {
      ...state,
      myEducations: [payload, ...state.myEducations]
    };
  case EDIT_EDUCATION:
    return {
      ...state,
      educationData: {
        ...state.educationData,
        [payload.name]: payload.value,
      },
    };
  case EDUCATION_LOADING:
    return {
      ...state,
      educationLoading: true,
    };
  case STOP_EDUCATION_LOADING:
    return {
      ...state,
      educationLoading: false,
    };
  default:
    return state;
  }
};
