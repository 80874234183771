/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  DialogActions,
} from "@material-ui/core";
import icons from "../../../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../../../components/CustomButton";
import CustomTextArea from "../../../../../components/CustomTextArea";
import { SectionLoader } from "../../../../../components/PageLoader";
import { func } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUpdated,
  declineApplication,
  getBusinessJobApplicants,
  moveBusinessApplicant,
  hireBusinessApplicant,
} from "../../../../../redux/actions/insightActions";
import { useHistory, useParams } from "react-router-dom";
import EmptyState from "../../../../../components/EmptyState";
import CustomInput from "../../../../../components/CustomInput";

const Reason = ({ setMessage }) => {
  const [reason, setReason] = useState("");

  const handleChangeReason = ({ target: { value } }) => {
    setReason(value);
    setMessage(value);
  };

  return (
    <div className="mb-3">
      <CustomTextArea
        id="message"
        placeholder="Enter Message"
        onChange={handleChangeReason}
        value={reason}
        label=""
      />
      {/* <div className="d-flex">
        <button className="w-40 co-primary borderless bg-white">
          <b>Upload File</b>
        </button>
      </div> */}
    </div>
  );
};

Reason.propTypes = {
  setSuccess: func.isRequired,
};

const Applicants = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, stage } = useParams();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [level, setLevel] = useState(0);
  const [currentApplicant, setCurrentApplicant] = useState();
  const [reason, setReason] = useState("");
  const [openSalary, setOpenSalary] = useState(false);
  const [message, setMessage] = useState("");
  const [hiring, setHiring] = useState(false);

  const { businessApplicants, updated } = useSelector(({ insight }) => insight);
  const { userData } = useSelector((state) => state.auth);
  // const [jobApplicants, setJobApplicants] = useState([]);

  const mapStage = {
    consider: 1,
    test: 2,
    interview: 3,
    regret: 5,
  };

  const handleSetMessage = (message) => {
    setMessage(message);
  };

  useEffect(() => {
    dispatch({ type: "RESET_BUSINESS_APPLICANTS" });
    const applStage = stage ? mapStage[stage] : "*";
    const getApplicants = async () => {
      setLoading(true);
      await getBusinessJobApplicants(
        dispatch,
        id,
        userData.company.id,
        applStage
      );
      setLoading(false);
    };
    getApplicants();
  }, [id, stage]);

  const jobApplicants = businessApplicants
    ? businessApplicants["Applicants"]
    : [];

  const handleChange = (event) => {
    let val = parseInt(event.target.value);
    setValue(val);
  };

  const handleReason = (reason) => {
    setReason(reason);
  };

  const handleOpen = (applicant) => {
    setCurrentApplicant(applicant);
    setLevel(mapStage[stage]);
    setOpen(true);
  };

  const handleClose = () => {
    clearUpdated(dispatch);
    setSuccess(false);
    setOpen(false);
  };

  const handleCloseSalary = () => {
    setOpenSalary(false);
  };
  const handleSubmit = async () => {
    const updateApplData = {
      job_id: parseInt(id, 10),
      stage_code: parseInt(value, 10),
      applicant_id: parseInt(currentApplicant.applicant_id, 10),
      reason: reason,
    };

    await moveBusinessApplicant(dispatch, updateApplData);
    if (updated) {
      handleClose();
    }
  };

  const rejectApplication = async () => {
    await declineApplication(dispatch, id, currentApplicant.applicant_id);
    if (updated) {
      handleClose();
    }
  };

  const hireApplicant = () => {
    // const data = {
    //   job_id: 2,
    //   applicant_id: 1,
    //   salary: 200000,
    //   isRecruiterJob: 0,
    // };
    // setOpenSalary(true);

    const hire = async () => {
      const data = {
        job_id: parseInt(id, 10),
        applicant_id: currentApplicant.applicant_id,
        message: message,
        company_id: parseInt(userData.company.id, 10),
      };

      setHiring(true);
      await hireBusinessApplicant(dispatch, data);
      if (updated) {
        handleClose();
      }
      setHiring(false);
      // setOpen(false);
    };
    hire();
  };

  const openProfile = (profileId) => {
    const win = window.open(`/profile/${profileId}`, "_blank");
    win.focus();
  };

  return (
    <div>
      <div className="d-flex justify-content-between filters mb-4">
        <button onClick={() => history.push(`/admin/jobs/applicants/${id}`)}>
          All
        </button>
        <button
          onClick={() => history.push(`/admin/jobs/applicants/${id}/consider`)}
        >
          consider
        </button>
        <button
          onClick={() => history.push(`/admin/jobs/applicants/${id}/test`)}
        >
          test
        </button>
        <button
          onClick={() => history.push(`/admin/jobs/applicants/${id}/interview`)}
        >
          interview
        </button>
        <button
          onClick={() => history.push(`/admin/jobs/applicants/${id}/regret`)}
        >
          regret
        </button>
      </div>
      {loading && <SectionLoader />}
      {!loading && !jobApplicants && <EmptyState text="No Applicant Found" />}
      {!loading && jobApplicants && jobApplicants[0] && (
        <>
          {jobApplicants.map((applicant) => (
            <div
              key={applicant.id}
              className="d-flex align-items-center cursor-pointer justify-content-between mb-3"
            >
              <span
                className="d-flex align-items-center"
                onClick={() => {
                  openProfile(applicant.applicant_id);
                }}
              >
                <img width="50" src={applicant.image_url} alt="avatar" />
                <span className="d-flex flex-column ml-3">
                  <h6 className="mb-0">
                    {applicant.first_name} {applicant.last_name}
                  </h6>
                  <span className="co-gray small-text">
                    @{applicant.username}
                  </span>
                </span>
              </span>
              <span>
                <CustomButton
                  text="Move"
                  alt="Move"
                  type="button"
                  backgroundColor="white"
                  borderColor="var(--primary-color)"
                  additionalClass={"px-4"}
                  handleClick={() => handleOpen(applicant)}
                ></CustomButton>
              </span>
            </div>
          ))}
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "30rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        {currentApplicant && (
          <div className="d-flex justify-content-between align-items-centter">
            <DialogTitle id="form-dialog-title">
              {" "}
              {!success && (
                <span className="w-100 d-flex align-items-center">
                  <img
                    width="50"
                    src={currentApplicant.image_url}
                    alt="avatar"
                  />
                  <span className="d-flex flex-column ml-3">
                    <h6 className="mb-0">
                      {currentApplicant.first_name} {currentApplicant.last_name}
                    </h6>
                    <CustomButton
                      handleClick={() =>
                        openProfile(currentApplicant.applicant_id)
                      }
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text="View Profile"
                      additionalClass="mt-2 p-1 small-text"
                    />
                  </span>
                </span>
              )}
            </DialogTitle>
            <div className="close-button">
              <IconButton onClick={handleClose}>
                <svg width="30" height="30" className="rounded-circle">
                  <use href={`${icons}#close`}></use>
                </svg>
              </IconButton>
            </div>
          </div>
        )}

        <DialogContent>
          <RadioGroup
            aria-label="change status"
            name="status"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label="Consider"
              disabled={level >= 1}
            />
            {value === 1 && (
              <Reason
                key={1}
                setSuccess={setSuccess}
                handleReason={handleReason}
                setMessage={handleSetMessage}
              />
            )}
            {/* <Reason setSuccess={setSuccess} /> */}
            <FormControlLabel
              value={2}
              control={<Radio color="primary" />}
              label="Test"
              disabled={level >= 2}
            />
            {value === 2 && (
              <Reason
                key={2}
                setSuccess={setSuccess}
                handleReason={handleReason}
                setMessage={handleSetMessage}
              />
            )}
            <FormControlLabel
              value={3}
              control={<Radio color="primary" />}
              label="Interview"
              disabled={level >= 3}
            />
            {value === 3 && (
              <Reason
                key={3}
                setSuccess={setSuccess}
                handleReason={handleReason}
                setMessage={handleSetMessage}
                value={reason}
              />
            )}
            <FormControlLabel
              value={4}
              control={<Radio color="primary" />}
              label="Hire"
              disabled={level >= 4}
            />
            {value === 4 && (
              <Reason
                key={4}
                setSuccess={setSuccess}
                handleReason={handleReason}
                setMessage={handleSetMessage}
              />
            )}
          </RadioGroup>
        </DialogContent>
        {/* )} */}
        {!success && (
          <DialogActions className="mt-5">
            <span className="w-100 mr-4">
              <span className="d-flex">
                <CustomButton
                  type="button"
                  handleClick={rejectApplication}
                  backgroundColor="#FF5964"
                  color="#fff"
                  text="Regret Applicant"
                  additionalClass="mx-3 mt-2"
                />
                {value !== 4 && (
                  <CustomButton
                    type="button"
                    handleClick={handleSubmit}
                    disabled={value === 0}
                    backgroundColor="#3a6ed4"
                    color="#fff"
                    text="Move Applicant"
                    additionalClass="mx-3 mt-2"
                  />
                )}
                {value === 4 && (
                  <CustomButton
                    type="button"
                    handleClick={hireApplicant}
                    disabled={value === 0}
                    backgroundColor="#3a6ed4"
                    color="#fff"
                    text="Hire Applicant"
                    additionalClass="mx-3 mt-2"
                    loading={hiring}
                  />
                )}
              </span>
            </span>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        open={openSalary}
        onClose={handleCloseSalary}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "30rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          Employee&apos;s Annual Salary
        </DialogTitle>
        <DialogContent>
          <CustomInput
            name="salary"
            type="number"
            isRequired={true}
            label="Employee's Annual Salary"
            id="salary"
          />
          <CustomButton
            type="button"
            handleClick={() => {
              // console.log("Completing Hiring...");
            }}
            disabled={value === 0}
            backgroundColor="#3a6ed4"
            color="#fff"
            text="Complete Hiring"
            additionalClass=" mt-2"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Applicants;
