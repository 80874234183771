/* eslint-disable react/prop-types */
import cogoToast from "cogo-toast";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomButton from "../../../../../../components/CustomButton";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import { getMyRecruiterData } from "../../../../../../redux/actions/recruiterAction";

const Refer = ({ job }) => {
  const { jobId } = useParams();
  const [copied, setCopied] = useState(false);
  const { userData } = useSelector(({ auth }) => auth);
  const textAreaRef = useRef(null);
  const dispatch = useDispatch();
  const { myRecruiterData } = useSelector(({ recruiter }) => recruiter);
  const [jobApplyURL, setJobApplyURL] = useState("");

  useEffect(() => {
    if (copied) {
      cogoToast.success("Link copied");
      setCopied(false);
    }
  }, [copied]);
  useEffect(() => {
    setJobApplyURL(
      `${process.env.REACT_APP_WEB_URL}/home/${jobId}/apply/${myRecruiterData?.id}/${userData.refCode}`
    );
  }, [job, myRecruiterData]);
  useEffect(() => {
    getMyRecruiterData(dispatch);
  }, [dispatch]);

  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand("copy");
    setCopied(true);
  };
  let title = `Job Referral for ${job.role_title}`;
  title += job.campany_name ? ` at ${job.campany_name}` : "";
  const hashtags = ["workbrook", "job", "workbrookjobs"];

  return (
    <div className="w-100">
      {userData && (
        <div className="py-4 mt-4">
          <h5 className="co-primary text-center">
            Refer people using your unique link
          </h5>
          <div className="mx-auto w-75 my-5 w-100">
            <textarea
              ref={textAreaRef}
              value={jobApplyURL}
              className="borderless w-100 copy-link"
              rows={4}
              onChange={() => {}}
            />
            <div className="d-flex px-4 mt-3 justify-content-between">
              <CustomButton
                backgroundColor="#41B883"
                color="#fff"
                text="Copy Link"
                handleClick={copyToClipboard}
                additionalClass="mr-3 bg-primary"
              />
            </div>
            <div
              className="mx-auto w-75 w-100 mt-4"
              style={{ textAlign: "center" }}
            >
              <h6>Share to Social Network</h6>
              <div>
                <FacebookShareButton
                  url={jobApplyURL}
                  quote={title}
                  className="mr-2"
                  hashtag="#workbrook #job"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>

                <TwitterShareButton
                  url={jobApplyURL}
                  title={title}
                  className="mr-2"
                  hashtags={hashtags}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>

                 <LinkedinShareButton url={jobApplyURL} className="mr-2">
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Refer;
