import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RecruiterWrapper from "./RecruiterWrapper";

const Recruiter = () => {
  const history = useHistory();
  const { userData } = useSelector(({ auth }) => auth);
  useEffect(() => {
    if (userData.isRecruiter === 1) {
      history.push("/recruiter/requests");
    } else {
      history.push("/recruiter/become-recruiter");
    }
  }, [userData]);
  return <RecruiterWrapper></RecruiterWrapper>;
};

// To re-enable the Recruiter. Delet from this line to end of component. And uncomment component above...
// import Card from "../../../components/Card";
// import Layout from "../../../layout";

// const Recruiter = () => {
//   return (
//     <Layout recruiter>
//       <div className="d-flex flex-column flex-md-row mt-3">
//         <div className="main-content ml-md-4">
//           <Card className="mt-5">
//             <h4>Coming Soon</h4>
//           </Card>
//         </div>
//       </div>
//     </Layout>
//   );
// };

export default Recruiter;
