import React, { useEffect, useState } from "react";
import HomeModal from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { getJobsAction } from "../../../../redux/actions/jobActions";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { saveBusinessRef } from "../../../../redux/actions/businessActions";
import JobCard from "./JobCard";

import { SEARCH_JOBS } from "../../../../redux/actionTypes";
import CustomButton from "../../../../components/CustomButton";

const SearchResult = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [page] = useState(1);
  const { jobId, refLink } = useParams();
  const dispatch = useDispatch();
  const [section, setSection] = useState("details");
  const [loading, setLoading] = useState(false);
  const [setLoadingMore] = useState(false);

  const { jobs, searchedTerm } = useSelector(({ job }) => job);

  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getJobsAction(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);
  
  const saveForLater = () => {
    const payload = {
      job_id: jobId,
      status: 1,
      refCode: refLink ? refLink : null,
    };
    const saveLater = async () => {
      await saveBusinessRef(dispatch, page, payload);
    };
    saveLater();
  };

  return (
    <>
      {jobs && jobs[0] && (
        <div
          style={{
            display: "flex",
            justifyContent: "between",
            alignItems: "center",
            flexFlow: "row nowrap",
          }}
        >
          <p
            style={{
              textAlign: "left",
              padding: "20px",
              fontSize: "20px",
              minWidth: "80%",
            }}
          >{`Results for ${searchedTerm}`}</p>
          <CustomButton
            backgroundColor="#3A6ED4"
            color="#fff"
            alt="add post"
            text="Back"
            additionalClass="my-3"
            handleClick={(e) => {
              e.preventDefault();
              dispatch({
                type: SEARCH_JOBS,
                payload: { data: [], term: "" },
              });
            }}
          />
        </div>
      )}
      <div className="row position-relative mt-3 align-items-stretch">
        {jobId && (
          <HomeModal
            setSection={setSection}
            section={section}
            open={true}
            handleClose={() => history.push("/home")}
            isBusiness={
              pathname.includes("biz") || pathname.includes("coy") == true
                ? true
                : false
            }
            isFromCompany={pathname.includes("coy") == true ? true : false}
            saveForLater={saveForLater}
          />
        )}
        {loading && !jobs[0] && <SectionLoader />}
        {!loading && !jobs[0] && (
          <EmptyState text={`There are no jobs for ${searchedTerm}`} />
        )}
        {jobs && jobs[0] && (
          <>
            {jobs.map((job, index) => (
              <div
                key={index.toString()}
                className="col-12 col-md-6"
                style={{
                  padding: "10px",
                  maxHeight: "280px",
                }}
              >
                <JobCard job={job} />
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default SearchResult;
