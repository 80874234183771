import { func } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../components/CustomButton";
import CustomSelect from "../../../../../components/CustomSelect";
import SearchLocationInput from "../../../../../components/LocationComponent";
import { searchRecruiter } from "../../../../../redux/actions/recruiterAction";

const SearchRecruiter = ({ setStage }) => {
  const dispatch = useDispatch();
  const [searchBy, setSearchBy] = useState(0);
  const [industry, setIndustry] = useState("");
  const [experience, setExperience] = useState("");
  const [location, setLocation] = useState("");
  const [industryOptions, setIndustryOptions] = useState({});
  const { industries } = useSelector(({ industry }) => industry);
  useEffect(() => {
    const option = {};
    industries && industries.forEach((industry) => {
      option[industry.title] = industry.title;
    });
    setIndustryOptions(option);
  }, [industries]);
  const handleSearch = () => {
    let term = industry;
    if (searchBy === 1) {
      term = location;
    } else if (searchBy === 2) {
      term = experience;
    } else {
      term = industry;
    }
    Promise.resolve(searchRecruiter(dispatch, searchBy, term, 0)).finally(() =>
      setStage(3)
    );
  };

  const handleLocationChange = (address) => {
    setLocation(address);
  };

  return (
    <div>
      <h3 className="my-4">Hire-A-Recruiter</h3>
      <div className="d-flex flex-column">
        <CustomSelect
          handleChange={(e) => setSearchBy(e.target.value)}
          options={{
            0: "General",
            1: "Location",
            2: "Experience",
            3: "Industry",
          }}
          id="searchBy"
          label="Search By"
          currentValue={searchBy}
        />

        {searchBy === 1 && (
          <SearchLocationInput handleLocationChange={handleLocationChange} />
        )}

        {searchBy === 2 && (
          <CustomSelect
            handleChange={(e) => setExperience(e.target.value)}
            options={{
              0: "Select Experience Level",
              "0-2 years": "0-2 years",
              "2-4 years": "2-4 years",
              "> 4 years": "> 4 years",
            }}
            id="experience"
            name="industryRecruitementExperience"
            label="Recruitment Experience"
            currentValue={experience}
          />
        )}

        {(searchBy === 0 || searchBy === 3) && (
          <CustomSelect
            name="industry"
            handleChange={(e) => setIndustry(e.target.value)}
            options={industryOptions}
            id="industry"
            label="Industry"
            currentValue={industry}
          />
        )}
      </div>
      <CustomButton
        handleClick={handleSearch}
        text="Search"
        backgroundColor="#000"
        color="#fff"
        additionalClass="p-3 mt-4 mb-5"
      />
    </div>
  );
};

SearchRecruiter.propTypes = {
  setStage: func.isRequired,
};

export default SearchRecruiter;
