/* eslint-disable react/prop-types */
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../../../../components/EmptyState";
import { SectionLoader } from "../../../../components/PageLoader";
import { getMyJobsAction } from "../../../../redux/actions/jobActions";
import CustomButton from "../../../../components/CustomButton";
import { sendJob } from "../../../../redux/actions/recruiterAction";
import { useHistory, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { createJobAction } from "../../../../redux/actions/jobActions";
import icons from "../../../../assets/icons/icon-collection.svg";
const SendJob = ({ name, recruiterId, jobId, job }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(job.id ? true : false);
  const { myJobs, jobTosend } = useSelector(({ job }) => job);
  const history = useHistory();
  const [selected, setSelected] = useState(jobTosend || jobId || job?.id || 0);
  useEffect(() => {
    setLoading(true);
    Promise.resolve(getMyJobsAction(dispatch)).finally(() => setLoading(false));
  }, [dispatch]);

  const handleChange = (event) => {
    setSelected(event.target.value);
    setPrompt(false);
  };

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      if (state.jobToSend) {
        setPrompt(true);
      }
    }
  }, [state]);

  return (
    <div className="position-relative">
      {prompt ? (
        <Dialog
          open={prompt}
          onClose={() => setPrompt(false)}
          aria-labelledby="Prompt"
          PaperProps={{
            style: {
              width: "35rem",
              borderRadius: "1rem",
              padding: "0.75rem",
            },
          }}
        >
          <DialogContent>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <DialogTitle id="details-dialog-title">
                  <p>
                    Are you sure you want to send <br />
                    <b>
                      {myJobs.find((itm) => itm.id === selected)?.role_title}
                      {!job.role_title &&
                        state.jobToSend &&
                        "newly created job"}{" "}
                    </b>
                    to {name}
                  </p>
                </DialogTitle>
                <div className="close-button">
                  <IconButton onClick={() => setPrompt(false)}>
                    <svg width="30" height="30" className="rounded-circle">
                      <use href={`${icons}#close`}></use>
                    </svg>
                  </IconButton>
                </div>
              </div>
              <form>
                <div className="view mt-4">
                  <CustomButton
                    handleClick={async () => {
                      setLoading(true);
                      if (!state.jobToSend) {
                        sendJob(dispatch, selected, parseInt(recruiterId, 10));
                        if (jobId !== "undefined") {
                          history.push("/insights/jobs");
                        } else {
                          setPrompt(false);
                        }
                      } else {
                        await createJobAction(dispatch, {
                          ...state.jobToSend,
                          isToRefTimeLineOrToHireRecruter: 2,
                          refererIdOne: parseInt(recruiterId, 10),
                        });
                      }
                      setLoading(false);
                    }}
                    backgroundColor="#fff"
                    text="Send Job Details"
                    color="#3A6ED4"
                    borderColor="#3A6ED4"
                    additionalClass="px-3"
                  />
                </div>
              </form>
            </div>
          </DialogContent>
        </Dialog>
      ) : null}
      <>
        <h3>Send Job to {name}</h3>
        {loading && <SectionLoader />}
        {!loading && !myJobs[0] && <EmptyState text="no jobs" />}
        {!loading && myJobs && myJobs[0] && (
          <RadioGroup value={selected} onChange={handleChange}>
            <label>
              <b>My jobs</b>
            </label>
            <div className="d-flex flex-column">
              {myJobs.map((job) => (
                <FormControlLabel
                  key={job.id}
                  onChange={handleChange}
                  value={job.id}
                  control={<Radio color="primary" />}
                  label={
                    <div>
                      <div className="d-flex flex-column">
                        {job.role_title}
                        <span className="co-gray small-text">
                          {job.campany_name}
                        </span>
                      </div>
                    </div>
                  }
                />
              ))}

              <CustomButton
                handleClick={() => {
                  setPrompt(true);
                  // sendJob(dispatch, selected, parseInt(recruiterId, 10));
                }}
                backgroundColor="#000"
                text="Send Job"
                color="#fff"
                borderColor="#000"
                additionalClass="mt-3"
              />
            </div>
          </RadioGroup>
        )}
      </>
    </div>
  );
};

SendJob.propTypes = {
  name: string.isRequired,
  recruiterId: string.isRequired,
};

export default SendJob;
