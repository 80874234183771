import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { SearchIcon } from "../CustomIcons";

const SearchInput = ({ handleChange }) => {
  return (
    <TextField
      label="Search"
      className="w-100"
      onChange={handleChange}
      // id="filled-start-adornment"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};

export default SearchInput;
