import httpRequest from "../../ajax";
import { logout } from "../../utils/helpers";
import { LOGIN, ERROR, CREATE_USER, RESEND_OTP } from "../actionTypes";

/**
 * @description persist profile to store
 * @function saveToStorage
 * @param {object} data - data
 * @returns {void}
 */
const saveToStorage = ({ rememberMe, access_token, profile }) => {
  if (rememberMe) {
    
    localStorage.setItem("token", access_token);
    localStorage.setItem("userData", JSON.stringify(profile));
  } 
  else {
    sessionStorage.setItem("token", access_token);
    sessionStorage.setItem("userData", JSON.stringify(profile));
   }
};

/**
 * @description login action
 * @function loginAction
 * @param {object} requestData - request data
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const loginAction = (requestData, dispatch) => {
  const { rememberMe, ...requestPayload } = requestData;
  return httpRequest("post", "/user/login", requestPayload)
    .then(({ data: { data } }) => {
      const {
        access_token,
        profile,
        account_type,
        isUserSubmittedMonthlySurvey,
        user_type,
        company,
      } = data;
      
      // console.log("ID", data.id);
      profile["id"] = data.id;
      profile["account_type"] = account_type;
      profile["user_type"] = user_type;
      profile["company"] = company;
      profile["isUserSubmittedMonthlySurvey"] = isUserSubmittedMonthlySurvey;
      
      // TODO: check if profile.account_type == localstorage.acctType.
      // If not, kick user out. Means biz user is not logging into biz...and vice versa
      
      //console.log(access_token);
      saveToStorage({ rememberMe, access_token, profile, company });
      const action = { type: LOGIN, payload: profile };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      const message = response?.data?.message;
      return {
        payload: message,
        type: ERROR,
      };
    });
};

/**
 * @description activate action
 * @function activateAction
 * @param {string} code - code
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const activateAction = (code, dispatch) => {
  const unknownCode = "c18a62dsfsfw";
  return httpRequest("get", `/user/activate/${code}/${unknownCode}`)
    .then(({ data: { data } }) => {
      const { access_token, profile, isUserSubmittedMonthlySurvey } = data;
      profile["isUserSubmittedMonthlySurvey"] = isUserSubmittedMonthlySurvey;
      saveToStorage({
        rememberMe: !!localStorage.rememberMe,
        access_token,
        profile,
      });
      const action = { type: LOGIN, payload: profile };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      const message = response?.data?.message;
      return {
        payload: message,
        type: ERROR,
      };
    });
};

/**
 * @description logout action
 * @param {function} dispatch - dispatch function
 * @returns {void}
 */
export const logoutAction = () => {
  sessionStorage.removeItem("pageToVisit");
  logout();
  window.location.reload();
};

export const createUserAction = (data, dispatch) => {
  return httpRequest("post", "/user/register", data)
    .then(({ data: { data } }) => {
      const action = { type: CREATE_USER, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      const message = response?.data?.message;
      return {
        payload: message,
        type: ERROR,
      };
    });
};


export const resendOTPAction = (data, dispatch) => {
  return httpRequest("post", "/user/resend/code ", data)
    .then(({ data: { data } }) => {
       
      const action = { type: RESEND_OTP, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
     // alert("Response: "+JSON.stringify(response))
      const message = response?.data?.message;
      return {
        payload: message,
        type: ERROR,
      };
    });
};


export const socialMediaRegister = (data, dispatch) => {
  return httpRequest("post", "/user/socialMediaRegister", data)
    .then(({ data: { data } }) => {
      const { access_token, profile, isUserSubmittedMonthlySurvey } = data;
      profile["isUserSubmittedMonthlySurvey"] = isUserSubmittedMonthlySurvey;
      saveToStorage({
        rememberMe: !!localStorage.rememberMe,
        access_token,
        profile,
      });
      const action = { type: LOGIN, payload: profile };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      const message = response?.data?.message;
      return {
        payload: message,
        type: ERROR,
      };
    });
};

export const activateUserAction = (dispatch, activationCode) => {
  // console.log(
  //   "REACT_APP_ACTIVATION_CODE",
  //   process.env.REACT_APP_ACTIVATION_CODE
  // );
  return httpRequest(
    "get",
    `/user/activate/${activationCode}/${process.env.REACT_APP_ACTIVATION_CODE}`
  )
    .then(({ data: { data } }) => {
      const { access_token, profile } = data;
     // saveToStorage({ access_token, profile });
      const action = { type: LOGIN, payload: profile };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      const message = response?.data?.message;
      return {
        payload: message,
        type: ERROR,
      };
    });
};

export const forgotPassword = (dispatch, email) => {
  return httpRequest("post", "/user/forgotPassword", email)
    .then((data) => {
      // console.log("data", data);
    })
    .catch((error) => {
      // console.log("error occ:", error);
    });
};

export const resetPassword = (dispatch, resetData) => {
  return httpRequest("post", "/user/resetPassword", resetData)
    .then((data) => {
      // console.log("data", data);
    })
    .catch((error) => {
      // console.log("error occ:", error);
    });
};
