/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../../../components/CustomSearchInput";
import RecruiterWrapper from "../RecruiterWrapper";
import icons from "../../../../assets/icons/icon-collection.svg";
import Card from "../../../../components/Card";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import { getRecruiterRequestsJobs } from "../../../../redux/actions/jobActions";

const Requests = () => {
  const dispatch = useDispatch();
  const { recruitRequests } = useSelector(({ job }) => job);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.resolve(getRecruiterRequestsJobs(dispatch)).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);
  return (
    <RecruiterWrapper>
      <div className="row m-4">
        <h3 className="mb-5">
          <b>Requests Sent</b>
        </h3>
        <SearchInput />
        <div className="my-4 w-100 position-relative">
          {loading && <SectionLoader />}
          {!loading && !recruitRequests[0] && (
            <EmptyState text="You have not sent any requests" />
          )}
          {!loading && recruitRequests[0] && (
            <>
              {recruitRequests.map((request) => {
                const { job, isAccepted, action_taken } = request;
                return (
                  <Card
                    key={job?.id}
                    noPadding
                    additionalClass="w-100 p-0 mb-4"
                  >
                    <div className="d-flex align-items-center status-recruiter px-3 py-2">
                      <span className="mr-2">
                        <svg width="13" height="13">
                          <use href={`${icons}#elipse`}></use>
                        </svg>
                      </span>
                      {/* 200 Applicants */}
                    </div>
                    <div className="d-flex flex-column mt-2 mx-4">
                      {job?.role_title}
                      <span className="smalltext co-darkgray mt-1">
                        {job?.campany_name}
                      </span>
                    </div>
                    <div className="w-100 d-flex justify-content-end">
                      <div className="w-50 pb-3 d-flex align-items-center">
                        {
                          <button
                            onClick={() => {}}
                            className="borderless bg-white mx-3"
                            s
                            style={{
                              color:
                                action_taken === "0"
                                  ? "red"
                                  : isAccepted === "1"
                                  ? "blue"
                                  : "red",
                            }}
                          >
                            {action_taken === "0"
                              ? "Pending"
                              : isAccepted === "1"
                              ? "Under consideration"
                              : "Rejected"}
                          </button>
                        }
                      </div>
                    </div>
                  </Card>
                );
              })}
            </>
          )}
        </div>
      </div>
    </RecruiterWrapper>
  );
};

export default Requests;
