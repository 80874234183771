import React, { useEffect,useRef,useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import icons from "../../../../assets/icons/icon-collection.svg";
import { useHistory } from "react-router";
import CustomInput from "../../../../components/CustomInput";
import ReactStars from "react-rating-stars-component";
import CustomTextArea from "../../../../components/CustomTextArea";
import cogoToast from "cogo-toast";
import { uploadFile } from "../../../../utils/helpers";


import {
  getAllExpertCategoryAction,
  createExpertAction
} from "../../../../redux/actions/expertCategoryAction";

import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import TextField from '@material-ui/core/TextField';
 import Autocomplete from '@material-ui/lab/Autocomplete';
 import { makeStyles } from '@material-ui/core/styles';
import './style.css';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

 

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
       fontSize: 18,
    },
  },
});
 

const CreateExpert = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [uploadedIcon, setUploadedIcon] = useState();
  const classes = useStyles();
  const uploadInputRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const {get_all_expert_categories} = useSelector((store) => store);
 
 

  const [stateRecognition, setStateRecognition] = useState({
    category_id:"0",
    title: "",
    fullname: "",
    description:"",
    email:"",
    phone:""
   });


  const handleRefClick = () => {
    uploadInputRef.current.click();
  };


 

const handleChange = ({ target: { id, value, checked } }) => {
  setStateRecognition((prevState) => ({ ...prevState, [id]: value }));
};



useEffect(() => {
  Promise.resolve(getAllExpertCategoryAction(dispatch, null)).finally(() =>
  {
    
  }
 
  );
}, [dispatch, 1]);


const handleSubmit = async (e) => {
     
  setLoading(true)
  e.preventDefault();
  
 
 
  
  const promise =  createExpertAction(dispatch, {
    ...stateRecognition,
  });
  Promise.resolve(promise).finally(() => {
        setLoading(false)
        setTimeout( ()=>{
        //  window.location.reload(false);
        },2000)
       
  });



 };


 
  return (
    <Card additionalClass="mt-4 p-4  maxh">
      
      <h5>Add Expert</h5>

      <div
                
                style={{
                  padding: "10px",
                  maxHeight: "280px",
                }}
                className="col-12 col-md-12 list-achievements">

          <form onSubmit={handleSubmit}> 
                
                <label className="co-grayx">Select Expert Category</label>
                <select className="form-control"
                   
                       name="category_id"
                       placeholder=""
                    
                       id="category_id"
                       value={stateRecognition.category_id}
                       onChange={handleChange}
                
                >
                <option>Choose an Option</option>
                   {
            get_all_expert_categories &&

            get_all_expert_categories.get_all_expert_categories
            &&
            get_all_expert_categories.get_all_expert_categories.map( (item, index)=>{
              return(
                 
                <option value={item.id}>{item.title}</option>
                
              )
            })
          }
                 
                </select>
                <CustomInput
                  type="text"
                  name="title"
                  placeholder=""
                  label="Title"
                  id="title"
                  value={stateRecognition.title}
                  onChange={handleChange}

                />

              <CustomInput
                  type="text"
                  name="fullname"
                  placeholder=""
                  label="Full Name"
                  id="fullname"
                  value={stateRecognition.fullname}
                  onChange={handleChange}

                />


          <CustomInput
                  type="text"
                  name="description"
                  placeholder=""
                  label="description"
                  id="description"
                  value={stateRecognition.description}
                  onChange={handleChange}

                />

          <CustomInput
                  type="text"
                  name="email"
                  placeholder=""
                  label="Email"
                  id="email"
                  value={stateRecognition.email}
                  onChange={handleChange}

                />
                
                <CustomInput
                  type="text"
                  name="phone"
                  placeholder=""
                  label="Phone"
                  id="phone"
                  value={stateRecognition.phone}
                  onChange={handleChange}

                />
                  
                    <div>
                      <br/><br/>

                    <CustomButton
                      backgroundColor="#3A6ED4"
                      color="#fff"
                         type="submit"
                      text="Submit"
                      loading={isUploading}
                    />

                  
                    </div>
 
            </form> 
    </div>



    </Card>

  );
};


export default CreateExpert;
