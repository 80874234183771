/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { bool, func, object, string } from "prop-types";
import { Divider, IconButton } from "@material-ui/core";
import icons from "../../../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../../../components/CustomButton";
import ViewDetails from "./ViewDetails";
import Refer from "./ReferJob";
import { useDispatch, useSelector } from "react-redux";
import { getEitherJob } from "../../../../../redux/actions/jobActions";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SectionLoader } from "../../../../../components/PageLoader";

const HomeModal = ({ open, handleClose, hideActions, isBusiness }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { jobId } = useParams();
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState();
  const [subSection, setSubSection] = useState("details");

  const path = history.location.pathname;
  const { job } = useSelector((state) => state.job);

  useEffect(() => {
    setLoading(true);
    Promise.resolve(
      getEitherJob(
        dispatch,
        jobId,
        path.includes("recommended") ? true : isBusiness
      )
    ).finally(() => setLoading(false));
  }, [dispatch, jobId]);

  useEffect(() => {
    if (pathname.includes("refer")) {
      setSection("refer");
    } else {
      setSection("details");
    }
  }, [pathname]);

  const viewJobDetails = () => {
    history.push(`/recruiter/requests/${jobId}`);
    setSection("details");
    setSubSection("details");
  };

  const viewDocument = () => {
    history.push(`/recruiter/requests/${jobId}`);
    setSection("details");
    setSubSection("documents");
  };
  return (
    <div className="position-relative">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="View details"
        PaperProps={{
          style: {
            minWidth: "50rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        {!job && loading && (
          <div className="min-height-25">
            <SectionLoader />
          </div>
        )}

        {job && !loading && (
          <>
            <div className="d-flex justify-content-between align-items-centter">
              <DialogTitle id="details-dialog-title">
                <div>
                  <h2 className="mb-0">{job.role_title}</h2>
                  <p className="mb-0 co-darkgray">{job.campany_name}</p>
                </div>
              </DialogTitle>
              <div className="close-button">
                <IconButton onClick={handleClose}>
                  <svg width="30" height="30" className="rounded-circle">
                    <use href={`${icons}#close`}></use>
                  </svg>
                </IconButton>
              </div>
            </div>
            <div className="position-relative sticky">
              <div className="bg-white w-100 position-absolute">
                <div className="d-flex px-4 justify-content-start">
                  <button
                    onClick={viewJobDetails}
                    className="co-white px-4 py-2 mr-2 fw-bold border-0 bg-primary rounded"
                  >
                    Job Description
                  </button>
                  <button
                    onClick={viewDocument}
                    className="co-white px-4 py-2 fw-bold border-0 bg-darkgrey rounded"
                  >
                    Additional Documents
                  </button>
                </div>
                <div className="d-flex justify-content-start my-3">
                  <span className="ml-4 mr-2">
                    <svg width="17" height="16" className="mr-1">
                      <use href={`${icons}#briefcase`}></use>
                    </svg>
                    {job.job_level && job.job_level.title}
                  </span>
                  <span className="mr-2">
                    <svg width="14" height="14" className="mr-1">
                      <use href={`${icons}#mappin`}></use>
                    </svg>
                    {job.location}
                  </span>
                  <span className="mr-2">
                    <svg width="24" height="17" className="mr-1">
                      <use href={`${icons}#persons`}></use>
                    </svg>
                    {job.no_of_staff} workers
                  </span>
                </div>
                <Divider />
              </div>
            </div>

            <DialogContent>
              <div className="mt-3">
                {section === "details" && (
                  <ViewDetails job={job} section={subSection} />
                )}
                {section === "refer" && (
                  <Refer job={job} isBusiness={isBusiness} />
                )}
              </div>
            </DialogContent>
            {!hideActions && (
              <DialogActions>
                {section === "details" && (
                  <>
                    <CustomButton
                      backgroundColor="#41B883"
                      handleClick={() =>
                        history.push(`/recruiter/requests/${jobId}/refer`)
                      }
                      color="#fff"
                      text="Refer"
                      additionalClass="mx-3 mt-2"
                    />
                  </>
                )}
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
};

HomeModal.propTypes = {
  success: bool,
  setSuccess: func,
  open: bool.isRequired,
  handleClose: func.isRequired,
  section: string.isRequired,
  setSection: func.isRequired,
  job: object,
  hideActions: bool,
};

HomeModal.defaultProps = {
  job: {},
  success: false,
  setSuccess: () => {},
  hideActions: false,
};

export default HomeModal;
