import React, { useRef, useState ,useEffect} from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import icons from "../../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import ReactStars from "react-rating-stars-component";
import {
  getAchievementsData
}
from "../../../../redux/actions/recognitionAction";

import { useHistory } from "react-router";
import CustomTextArea from "../../../../components/CustomTextArea";
import CustomInput from "../../../../components/CustomInput";
import cogoToast from "cogo-toast";
import { uploadFile } from "../../../../utils/helpers";
import Grid from '@material-ui/core/Grid';
import './achievement.css';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
 

const MyAchievement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [isAdding, setisAdding] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [id, setID] = useState(1);

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const uploadInputRef = useRef(0);
  const uniqueURL = `${process.env.REACT_APP_WEB_URL}`;
  const {achievement_data} = useSelector((store) => store);

  const title = ``;
  const hashtags = ["workbrook"];
  const handleChangeUpload = ({ target: { files } }) => {
    if (files && files[0]) {
      setIsUploading(true);

      Promise.resolve(
        uploadFile(
          files[0],
          "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
        )
      )
        .then(({ data, success }) => {
          if (success) {
            //addDocLink(data);

           // addDocLinkObject({ link: data, name: files[0].name });
          } else {
            cogoToast.error("Unsuccessful document upload", {
              hideAfter: 4,
              position: "top-center",
            });
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setLoading(true);
    Promise.resolve(getAchievementsData(dispatch, id)).finally(() =>setLoading(false)  
    );
  }, [dispatch, id]);

  const handleClose = () => {
     setAnchorEl(null);
    setOpen(false);
  };


  const handleRefClick = () => {
    uploadInputRef.current.click();
  };
  return (
     <div additionalClass="mt-4 p-4 maxh">
     <h5>My Accomplishment</h5>
        
  {  !isAdding ?
      <div
                
                style={{
                  padding: "10px",
                  maxHeight: "280px",
                  display:"none"
                }}
                className="col-12 col-md-12 list-achievements"
              >

   {/* <button className="btnc" 
        onClick={
          ()=>{
            setisAdding(true)
          }
        }
        >Add New Achievement</button> */}

 
      <Card
                    additionalClass="mx-md-2x px-4x mb-4x"
                    additionalStyle={{
                      height: "100%",
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "stretch",
                      justifyContent: "space-between",
                    }}
                  >


              <Grid container spacing={0}>
                      <Grid item xs={1}>
                        <br/>
                      <img src="https://friendkit.cssninja.io/assets/img/avatars/dan.jpg" 
                    data-demo-src="assets/img/avatars/dan.jpg" 
                    data-user-popover="1" 
                    className="md-dd img-circle"
                    alt="" 
                    data-target="webuiPopover67" />
                      </Grid>
                      <Grid item xs={11}>
                      <br/>
                      <span className="">Alex Cairo (CEO Caroi limited)</span><br/>
                      <span className="co-gray">19th July 2021</span>

                      </Grid>

              </Grid>
                
                <Divider/>

                    <br/>


      <div className="d-flex mb-2 align-items-center justify-content-between">
      

      <div className="d-flex align-items-center">


      <span className="d-flex flex-column ml-3x">
      <h6 className="mb-0 fontbold">Revenue or sales you increased for the company.</h6> 
      <span className="co-gray">While David worked for my company, he demostrated a remarkable capability in making sales. We had a turnover 7x due to his
      strategies. I highly recommend him. 
      </span>
       </span>

    

                            
        </div>

        <IconButton  onClick={(e) => handleClick(e)}>
        <svg width="20" height="15">
        <use href={`${icons}#dots`}></use>
        </svg>
        </IconButton> 
        </div> 
        
        <ReactStars
                count={5}
                size={14}
                edit={false}
                activeColor="#ffd700"
                value={4.5}
              />

        <br/>
        <Divider/>
      
        <div class="likers-group">
        <img src="https://i.pravatar.cc/20" data-demo-src="https://i.pravatar.cc/20" data-user-popover="5" alt="" data-target="webuiPopover20" />
         <img src="https://i.pravatar.cc/38" data-demo-src="https://i.pravatar.cc/38" data-user-popover="5" alt="" data-target="webuiPopover20" />
         <img src="https://i.pravatar.cc/40" data-demo-src="https://i.pravatar.cc/40" data-user-popover="5" alt="" data-target="webuiPopover20" />

         </div>
   
        
 
        <div class="likers-text">
        <p>
        <span className="name-1">Milly</span>,
        <span className="name-2">David</span>
        <span> and 2 more supported this</span>
        </p>
        
        </div>
 

      </Card>

 
        
      <Card
                    additionalClass="mx-md-2x px-4x mb-4x mgtop"
                    additionalStyle={{
                      height: "100%",
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "stretch",
                      justifyContent: "space-between",
                    }}
                  >


              <Grid container spacing={0}>
                      <Grid item xs={1}>
                        <br/>
                      <img src="https://i.pravatar.cc/70" 
                    data-demo-src="https://i.pravatar.cc/70" 
                    data-user-popover="1" 
                    className="md-dd img-circle"
                    alt="" 
                    data-target="webuiPopover67" />
                      </Grid>
                      <Grid item xs={11}>
                      <br/>
                      <span className="">Jimx Adelaja (HR Mini Consultants)</span><br/>
                      <span className="co-gray">20th Jan 2021</span>

                      </Grid>

              </Grid>
                
                <Divider/>

                    <br/>


      <div className="d-flex mb-2 align-items-center justify-content-between">
      

      <div className="d-flex align-items-center">


      <span className="d-flex flex-column ml-3x">
      <h6 className="mb-0 fontbold">Outstanding Performance in Human Management</h6> 
      <span className="co-gray">He served under me as an H.R Intern during his early days and was one of the best people I have ever had
      work/learn/sever under me. 
      </span>
       </span>
 
                            
        </div>

        <IconButton  onClick={(e) => handleClick(e)}>
        <svg width="20" height="15">
        <use href={`${icons}#dots`}></use>
        </svg>
        </IconButton> 
        </div> 


           <ReactStars
                count={5}
                size={14}
                edit={false}
                activeColor="#ffd700"
                value={3.5}
              />

        <br/>
        <Divider/>

      
        <div class="likers-group">
        <img src="https://i.pravatar.cc/52" data-demo-src="https://i.pravatar.cc/52" data-user-popover="5" alt="" data-target="webuiPopover20" />
         <img src="https://i.pravatar.cc/61" data-demo-src="https://i.pravatar.cc/61" data-user-popover="5" alt="" data-target="webuiPopover20" />
         <img src="https://i.pravatar.cc/21" data-demo-src="https://i.pravatar.cc/21" data-user-popover="5" alt="" data-target="webuiPopover20" />
        </div>
   
        
 
        <div class="likers-text">
        <p>
        <span className="name-1">Cynthia</span>,
        <span className="name-1">Uche</span>

         <span> and 4 supported this</span>
        </p>
        
        </div>
 

      </Card>


      <Card
                    additionalClass="mx-md-2x px-4x mb-4x mgtop"
                    additionalStyle={{
                      height: "100%",
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "stretch",
                      justifyContent: "space-between",
                    }}
                  >


              <Grid container spacing={0}>
                      <Grid item xs={1}>
                        <br/>
                      <img src="https://i.pravatar.cc/75" 
                    data-demo-src="https://i.pravatar.cc/75" 
                    data-user-popover="1" 
                    className="md-dd img-circle"
                    alt="" 
                    data-target="webuiPopover67" />
                      </Grid>
                      <Grid item xs={11}>
                      <br/>
                      <span className="">Pet Fox (Head I.T)</span><br/>
                      <span className="co-gray">20th Jan 2020</span>

                      </Grid>

              </Grid>
                
                <Divider/>

                    <br/>


      <div className="d-flex mb-2 align-items-center justify-content-between">
      

      <div className="d-flex align-items-center">


      <span className="d-flex flex-column ml-3x">
      <h6 className="mb-0 fontbold">Great Learning abilities</h6> 
      <span className="co-gray">He has a outstanding adaptability and learning ability 
      </span>
       </span>
 
                            
        </div>

        <IconButton  onClick={(e) => handleClick(e)}>
        <svg width="20" height="15">
        <use href={`${icons}#dots`}></use>
        </svg>
        </IconButton> 
        </div> 


           <ReactStars
                count={5}
                size={14}
                edit={false}
                activeColor="#ffd700"
                value={5}
              />

        <br/>
        <Divider/>

      
        <div class="likers-group">
        <img src="https://i.pravatar.cc/62" data-demo-src="https://i.pravatar.cc/62" data-user-popover="5" alt="" data-target="webuiPopover20" />
         <img src="https://i.pravatar.cc/71" data-demo-src="https://i.pravatar.cc/71" data-user-popover="5" alt="" data-target="webuiPopover20" />
         <img src="https://i.pravatar.cc/91" data-demo-src="https://i.pravatar.cc/91" data-user-popover="5" alt="" data-target="webuiPopover20" />
        </div>
   
        
 
        <div class="likers-text">
        <p>
        <span className="name-1">Obi</span>,
        <span className="name-1">Ikena</span>,

         <span> and 5 more supported this</span>
        </p>
        
        </div>
 

      </Card>


<div>
<br/><br/><br/><br/><br/><br/>
</div>
                    
               
                   <Menu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: "15rem",
                        marginTop: "0.2rem",
                        borderRadius: "1rem",
                        boxShadow: "0px 0px 5px -3px rgba(0,0,0,0.2)",
                      },
                    }}
                  >
                    <MenuItem
                      className="px-4 py-2 medium-text"
                    
                  
                    >
                     <FacebookShareButton
                      url={uniqueURL}
                      quote={title}
                      className="mr-2"
                      hashtag="#workbrook #job"
                    >
                    <FacebookIcon size={32} round /> Share on Facebook
                    </FacebookShareButton>
                    </MenuItem>
                    

                    <MenuItem
                      className="px-4 py-2 medium-text"
                    
                  
                    >
                    <TwitterShareButton
                      url={uniqueURL}
                      title={title}
                      className="mr-2"
                      hashtags={hashtags}
                    >
                      <TwitterIcon size={32} round />Share on Twitter
                    </TwitterShareButton>
                    </MenuItem>

                    <MenuItem
                      className="px-4 py-2 medium-text"
                      onClick={handleClose}
                    >
                    <LinkedinShareButton url={uniqueURL} className="mr-2">
                    <LinkedinIcon size={32} round /> Share on LinkedIn
                  </LinkedinShareButton>
                    </MenuItem>
 
                    <Divider />
                    
                  </Menu>
                 

      </div>

:

      <div>
      <CustomTextArea
        name="what_achievement"
        label="What ?"
        id="what_achievement"
      />

      <CustomTextArea
        name="how_achievement"
        label="How ?"
        id="how_achievement"
      />

    {/*
      <CustomInput
        type="text"
        name="supported_by"
        placeholder=""
        label="Supported By"
        id="supported_by"
      />

        <CustomInput
        type="text"
        name="confrimed_by"
        placeholder=""
        label="Confirmed By"
        id="confrimed_by"
      />
      */}


      <div>
        <br/>
          <input
            ref={uploadInputRef}
            type="file"
            onChange={handleChangeUpload}
            style={{ display: "none" }}
          />

          <CustomButton
            backgroundColor="#fff"
            color="#000"
            text="Add Attachments"
            handleClick={handleRefClick}
            loading={isUploading}
          />
        </div>

        {/*<div className="text-center mb-4 mt-4">
            <h5 className="mb-2">Share on Social Media</h5>
            <div>
              <FacebookShareButton
                url={uniqueURL}
                quote={title}
                className="mr-2"
                hashtag="#workbrook #job"
              >
              <FacebookIcon size={32} round />
              </FacebookShareButton>

              <TwitterShareButton
                url={uniqueURL}
                title={title}
                className="mr-2"
                hashtags={hashtags}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <LinkedinShareButton url={uniqueURL} className="mr-2">
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>

              <EmailShareButton
                url={uniqueURL}
                subject="You have been invited to join workbrook"
                body={title}
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div> */}
       



        <div >
        <CustomButton
            backgroundColor="#3A6ED4"
            color="#fff"
            text="Submit"
             loading={isUploading}
          />


        <a className="centralised-text" onClick={
          ()=>{
              setisAdding(false)
          }
        }>
          Close 
          </a>
        </div>



      </div>
     
}  
     
     </div>
 
   );
};

export default MyAchievement;
