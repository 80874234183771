/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import icons from "../../../../assets/icons/icon-collection.svg";
import { useHistory } from "react-router-dom";
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import LinkButton from "../../../../components/LinkButton";
import CustomTextArea from "../../../../components/CustomTextArea";
import Card from "../../../../components/Card";
import {
  likeUnlikeJob,
  saveJobAction,
  reportJobAction,
} from "../../../../redux/actions/jobActions";
import { DemoPointer } from "../../../../demo";
import { demoAction } from "../../../../redux/actions/demoActions";

//import createDOMPurify from 'dompurify'
//import { JSDOM } from 'jsdom'


const JobCard = ({ job }) => {

  //const window = (new JSDOM('')).window
  //const DOMPurify = createDOMPurify(window)

  const history = useHistory();
  const dispatch = useDispatch();
  const [likes, setLikes] = useState(job.total_likes);
  const [isLiked, setIsLiked] = useState(job.isLikedd);
  const [anchorEl, setAnchorEl] = useState(null);
  const [report, setReport] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [, setSection] = useState("details");

  const {
    userData: { isRecruiter },
  } = useSelector((store) => store.auth);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSection("details");
    setAnchorEl(null);
  };

  const handleSaveJob = (id) => {
    saveJobAction(dispatch, id);
    setSection("details");
    setAnchorEl(null);
  };

  const handleShareJob = (id) => {
    history.push(`/home/${id}/refer`);
    setSection("details");
    setAnchorEl(null);
  };

  const handleReportJob = () => {
    setReport(true);
  };

  const handleReportChange = (e) => {
    setReportReason(e.target.value);
  };

  const handleReportClose = () => {
    setReport(false);
  };

  const handleReportSubmit = (id) => {
    reportJobAction(dispatch, id, reportReason);
    setReport(false);
  };

  const handleLikeJob = (id) => {
    let likeCount = parseInt(likes);
    setLikes(likeCount + 1);
    setIsLiked("1");
    processLikeUnlike(id);
  };

  const handleUnlikeJob = (id) => {
    let likeCount = parseInt(likes);
    setLikes(likeCount - 1);
    setIsLiked("0");
    processLikeUnlike(id);
  };

  const processLikeUnlike = (id) => {
    const likePayload = {
      job_id: id,
    };

    likeUnlikeJob(dispatch, likePayload);
  };

  return (
    <>
      <Card
        additionalClass="mx-md-2 px-4 mb-4 "
        additionalStyle={{
          height: "100%",
          display: "flex",
          flexFlow: "column",
          alignItems: "stretch",
          justifyContent: "space-between",
        }}
      >
        <div className="d-flex mb-2 align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              width="30"
              className="rounded-circle"
              src={job.created_by_profile.image_url}
              alt="avatar"
            />
            <span className="d-flex flex-column ml-3">
              <h6 className="mb-0">{job.campany_name}</h6>
              <span className="co-gray">
                {job.created_by_profile.first_name}{" "}
                {job.created_by_profile.last_name}
              </span>
            </span>
          </div>
          <IconButton onClick={(e) => handleClick(e)}>
            <svg width="20" height="15">
              <use href={`${icons}#dots`}></use>
            </svg>
          </IconButton>
        </div>
        <div className="d-flex justify-content-start my-3">
          <span className="mr-2">
            <svg width="14" height="14" className="mr-1">
              <use href={`${icons}#mappin`}></use>
            </svg>
            {job.location}
          </span>
        </div>
        <Divider />

       {/* <DemoPointer text="See more details of job here" position="top"> */}
          <div className="pb-3">
            <b className="d-block my-3 co-primary">{job.role_title}</b>
            <span>
               {/* <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.about_company.substring(0, 25)) }}></span> */}
               {/* <span dangerouslySetInnerHTML={{ __html: job.about_company.substring(0, 160) }}></span> }*/}
          
          {
          job.about_company.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 80)} ...
              <button
              style={{display:"inline"}}
                type="button"
                onClick={() => {
                  history.push(`/home/${job.id}`);
                  const state = {
                    ["See more details of job here"]: false,
                    ["Apply here"]: true,
                    ["Refer the great people you know right here"]: true,
                  };
                  demoAction(dispatch, state);
                }}
                className="co-primary bg-white borderless">
                <b>See More</b>
              </button>
            </span>
          </div>
      {/*  </DemoPointer> */}
             
        
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className="small-text thin-text">{likes} Likes</span>
          <span>
            {/* <img src={SmallPlaceholder} alt="avatar" className="mx-1" />
                    <img src={SmallPlaceholder} alt="avatar" className="mx-1" />
                    <img src={SmallPlaceholder} alt="avatar" className="mx-1" /> */}
          </span>
        </div>

        <Divider />

        <div className="d-flex pt-2 align-items-center justify-content-between">
          {isLiked === "0" ? (
            <LinkButton label="Like" onClick={() => handleLikeJob(job.id)} />
          ) : (
            <LinkButton
              label="Unlike"
              onClick={() => handleUnlikeJob(job.id)}
            />
          )}
          <div  className="joyride-refer-candidate">
          <LinkButton
            label="Refer"
            
            onClick={() => history.push(`/home/${job.id}/refer`)}
          />
          </div>
            
          <div  className="joyride-request-apply-candidate">
          {isRecruiter === 1 && job.canRecruiterRequest === "1" ? (
            <LinkButton
              label="Request"
              onClick={() => history.push(`/home/${job.id}`)}
            />
          ) : (
            <LinkButton
              label="Apply"
              onClick={() => history.push(`/home/${job.id}/apply`)}
            />
          )}
          </div>
        </div>
      </Card>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "15rem",
            marginTop: "0.2rem",
            borderRadius: "1rem",
            boxShadow: "0px 0px 5px -3px rgba(0,0,0,0.2)",
          },
        }}
      >
        <MenuItem
          className="px-4 py-2 medium-text"
          onClick={() => handleSaveJob(job.id)}
        >
          Save
        </MenuItem>
        <MenuItem
          className="px-4 py-2 medium-text"
          onClick={() => handleShareJob(job.id)}
        >
          Share
        </MenuItem>
        {/* <MenuItem className="px-4 py-2 medium-text" onClick={handleClose()}>
          Hide Post
        </MenuItem> */}
        <Divider />
        <MenuItem
          className="px-4 py-3 co-lightred medium-text"
          onClick={() => handleReportJob(job.id)}
        >
          Report This Post
        </MenuItem>
      </Menu>

      {report && (
        <Dialog
          open={report}
          onClose={handleReportClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Report Post</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Let us know why you want to report this job
            </DialogContentText>
            <CustomTextArea
              id="report_reason"
              label=""
              value={reportReason}
              onChange={(e) => handleReportChange(e)}
              isRequired
            />
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose} color="primary">
              Cancel
            </Button> */}
            <Button onClick={() => handleReportSubmit(job.id)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default JobCard;
