import httpRequest from "../../ajax";
import {
  CREATE_RECRUITER,
  UPDATE_RECRUITER,
  GET_MY_RECRUITER_DATA,
  ERROR,
  GET_ALL_RECRUITERS,
  GET_RECRUITER,
  GET_RECRUITER_JOBS,
  SEARCH_RECRUITER,
  SEND_JOB,
  ACCEPT_DECLINE_JOB,
  GET_RECRUITER_INSIGHT,
  GET_RECRUITER_JOBS_APPLICANTS,
  GET_RECRUITER_JOB_APPLICANTS,
  GET_RECRUITER_JOBS_SHORTLISTED,
  SHORTLIST_APPLICANT,
  GET_RECRUITER_APPLICANTS_SHORTLISTED,
  SEND_SHORTLIST,
  RECRUITER_REQUEST_ACCEPTANCE,
  RECRUITER_COMPLETED_JOBS_LEVELS,
  RECRUITER_COMPLETED_BY_INDUSTRY,
  RECRUITER_SHORTLIST_INTERVIEW_RATIO,
  GET_RECRUITER_REVIEW,
} from "../actionTypes";

export const createRecruiter = (dispatch, data) =>
  httpRequest("post", "/recruiter/create", data)
    .then(({ data: { data } }) => {
      const action = { type: CREATE_RECRUITER, payload: data };
      const userData =
        JSON.parse(localStorage.getItem("userData")) ||
        JSON.parse(sessionStorage.getItem("userData"));
      const profile = { ...userData, ...{ isRecruiter: 1 } };
      localStorage.setItem("userData", JSON.stringify(profile));
      setTimeout(window.location.reload(), 1000);
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const updateRecruiter = (dispatch, data) =>
  httpRequest("post", "/recruiter/update", data)
    .then(({ data: { data } }) => {
      const action = { type: UPDATE_RECRUITER, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getMyRecruiterData = (dispatch) =>
  httpRequest("get", "/recruiter/own")
    .then(({ data: { data } }) => {
      const action = { type: GET_MY_RECRUITER_DATA, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const sendJob = (dispatch, jobId, refererIdOne) =>
  httpRequest("post", `/jobs/${jobId}/jobToRecruiter`, {
    refererIdOne,
    refererIdTwo: 0,
  })
    .then(({ data: { data } }) => {
      const action = { type: SEND_JOB, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const acceptOrDeclineJob = (dispatch, data, callback = () => {}) =>
  httpRequest("post", "/recruiter/jobs/acceptOrDecline", data)
    .then(({ data: { data } }) => {
      callback();
      const action = { type: ACCEPT_DECLINE_JOB, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getAllRecruiters = (dispatch) =>
  httpRequest("get", "/recruiter")
    .then(({ data: { data } }) => {
      const action = { type: GET_ALL_RECRUITERS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruiter = (dispatch, id) =>
  httpRequest("get", `/recruiter/${id}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_RECRUITER, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruiterJobs = (dispatch) =>
  httpRequest("get", "/recruiter/jobs")
    .then(({ data: { data } }) => {
      const action = { type: GET_RECRUITER_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const searchRecruiter = (dispatch, searchBy, term, jobId) =>
  httpRequest("get", `/recruiter/${searchBy}/${term}/${jobId}/search`)
    .then(({ data: { data } }) => {
      const action = { type: SEARCH_RECRUITER, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

 


export const getRecruiterInsight = (dispatch) =>
  httpRequest("get", `/recruiter/insight/1/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_RECRUITER_INSIGHT, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruiterJobsApplicants = (dispatch, jobId) =>
  httpRequest("get", `/insight/${jobId}/applicants/1/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_RECRUITER_JOBS_APPLICANTS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruiterJobApplicants = (dispatch, id) =>
  // httpRequest("get", `/insiight/jobOffers/${id}/applicants/*/1/10`);
  httpRequest("get", `/recruiter/insight/${id}/applicants/1/10`)
    .then(({ data: { data } }) => {
      // console.log("RRR", data);
      const action = { type: GET_RECRUITER_JOB_APPLICANTS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruiterJobsShortlisted = (dispatch) =>
  httpRequest("get", `/recruiter/insight/jobs/1/100`)
    .then(({ data: { data } }) => {
      // console.log(data);
      const action = {
        type: GET_RECRUITER_JOBS_SHORTLISTED,
        payload: data?.data || [],
      };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const shortlistApplicant = (dispatch, job_id, app_id, data) =>
  httpRequest(
    "post",
    `/recruiter/insight/${job_id}/applicants/${app_id}/shortlist`,
    data
  )
    .then(({ data: { data } }) => {
      const action = { type: SHORTLIST_APPLICANT, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruiterShortlistedApplicants = (dispatch, jobId) =>
  httpRequest("get", `/recruiter/insight/${jobId}/applicants/shortlisted/1/100`)
    .then(({ data: { data } }) => {
      const action = {
        type: GET_RECRUITER_APPLICANTS_SHORTLISTED,
        payload: data.data,
      };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const sendShortlist = (dispatch, job_id) =>
  httpRequest(
    "post",
    `/recruiter/insight/${job_id}/applicants/shortlisted/send`,
    {}
  )
    .then(({ data: { data } }) => {
      const action = { type: SEND_SHORTLIST, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getTotalRequestAndAcceptance = (dispatch, recruiterId) =>
  httpRequest(
    "get",
    `/recruiter/insight/analytics/totalRequestAndAcceptance/${recruiterId}`
  )
    .then(({ data: { data } }) => {
      // console.log(data);
      const action = {
        type: RECRUITER_REQUEST_ACCEPTANCE,
        payload: data,
      };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruiterJobsLevels = (dispatch, recruiterId) =>
  httpRequest(
    "get",
    `/recruiter/insight/analytics/completedJobsLevel/${recruiterId}`
  )
    .then(({ data: { data } }) => {
      // console.log(data);
      const action = {
        type: RECRUITER_COMPLETED_JOBS_LEVELS,
        payload: data,
      };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruiterJobsCompletedByIndustry = (dispatch, recruiterId) =>
  httpRequest(
    "get",
    `/recruiter/insight/analytics/recruiterComplitedByIndustry/${recruiterId}/1/100`
  )
    .then(({ data: { data } }) => {
      const action = {
        type: RECRUITER_COMPLETED_BY_INDUSTRY,
        payload: data,
      };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const shortlistAndInterviewRatio = (dispatch, recruiterId) =>
  httpRequest(
    "get",
    `/recruiter/insight/analytics/shortlistAndInterviewRatio/${recruiterId}`
  )
    .then(({ data: { data } }) => {
      const action = {
        type: RECRUITER_SHORTLIST_INTERVIEW_RATIO,
        payload: data,
      };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruiterReview = (dispatch, recruiterId) =>
  httpRequest("get", `/recruiter/insight/analytics/reviews/${recruiterId}`)
    .then(({ data: { data } }) => {
      // console.log("rev", data);
      const action = {
        type: GET_RECRUITER_REVIEW,
        payload: data,
      };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
