import React, { useEffect } from "react";
import { DialogTitle, DialogContent, Menu } from "@material-ui/core";
import { func, object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../../redux/actions/settingsActions";
import EmptyState from "../../../components/EmptyState";

const Notifications = ({ anchorEl, handleClose }) => {
  const dispatch = useDispatch();
  const { notifications } = useSelector(({ settings }) => settings);

  useEffect(() => {
    getNotifications(dispatch);
  }, [dispatch]);
  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        aria-labelledby="notifications"
        PaperProps={{
          style: {
            width: "25rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Notifications</DialogTitle>

        <DialogContent>
          {!notifications[0] && <EmptyState text="You have no notifications" />}
          {notifications[0] && (
            <>
              {notifications.map((notification, index) => (
                <div key={index} className="d-flex align-items-center mb-3">
                  <img
                    width="50"
                    className="rounded-circle"
                    src={notification.image_url}
                    alt="avatar"
                  />
                  <span className="d-flex flex-column ml-3">
                    <h6 className="co-gray mb-0">{notification.category}</h6>
                    <span className="">{notification.message}</span>
                    <span className="co-gray small-text">
                      {new Date(notification.date_created).toLocaleString()}
                    </span>
                  </span>
                </div>
              ))}
            </>
          )}
        </DialogContent>
      </Menu>
    </div>
  );
};

Notifications.propTypes = {
  anchorEl: object,
  handleClose: func.isRequired,
};

export default Notifications;
