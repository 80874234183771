import axios from "axios";
import phoneNumberPrefix from "./phoneNumberPrefixes";

/**
 * Anonymous Function
 * @param {any} event - event
 * @returns {void}
 */
export const anonymousFunc = () => {};

/**
 * @function ternaryResolver
 * @description resolves a ternary expression
 * @param {any} resolvedExpression - resolvedExpression
 * @param {any} truthyValue - truthyValue
 * @param {any} falsyValue - falsyValue
 * @return {any} - any
 */
export const ternaryResolver = (resolvedExpression, truthyValue, falsyValue) =>
  resolvedExpression ? truthyValue : falsyValue;

/**
 * @function isValidEmail
 * @description checks for valid email
 * @param {string} email - email to be tested
 * @returns {boolean} -  returns a boolean true or false
 */
export const isValidEmail = (email) => {
  const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return pattern.test(email);
};

/**
 * @function logout
 * @description clears users data from the browsers storage
 * @returns {boolean} - boolean
 */
export const logout = () => {
  // remove local auth
  localStorage.removeItem("rememberMe");
  localStorage.removeItem("userData");
  localStorage.removeItem("token");

  // clear session auth
  sessionStorage.removeItem("rememberMe");
  sessionStorage.removeItem("userData");
  sessionStorage.removeItem("token");
};


 //A simple function to help sort
 export const listToMatrix = (list, elementsPerSubArray)=> {
  var matrix = [], i, k;

  for (i = 0, k = -1; i < list.length; i++) {
      if (i % elementsPerSubArray === 0) {
          k++;
          matrix[k] = [];
      }

      matrix[k].push(list[i]);
  }

  return matrix;
}

/**
 * delayExecution
 */
export const delayExecution = {
  /**
   * @method exec
   * @description delays execution of a function
   * @param {Function} callback - function to fire
   * @param {number} delay - delay time in milliseconds before function fires
   * @return {void}
   */
  exec: (callback, delay = 5000) => setTimeout(callback, delay),
};

/**
 * @description naira currency instance
 * @class CurrencyConverter
 */
export class CurrencyConverter {
  constructor() {
    this.nairaCurrency = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  toNaira(value) {
    return this.nairaCurrency.format(`${value}`);
  }
  strip(value) {
    return `${value}`
      .split("")
      .filter((val) => Number.isInteger(+`${val}`) && val !== " ")
      .join("");
  }
}

/**
 * @function isValidPhoneNumber
 * @description returns a boolean for a valid phone number
 * @param {string} value - mobile number
 * @returns {boolean} - returns a boolean
 */
export const isValidPhoneNumber = (value) =>
  !!phoneNumberPrefix.find(
    (prefix) => prefix === `${value}`.slice(0, prefix.length)
  ) &&
  `${value}`.length === 11 &&
  /^[0-9]*$/.test(value);

/**
 * @function strictRender
 * @description renders component where the resolvedCondition is truthy
 * @param {Object} arguments - object
 * @param {boolean[]} arguments.resolvedCondition - array of booleans
 * @param {Object} arguments.component - JSX component
 * @returns {JSX} - returns JSX
 */
export const strictRender = ({ resolvedCondition, component }) =>
  resolvedCondition?.every?.((val) => val === true) && component;

/**
 * @function handleInputFocus
 * @description handles input focus
 * @return {any} - any
 */
export const handleInputFocus = () =>
  document.querySelector(".input-container > input")?.focus();

/**
 * @function preventDefaultBehavior
 * @description handles prevent default behavior
 * @param {Object} event - event object
 * @return {void}
 */
export const preventDefaultBehavior = (event) => event?.preventDefault?.();

/**
 * @description Set Token
 * @returns {void}
 */
export const setToken = () => {
  const token = sessionStorage.token ?? localStorage.token;
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

/**
 * @description convert file to url
 * @function convertFileToUrl
 * @returns {string} - axios instance
 */
export const convertFileToUrl = (file) => {
  try {
    return URL.createObjectURL(file);
  } catch (err) {
    return "";
  }
};

/**
 * @function uploadFile
 * @description file upload setup method
 * @param {object} file - file
 * @param {object} URL - URL
 * @param {string} method - http method
 * @returns {string} - url params
 */
export const uploadFile = (
  file,
  URL = "https://api.cloudinary.com/v1_1/workbrook-hash/image/upload",
  method = "post"
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "eaiybpes");
  delete axios.defaults.headers.common.Authorization;
  return axios[method](URL, formData, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then(({ data }) => {
      return {
        data: data.secure_url,
        success: true,
        fileName: data.original_filename,
      };
    })
    .catch((error) => {
      return { data: error.response, success: false };
    });
};

export const abbreviate = (industry) => {
  let industryArr = industry.split(" ");
  if (industryArr.length === 1) {
    return `${industry.charAt(0)}${industry.charAt(
      industry.length - 1
    )}`.toUpperCase();
  } else {
    return `${industryArr[0].charAt(0)}${industryArr[1].charAt(
      0
    )}`.toUpperCase();
  }
};

export const abbrDepartment = (department) => {
  let departmentArr = department.split("_");
  let dept = "";
  for (let i = 0; i < departmentArr.length; i++) {
    dept += `${departmentArr[i].charAt(0).toUpperCase()}${departmentArr[i]
      .slice(1)
      .toLowerCase()} `;
  }
  return dept;
};
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
