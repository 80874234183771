import { string } from 'prop-types';
import React from 'react';
import NoData from "../../assets/icons/nodata.svg";

const EmptyState = ({text}) => {
  return (
    <div className="empty-state d-flex flex-column align-items-center justify-content-center w-100">
      <img className="w-25" src={NoData} alt="nodata" />
      <p className="mt-3"><b>{text}</b></p>
    </div>
  );
};

EmptyState.propTypes = {
  text: string.isRequired
};

export default EmptyState;
