import React, { useEffect, useState } from "react";
import { IoPersonSharp } from "react-icons/io5";
import CustomButton from "../../../../components/CustomButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomInput from "../../../../components/CustomInput";
import cogoToast from "cogo-toast";

import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import CustomTextArea from "../../../../components/CustomTextArea";

import { postperformances } from "../../../../redux/actions/jobActions";
import { BsPeople, BsGraphUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import {
  getEmployee,
  getPerformance,
  getEachPerformance,
} from "../../../../redux/actions/jobActions";
import "./add.css";

function AddPerformance() {
  const maxTextareas = 5;
  const [activeTab, setActiveTab] = useState("Leadership");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [leadershipData, setLeadershipData] = useState([]);
  const [developmentData, setDevelopmentData] = useState([]);
  const [performanceeData, setPerformanceeData] = useState([]);
  const [goalSubmit, setGoalSubmit] = useState(false);

  const [EachEmployees, setEachEmployees] = useState([]);
  const userDataString = sessionStorage.getItem("userData");
     
      const userData = JSON.parse(userDataString);
      const profileId = Number(userData.id);

  const [employees, setEmployees] = useState([]);
  // const [leadershipPerformanceData, setLeadershipPerformanceData] = useState(
  //   {}
  // );
  // const [performancePerformanceData, setPerformancePerformanceData] = useState(
  //   {}
  // );
  // const [developmentPerformanceData, setDevelopmentPerformanceData] = useState(
  //   {}
  // );

  const [newDivs, setNewDiv] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const [sendEmployee, setSendEmployee] = useState({
    employee_id: "",
    employee_name: "",
    employee_goals: [],
  });

  const [leadershipGoal, setLeadershipGoal] = useState([
    {
      goal_title: "",
      goal_type: "Performance",
      goal_content: "",
      status: "",
      target_measurement: "",
      target_achieved: "",
      due_date: "",
    },
  ]);

  const [performanceGoal, setperformanceGoal] = useState([
    {
      goal_title: "",
      goal_type: "Performance",
      goal_content: "",
      status: "",
      target_measurement: "",
      target_achieved: "",
      due_date: "",
    },
  ]);

  const [developmentGoal, setDevelopmentGoal] = useState([
    {
      goal_title: "",
      goal_type: "Development",
      goal_content: "",
      status: "",
      target_measurement: "",
      target_achieved: "",
      due_date: "",
    },
  ]);

  useEffect(() => {
    getEmployee(dispatch)
      .then((action) => {
        setEmployees(action.payload.data);
        console.log(action.payload.data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      })
      .finally(() => {
        setTimeout(() => {}, 1000);
      });
  }, [dispatch]);

  const handleSubmit = () => {
    const userDataString = sessionStorage.getItem("userData");
     
      const userData = JSON.parse(userDataString);
      const profileId = Number(userData.id);
    const allGoals = JSON.parse(localStorage.getItem("allGoals")) || {};

    const data = {
      employee_id: profileId,
      employee_name: sendEmployee.employee_name,

      employee_goals: JSON.stringify(allGoals),
    };
    console.log(data);
    try {
      setIsLoading(true);
      const response = postperformances(dispatch, data);


     
        localStorage.removeItem("allGoals");
     
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const validateGoals = () => {
    for (const goal of leadershipGoal) {
      if (
        !goal.goal_title ||
        !goal.goal_content ||
        !goal.status ||
        !goal.target_measurement ||
        !goal.target_achieved ||
        !goal.due_date
      ) {
        return false;
      }
    }
    return true;
  };
  const validatePerformanceGoals = () => {
    for (const goal of performanceGoal) {
      if (
        !goal.goal_title ||
        !goal.goal_content ||
        !goal.status ||
        !goal.target_measurement ||
        !goal.target_achieved ||
        !goal.due_date
      ) {
        return false;
      }
    }
    return true;
  };
  const validateDevelopmentGoals = () => {
    for (const goal of developmentGoal) {
      if (
        !goal.goal_title ||
        !goal.goal_content ||
        !goal.status ||
        !goal.target_measurement ||
        !goal.target_achieved ||
        !goal.due_date
      ) {
        return false;
      }
    }
    return true;
  };

  const handleLeaderShip = () => {
    if (!validateGoals()) {
      cogoToast.error("Please fill out all fields before saving.");
      return;
    }
    const newGoal = leadershipGoal.map((goal) => ({
      goal_title: goal.goal_title,
      goal_type: "Leadership",
      goal_content: goal.goal_content,
      status: goal.status,
      target_measurement: goal.target_measurement,
      target_achieved: goal.target_achieved,
      due_date: goal.due_date,
    }));

    setSendEmployee((prevSendEmployee) => ({
      ...prevSendEmployee,
      employee_goals: [...prevSendEmployee.employee_goals, newGoal],
    }));

    const allGoals = JSON.parse(localStorage.getItem("allGoals")) || {};
    localStorage.setItem(
      "allGoals",
      JSON.stringify({
        ...allGoals,
        Leadership: [...(allGoals.Leadership || []), newGoal], 
      })
    );
    cogoToast.success("Leadership Goal saved successfully");
    console.log(newGoal);
  };

  const handlePerformance = () => {
    if (!validatePerformanceGoals()) {
      cogoToast.error("Please fill out all fields before saving.");
      return;
    }
    const newGoal = performanceGoal.map((goal) => ({
      goal_title: goal.goal_title,
      goal_type: "Performance",
      goal_content: goal.goal_content,
      status: goal.status,
      target_measurement: goal.target_measurement,
      target_achieved: goal.target_achieved,
      due_date: goal.due_date,
    }));

    setSendEmployee((prevSendEmployee) => ({
      ...prevSendEmployee,
      employee_goals: [...prevSendEmployee.employee_goals, newGoal],
    }));

    const allGoals = JSON.parse(localStorage.getItem("allGoals")) || {};
    localStorage.setItem(
      "allGoals",
      JSON.stringify({
        ...allGoals,
        Performance: [...(allGoals.Performance || []), newGoal],
      })
    );
    cogoToast.success("Performance Goal saved successfully");
    console.log(newGoal);
  };

  const handleDevelopmentSubmit = () => {
    if (!validateDevelopmentGoals()) {
      cogoToast.error("Please fill out all fields before saving.");
      return;
    }
    const newGoal = developmentGoal.map((goal) => ({
      goal_title: goal.goal_title,
      goal_type: "Development",
      goal_content: goal.goal_content,
      status: goal.status,
      target_measurement: goal.target_measurement,
      target_achieved: goal.target_achieved,
      due_date: goal.due_date,
    }));

    setSendEmployee((prevSendEmployee) => ({
      ...prevSendEmployee,
      employee_goals: [...prevSendEmployee.employee_goals, newGoal],
    }));

    const allGoals = JSON.parse(localStorage.getItem("allGoals")) || {};
    localStorage.setItem(
      "allGoals",
      JSON.stringify({
        ...allGoals,
        Development: [...(allGoals.Development || []), newGoal],
      })
    );
    setGoalSubmit(true);
     cogoToast.success("Development Goal saved successfully, please submit after saving");
  };

  const handleEmployeeChange = (e) => {
    const {value } = e.target;
   
      setSendEmployee((prevSendEmployee) => ({
        ...prevSendEmployee,
       
        employee_name: value
      }));
     
    }


  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedGoals = [...leadershipGoal];
    updatedGoals[index][name] = value;
    setLeadershipGoal(updatedGoals);
  };

  const handleChangePerformance = (index, e) => {
    const { name, value } = e.target;
    const updatedGoals = [...performanceGoal];
    updatedGoals[index][name] = value;
    setperformanceGoal(updatedGoals);
  };
  const handleChangeDevelopment = (index, e) => {
    const { name, value } = e.target;
    const updatedGoals = [...developmentGoal];
    updatedGoals[index][name] = value;
    setDevelopmentGoal(updatedGoals);
  };

  const handleAddLeadership = () => {
    setLeadershipGoal([
      ...leadershipGoal,
      {
        goal_title: "",
        goal_content: "",
        status: "",
        target_measurement: "",
        target_achieved: "",
        due_date: "",
      },
    ]);
  };
  const handleAddPerformance = () => {
    setperformanceGoal([
      ...performanceGoal,
      {
        goal_title: "",
        goal_content: "",
        status: "",
        target_measurement: "",
        target_achieved: "",
        due_date: "",
      },
    ]);
  };
  const handleAddDevelopment = () => {
    setDevelopmentGoal([
      ...developmentGoal,
      {
        goal_title: "",
        goal_content: "",
        status: "",
        target_measurement: "",
        target_achieved: "",
        due_date: "",
      },
    ]);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="card-height" additionalClass="mt-4 p-4">
    
      <h5 style={{ fontWeight: "bolder" }} className="co-grayx">
        Employee's Add Performance
      </h5>
      <br />
      <br />

      <div>
        <div className=" mt-3">
          <strong>Employee's FullName</strong>
           <CustomInput
                        type="text"
                        placeholder="Enter your name"
                        label=""
                       
                        name="target_measurement"
                        value={sendEmployee.employee_name}
                        onChange={(e) => handleEmployeeChange( e)}
                      />
        </div>
      </div>

      <strong className="mt-1">Click on each performance to add</strong>
      <div className="mt-3">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link d-flex align-items-center justify-content-center rounded-3 ${
                activeTab === "Leadership" ? "active" : ""
              }`}
              onClick={() => {
                handleTabClick("Leadership");
              }}
              style={{
                backgroundColor:
                  activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                color: activeTab === "Leadership" ? "#fff" : "#fff",
                fontWeight: "bold",
                fontSize: "12.5px",
              }}
            >
              <BsPeople className="" /> Leadership
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link d-flex align-items-center justify-content-center rounded-3 ${
                activeTab === "Performance" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Performance")}
              style={{
                backgroundColor:
                  activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                color: activeTab === "Performance" ? "#fff" : "#fff",
                marginLeft: "2rem",
                fontWeight: "bold",
                fontSize: "12.5px",
              }}
            >
              <strong>
                <BsGraphUp className="mb-1" />{" "}
              </strong>{" "}
              Performance
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link d-flex align-items-center justify-content-center ${
                activeTab === "Development" ? "active" : ""
              }`}
              onClick={() => {
                handleTabClick("Development");
              }}
              style={{
                backgroundColor:
                  activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                color: activeTab === "Development" ? "#fff" : "#fff",
                marginLeft: "2rem",
                fontWeight: "bold",
                fontSize: "12.5px",
              }}
            >
              <IoPersonSharp /> Development
            </button>
          </li>
        </ul>

        <div className="tab-content mt-3">
          <div
            className={`tab-pane fade ${
              activeTab === "Leadership" ? "show active" : ""
            }`}
            id="leadership-tab"
            style={{ marginTop: "7px" }}
          >
            <main>
              {Array.isArray(leadershipData) && leadershipData.length > 0 ? (
                leadershipData.map((goal, index) => (
                  <div key={index} style={{ marginTop: "25px" }}>
                    <strong>Leadership Goal/Objective(Title)</strong>
                    <CustomInput
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        height: "2rem",
                        border: "1px solid #e8e9eb",
                        borderRadius: "0.875rem",
                        padding: "1rem",
                        marginBottom: "8px",
                      }}
                      placeholder="Enter your Leadership goal title"
                      name="goal_title"
                      value={goal.goal_title}
                      onChange={(e) => handleChange(index, e)}
                    />
                    <br />
                    <div>
                      <strong>
                        Write your Leadership Goals/Objectives (Free text)
                      </strong>
                      <textarea
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          height: "9rem",
                          border: "1px solid #e8e9eb",
                          borderRadius: "0.875rem",
                          padding: "1rem",
                          marginBottom: "8px",
                        }}
                        placeholder="Enter your Leadership goals"
                        name="goal_content"
                        value={goal.goal_content}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <br />

                    <div className="select-container">
                      <strong>Status</strong>
                      <select
                        name="status"
                        value={goal.status}
                        onChange={(e) => handleChange(index, e)}
                        style={{ border: "1px solid #e8e9eb" }}
                      >
                        <option value="option1">Select an Option</option>
                        <option value="notStarted">Not started</option>
                        <option value="inProgress">In Progress</option>
                        <option value="completed">Completed</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                    <br />
                    <br />

                    <div>
                      <strong>Target Measurement (%)</strong>
                      <CustomInput
                        type="text"
                        placeholder="Enter your target measurement for Leadership Goals"
                        label=""
                        id={`target_measurement_for_leadership_goals_${index}`}
                        name="target_measurement"
                        value={goal.target_measurement}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <br />

                    <div>
                      <strong>Target Achieved (%)</strong>
                      <CustomInput
                        name="target_achieved"
                        value={goal.target_achieved}
                        type="text"
                        placeholder="Enter your target measurement for Leadership Goals"
                        label=""
                        id={`target_achieved_for_leadership_goals_${index}`}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <br />

                    <div>
                      <strong>Due Date</strong> <br />
                      <input
                        style={{ border: "1px solid #e8e9eb" }}
                        className="select-contain"
                        type="date"
                        name="due_date"
                        value={goal.due_date}
                        onChange={(e) => handleChange(index, e)}
                        id={`due_date_for_leadership_goals_${index}`}
                      />
                    </div>
                    <br />
                    <br />
                  </div>
                ))
              ) : (
                <div>
                  {leadershipData.length === 0 &&
                    leadershipGoal.map((goal, index) => (
                      <div key={index} style={{ marginTop: "25px" }}>
                        <strong>Leadership Goal/Objective(Title)</strong>
                        <CustomInput
                          style={{
                            width: "100%",
                            marginTop: "10px",
                            height: "2rem",
                            border: "1px solid #e8e9eb",
                            borderRadius: "0.875rem",
                            padding: "1rem",
                            marginBottom: "8px",
                          }}
                          placeholder="Enter your Leadership goal title"
                          name="goal_title"
                          value={goal.goal_title}
                          onChange={(e) => handleChange(index, e)}
                        />
                        <br />
                        <div>
                          <strong>
                            Write your Leadership Goals/Objectives (Free text)
                          </strong>
                          <CKEditor
  editor={ClassicEditor}
  config={{}}  
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
  handleChange(index, {
      target: {
        name: 'goal_content',
        value: data
      }
    });
  }}
/>
                          {/* <textarea
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              height: "9rem",
                              border: "1px solid #e8e9eb",
                              borderRadius: "0.875rem",
                              padding: "1rem",
                              marginBottom: "8px",
                            }}
                            placeholder="Enter your Leadership goals"
                            name="goal_content"
                            value={goal.goal_content}
                            onChange={(e) => handleChange(index, e)}
                          /> */}
                        </div>
                        <br />

                        <div className="select-container">
                          <strong>Status</strong>
                          <select
                            name="status"
                            value={goal.status}
                            onChange={(e) => handleChange(index, e)}
                            style={{ border: "1px solid #e8e9eb" }}
                          >
                            <option value="option1">Select an Option</option>
                            <option value="notStarted">Not started</option>
                            <option value="inProgress">In Progress</option>
                            <option value="completed">Completed</option>
                            <option value="cancelled">Cancelled</option>
                          </select>
                        </div>
                        <br />
                        <br />

                        <div>
                          <strong>Target Measurement (%)</strong>
                          <CustomInput
                            type="text"
                            placeholder="Enter your target measurement for Leadership Goals"
                            label=""
                            id={`target_measurement_for_leadership_goals_${index}`}
                            name="target_measurement"
                            value={goal.target_measurement}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </div>
                        <br />

                        <div>
                          <strong>Target Achieved (%)</strong>
                          <CustomInput
                            name="target_achieved"
                            value={goal.target_achieved}
                            type="text"
                            placeholder="Enter your target measurement for Leadership Goals"
                            label=""
                            id={`target_achieved_for_leadership_goals_${index}`}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </div>
                        <br />

                        <div>
                          <strong>Due Date</strong> <br />
                          <input
                            style={{ border: "1px solid #e8e9eb" }}
                            className="select-contain"
                            type="date"
                            name="due_date"
                            value={goal.due_date}
                            onChange={(e) => handleChange(index, e)}
                            id={`due_date_for_leadership_goals_${index}`}
                          />
                        </div>
                        <br />
                        <br />
                      </div>
                    ))}

                  <CustomButton
                    color="#fff"
                    className="rounded-3 border border-none"
                    backgroundColor="#3A6ED4"
                    text="Add"
                    handleClick={handleAddLeadership}
                  />

                  <CustomButton
                    backgroundColor="#3A6ED4"
                    color="#ffffff"
                    text="Save Leadership Goals"
                    handleClick={handleLeaderShip}
                  />
                </div>
              )}
            </main>

            <br />
            <br />
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "Performance" ? "show active" : ""
            }`}
            id="performance-tab"
            style={{ marginTop: "7px" }}
          >
            <main>
              {performanceGoal.map((goal, index) => (
                <div style={{ marginTop: "25px" }}>
                  <strong>Performance Goal/Objective(Title)</strong>
                  <CustomInput
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      height: "2rem",
                      border: "1px solid #e8e9eb",
                      borderRadius: "0.875rem",
                      padding: "1rem",
                      marginBottom: "8px",
                    }}
                    placeholder="Enter your performance goal title"
                    name="goal_title"
                    value={goal.goal_title}
                    // value={
                    //   leadershipPerformanceData.goal_title || sendEmployee.goal_title
                    // }
                    onChange={(e) => handleChangePerformance(index, e)}
                  />
                  <br />
                  <div>
                    <strong>
                      Write your Performance Goals/Objectives (Free text)
                    </strong>
                     <CKEditor
  editor={ClassicEditor}
  config={{}}  
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
  handleChangePerformance(index, {
      target: {
        name: 'goal_content',
        value: data
      }
    });
  }}
/>
            
                    {/* <textarea
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        height: "9rem",
                        border: "1px solid #e8e9eb",
                        borderRadius: "0.875rem",
                        padding: "1rem",
                        marginBottom: "8px",
                      }}
                      placeholder="Enter your Leadership goals"
                      name="goal_content"
                      value={goal.goal_content}
                      onChange={(e) => handleChangePerformance(index, e)}
                    /> */}
                  </div>
                  <br />

                  <div className="select-container">
                    <strong>Status</strong>
                    <select
                      name="status"
                      value={
                        goal.status // Accessing the first element of the array
                      }
                      onChange={(e) => handleChangePerformance(index, e)}
                      style={{ border: "1px solid #e8e9eb" }}
                    >
                      <option value="option1">Select an Option</option>
                      <option value="notStarted">Not started</option>
                      <option value="inProgress">In Progress</option>
                      <option value="completed">Completed</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </div>
                  <br />
                  <br />

                  <div>
                    <strong>Target Measurement (%)</strong>
                    <CustomInput
                      type="text"
                      placeholder={`Enter your target measurement for Performance Goals`}
                      label=""
                      id={`target_measurement_for Performance goals`}
                      name="target_measurement"
                      value={
                        goal.target_measurement 
                      }
                      onChange={(e) => handleChangePerformance(index, e)}
                    />
                  </div>
                  <br />

                  <div>
                    <strong>Target Achieved (%)</strong>
                    <CustomInput
                      name="target_achieved"
                      value={
                        goal.target_achieved 
                      }
                      onChange={(e) => handleChangePerformance(index, e)}
                      type="text"
                      placeholder={`Enter your target measurement for Performance Goals`}
                      label=""
                      id={`target_measurement_for leadership goals`}
                    />
                  </div>
                  <br />

                  <div>
                    <strong>Due Date</strong> <br />
                    <input
                      style={{ border: "1px solid #e8e9eb" }}
                      className="select-contain"
                      type="date"
                      name="due_date"
                      value={goal.due_date}
                      onChange={(e) => handleChangePerformance(index, e)}
                      id={`due_date_for Performance goals`}
                    />
                  </div>
                  <br />
                  <br />
                </div>
              ))}
              <CustomButton
                color="#fff"
                backgroundColor="#3A6ED4"
                text="Add"
                handleClick={handleAddPerformance}
              />

              <CustomButton
                backgroundColor="#3A6ED4"
                color="#fff"
                text="Save Performance Goals"
               
                handleClick={handlePerformance}
              />
            </main>
            <br />
            <br />
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "Development" ? "show active" : ""
            }`}
            id="development-tab"
            style={{ marginTop: "7px" }}
          >
            <main>
              {developmentGoal.map((goal, index) => (
                <div style={{ marginTop: "25px" }}>
                  <strong>Development Goal/Objective(Title)</strong>
                  <CustomInput
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      height: "2rem",
                      border: "1px solid #e8e9eb",
                      borderRadius: "0.875rem",
                      padding: "1rem",
                      marginBottom: "8px",
                    }}
                    placeholder="Enter your Development goal title"
                    name="goal_title"
                    value={goal.goal_title}
                    // value={
                    //   leadershipPerformanceData.goal_title || sendEmployee.goal_title
                    // }
                    onChange={(e) => handleChangeDevelopment(index, e)}
                  />
                  <br />
                  <div>
                    <strong>
                      Write your Development Goals/Objectives (Free text)
                    </strong>
                    <CKEditor
  editor={ClassicEditor}
  config={{}}  
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
  handleChangeDevelopment(index, {
      target: {
        name: 'goal_content',
        value: data
      }
    });
  }}
/>
                  </div>
                  <br />

                  <div className="select-container">
                    <strong>Status</strong>
                    <select
                      name="status"
                      value={
                        developmentGoal.status 
                      }
                      onChange={(e) => handleChangeDevelopment(index, e)}
                      style={{ border: "1px solid #e8e9eb" }}
                    >
                      <option value="option1">Select an Option</option>
                      <option value="notStarted">Not started</option>
                      <option value="inProgress">In Progress</option>
                      <option value="completed">Completed</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </div>
                  <br />
                  <br />

                  <div>
                    <strong>Target Measurement (%)</strong>
                    <CustomInput
                      type="text"
                      placeholder={`Enter your target measurement for Development Goals`}
                      label=""
                      id={`target_measurement_for Development goals`}
                      name="target_measurement"
                      value={goal.target_measurement}
                      onChange={(e) => handleChangeDevelopment(index, e)}
                    />
                  </div>
                  <br />

                  <div>
                    <strong>Target Achieved (%)</strong>
                    <CustomInput
                      name="target_achieved"
                      value={goal.target_achieved}
                      onChange={(e) => handleChangeDevelopment(index, e)}
                      type="text"
                      placeholder={`Enter your target measurement for Development Goals`}
                      label=""
                      id={`target_measurement_for Development goals`}
                    />
                  </div>
                  <br />

                  <div>
                    <strong>Due Date</strong> <br />
                    <input
                      style={{ border: "1px solid #e8e9eb" }}
                      className="select-contain"
                      type="date"
                      name="due_date"
                      value={goal.due_date}
                      onChange={(e) => handleChangeDevelopment(index, e)}
                      id={`due_date_for Development goals`}
                    />
                  </div>
                  <br />
                </div>
              ))}

              <CustomButton
                className="rounded-3 border border-none"
                backgroundColor="#3A6ED4"
                color="#fff"
                text="Add"
                handleClick={handleAddDevelopment}
              />

              {!goalSubmit ? (
                <CustomButton
                  backgroundColor="#3A6ED4"
                  text="Save Development Goals"
                  color="#fff"
                 
                  handleClick={handleDevelopmentSubmit}
                />
              ) : (
                <CustomButton
                  backgroundColor="#3A6ED4"
                  text="Submit All goals"
                  color="#fff"
                  handleClick={handleSubmit}
                />
              )}
            </main>
            <br />
            <br />
          </div>
        </div>

        <br />
      </div>
    </div>
  );
}

export default AddPerformance;
