import { node } from "prop-types";
import React from "react";
import WBtext from "../../../../assets/icons/WBtext.svg";
import WorkImage from "../../../../assets/images/wbg.png";
import "./index.scss";

const AuthWrapper = ({ children }) => {
  return (
    <div
      className="auth signin"
      style={{
        background: "rgba(0,0,0,0.85)",
      }}
    >
      <div className="wrapper  d-flex align-items-center">
        <div className="d-none d-md-flex flex-column justify-content-center w-50 p-3">
          <img
            style={{ width: "60%", display: "block", margin: "0 auto" }}
            src={WorkImage}
            alt="logo"
          />

          <img
            style={{ width: "100%", display: "block", margin: "0 auto" }}
            src={WBtext}
            alt="logo"
          />
          <h4
            className="co-white text-center mt-2"
            style={{
              fontSize: "40px",
            }}
          >
            Business
          </h4>
        </div>
        {children}
      </div>
    </div>
  );
};

AuthWrapper.propTypes = {
  children: node.isRequired,
};

export default AuthWrapper;
