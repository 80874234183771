import React, { useEffect } from "react";
import { Dialog } from "@material-ui/core";
import SearchInput from "../../../../components/CustomSearchInput";

const ViewReferral = ({ open, handleClose, applicants }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: "35rem",
          borderRadius: "1rem",
          padding: "0.75rem",
        },
      }}
    >
      <div>
        <SearchInput />
        {applicants.map((invitee) => (
          <span key={invitee.id} className="d-flex my-4 align-items-center">
            <img src={invitee.image_url} width="50" alt="avatar" />
            <span className="d-flex flex-column ml-3">
              <h6 className="mb-0" style={{ textTransform: "capitalize" }}>
                {invitee.first_name} {invitee.last_name}
              </h6>
              <span className="co-gray">@{invitee.username}</span>
            </span>
          </span>
        ))}
        {/* {count > 10 * page && (
          <div className="mx-auto mt-3 w-75">
            <CustomButton
              // handleClick={() => setPage(page + 1)}
              text={loadingMore ? "<ButtonLoader />" : "Load More"}
            />
          </div>
        )} */}
      </div>
    </Dialog>
  );
};

export default ViewReferral;
