import {
  GET_LEAGUE, GET_LEAGUE_POINTS, GET_LEAGUE_POSITION
} from '../actionTypes';

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case GET_LEAGUE:
    return {
      ...state,
      league: payload
    };

  case GET_LEAGUE_POINTS:
    return {
      ...state,
      myPoints: payload
    };

  case GET_LEAGUE_POSITION:
    return {
      ...state,
      myPosition: payload
    };

  default:
    return state;
  }
};
