/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { SectionLoader } from "../../../../../components/PageLoader";
import EmptyState from "../../../../../components/EmptyState";
import { getJobOffersAction } from "../../../../../redux/actions/insightActions";
import ReferralJobCard from "./ReferralJobCard";
import RecruiterJobCard from "./RecruiterJobCard";

const General = ({ search }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    tooLong: true,
    noApplication: false,
    notAccepting: false,
    other: false,
  });
  const {
    jobOffers: { jobs },
  } = useSelector(({ insight }) => insight);

  // console.log("Jobs", jobs);

  useEffect(() => {
    setLoading(true);
    Promise.resolve(getJobOffersAction(dispatch)).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const openEditDialog = () => {
    setOpenEdit(true);
  };

  const closeEditDialog = () => {
    setOpenEdit(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { tooLong, noApplication, notAccepting, other } = state;

  const jobCards = () => {
    return jobs && jobs[0] ? (
      jobs
        .filter((job) => {
          if (
            search &&
            !JSON.stringify(job).toLowerCase().includes(search.toLowerCase())
          ) {
            return false;
          }
          return true;
        })
        .map((job) =>
          job.isToRefTimeLineOrToHireRecruter === "1" ? (
            <ReferralJobCard
              key={job.id}
              job={job}
              handleClick={handleClick}
              anchorEl={anchorEl}
              handleClose={handleClose}
              openDialog={openDialog}
              closeDialog={closeDialog}
              openEditDialog={openEditDialog}
              closeEditDialog={closeEditDialog}
              tooLong={tooLong}
              handleChange={handleChange}
              noApplication={noApplication}
              notAccepting={notAccepting}
              other={other}
              open={open}
              openEdit={openEdit}
            />
          ) : (
            <RecruiterJobCard
              key={job.id}
              job={job}
              handleClick={handleClick}
              anchorEl={anchorEl}
              handleClose={handleClose}
              openDialog={openDialog}
              closeDialog={closeDialog}
              openEditDialog={openEditDialog}
              closeEditDialog={closeEditDialog}
              tooLong={tooLong}
              handleChange={handleChange}
              noApplication={noApplication}
              notAccepting={notAccepting}
              other={other}
              open={open}
              openEdit={openEdit}
            />
          )
        )
    ) : (
      <EmptyState text="you have not posted any job" />
    );
  };

  return (
    <div className="position-relative w-100">
      {loading && <SectionLoader />}
      {jobCards()}
    </div>
  );
};

export default General;
