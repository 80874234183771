import React, { useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import { Modal, Button } from "react-bootstrap";

const Support = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div>
      <h6 className="bolder-text">Help</h6>
      <p className="small-text co-darkgray mt-3">
        We can provide you with the help you need by either sending us a ticket
        or reading through our FAQs.
      </p>
      <button
        style={{
          width: "100%",
          padding: "10px 10px 10px 19px",

          borderRadius: "10px",
          border: "none",

          color: "#fff",
          textAlign: "left",
          fontWeight: "600",
          backgroundColor: "#3A6ED4",
        }}
        onClick={handleShow}
      >
        Send US A Ticket
      </button>
      {/* <CustomButton
        leftALign
        additionalClass="my-3"
        text="Send US A Ticket"
        backgroundColor="#3A6ED4"
        color="#fff"
        onClick={handleShow}
      /> */}
      <CustomButton
        leftALign
        additionalClass="my-3"
        text="Read Our FAQs"
        backgroundColor="#3A6ED4"
        color="#fff"
      />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "15px", fontWeight: "600" }}>
            Send a Mail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <a
            style={{ color: "#3A6ED4" }}
            href="mailto:tech-support@workbrook.com"
          >
            tech-support@workbrook.com
          </a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* Additional buttons can be added here */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Support;
