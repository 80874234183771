import React, { useEffect, useState } from "react";
import {
  // FormControl,
  // MenuItem,
  // Select,
  IconButton,
  // TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import { SectionLoader } from "../../../../components/PageLoader";
import RecruiterWrapper from "../../Recruiter/RecruiterWrapper";
import {
  updateRecruiter,
  getMyRecruiterData,
} from "../../../../redux/actions/recruiterAction";
// import SearchLocationInput from "../../../../components/LocationComponent";
import CustomTextArea from "../../../../components/CustomTextArea";
// import { useHistory } from "react-router-dom";
import icons from "../../../../assets/icons/icon-collection.svg";

const EditRecruiterProfile = () => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const [industryOptions, setIndustryOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const { industries } = useSelector(({ industry }) => industry);
  const { userData } = useSelector(({ auth }) => auth);
  const { myRecruiterData } = useSelector(({ recruiter }) => recruiter);

  useEffect(() => {
    setLoading(true);
    Promise.resolve(getMyRecruiterData(dispatch)).finally(() => {
      if (myRecruiterData) {
        setLoading(false);

        setLocation({
          location: myRecruiterData.location,
          coordinate: myRecruiterData.coordinate,
        });

        const name = myRecruiterData.fullname.split(" ");

        setState({
          summary: myRecruiterData.summary,
          profilePicture: myRecruiterData.ProfilePicture,
          placementPercentage: myRecruiterData.placementPercentage || "",
          industry: myRecruiterData.industry || [],
          first_name: name[0],
          last_name: name[1],
          fullname: myRecruiterData.fullname,
          phone: myRecruiterData.phone,
          email: myRecruiterData.email,
          industryRecruitementExperience:
            myRecruiterData.industryRecruitementExperience,
          additionalFiles: [process.env.REACT_APP_WEB_URL],
          linkdnUrl: myRecruiterData.linkdnUrl,
          accept: false,
        });
      }
    });
  }, [dispatch]);

  // const [location, setLocation] = useState({
  //   location: userData.location || "",
  //   coordinate: "",
  // });
  const [location, setLocation] = useState({
    location: "",
    coordinate: "",
  });

  const [state, setState] = useState({
    summary: "",
    profilePicture: "",
    placementPercentage: "",
    industry: [],
    first_name: "",
    last_name: "",
    fullname: "",
    phone: "",
    email: "",
    industryRecruitementExperience: "",
    additionalFiles: [process.env.REACT_APP_WEB_URL],
    linkdnUrl: "",
    accept: false,
  });

  useEffect(() => {
    // check if the currrent user is the owner of the recruiter acct to be edited
    // if(currentRecruiter email === userData email)
    // if (userData.isRecruiter === 1) {
    //   history.push("/recruiter/requests");
    // }
  }, [userData]);

  useEffect(() => {
    const option = {};
    industries &&  industries.forEach((industry) => {
      option[industry.title] = industry.title;
    });
    setIndustryOptions(option);
  }, [industries]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...state,
      ...location,
      fullname: `${state.first_name} ${state.last_name}`,
    };
    delete data.first_name;
    delete data.last_name;
    delete data.accept;
    // delete data.placementPercentage;
    updateRecruiter(dispatch, data);
  };

  // const handleLocationChange = (place, lng, lat) => {
  //   setLocation({ coordinate: `${lng},${lat}`, location: place });
  // };

  const handleChange = ({ target: { value, name } }) => {
    const changed = {};
    changed[name] = value;
    setState({ ...state, ...changed });
  };

  const handleCheckbox = (event) => {
    setState({ ...state, accept: event.target.checked });
  };

  const removeIndustry = (industryToRemove) => {
    const industryArray = [...state.industry];
    const newIndustryArray = industryArray.filter(
      (industry) => industry !== industryToRemove
    );

    setState({ ...state, industry: newIndustryArray });
  };

  const displayIndustries = () => {
    return state.industry.map((industry, index) => (
      <span
        className="d-flex align-items-center justify-content-between mb-2 mt-2 mr-2 py-1 pl-3 outlined"
        key={index.toString()}
      >
        {industry}
        <IconButton onClick={() => removeIndustry(industry)}>
          <svg width="10" height="8">
            <use href={`${icons}#close-blue`}></use>
          </svg>
        </IconButton>
      </span>
    ));
  };

  return (
    <RecruiterWrapper>
      <div className="m-4">
        {loading && <SectionLoader />}
        {!loading && myRecruiterData && (
          <>
            <h4>Edit Recruiter Profile</h4>
            <p className="co-lightred">Required fields are marked * </p>
            <form onSubmit={handleSubmit}>
              <CustomInput
                type="text"
                onChange={handleChange}
                value={state.first_name}
                label="First Name"
                id="fname"
                name="first_name"
                isRequired
              />

              <CustomInput
                type="text"
                onChange={handleChange}
                value={state.last_name}
                label="Last Name"
                id="lname"
                name="last_name"
                isRequired
              />

              <CustomInput
                type="text"
                onChange={handleChange}
                value={state.email}
                label="Email Address"
                id="email"
                name="email"
                isRequired
              />
              <CustomInput
                type="text"
                onChange={handleChange}
                value={state.phone}
                label="Phone Number"
                name="phone"
                id="phone"
                isRequired
              />

              <div className="d-flex flex-row">
                <div className="col-6" style={{ paddingLeft: "0px" }}>
                  <CustomInput
                    type="number"
                    onChange={handleChange}
                    value={state.placementPercentage}
                    label="Placement Fee"
                    name="placementPercentage"
                    id="placementPercentage"
                    isRequired
                    helperText="Some important text"
                  />
                </div>
                <div
                  className="col-6"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "16px",
                    fontSize: "14px",
                  }}
                >
                  % of Annual Salary
                </div>
              </div>

              {/* <SearchLocationInput handleLocationChange={handleLocationChange} /> */}

              <CustomSelect
                name="industry"
                handleChange={handleChange}
                options={industryOptions}
                id="industry"
                label="Industry Experience"
                currentValue={state.industry}
                multiple
              />
              {state.industry[0] && (
                <div className="w-100 d-flex flex-wrap">
                  {displayIndustries()}
                </div>
              )}

              <CustomSelect
                handleChange={handleChange}
                options={{
                  0: "Select Experience Level",
                  "<2 years": "<2 years",
                  "2-4 years": "2-4 years",
                  "5 - 9 years": "5 - 9 years",
                  "10 - 16 years": "10 - 16 years",
                  ">16 years": ">16 years",
                }}
                id="experience"
                name="industryRecruitementExperience"
                label="Recruitment Experience"
                currentValue={state.industryRecruitementExperience}
              />

              {/* <CustomInput
                type="text"
                onChange={handleChange}
                value={state.linkdnUrl}
                label="LinkedIn URL"
                name="linkdnUrl"
                id="linkdnUrl"
                isRequired
              /> */}

              <CustomTextArea
                onChange={handleChange}
                id="summary"
                name="summary"
                value={state.summary}
                label="Summary"
                isRequired
              />

              <label className="d-flex align-items-center mt-3 mb-0">
                <input
                  className="mr-2"
                  type="checkbox"
                  value={state.accept}
                  onChange={handleCheckbox}
                />
                <p className="mt-3">
                  I confirm that I have read and I understand the{" "}
                  <span className="co-primary">
                    <a
                      href="https://workbrook.com/recruiter-agreement/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms & Conditions
                    </a>
                  </span>{" "}
                  to become a recruiter on workbrook.
                </p>
              </label>
              <div className="mt-2">
                <CustomButton
                  type="submit"
                  backgroundColor="#000"
                  color="#fff"
                  text="Update Recruiter Profile"
                  disabled={!state.accept}
                />
              </div>
            </form>
          </>
        )}
      </div>
    </RecruiterWrapper>
  );
};

export default EditRecruiterProfile;
