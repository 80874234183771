import { string } from 'prop-types';
import React from 'react';


const ProgressBar = ({color, percent}) => {
  return (
    <div className="progress" style={{height: "10px"}}>
      <div className="progress-bar" role="progressbar" style={{width: percent, backgroundColor: color}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  );
};

ProgressBar.propTypes = {
  color: string.isRequired,
  percent: string.isRequired
};

export default ProgressBar;
