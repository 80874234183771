/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import icons from "../../../assets/icons/icon-collection.svg";
import Card from "../../../components/Card";
import moment from "moment";
import { abbreviate, abbrDepartment } from "../../../utils/helpers";

const AdminJobCard = ({ job, isMine, refCode, companyId, isBusiness }) => {
  const history = useHistory();
  const { userData } = useSelector(({ auth }) => auth);

  // console.log("JOB", job);

  return (
    <Card noPadding additionalClass="pt-3 mt-3">
      <div className="d-flex justify-content-between w-100 px-4">
        <div style={thumbnailStyle}>
          {abbreviate(abbrDepartment(job.department || job.role_title))}
        </div>
        <div className="inner flex-grow-1 ml-4">
          <div className="w-100 d-flex justify-content-between">
            <span className="d-flex flex-column mb-2">
              <b>{job.role_title}</b>
              <span>{abbrDepartment(job.department || job.role_title)}</span>
            </span>
          </div>
          <div className="d-flex mb-2">
            <span style={{ textTransform: "capitalize", marginRight: 10 }}>
              <svg width="17" height="16" className="mr-2">
                <use href={`${icons}#briefcase`}></use>
              </svg>
              {job.job_type}
            </span>
            <span>
              <svg width="14" height="14" className="mr-2">
                <use href={`${icons}#mappin`}></use>
              </svg>
              {job.location}
            </span>
          </div>
          <div className="mb-3">
            <svg width="17" height="17" className="mr-2">
              <use href={`${icons}#filetext`}></use>
            </svg>
            {job.about_company}{" "}
            <Link
              to={{
                pathname: isMine ? `/admin/jobs/${job.id}` : `/home/${job.id}`,
                state: { isBusiness: isBusiness },
              }}
            >
              see more
            </Link>
          </div>
        </div>
        <div className="co-primary thintext">
          {moment(job.date_created).fromNow()}
        </div>
      </div>
      <Divider />
      <div className="d-flex">
        {getFooterActions(isMine, job, history, refCode, companyId)}
      </div>
    </Card>
  );
};

export default AdminJobCard;

const getFooterActions = (isMine, job, history) => {
  // const referJob = () => {
  //   // history.push(`/home/${job.id}/apply/${refCode}/coy/${companyId}`);
  // }
  if (isMine) {
    return (
      <>
        <CustomButton
          additionalClass="py-3"
          text="View Applicants"
          borderColor="#fff"
          backgroundColor="#fff"
          color="#3a6ed4"
          handleClick={() => history.push(`/admin/jobs/applicants/${job.id}`)}
        />
        <Divider orientation="vertical" flexItem />
        {/* <CustomButton
          additionalClass="py-3"
          text="View Referrals"
          borderColor="#fff"
          backgroundColor="#fff"
          color="#3a6ed4"
          handleClick={() => history.push(`/admin/jobs/applicants/${job.id}`)}
        /> */}
      </>
    );
  } else {
    return (
      <>

      {/*
       <CustomButton
          additionalClass="py-3"
          text="Job Details"
          borderColor="#fff"
          backgroundColor="#fff"
          color="#3a6ed4"
          handleClick={() => history.push(`/home/${job.id}`)}
        />
        history.push(`/home/${job.id}`)
        ${process.env.REACT_APP_WEB_URL}/home/${jobId}/apply/${userData.refCode}
        ${process.env.REACT_APP_WEB_URL}/home/${jobId}/apply/${userData.refCode}
      */}
        <CustomButton
          additionalClass="py-3"
          text="Apply"
          borderColor="#fff"
          backgroundColor="#fff"
          color="#3a6ed4"
          handleClick={() => 
            history.push(`/home/${job.id}/apply`)
        
        }
        />
        <Divider orientation="vertical" flexItem />
        <CustomButton
          additionalClass="py-3"
          text="Refer"
          borderColor="#fff"
          backgroundColor="#fff"
          color="#3a6ed4"
          handleClick={() => history.push(`/home/${job.id}/refer`)}
        />
      </>
    );
  }
};

const thumbnailStyle = {
  width: "59px",
  height: "57px",
  backgroundColor: "rgb(192, 101, 6)",
  borderRadius: "50%",
  color: "rgb(255, 255, 255)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  fontSize: "20px",
};
