import React from 'react';
import icons from "../../assets/icons/icon-collection.svg";
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';

const BackButton = ({color}) => {
  const {goBack} = useHistory();
  return (
    <button className="bg-white borderless p-3 mid-radius my-1 my-md-3" style={{color: color}} onClick={goBack}>
      <svg width="22" height="14" className="rounded-circle">
        <use href={`${icons}#back`}></use>
      </svg>
    </button>
  );
};

BackButton.propTypes ={
  color: string
};

BackButton.defaultProps ={
  color: "#3A6ED4"
};

export default BackButton;
