/* eslint-disable indent */

export const introActions = [
  "Post a Job",
  "Refer a Candidate",
  "Apply for a Job",
  // "Become a Recruiter",
  // "Hire a Recruiter for a New Job",
  // "Hire a recruiter for an existing Job",
  "I know my way around thanks",
];

export const postJobActions = [
  "Click on Post a Job",
  "Fill In Details",
  "Don't forget to add your files and links",
  "Post to referral timeline",
  "Share job with your network",
  "See applicants interested in your job here",
  "See... that was easy",
];

export const applyToJobActions = [
  "Search for jobs of interest here",
  "See more details of job here",
  "Apply here",
  "Check if every detail is fine and go for it!",
  "Track your application here (Insight-My Referral)",
  "See… that was easy! You think we did a good job? Let’s hear about it",
];

export const referCandidatesActions = [
  "Search for jobs of interest here",
  "See more details here or just go for it here…",
  "Refer the great people you know right here",
  "Watch this space for how you are doing (Insight-My Referral)",
];

const initialState = () => {
  const state = {
    intro: true,
    demo: localStorage.getItem("handledDemo") !== "true" ? true : false,
    cancel: localStorage.getItem("wb:have-seen-demo") === "true",
    recruiterModal: false,
  };

  for (let index = 0; index < introActions.length; index++) {
    const introAction = introActions[index];
    state[introAction] = false;
  }
  for (let index = 0; index < postJobActions.length; index++) {
    const postJobAction = postJobActions[index];
    state[postJobAction] = false;
  }

  for (let index = 0; index < applyToJobActions.length; index++) {
    const applyToJobAction = applyToJobActions[index];
    state[applyToJobAction] = false;
  }

  for (let index = 0; index < referCandidatesActions.length; index++) {
    const referCandidatesAction = referCandidatesActions[index];
    state[referCandidatesAction] = false;
  }
  return state;
};

export default (state = initialState(), { type, payload }) => {
  switch (type) {
    case "DEMO_ACTION":
      return {
        ...state,
        ...payload,
      };
    case "OPEN_RECRUITER_MODAL":
      return {
        ...state,
        recruiterModal: true,
      };
    case "CLOSE_RECRUITER_MODAL":
      return {
        ...state,
        recruiterModal: false,
      };
    default:
      return state;
  }
};
