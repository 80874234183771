/* eslint-disable react/jsx-no-target-blank */
import { object, string } from "prop-types";
import React from "react";
// import { Link } from "react-router-dom";
//import createDOMPurify from 'dompurify'
//import { JSDOM } from 'jsdom'

const ViewDetails = ({ job, section }) => {
  // console.log("JOB", job);
 // const window = (new JSDOM('')).window
 // const DOMPurify = createDOMPurify(window)

  return (  
    <div className="">
      {section === "documents" && (
        <div className="pb-5">
          {job.additional_files && job.additional_files[0] ? (
            <div className="d-flex flex-column">
              {job.additional_files.map((fileLink, index) => (
                <a
                  className="doc-link bg-primary mid-radius py-3 my-2 w-100"
                  key={index.toString()}
                  target="_blank"
                  href={fileLink}
                >
                  <b>{fileLink}</b>
                </a>
              ))}
            </div>
          ) : (
            <span>No Additional documents</span>
          )}
        </div>
      )}

      {section === "details" && (
        <div>
          <div>
            <h5>Reports To</h5>
            <p>{job.reports_to}</p>
          </div>
          <div>
            <h5>About the company</h5>
        
            <p>
            {/* <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.about_company) }}></span> */}
            { <span dangerouslySetInnerHTML={{ __html: job.about_company}}></span> }

            </p>
          </div>
          <div>
            <h5>Applicants Benefits</h5>
            <p>{job.applicant_benefit}</p>
          </div>
          <div>
            <h5>Salary</h5>
            <p>
              ${new Intl.NumberFormat().format(job.salary)} {job.duration}
            </p>
          </div>
          <div>
            <h5>Skills Required</h5>
            <ul className="d-flex flex-wrap mt-3">
              {job.skills_required && job.skills_required[0] && (
                <>
                  {job.skills_required.map((skill, index) => (
                    <li
                      key={index.toString()}
                      className="mr-2 py-1 px-2 mt-1 outlined"
                    >
                      {skill}
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

ViewDetails.propTypes = {
  job: object.isRequired,
  section: string.isRequired,
};

export default ViewDetails;
