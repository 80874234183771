import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  object as objectPropType,
  array as arrayPropType,
  func,
  oneOfType,
} from "prop-types";

/**
 * @function PublicRoute
 * @description - constructs the applications public route layout
 * @returns {JSX} - JSX
 */
const PublicRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((store) => store.auth);
  return (
    <Route
      {...rest}
      render={() => (isAuthenticated ? <Redirect to="/home" /> : <Component />)}
    />
  );
};

PublicRoute.propTypes = {
  component: oneOfType([arrayPropType, objectPropType, func]).isRequired,
};

export default PublicRoute;
