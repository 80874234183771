import {
  GET_INVITES
} from '../actionTypes';

const initialState = {
  inviteesData: { invitees: [], count: 0 },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_INVITES:
    return {
      ...state,
      inviteesData: { invitees: [...state.inviteesData.invitees, ...payload["0"]], count: payload["total_count"] }
    };
  default:
    return state;
  }
};