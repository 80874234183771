import { GET_CURRENCY_RATES } from "../actionTypes";

const initialState = {
  rates: {},
  base: {},
  currency: "",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CURRENCY_RATES:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
