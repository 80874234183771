import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../../../components/BackButton";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import AddPerformance from "./AddPerformance";



import EmployeeApprasial from "./EmployeeApprasial";


import { getReferralJobs } from "../../../redux/actions/jobActions";

const subMenuOptionsByTab = {
  Employee: [
    ["Add performance","/employee_performance_management/addperformance",],
    ["Review performance","/employee_performance_management/employeeapprasial",],
  ],
};

const EmployeesPerformance
 = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Employee"); 
  const {
    userData: { user_type },
  } = useSelector((store) => store.auth);

  useEffect(() => {
    if (user_type === "1") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [dispatch]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const displaySection = () => {
    if (selectedTab === "Employee" && pathname.includes("addperformance")) {
      return <AddPerformance />;

      // } else if (selectedTab === "Supervisor") {
      //   return (
      //     <>
      //       <ReviewPeformance />
      //       <SupervisorApproval />
      //       <RewardPerformance />
      //     </>
      //   );
      // } else if (selectedTab === "HR") {
      //   return <AdminApproval />;

      // Add HR-related components here if needed
      // return <HrComponent />;
    } else if (
      selectedTab === "Employee" &&
      pathname.includes("employeeapprasial")
    ) {
      return <EmployeeApprasial />;
    } 
  };

  return (
    <Layout>
      <div className="referrals d-flex mt-3">
        <div className="w-25">
          <BackButton />
          <ul style={{ marginBottom: "1.5rem" }} className="nav nav-pills">
            <li className="nav-item">
              <span
                className={`nav-link ${
                  selectedTab === "Employee" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Employee")}
              >
                Employee
              </span>
            </li>
           
          </ul>
          {isAdmin ? (
            <SubMenu menuOptions={subMenuOptionsByTab[selectedTab]} />
          ) : (
            <SubMenu menuOptions={subMenuOptionsByTab[selectedTab]} />
          )}
        </div>
        <div className="w-75 ml-4 mt-3 d-flex align-items-center justify-content-center">
          {displaySection()}
        </div>
      </div>
    </Layout>
  );
};
export default EmployeesPerformance
;
