const { NODE_ENV, REACT_APP_BASE_URL, REACT_APP_BASE_Url } = process.env;

/**
 * Collection of environments
 * @type {Array<object>}
 */
const envCollections = [
  {
    env: "development",
    baseUrl: REACT_APP_BASE_URL,
  },
  {
    env: "test",
    baseUrl: REACT_APP_BASE_URL,
  },
  {
    env: "production",
    baseUrl: REACT_APP_BASE_URL,
  },
  {
    env: "staging",
    baseUrl: REACT_APP_BASE_Url,
  },
];

/**
 * @function getBaseUrl
 * @description acquire the API BASE_URL
 * @returns {string} - returns the BASE_URL
 */
const getBaseUrl = () =>
  envCollections?.find(({ env }) => env === `${NODE_ENV}`.toLowerCase())
    ?.baseUrl;

export default getBaseUrl();
