import React, { useEffect, useState } from "react";
import HomeModal from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { getJobsAction } from "../../../../redux/actions/jobActions";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import { useHistory, useParams, useLocation } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import { ButtonLoader } from "../../../../components/ButtonLoader";
import { saveBusinessRef } from "../../../../redux/actions/businessActions";
import JobCard from "./JobCard"; 


const AllJobs = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [page, setPage] = useState(1);
  const { jobId, refLink } = useParams();
  const dispatch = useDispatch();
  const [section, setSection] = useState("details");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const { jobsData } = useSelector(({ job }) => job);
  const jobs = jobsData && jobsData.jobs;
  
  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getJobsAction(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);

  const saveForLater = () => {
    const payload = {
      job_id: jobId,
      status: 1,
      refCode: refLink ? refLink : null,
    };
    const saveLater = async () => {
      await saveBusinessRef(dispatch, page, payload);
    };
    saveLater();
  };

  return (
    <div className="row position-relative mt-3 align-items-stretch">
      
      {jobId && (
        <HomeModal
          setSection={setSection}
          section={section}
          open={true}
          handleClose={() => history.push("/home")}
          isBusiness={
            pathname.includes("biz") || pathname.includes("coy") == true
              ? true
              : false
          }
          isFromCompany={pathname.includes("coy") == false ? true : false}
          saveForLater={saveForLater}
        />
      )}

      {loading && jobs && !jobs[0] && <SectionLoader />}
      {!loading && jobs  && !jobs[0] && (
        <EmptyState text="There are no recommended jobs" />
      )}
      {jobs && jobs[0] && (
        <>
          {jobs.map((job, index) => (
            <div
              style={{
                padding: "10px",
                maxHeight: "280px",
              }}
              key={index.toString()}
              className="col-12 col-md-6"
            >
              <JobCard job={job} />
            </div>
          
          ))} 
        </>
      )}
      

  

      {/* Pagination Part Start 
      <div className="container"  style={{marginTop:'5%'}}>
      <div className="row clearfix">
          <div className="mx-auto col-md-2 mt-3 w-75"></div>

          <div className="mx-auto col-md-2 mt-3 w-75">
            <CustomButton
              handleClick={() => setPage(page - 1)}
              text={loadingMore ? <ButtonLoader /> : "Prev"}
            />
            </div>

              <div className="col-md-2 text-center"   style={{marginTop:'2.5%'}} >
                Page:  {page} of {count-1}
               
              </div>  
          
              <div className="mx-auto col-md-2 mt-3 w-75">
              <CustomButton
                handleClick={() => setPage(page + 1)}
                text={loadingMore ? <ButtonLoader /> : "Next"}
              />
            </div> 
 
 
         <div className="mx-auto col-md-2 mt-3 w-75"></div>
    

          </div>
      </div>
      Pagination Part Ends */}

   
    </div>
  );
};

export default AllJobs;
