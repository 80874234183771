/* eslint-disable indent */
import {
  
  CREATE_EXPERT_CATEGORY,
  GET_ALL_EXPERT_CATEGORY,
  CREATE_EXPERT,
  GET_ALL_EXPERT
  } from "../actionTypes";  

  const initialState = {
 
   };
 
export default (state = initialState, { type, payload }) => 
{
    switch (type) {
      
         case CREATE_EXPERT_CATEGORY: {
          return {
            ...state,
            expertCategoryCreated: true,
          };
        };
        case GET_ALL_EXPERT_CATEGORY:
            return {
              ...state,
              get_all_expert_categories : payload,
            };    
        case GET_ALL_EXPERT:
            return {
              ...state,
              get_all_expert : payload,
            };

            case CREATE_EXPERT: {
                return {
                  ...state,
                  expertCreated: true,
                };
              };
         
         default:
         return state;
    }
    
  };

   