import httpRequest from "../../ajax";
import { GET_CURRENCY_RATES, ERROR } from "../actionTypes";
import axios from "axios";

export const getCurrencyRates = (dispatch) =>
  httpRequest("get", "/wallet/currency/rate")
    .then(({ data: { rates } }) => {
      return axios
        .get("https://ipapi.co/currency")
        .then((res) => {
          const action = {
            type: GET_CURRENCY_RATES,
            payload: { ...rates, currency: res.data },
          };
          dispatch(action);
          return action;
        })
        .catch(() => ({
          payload: "Error Getting currency",
          type: ERROR,
        }));
    })
    .catch(() => ({
      payload: "Error Getting currency",
      type: ERROR,
    }));
