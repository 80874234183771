import React, { useEffect, useState } from 'react'
import Organogram from "../../../../components/Organogram"
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../../components/CustomButton';
import { createOrganogram, getRole, getCompanyStaff, assignRoles, unAssignRoles, editRole } from '../../../../redux/actions/businessActions';
import { Modal, Button, Form } from 'react-bootstrap';
import "../organization.css"
import { ButtonLoader } from '../../../../components/ButtonLoader';
function AssignRoles() {
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const [parentRoleId, setParentRoleId] = useState(null);
    const [selectedStaffId, setSelectedStaffId] = useState({});
  const [lastRole, setLastRole] = useState(null)
  const [editState, setEditState] = useState({
    name: "",
    company_id: "",
    parent_role_id: "",
  })
  const [assignedRoles, setAssignedRoles] = useState({});
  const [openEdit, setOpenEdit] = useState(false)
  const[editRoleId, setEditRoleId] = useState("")
  const[editRoleName, setEditRoleName] = useState("")

const[loading, setLoading] = useState(false)
  const [companyStaff, setCompanyStaff] = useState([])
   const [currentPage, setCurrentPage] = useState(1);
  const rolesPerPage = 5;
  const [createRole, setCreateRole] = useState({
    name: "",
    parent_role_id: ""
  })
  const[selectedRoleId, setSelectedRoleId] = useState({})
  const {
    userData: {  company },
  } = useSelector((store) => store.auth);

  const company_id = company ? company.id : "";
 

  const handleShow=(() => {
    setShow(true)
  })
  const handleEditShow = (roles, rolesname) => {
      setEditRoleId(roles)
      setEditRoleName(rolesname)
    setOpenEdit(true)
  }
  const handleClose = (() => {
    setShow(false)
  })
  const handleEditClose = () => {
    setOpenEdit(false)
  }
 
  useEffect(() => {
    const getAll = async () => {
        const parsedCompanyId = parseFloat(company_id); 
        console.log("company_id:", company_id, "Type:", typeof parsedCompanyId);
        
        const data = {
            company_id: Math.floor(parsedCompanyId) 
        };
        
        try {
            console.log("Making request with company_id:", data);
            const response = await getRole(dispatch, data);
            console.log(response.payload.id);
          setRoles(response.payload);
          if (response.payload.length > 0) {
            setLastRole(response.payload[response.payload.length -1].id)
          }
        } catch (error) {
            console.log(error);
        }
    };
    getAll();
  }, [company_id]);
  const handleEditRoles = async () => {
    const data = {
      name: editState.name?.toString() || editRoleName?.toString(),
      company_id: parseFloat(company_id),
      parent_role_id: editState.parent_role_id

    }
      
      
    try {
      const response = await editRole(dispatch, editRoleId, data)
      if (response) {
        setOpenEdit(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    const getCompany = async () => {
        const parsedCompanyId = parseFloat(company_id); 
        console.log("company_id:", company_id, "Type:", typeof parsedCompanyId);
        
        const data = {
            company_id: Math.floor(parsedCompanyId) 
        };
        
        try {
            console.log("Making request with company_id:", data);
            const response = await getCompanyStaff(dispatch, data);
            console.log(response.payload.id);
          setCompanyStaff(response.payload);
          
        } catch (error) {
            console.log(error);
        }
    };
    getCompany();
  }, [company_id]);
    const handleRoleSelection =  (roleId, event) => {
    const staffId = event.target.value;
    setSelectedStaffId({
      ...selectedStaffId,
      [roleId]: staffId, 
    });
  };

  const handleRoles = async (roleId) => {
   const staffId = selectedStaffId[roleId];
    const data = {
      occupant_id:parseFloat(staffId), 
      company_id: parseFloat(company_id),     
    };
    console.log(data)
    try {
      setLoading(true)
   const response = await assignRoles(dispatch, roleId, data)
      console.log(response.payload)
     window.location.reload()
       
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
   const handleUnAssigned = async (roleId) => {
    const staffId = selectedStaffId[roleId];
    const data = {
      role_id: roleId     
    };
    console.log(data)
    try {
   const response = await unAssignRoles(dispatch, data)
      console.log(response.payload)
      window.location.reload()
      
    } catch (error) {
      console.log(error)
    }
  }
  
    
 
  const handleRoleCreation = async () => {
    const data = {
      name: createRole.name.toString(),
      company_id: parseFloat(company_id),
      parent_role_id: createRole.parent_role_id || null,

    }
    console.log(data)
   try {
     const response = await createOrganogram(dispatch, data)
     if (response) {
       setShow(false)
     }
     window.location.reload()
   } catch (error) {
    console.log(error)
   }
  }

  const handleEditInputChanges = (e) => {
    const { name, value } = e.target
    setEditState({...editState, [name]: value})
  }
  const handleEditInputChange = (e) => {
    const selectedid = e.target.value
    setEditState({...editState, parent_role_id: selectedid})
  }
  const handleInputChange = (e) => {
    const selectedRole = e.target.value
    setCreateRole({ ...createRole, parent_role_id: selectedRole })

  }
  const handleInputChanges = (e) => {
    const { name, value } = e.target
    setCreateRole({...createRole, [name]: value})
  }
    const indexOfLastRole = currentPage * rolesPerPage;
  const indexOfFirstRole = indexOfLastRole - rolesPerPage;
  const currentRoles = roles.slice(indexOfFirstRole, indexOfLastRole);

  const nextPage = () => {
    if (currentPage < Math.ceil(roles.length / rolesPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
          
      
    
     
      
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title  style={{fontWeight: "bold"}}>Create Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body><Form>
            <Form.Group controlId="formBasicInput">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
              type="text"
              name="name"
                placeholder="Enter the name of role"
                value={createRole.name}
                onChange={(e)=>handleInputChanges(e)}
              />
          </Form.Group>
             <Form.Group controlId="formRoleSelect">
            <Form.Label>Select a Role</Form.Label>
            <Form.Control
              as="select"
              name=" parent_role_id"
              value={createRole.parent_role_id}
              onChange={handleInputChange}
            >
              <option value="">Select a role</option>
              {Array.isArray(roles) && roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          </Form></Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor: "#3A6ED4"}} disabled={!createRole.name} onClick={handleRoleCreation}>
           {loading ? <ButtonLoader/> : "Create"} 
          </Button>
          <Button style={{backgroundColor: "black"}} onClick={handleClose}>
          Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div class="table-title-area">
              <h3>Staff Management</h3>
              <span>
              <CustomButton
         
              backgroundColor="#3A6ED4"
              handleClick={handleShow}
              color="#fff"
              loading={false}
              text={"Create Roles"}
              
                additionalClass="p-2  mt-2"
   
      />
      </span>
    
</div>

<div class="table-wrapper">
    <table class="eg-table order-table table mb-0">
        <thead>
            <tr>
                <th>Roles</th>
                <th>Staff Email</th>
                <th>Roles Created</th>
                <th>Action</th>
            </tr>
        </thead>
      <tbody>
  {Array.isArray(roles) && roles.map((roles, index) => {
      

    return (
      <tr key={index}>
        <td>{roles.name}</td>
        <td>
          <div className="dropdown-wrapper">
            <select className="custom-select  mt-2" name="role"
              onChange={(event) => handleRoleSelection(roles.id, event)}
              id={`role-select-${index}`}>
              <option value="">Select a Staff</option>
              {Array.isArray(companyStaff) && companyStaff.map((companyStaff) => (
                <option key={companyStaff.id} value={companyStaff.id}>
                  {companyStaff.username}
                </option>
              ))}
            </select>
          </div>
        </td>
        <td>
          {roles.occupant_id != null ? (
            <CustomButton
              backgroundColor="#3A6ED4"
              handleClick={() => handleUnAssigned(roles.id)}
              color="#fff"
              loading={false}
              text={"Unassign"}
              additionalClass="mx-3 mt-2"
            />
          ) : (
            <CustomButton
              backgroundColor="#3A6ED4"
              handleClick={() => handleRoles(roles.id)}
              color="#fff"
              loading={false}
              text={"Assign"}
              additionalClass="mx-3 mt-2"
            />
          )}
          
        </td>
        <td>
           <CustomButton
              backgroundColor="#3A6ED4"
              handleClick={() => handleEditShow(roles.id, roles.name)}
              color="#fff"
              loading={false}
              text={"Edit Roles"}
              additionalClass="mx-3 mt-2"
            />
        </td>
      </tr>
    );
  })}
             <Modal show={openEdit} onHide={handleEditClose}centered>
        <Modal.Header closeButton>
          <Modal.Title style={{fontWeight: "bold"}}>Edit Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body><Form>
            <Form.Group controlId="formBasicInput">
              <Form.Label>Role Name</Form.Label>
              <Form.Control                                                
              type="text"
              name="name"
                placeholder="Enter the name of role"
                defaultValue={editRoleName || editState.name}
                onChange={(e)=>handleEditInputChanges(e)}
              />
          </Form.Group>
             <Form.Group controlId="formRoleSelect">
            <Form.Label>Select a Role</Form.Label>
            <Form.Control
              as="select"
              name=" parent_role_id"
              value={editState.parent_role_id}
              onChange={handleEditInputChange}
            >
              <option value="">Select a Supervisor</option>
              {Array.isArray(roles) && roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          </Form></Modal.Body>
        <Modal.Footer>
    <Button disabled={!editState.parent_role_id } style={{backgroundColor: "#3A6ED4"}} onClick={handleEditRoles}>
            Edit
          </Button>
          <Button style={{backgroundColor: "black"}} onClick={handleEditClose}>
          Close
          </Button>
        </Modal.Footer>
      </Modal>
</tbody>


    </table>
</div>

 <div className="table-pagination">
        <p>
          Showing {indexOfFirstRole + 1} to {Math.min(indexOfLastRole, roles.length)} of {roles.length} entries
        </p>
        <nav className="pagination-wrap">
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={prevPage}>
                Prev
              </button>
            </li>
            <li className={`page-item ${currentPage === Math.ceil(roles.length / rolesPerPage) ? 'disabled' : ''}`}>
              <button className="page-link" onClick={nextPage}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default AssignRoles;
