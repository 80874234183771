import React, { useEffect,useRef,useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import icons from "../../../../assets/icons/icon-collection.svg";
import { useHistory } from "react-router";
import CustomInput from "../../../../components/CustomInput";
import ReactStars from "react-rating-stars-component";
import CustomTextArea from "../../../../components/CustomTextArea";
import cogoToast from "cogo-toast";
import { uploadFile } from "../../../../utils/helpers";
import {
   getAllExpertAction
} from "../../../../redux/actions/expertCategoryAction";
 
 
 import { makeStyles } from '@material-ui/core/styles';
import './style.css';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

 

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
       fontSize: 18,
    },
  },
});
 

const ExpertList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const  classes = useStyles();
  const [loading, setLoading] = useState(false);
  const {get_all_expert} = useSelector((store) => store);

  
 
useEffect(() => {
  Promise.resolve(getAllExpertAction(dispatch, null)).finally(() => setLoading(false) 
  );
}, [dispatch, 1]);

 

 
  return (
    <Card additionalClass="mt-4 p-4  maxh">
      
       <h5> List Experts</h5>
 
      <div style={{
                  padding: "10px",
                  maxHeight: "280px",
                }}
      className="col-12 col-md-12 list-achievements">
      <table className="col-md-12 table-striped table-bordered-padding">
        <thead>
          <tr>
          <th>Title</th>
          <th>Full Name</th>
          <th>Email Address</th>
          <th>Phone Number</th>
          <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {
            get_all_expert &&

            get_all_expert.get_all_expert
            &&
            get_all_expert.get_all_expert.map( (item, index)=>{
              return(
                <tr>
                <td>{item.title}</td>
                <td>{item.fullname}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>

                <td style={{width:100}}>
               
                <button className="btn btn-sm">Edit</button>
                &nbsp;
                <button className="btn btn-sm">Delete</button>
              
              </td>
              </tr>
    
              )
            })
          }
        </tbody>
      </table>
        
    
    </div>



    </Card>

  );
};


export default ExpertList;
