import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmptyState from "../../../../components/EmptyState";
import { SectionLoader } from "../../../../components/PageLoader";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import {
  FormControl,
  MenuItem,
  Select,
  // Modal,
  DialogContent,
  Dialog,
} from "@material-ui/core";

import {
  getBanksList,
  createBankAccount,
  // getAccountBalance,
  getBankAccount,
  getMyAccounts,
  resetMyAccounts,
} from "../../../../redux/actions/walletActions";
// import CustomSelect from "../../../../components/CustomSelect";
// import axios from "axios";
import Account from "../Add/Account";
import StripeAddAccount from "./StripeAddAccount";

const Withdraw = ({ type }) => {
  const dispatch = useDispatch();
  const [isAddAccount, setIsAddAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addingAccount, setAddingAccount] = useState(false);
  const[accountDetails, setAccountDetails] = useState([])
  const [open, setOpen] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [fullBankName, setFullBankName] = useState("");
  const [state, setState] = useState({
    accountNumber: "",
    bankCode: "",
  });
  const [banks, setBanks] = useState([]);

  const { bankList, accounts } = useSelector((state) => state.wallet);
useEffect(() => {
  setAddingAccount(true);
  if (bankList && bankList.length > 0) {
    setBanks(bankList);
    console.log("Bank list loaded successfully:", bankList);
  } else {
    setBanks([]);
    console.log("Bank list is empty"); 
  }
  setAddingAccount(false);
}, [bankList]);

  useEffect(() => {
    setAddingAccount(true);
    Promise.all([
      getBanksList(dispatch),
      getMyAccounts(dispatch, 1, 10),
    ]).finally(() => {
      setAddingAccount(false);
    });
  }, [dispatch]);
 
  
  useEffect(() => {
    const fetchBank = async() => {
      try {
      const response = await getBankAccount(dispatch)
        console.log(response.payload)
        setAccountDetails(response.payload)
        if (response.payload && response.payload.bank_code) {
          getBankNameFromList(response.payload.bank_code);
          console.log(response.payload.bank_code)
      }
    } catch (error) {
      console.log(error)
    }
    }
    fetchBank()
  }, [])

  const handleChange = ({ target: { id, value } }) => {
    const newValue = {};
    newValue[id] = value;
    // console.log(newValue);
    setState({ ...state, ...newValue });
  };

  const handleSelect = ({ target: { name, value } }) => {
    const changed = {};
    changed[name] = value;
    getBankNameFromList(value);
    setState({ ...state, ...changed });
  };

  const getBankNameFromList = (bankCode) => {
  console.log(bankList)
  if (bankList && banks.length > 0) {
    const bank = banks.find((bank) => bank.code === bankCode);
    if (bank) {
      setFullBankName(bank.name);
      console.log(bank.name);
    } else {
      setFullBankName("Bank not found");
      console.log("Bank code not found in the bank list");
    }
  } else {
    console.log("Bank list is not available yet.");
  }
};


  const getAccountName = (evt) => {
    if (!state.bankCode && !state.accountNumber) {
      alert("Enter Account Number and Select Bank Name");
    } else {
      evt.preventDefault();
      const PAYSTACK_API_KEY = process.env.REACT_APP_PAYSTACK_API_KEY;
      const url = `https://api.paystack.co/bank/resolve?account_number=${state.accountNumber}&bank_code=${state.bankCode}`;

      setLoading(true);
      try {
        fetch(url, {
          method: "get",
          headers: new Headers({
            Authorization: `Bearer ${PAYSTACK_API_KEY}`,
          }),
        })
          .then((result) => {
            return result.json();
          })
          .then((res) => {
            setLoading(false);
            res.status
              ? setAccountName(res.data.account_name)
              : alert(res.message);
          })
          .catch((error) => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };


  const addAccount = async (evt) => {
    evt.preventDefault();
    setAddingAccount(true);
    resetMyAccounts(dispatch);
    setLoading(true);
    const payload = {
      account_name: accountName,
      account_number: state.accountNumber,
      bank: fullBankName,
      bankCode: state.bankCode,
    };

    // console.log("bank payload", payload);

    Promise.resolve(createBankAccount(dispatch, payload)).finally(() => {
      getMyAccounts(dispatch, 1, 10);
      setAddingAccount(false);
      setState({
        accountNumber: "",
        bankCode: "",
      });
      setLoading(false);
      setIsAddAccount("");
      setAccountName("");
      setFullBankName("");
     });
  };

  const handleAddAccount = () => {
    if (type === "paystack") setIsAddAccount(true);
    else if (type === "stripe") setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const refreshList = () => {
    resetMyAccounts(dispatch);
    getMyAccounts(dispatch, 1, 10);
  };

  return (
    <div className="m-5 pb-5">
      {/* {addingAccount && !accounts && <SectionLoader />} */}
      {/* { !accountDetails && (
        <EmptyState text="You have not added any account" />
      )} */}
      {/* {accounts?.accounts && accounts?.accounts[0] && (
        <>
          <p>You can only withdraw from your available balance </p>
          {accounts.accounts.map((account, index) => (
            <Account
              key={index}
              account={account}
              type={type}
              refreshList={refreshList}
            />
          ))}
        </>
      )} */}

      {accountDetails && accountDetails?.account_number ? (
        
        <div>
          <h6 className="bolder-text">Bank Details</h6>
            <CustomInput
          type="number"
          name="account"
          label="Account Name"
          id="name"
         value={accountDetails.account_name}
          isRequired
        />
        <CustomInput
          type="number"
          name="account"
          label="Account Number"
            id="name"
            value={accountDetails.account_number}
          
          isRequired
          />
          <CustomInput
          type="text"
          name="account"
          label="Account Status"
          id="name"
         value={accountDetails.account_status}
          isRequired
          />
          <CustomInput
          type="text"
          name="account"
          label="Bank Name"
          id="name"
          onChange={fullBankName}
          isRequired
        />
        </div>
      ) : (
       <EmptyState text="You have not added any account" />   
)}
      {/* {isAddAccount && (
        <div className="mt-4">
          <h5>Add New Account</h5>
          <form className="pb-5">
            <CustomInput
              type="number"
              label="Account Number"
              id="accountNumber"
              isRequired={true}
              onChange={(e) => {
                handleChange(e);
                setAccountName("");
              }}
            />
            <FormControl variant="standard" className="w-100 custom-input ">
              <label className="d-block mb-0">Bank Name</label>
              <Select
                id="bankCode"
                name="bankCode"
                onChange={(e) => {
                  handleSelect(e);
                  setAccountName("");
                }}
                defaultValue="null"
              >
                <MenuItem key={"" + 0} value="null">
                  Select Bank Name
                </MenuItem>
                {bankList &&
                  bankList[0] &&
                  bankList.map((bank) => (
                    <MenuItem
                      key={bank.id}
                      value={bank.code}
                      data-account-name={bank.name}
                    >
                      {bank.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {accountName ? (
              <p
                style={{ fontWeight: "bold", fontSize: 15, marginTop: "10px" }}
              >
                {accountName}
              </p>
            ) : (
              ""
            )}
            {accountName ? (
              <CustomButton
                backgroundColor="#3A6ED4"
                color="#fff"
                // text="ADD ACCOUNT"
                loading={loading}
                additionalClass="mt-5 mb-2"
                handleClick={addAccount}
              />
            ) : (
              <CustomButton
                backgroundColor="#3A6ED4"
                color="#fff"
                text="GET ACCOUNT NAME"
                loading={loading}
                additionalClass="mt-5 mb-2 btn-secondary"
                handleClick={getAccountName}
              />
            )}
          </form>
        </div>
      )} */}
      {!accountDetails && (
        <CustomButton
          backgroundColor="#3A6ED4"
          color="#fff"
          text="Add Account"
          additionalClass="mt-5 mb-2 btn-secondary"
          handleClick={handleAddAccount}
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "35rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        <DialogContent>
          <StripeAddAccount handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Withdraw;
