
import React from 'react'
import AssignRoles from "./AssignRoles"
import { useState, useEffect } from 'react';
import {useSelector} from "react-redux"
import SubMenu from '../../../components/SubMenu';
import Card from '../../../components/Card';
import { useLocation } from 'react-router-dom';
import BackButton from '../../../components/BackButton';

import Layout from '../../../layout';
import Organizationalchart from "./Organizationalchart"


const subMenuOptions = [
  ["Roles", "/organization/assignroles"],
  ["Organogram", "/organization/organizational-chart"],
  
];
const Organization = () => {
   const [canPost, setCanPost] = useState(true);
  const { pathname } = useLocation();

   const {
    userData: { user_type },
  } = useSelector((store) => store.auth);

  useEffect(() => {
    // Log user_type and canPost when user_type changes
    console.log("user_type:", user_type);
    setCanPost(user_type === "1");
    console.log("canPost set to:", user_type === "1");
  }, [user_type]);

  const displaySection = () => {
    if (pathname.includes("assignroles")&& canPost) {
      return <AssignRoles/>;
    } else if (pathname.includes("organizational-chart")) {
      return <Organizationalchart/>;
    } 
  }
  

    
      return (
        <Layout>
          <div className="insights d-flex flex-column flex-md-row mt-3">
            <div className="sub-menu-container">
              <BackButton />
              <SubMenu menuOptions={subMenuOptions} includeSVG />
            </div>
            <div className="main-content pt-2 pt-md-0 ml-md-4">
              <Card>{displaySection()}</Card>
            </div>
          </div>
        </Layout>
      )
    
  }

export default Organization
