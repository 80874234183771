import {
  GET_PROFILE,
  UPDATE_PROFILE,
  EDIT_PROFILE,
  PROFILE_LOADING,
  STOP_PROFILE_LOADING,
  SET_RESUME,
  GET_OTHER_USER,
  GET_ADMIN_REQUESTS,
  APPROVE_ADMIN_REQUEST,
  DECLINE_ADMIN_REQUEST,
} from "../actionTypes";

const initialState = {
  profileData: {
    first_name: "",
    last_name: "",
    title: "",
    gender: "",
    dob: "",
    location: "",
    state: "",
    country: "",
    industry: [],
    image_url: "",
    resume: "",
    resumeName: "",
    phone: "",
    links: [
      {
        linkdn: "",
        twitter: "",
        facebook: "",
        potfolio: "",
      },
    ],
    isShowOnProfile: 1,
    about: "",
  },
  profileLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PROFILE:
    case UPDATE_PROFILE:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          ...payload,
        },
      };
    case EDIT_PROFILE:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          [payload.name]: payload.value,
        },
      };
    case PROFILE_LOADING:
      return {
        ...state,
        profileLoading: true,
      };
    case STOP_PROFILE_LOADING:
      return {
        ...state,
        profileLoading: false,
      };
    case SET_RESUME:
      return {
        ...state,
        resume: payload,
      };

    case GET_OTHER_USER:
      return {
        ...state,
        user: payload,
      };
    case GET_ADMIN_REQUESTS:
      return {
        ...state,
        requests: payload,
      };
    case APPROVE_ADMIN_REQUEST:
      return {
        ...state,
        request: payload,
      };
    case DECLINE_ADMIN_REQUEST:
      return {
        ...state,
        request: payload,
      };
    default:
      return state;
  }
};
