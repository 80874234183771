import {
  GET_BANKS,
  CREATE_BANK_ACCOUNT,
  GET_ACCOUNT_BALANCE,
  GET_MY_ACCOUNTS,
  RESET_MY_ACCOUNTS,
  GET_TRANSACTIONS,
  CARD_TOKEN_SUCCESS,
  CARD_CREATE_SUCCESS,
  GET_CARDS,
  RESET_CARDS,
  DELETE_BANK_ACCOUNT,
  SET_DEFAULT_CARD,
  FUND_WALLET,
  WITHDRAW_FROM_WALLET,
  TRANSFER_FROM_AVAILABLE,
} from "../actionTypes";

const initialState = {
  banks: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BANKS:
      return {
        ...state,
        bankList: payload,
      };
    case CREATE_BANK_ACCOUNT:
      return {
        ...state,
        account: payload,
      };
    case GET_ACCOUNT_BALANCE:
      return {
        ...state,
        balance: payload,
      };
    case GET_MY_ACCOUNTS:
      return {
        ...state,
        accounts: payload,
      };
    case RESET_MY_ACCOUNTS:
      return {
        ...state,
        accounts: [],
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: payload,
      };
    case CARD_TOKEN_SUCCESS:
      return {
        ...state,
        token: payload,
      };
    case CARD_CREATE_SUCCESS:
      return {
        ...state,
        cardCreated: payload,
      };
    case GET_CARDS:
      return {
        ...state,
        cards: payload,
      };
    case RESET_CARDS:
      return {
        ...state,
      };
    case DELETE_BANK_ACCOUNT:
      return {
        ...state,
        deleted: payload,
      };
    case SET_DEFAULT_CARD:
      return {
        ...state,
        default: payload,
      };
    case FUND_WALLET:
      return {
        ...state,
        fundWallet: payload,
      };
    case WITHDRAW_FROM_WALLET:
      return {
        ...state,
        withdrawal: payload,
      };

    case TRANSFER_FROM_AVAILABLE:
      return {
        ...state,
        transferFromAvailable: payload,
      };
    default:
      return state;
  }
};
