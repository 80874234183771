/* eslint-disable indent */
import {
    SEARCH_COLLEAGUE, 
    CREATE_RECOGNITION,
    GET_RECOGNITION_REQUEST,
    SUBMIT_RECOGNITOIN_REQUEST,
    GET_ACHIEVMENT_DATA_HR,
    UPDATE_RECOGNITOIN_REQUEST_APPROVAL_HR,
    GET_ACHIEVMENT_DATA
  } from "../actionTypes";  

  const initialState = {
    search_colleagues: [],
    recognition_requests: []

   };
 
export default (state = initialState, { type, payload }) => 
{
    switch (type) {
      case SEARCH_COLLEAGUE:
        return {
          search_colleagues: payload,
        };
        case GET_RECOGNITION_REQUEST:
          return {
            recognition_requests: payload,
          };
        case GET_ACHIEVMENT_DATA:
            return {
              achievement_data: payload,
            };
         case GET_ACHIEVMENT_DATA_HR:
            return {
                achievement_data: payload,
              };
         case CREATE_RECOGNITION: {
          return {
            ...state,
            recognitionCreated: true,
          };
        };
        case SUBMIT_RECOGNITOIN_REQUEST: {
          return {
            ...state,
            recognitionRequestSubmitted: true,
          };
        };
        case UPDATE_RECOGNITOIN_REQUEST_APPROVAL_HR: {
          return {
            ...state,
            recognitionRequestUpdateSubmitted: true,
          };
        }
  
      default:
         return state;
    }
    
  };

   