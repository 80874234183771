export const demoAction = (dispatch, payload) => {
  dispatch({ type: "DEMO_ACTION", payload });
};

export const closeRecruiterModal = (dispatch) => {
  dispatch({ type: "CLOSE_RECRUITER_MODAL" });
};

export const openRecruiterModal = (dispatch) => {
  dispatch({ type: "OPEN_RECRUITER_MODAL" });
};
