import {
    POST_ADD_BANK
  } from '../actionTypes';
      
  const initialState = {
   
    bank: [],
  };
      
  export default (state = initialState, { type, payload }) => {
    switch (type) {
    case POST_ADD_BANK:
      return {
        ...state,
        bank: payload,
      };
    default:
      return state;
    }
  };