import cogoToast from "cogo-toast";
import httpRequest from "../../ajax";

import { 
  CREATE_FEED_POST,
  GET_FEEDS_RESULTS,
  CREATE_COMMENT_POST,
  DELETE_FEEDS_CATEGORY,
  ERROR
} from "../actionTypes";

  
export const createFeedAction = (dispatch, data) =>
{

  console.log("<<<<createFeedAction>>>>"+JSON.stringify(data));
  httpRequest("post", "/feeds/create", data)
  .then(({ data: { data } }) => {
    const action = { type: CREATE_FEED_POST, payload: data };
    dispatch(action);
     return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

}


  
export const createCommentPostAction = (dispatch, data) =>
{  

  console.log("<<<<createCommentPostAction>>>>"+JSON.stringify(data));
  httpRequest("post", "/feeds/comment", data)
  .then(({ data: { data } }) => {
    const action = { type: CREATE_COMMENT_POST, payload: data };
    dispatch(action);
     return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

}

export const getFeedsAction = (dispatch, page) =>{
 
    httpRequest("get", `/feeds`)
     .then(({ data: { data } }) => {
      
        console.log("<<<<<<getFeedsAction>>>>>"+JSON.stringify(data));
  
        const action = { type: GET_FEEDS_RESULTS, payload: data };
       dispatch(action);
       return action;
     })
     .catch(({ response }) => ({
       payload: response?.data?.message,
       type: ERROR,
     }));
   }
  
 
   export const deleteFeedsAction = (dispatch, data) =>{
    httpRequest("delete", `/feeds/`+data.item_id)
     .then(({ data: { data } }) => {
     console.log("<<<deleteFeedsAction>>>"+JSON.stringify(data));
    const action = { type: DELETE_FEEDS_CATEGORY, payload: data };
       dispatch(action);
       return action;
     })
     .catch(({ response }) => ({
       payload: response?.data?.message,
       type: ERROR,
     }));
   }


 

 

 
 