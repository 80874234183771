import React, { useState } from "react";
import { FormControl, Select, MenuItem, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { array as arrayPropType, bool, object } from "prop-types";
import EditCard from "../EditCard";
import icons from "../../../../../assets/icons/icon-collection.svg";
import { createSkillAction } from "../../../../../redux/actions/skillsAction";
import {
  SKILL_LOADING,
  STOP_SKILL_LOADING,
} from "../../../../../redux/actionTypes";

// const suggestedSkills = [
//   "martial arts", "interior decoration", "fishing", "java"
// ];

const Skills = ({ allSkills, skillLoading, mySkills }) => {
  const MAX_SKILL = 20;
const [selectedSkills, setSelectedSkills] = useState(Array.isArray(mySkills?.skills) ? mySkills.skills : []);
  const dispatch = useDispatch();

  // const handleChange = ({ target: { value } }) => {
  //   if(selectedSkills.length >= MAX_SKILL) return;
  //   const skills = [...selectedSkills];
  //   if (!selectedSkills.includes(value)) {
  //     skills.push(value);
  //   }
  //   setSelectedSkills(skills);
  // };
const handleChange = (event) => {
  const { value } = event.target;
  if (value.length <= MAX_SKILL) {
    setSelectedSkills(value);
  }
};


  const removeSkill = (skillToRemove) => {
    const skillArray = [...selectedSkills];
    const newSkillArray = skillArray.filter((skill) => skill !== skillToRemove);

    setSelectedSkills(newSkillArray);
  };

  // const addSkill = (skilltoAdd) => {
  //   const skills = [...selectedSkills];
  //   if (!selectedSkills.includes(skilltoAdd)) {
  //     skills.push(skilltoAdd);
  //   }
  //   setSelectedSkills(skills);
  // };

  const displaySkills = () => {
    return selectedSkills.map((skill, index) => (
      <span
        className="d-flex align-items-center justify-content-between mt-2 mr-2 py-1 pl-3 outlined"
        key={index.toString()}
      >
        {skill}
        <IconButton onClick={() => removeSkill(skill)}>
          <svg width="10" height="8">
            <use href={`${icons}#close-blue`}></use>
          </svg>
        </IconButton>
      </span>
    ));
  };

  // const displaySuggestedSkills = () => {
  //   return suggestedSkills.map((skill, index) => (
  //     <span
  //       className="d-flex align-items-center justify-content-between mt-2 mr-2 py-1 pl-3 filled"
  //       key={index.toString()}
  //     >
  //       {skill}
  //       <IconButton onClick={() => addSkill(skill)}>
  //         <svg width="12" height="12">
  //           <use href={`${icons}#plus`}></use>
  //         </svg>
  //       </IconButton>
  //     </span>
  //   ));
  // };

  const handleSubmit = () => {
    dispatch({ type: SKILL_LOADING });
    const payload = {
      skills: selectedSkills,
    };
    Promise.resolve(createSkillAction(payload, dispatch)).finally(() =>
      dispatch({ type: STOP_SKILL_LOADING })
    );
  };

  return (
    <EditCard
      handleSubmit={handleSubmit}
      loading={skillLoading}
      disable={selectedSkills.length === 0}
    >
      <FormControl variant="filled" className="w-100">
        <Select
          id="skills"
          label="skills"
          onChange={handleChange}
          value={selectedSkills}
          multiple={true}
        >
          <MenuItem value=" ">
            <em>Add Skill (ex: Interior Design)</em>
          </MenuItem>
          {allSkills
            .filter(({ title }) => !selectedSkills.find((val) => val === title))
            .map(({ title, id }) => (
              <MenuItem key={id} value={title}>
                {title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <div className="skills-count mt-3 w-100 d-flex align-items-center">
        <span className="blob rounded px-2 py-1 bg-green co-white">
          <b>
            {selectedSkills.length}/{MAX_SKILL}
          </b>
        </span>
        <span className="ml-2 co-green medium-text">Skills count</span>
      </div>

      {selectedSkills[0] && (
        <div className="mt-5 w-100 d-flex flex-wrap">{displaySkills()}</div>
      )}

      {/* <div className="skills-section mt-5 pt-3 w-100">
        <h6 className="mb-3">Suggested Skills</h6>
        <div className="d-flex flex-wrap">
          {displaySuggestedSkills()}
        </div>
      </div> */}
    </EditCard>
  );
};

Skills.propTypes = {
  mySkills: object.isRequired,
  allSkills: arrayPropType.isRequired,
  skillLoading: bool.isRequired,
};

export default Skills;
