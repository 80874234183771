import React from "react";
import { string } from "prop-types";
import "./index.scss";

const CustomBadge = ({ figure }) => {
  return <div className="customBadge">{figure}</div>;
};

CustomBadge.propTypes = {
  figure: string.isRequired,
};

export default CustomBadge;
