/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../../components/CustomButton";
import EmptyState from "../../../../../components/EmptyState";
import { SectionLoader } from "../../../../../components/PageLoader";
import Placeholder from "../../../../../assets/icons/placeholder.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  shortlistApplicant,
  getRecruiterJobApplicants,
} from "../../../../../redux/actions/recruiterAction";
import { useHistory, useParams } from "react-router-dom";

const Applicants = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searching, setSearching] = useState(false);
  const [toggleChange, setToggleChange] = useState(false);
  const { applicants } = useSelector(({ recruiter }) => recruiter);

  useEffect(() => {
    const handleGetJobApplicants = async () => {
      setSearching(true);
      await getRecruiterJobApplicants(dispatch, id);
      setSearching(false);
    };
    handleGetJobApplicants();
  }, [toggleChange]);

  const recApplicants = applicants ? applicants["applicants"] : [];
  // console.log("Recruiter Applicants", recApplicants);

  const handleToggleChange = () => {
    setToggleChange(!toggleChange);
  };

  return (
    <div>
      {searching && <SectionLoader />}
      {!searching && recApplicants && !recApplicants[0] && (
        <EmptyState text="There are no applicant for this job" />
      )}
      {recApplicants && recApplicants[0] && (
        <>
          {recApplicants.map((applicant) => (
            <Applicant
              applicant={applicant}
              key={applicant.id}
              job_id={id}
              toggleChange={handleToggleChange}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default Applicants;

const Applicant = ({ applicant, job_id, toggleChange }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // console.log("Shortlisted", shortlisted);

  // if (shortlisted.isShortlisted == 1) {
  // }

  const handleShortlistApplicant = async (appl_id) => {
    const data = {
      job_id: job_id,
    };

    const jobid = parseInt(job_id, 10);
    // console.log(jobid, appl_id, data);

    setLoading(true);
    await shortlistApplicant(dispatch, jobid, appl_id, data);
    toggleChange();
    setLoading(false);
  };

  return (
    <div
      key={applicant.id}
      className="d-flex align-items-center cursor-pointer justify-content-between mb-3"
    >
      <span className="d-flex align-items-center">
        {applicant.profile.image_url && (
          <img width="50" src={applicant.profile.image_url} alt="avatar" />
        )}
        {!applicant.profile.image_url && <img src={Placeholder} alt="avatar" />}
        <span
          className="d-flex flex-column ml-3"
          onClick={() => history.push(`/profile/${applicant.user_id}`)}
        >
          <h6 className="mb-0">
            {applicant.profile.first_name} {applicant.profile.last_name}
          </h6>
        </span>
      </span>
      <span>
        {applicant.isShortlisted === "1" ? (
          <CustomButton
            text="Shortlisted"
            alt="Move"
            type="button"
            backgroundColor="white"
            borderColor="var(--primary-color)"
            additionalClass={"px-4"}
            disabled="true"
            loading={loading}
          ></CustomButton>
        ) : (
          <CustomButton
            text="Shortlist"
            alt="Move"
            type="button"
            backgroundColor="white"
            borderColor="var(--primary-color)"
            additionalClass={"px-4"}
            handleClick={() => handleShortlistApplicant(applicant.user_id)}
            loading={loading}
          ></CustomButton>
        )}
      </span>
    </div>
  );
};
