import httpRequest from "../../ajax";
import {
  ERROR,
  GET_MY_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_CONFIG,
  SUBSCRIBE_CREATE,
  GET_MY_SUBSCRIPTIONS_STATUS,
} from "../actionTypes";

export const getSubscriptionsConfig = (dispatch) =>
  httpRequest("get", "/subscription/config/1/10")
    .then(({ data: { data } }) => {
      const action = {
        type: GET_SUBSCRIPTIONS_CONFIG,
        payload: data.subscriptions,
      };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const createSubscription = (dispatch, payload) => {
  // console.log("PAYLOAD", payload);
  return httpRequest("post", "/subscription/create", payload)
    .then(({ data: { data } }) => {
      const action = { type: SUBSCRIBE_CREATE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const getMySubscriptions = (dispatch) =>
  httpRequest("get", `/subscription/active`)
    .then(({ data: { data } }) => {
      const action = { type: GET_MY_SUBSCRIPTIONS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const checkRecruiterActive = (dispatch) =>
  httpRequest("get", `/subscription/checkRecruiterActive`)
    .then(({ data: { data } }) => {
      const action = { type: GET_MY_SUBSCRIPTIONS_STATUS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
