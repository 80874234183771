import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import ServiceWorkerSetup from "./components/ServiceWorkerSetup";

import { requestInterceptor, responseInterceptor } from "./ajax/interceptors";
import Router from "./config/Router";
import AutoScroll from "./components/AutoScroll";

const isBusinessUser = () => {
  const accountType = localStorage.getItem("accountType");
  return accountType === "business";
};

function App() {
  const [initLoad, setInitLoad] = useState(false);

  /* 
 {
      target: '.joyride-first-intro',
      content: 'Welcome to the brook!',
      placement:'bottom',
      disableBeacon: true
    },
  */

  if (!initLoad) {
    requestInterceptor();
    responseInterceptor();
    setInitLoad(true);
  }

  if (process.env.REACT_APP_STAGE === "PROD") {
    console.log = function no_console() {};
  }

  return (
    <>
      <div
        style={{
          background: "#BEBEFF",
          position: "fixed",
          bottom: 0,
          right: 0,
          left: "0",
          zIndex: "9999999999999999999999999999999999999999999999999999999",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            color: "#003DB5",
            padding: "6px",
            fontSize: "7px",
            margin: "0",
          }}
        >
          {/* <b>
            We are currently in Test mode and this is a Beta release. Any
            transaction or wallet balance is virtual and for test cases only
          </b> */}
          We are currently in a Beta release. Any point earned on the league
          table cannot be converted into real cash until payment services start.
          Contact support via tech-support@workbrook.com for technical issues.
        </h1>
      </div>

      <BrowserRouter>
        <ServiceWorkerSetup />
        <AutoScroll>
          <Router business={isBusinessUser()} />
        </AutoScroll>
      </BrowserRouter>
    </>
  );
}

export default App;
