import cogoToast from "cogo-toast";
import httpRequest from "../../ajax";
import {
  SEARCH_COLLEAGUE,
  GET_RECOGNITION_REQUEST,
  CREATE_RECOGNITION,
  SUBMIT_RECOGNITOIN_REQUEST,
  UPDATE_RECOGNITOIN_REQUEST_APPROVAL_HR,
  GET_ACHIEVMENT_DATA,
  GET_ACHIEVMENT_DATA_HR,
  ERROR
} from "../actionTypes";


export const getColleagues = (dispatch, page) =>
  httpRequest("get", `/recognition/colleagues`)
    .then(({ data: { data } }) => {
     // console.log("===getColleagues=="+JSON.stringify(data));
      const action = { type: SEARCH_COLLEAGUE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
 


export const getRecognitionRequests = (dispatch, page) =>
httpRequest("get", `/recognition/requests`)
  .then(({ data: { data } }) => {
    console.log("###*****===DatagetRecognitionRequests===="+JSON.stringify(data));
     const action = { type: GET_RECOGNITION_REQUEST, payload: data };
    dispatch(action);
    return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));


export const getAchievementsData = (dispatch, page) =>
{
 
 httpRequest("get", `/recognition/myrecognitions`)
  .then(({ data: { data } }) => {
    console.log("GETTER^^^^^^^%%%%%%###*****===Data::getAchievementsData===="+JSON.stringify(data));
     const action = { type: GET_ACHIEVMENT_DATA, payload: data };
    dispatch(action);
    return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));
}

export const getAchievementsDataHR = (dispatch, page) =>{
 
  httpRequest("get", `/recognition/all`)
   .then(({ data: { data } }) => {
    console.log("%%%%%%###*****===Data::getAchievementsDataHR===="+JSON.stringify(data));

       const action = { type: GET_ACHIEVMENT_DATA_HR, payload: data };
     dispatch(action);
     return action;
   })
   .catch(({ response }) => ({
     payload: response?.data?.message,
     type: ERROR,
   }));
 }
 

export const createRecognitionAction = (dispatch, data) =>
{
  httpRequest("post", "/recognition/recognise", data)
  .then(({ data: { data } }) => {
    const action = { type: CREATE_RECOGNITION, payload: data };
    dispatch(action);
   //getJobsAction(dispatch, 1);
    return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

}


export const submitRecognitionRequestAction = (dispatch, data) =>
{
  console.log("==submitRecognitionRequestAction== data=="+JSON.stringify(data))
  httpRequest("put", "/recognition/"+data.recog_id, data)
  .then(({ data: { data } }) => {
    const action = { type: SUBMIT_RECOGNITOIN_REQUEST, payload: data };
    dispatch(action);
   //getJobsAction(dispatch, 1);
    return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

}


export const updateRecognitionRequestHrAction = (dispatch, data) =>
{
 // console.log("==###%%%updateRecognitionRequestHrAction== data=="+JSON.stringify(data))
  httpRequest("put", "/recognition/approve/"+data.request_id, data)
  .then(({ data: { data } }) => {
   // console.log("==###%%%updateRecognitionRequestHrAction== RESPONSE=="+JSON.stringify(data))
    const action = { type: UPDATE_RECOGNITOIN_REQUEST_APPROVAL_HR, payload: data };
    dispatch(action);
     return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

}