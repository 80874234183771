import React, { useEffect } from "react";
import "./index.scss";

/**
 * @function PageLoader
 * @description Page Loader
 * @returns {JSX} - returns Javascript Syntax Extension
 */
export const PageLoader = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  return (
    <div className="page-loader">
      <svg
        width="287"
        height="60"
        viewBox="0 0 287 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="w"
          d="M1.34011 12.3184H8.74362C8.99587 12.3204 9.23983 12.4066 9.4349 12.5626C9.62996 12.7187 9.76439 12.9352 9.81581 13.1762L13.6167 31.1277L18.1736 13.1134C18.231 12.8866 18.3647 12.6851 18.5532 12.541C18.7417 12.3969 18.9742 12.3185 19.2136 12.3184H26.5474C26.8219 12.3194 27.0882 12.4093 27.3046 12.574C27.521 12.7388 27.6752 12.969 27.7429 13.2285L32.2676 31.1277L36.0685 13.1919C36.1203 12.9498 36.2544 12.7318 36.449 12.5733C36.6436 12.4147 36.8873 12.3249 37.1407 12.3184H44.6246C44.7512 12.3194 44.8759 12.3484 44.9893 12.4033C45.1027 12.4582 45.2019 12.5375 45.2793 12.6352C45.3567 12.7329 45.4104 12.8465 45.4363 12.9674C45.4622 13.0883 45.4596 13.2133 45.4288 13.3331L37.2801 43.5452H27.6303L23.0092 24.9817L18.3183 43.5452H8.66856L0.535959 13.3383C0.503051 13.2181 0.498939 13.0922 0.52394 12.9702C0.54894 12.8482 0.602384 12.7334 0.680132 12.6348C0.75788 12.5362 0.857847 12.4563 0.972292 12.4015C1.08674 12.3466 1.21259 12.3182 1.34011 12.3184Z"
          fill="#FAFAFA"
        />
        <path
          className="o"
          d="M63.3348 12.4708C59.9696 11.906 56.5075 12.4009 53.4498 13.8838C50.3921 15.3667 47.8975 17.7606 46.3271 20.719C44.7567 23.6773 44.1922 27.0466 44.7152 30.3389C45.2381 33.6311 46.8215 36.6756 49.236 39.0314C51.6505 41.3871 54.7708 42.932 58.1451 43.4423C61.5195 43.9525 64.9727 43.4017 68.0048 41.8695C71.0368 40.3373 73.4904 37.9034 75.0103 34.92C76.5301 31.9366 77.0373 28.5587 76.4585 25.2754C75.8951 22.0796 74.3308 19.1326 71.9807 16.8397C69.6306 14.5468 66.6102 13.0205 63.3348 12.4708ZM62.5199 35.8569C60.7516 36.2604 58.8965 36.0902 57.2363 35.372C55.576 34.6539 54.2011 33.427 53.3201 31.8775C52.4391 30.328 52.1001 28.5405 52.3545 26.786C52.6089 25.0315 53.4428 23.4059 54.7297 22.1556C56.0167 20.9054 57.6864 20.0988 59.4857 19.8581C61.285 19.6175 63.1156 19.956 64.6999 20.8223C66.2842 21.6885 67.5357 23.0353 68.2645 24.6583C68.9934 26.2813 69.1599 28.0919 68.7387 29.8155C68.3787 31.2863 67.6063 32.6304 66.508 33.6973C65.4098 34.7642 64.0289 35.512 62.5199 35.8569Z"
          fill="#FAFAFA"
        />
        <path
          className="k1"
          d="M110.865 13.2128V43.5504H102.604V13.286C102.604 13.0294 102.708 12.7833 102.894 12.6018C103.08 12.4203 103.332 12.3184 103.595 12.3184H109.943C110.064 12.3177 110.183 12.3403 110.295 12.385C110.407 12.4296 110.509 12.4954 110.594 12.5785C110.68 12.6616 110.748 12.7605 110.794 12.8693C110.841 12.9782 110.865 13.0949 110.865 13.2128Z"
          fill="#FAFAFA"
        />
        <path
          className="k1"
          d="M110.865 27.9317L124.327 12.7629C124.447 12.6281 124.595 12.52 124.761 12.446C124.928 12.372 125.109 12.3338 125.292 12.334H133.022C133.134 12.3335 133.243 12.3642 133.337 12.4223C133.431 12.4804 133.506 12.5635 133.554 12.6619C133.601 12.7603 133.618 12.8699 133.604 12.9776C133.59 13.0854 133.544 13.187 133.472 13.2703L120.767 27.9317L134.282 43.5451H124.707L110.865 27.9317Z"
          fill="#FAFAFA"
        />
        <path
          className="r1"
          d="M99.8638 13.1653C99.8999 13.0795 99.9138 12.9863 99.9042 12.894C99.8946 12.8017 99.8619 12.7131 99.8089 12.6361C99.7559 12.559 99.6843 12.4959 99.6004 12.4523C99.5164 12.4087 99.4228 12.3859 99.3277 12.386C98.6147 12.386 97.6068 12.386 96.8938 12.386C93.1036 12.386 89.9728 13.0502 87.7212 15.7492V13.1862C87.7212 13.0701 87.6978 12.9552 87.6522 12.848C87.6067 12.7407 87.54 12.6433 87.4558 12.5612C87.3717 12.4791 87.2718 12.414 87.1619 12.3695C87.052 12.3251 86.9342 12.3023 86.8152 12.3023H80.1408C80.0139 12.3016 79.888 12.3254 79.7707 12.3725C79.6533 12.4196 79.5466 12.4889 79.4568 12.5765C79.3671 12.664 79.296 12.7681 79.2478 12.8827C79.1996 12.9972 79.1751 13.1199 79.1758 13.2438V43.5291H87.7212V26.268C87.7212 23.7992 91.8813 20.5143 96.1272 20.1063C96.3728 20.0926 96.6103 20.0157 96.8156 19.8834C97.0208 19.7511 97.1865 19.5681 97.2959 19.3531L99.8638 13.1653Z"
          fill="#FAFAFA"
        />
        <path
          className="b"
          d="M155.598 12.3158C151.481 12.3158 147.819 13.3619 145.825 15.5745V0.546884C145.826 0.448618 145.795 0.352475 145.738 0.27165C145.681 0.190826 145.599 0.129283 145.505 0.0954705C145.41 0.0616575 145.307 0.0572318 145.21 0.0828009C145.113 0.10837 145.026 0.16268 144.962 0.238277L137.606 8.73804C137.396 8.98517 137.281 9.29576 137.279 9.61678V43.5426H154.826C163.757 43.5426 169.606 36.8317 169.606 27.8507C169.606 19.0842 164.529 12.3158 155.598 12.3158ZM153.603 36.3767H145.819V24.0585C147.17 21.7361 150.451 19.4765 153.598 19.4765C158.611 19.4765 160.798 23.661 160.798 27.8821C160.798 32.1032 158.616 36.3923 153.603 36.3923V36.3767Z"
          fill="#FAFAFA"
        />
        <path
          className="r2"
          d="M193.397 13.1653C193.433 13.0795 193.447 12.9863 193.437 12.894C193.428 12.8017 193.395 12.7131 193.342 12.6361C193.289 12.559 193.218 12.4959 193.134 12.4523C193.05 12.4087 192.956 12.3859 192.861 12.386C192.148 12.386 191.145 12.386 190.432 12.386C186.637 12.386 183.506 13.0502 181.26 15.7492V13.1862C181.26 13.0697 181.236 12.9543 181.19 12.8467C181.144 12.7392 181.077 12.6415 180.993 12.5593C180.908 12.4772 180.807 12.4122 180.697 12.368C180.586 12.3239 180.468 12.3016 180.348 12.3023H173.695C173.569 12.3023 173.443 12.3266 173.326 12.3739C173.209 12.4212 173.103 12.4906 173.013 12.578C172.923 12.6655 172.852 12.7692 172.804 12.8835C172.755 12.9977 172.73 13.1201 172.73 13.2438V43.5291H181.281V26.268C181.281 23.7992 185.436 20.5143 189.682 20.1063C189.928 20.0931 190.165 20.0164 190.371 19.8841C190.576 19.7517 190.742 19.5685 190.851 19.3531L193.397 13.1653Z"
          fill="#FAFAFA"
        />
        <path
          className="oo"
          d="M239.292 12.4708C235.927 11.906 232.465 12.4009 229.407 13.8838C226.349 15.3667 223.854 17.7606 222.284 20.719C220.714 23.6773 220.149 27.0466 220.672 30.3389C221.195 33.6311 222.779 36.6756 225.193 39.0314C227.608 41.3871 230.728 42.932 234.102 43.4423C237.476 43.9525 240.93 43.4017 243.962 41.8695C246.994 40.3373 249.447 37.9034 250.967 34.92C252.487 31.9366 252.994 28.5587 252.416 25.2754C251.852 22.0796 250.288 19.1326 247.938 16.8397C245.588 14.5468 242.567 13.0205 239.292 12.4708ZM238.477 35.8569C236.709 36.2616 234.853 36.0926 233.192 35.3754C231.532 34.6582 230.156 33.432 229.274 31.8828C228.392 30.3337 228.052 28.5461 228.305 26.7913C228.559 25.0365 229.392 23.4103 230.679 22.1594C231.965 20.9085 233.635 20.1011 235.434 19.8599C237.233 19.6186 239.064 19.9565 240.649 20.8224C242.234 21.6883 243.486 23.0349 244.215 24.6579C244.944 26.2809 245.111 28.0917 244.69 29.8155C244.34 31.2899 243.571 32.6383 242.473 33.7066C241.374 34.775 239.989 35.5201 238.477 35.8569Z"
          fill="#FAFAFA"
        />
        <path
          className="oo"
          d="M218.493 27.9292C218.489 24.998 219.33 22.1248 220.922 19.6386C219.075 16.7435 216.312 14.5139 213.051 13.2875C209.79 12.061 206.208 11.9043 202.848 12.8411C199.487 13.778 196.531 15.7574 194.427 18.4797C192.322 21.2021 191.184 24.5192 191.184 27.9292C191.184 31.3391 192.322 34.6563 194.427 37.3786C196.531 40.101 199.487 42.0804 202.848 43.0172C206.208 43.954 209.79 43.7974 213.051 42.5709C216.312 41.3444 219.075 39.1149 220.922 36.2197C219.33 33.7335 218.489 30.8604 218.493 27.9292ZM209.186 35.8536C207.804 36.168 206.362 36.1334 204.997 35.753C203.631 35.3726 202.388 34.6589 201.384 33.6794C200.38 32.7 199.649 31.4869 199.259 30.155C198.869 28.8231 198.834 27.4161 199.156 26.0671C199.502 24.5879 200.268 23.234 201.367 22.1607C202.467 21.0874 203.854 20.3384 205.369 19.9996C206.755 19.6838 208.2 19.7183 209.568 20.1C210.936 20.4816 212.182 21.1977 213.187 22.1803C214.192 23.163 214.923 24.3798 215.311 25.7153C215.699 27.0509 215.732 28.461 215.405 29.8122C215.054 31.2872 214.285 32.6361 213.185 33.7045C212.085 34.7729 210.7 35.5176 209.186 35.8536Z"
          fill="#FAFAFA"
        />
        <path
          className="k2"
          d="M263.584 13.2128V43.5504H255.322V13.286C255.322 13.0294 255.427 12.7833 255.613 12.6018C255.799 12.4203 256.051 12.3184 256.314 12.3184H262.688C262.928 12.3238 263.155 12.4205 263.323 12.5876C263.49 12.7548 263.584 12.9792 263.584 13.2128Z"
          fill="#FAFAFA"
        />
        <path
          className="k2"
          d="M263.584 27.9317L277.045 12.7629C277.165 12.6281 277.314 12.52 277.48 12.446C277.647 12.372 277.827 12.3338 278.01 12.334H285.741C285.852 12.3345 285.96 12.3659 286.054 12.4244C286.147 12.4829 286.221 12.5661 286.268 12.6643C286.315 12.7625 286.332 12.8716 286.317 12.9789C286.303 13.0862 286.257 13.1873 286.186 13.2703L273.486 27.9317L287.001 43.5451H277.426L263.584 27.9317Z"
          fill="#FAFAFA"
        />
      </svg>
    </div>
  );
};

export const SectionLoader = () => {
  return (
    <div className="section-loader">
      <svg
        width="287"
        height="60"
        viewBox="0 0 287 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="w"
          d="M1.34011 12.3184H8.74362C8.99587 12.3204 9.23983 12.4066 9.4349 12.5626C9.62996 12.7187 9.76439 12.9352 9.81581 13.1762L13.6167 31.1277L18.1736 13.1134C18.231 12.8866 18.3647 12.6851 18.5532 12.541C18.7417 12.3969 18.9742 12.3185 19.2136 12.3184H26.5474C26.8219 12.3194 27.0882 12.4093 27.3046 12.574C27.521 12.7388 27.6752 12.969 27.7429 13.2285L32.2676 31.1277L36.0685 13.1919C36.1203 12.9498 36.2544 12.7318 36.449 12.5733C36.6436 12.4147 36.8873 12.3249 37.1407 12.3184H44.6246C44.7512 12.3194 44.8759 12.3484 44.9893 12.4033C45.1027 12.4582 45.2019 12.5375 45.2793 12.6352C45.3567 12.7329 45.4104 12.8465 45.4363 12.9674C45.4622 13.0883 45.4596 13.2133 45.4288 13.3331L37.2801 43.5452H27.6303L23.0092 24.9817L18.3183 43.5452H8.66856L0.535959 13.3383C0.503051 13.2181 0.498939 13.0922 0.52394 12.9702C0.54894 12.8482 0.602384 12.7334 0.680132 12.6348C0.75788 12.5362 0.857847 12.4563 0.972292 12.4015C1.08674 12.3466 1.21259 12.3182 1.34011 12.3184Z"
          fill="#FAFAFA"
        />
        <path
          className="o"
          d="M63.3348 12.4708C59.9696 11.906 56.5075 12.4009 53.4498 13.8838C50.3921 15.3667 47.8975 17.7606 46.3271 20.719C44.7567 23.6773 44.1922 27.0466 44.7152 30.3389C45.2381 33.6311 46.8215 36.6756 49.236 39.0314C51.6505 41.3871 54.7708 42.932 58.1451 43.4423C61.5195 43.9525 64.9727 43.4017 68.0048 41.8695C71.0368 40.3373 73.4904 37.9034 75.0103 34.92C76.5301 31.9366 77.0373 28.5587 76.4585 25.2754C75.8951 22.0796 74.3308 19.1326 71.9807 16.8397C69.6306 14.5468 66.6102 13.0205 63.3348 12.4708ZM62.5199 35.8569C60.7516 36.2604 58.8965 36.0902 57.2363 35.372C55.576 34.6539 54.2011 33.427 53.3201 31.8775C52.4391 30.328 52.1001 28.5405 52.3545 26.786C52.6089 25.0315 53.4428 23.4059 54.7297 22.1556C56.0167 20.9054 57.6864 20.0988 59.4857 19.8581C61.285 19.6175 63.1156 19.956 64.6999 20.8223C66.2842 21.6885 67.5357 23.0353 68.2645 24.6583C68.9934 26.2813 69.1599 28.0919 68.7387 29.8155C68.3787 31.2863 67.6063 32.6304 66.508 33.6973C65.4098 34.7642 64.0289 35.512 62.5199 35.8569Z"
          fill="#FAFAFA"
        />
        <path
          className="k1"
          d="M110.865 13.2128V43.5504H102.604V13.286C102.604 13.0294 102.708 12.7833 102.894 12.6018C103.08 12.4203 103.332 12.3184 103.595 12.3184H109.943C110.064 12.3177 110.183 12.3403 110.295 12.385C110.407 12.4296 110.509 12.4954 110.594 12.5785C110.68 12.6616 110.748 12.7605 110.794 12.8693C110.841 12.9782 110.865 13.0949 110.865 13.2128Z"
          fill="#FAFAFA"
        />
        <path
          className="k1"
          d="M110.865 27.9317L124.327 12.7629C124.447 12.6281 124.595 12.52 124.761 12.446C124.928 12.372 125.109 12.3338 125.292 12.334H133.022C133.134 12.3335 133.243 12.3642 133.337 12.4223C133.431 12.4804 133.506 12.5635 133.554 12.6619C133.601 12.7603 133.618 12.8699 133.604 12.9776C133.59 13.0854 133.544 13.187 133.472 13.2703L120.767 27.9317L134.282 43.5451H124.707L110.865 27.9317Z"
          fill="#FAFAFA"
        />
        <path
          className="r1"
          d="M99.8638 13.1653C99.8999 13.0795 99.9138 12.9863 99.9042 12.894C99.8946 12.8017 99.8619 12.7131 99.8089 12.6361C99.7559 12.559 99.6843 12.4959 99.6004 12.4523C99.5164 12.4087 99.4228 12.3859 99.3277 12.386C98.6147 12.386 97.6068 12.386 96.8938 12.386C93.1036 12.386 89.9728 13.0502 87.7212 15.7492V13.1862C87.7212 13.0701 87.6978 12.9552 87.6522 12.848C87.6067 12.7407 87.54 12.6433 87.4558 12.5612C87.3717 12.4791 87.2718 12.414 87.1619 12.3695C87.052 12.3251 86.9342 12.3023 86.8152 12.3023H80.1408C80.0139 12.3016 79.888 12.3254 79.7707 12.3725C79.6533 12.4196 79.5466 12.4889 79.4568 12.5765C79.3671 12.664 79.296 12.7681 79.2478 12.8827C79.1996 12.9972 79.1751 13.1199 79.1758 13.2438V43.5291H87.7212V26.268C87.7212 23.7992 91.8813 20.5143 96.1272 20.1063C96.3728 20.0926 96.6103 20.0157 96.8156 19.8834C97.0208 19.7511 97.1865 19.5681 97.2959 19.3531L99.8638 13.1653Z"
          fill="#FAFAFA"
        />
        <path
          className="b"
          d="M155.598 12.3158C151.481 12.3158 147.819 13.3619 145.825 15.5745V0.546884C145.826 0.448618 145.795 0.352475 145.738 0.27165C145.681 0.190826 145.599 0.129283 145.505 0.0954705C145.41 0.0616575 145.307 0.0572318 145.21 0.0828009C145.113 0.10837 145.026 0.16268 144.962 0.238277L137.606 8.73804C137.396 8.98517 137.281 9.29576 137.279 9.61678V43.5426H154.826C163.757 43.5426 169.606 36.8317 169.606 27.8507C169.606 19.0842 164.529 12.3158 155.598 12.3158ZM153.603 36.3767H145.819V24.0585C147.17 21.7361 150.451 19.4765 153.598 19.4765C158.611 19.4765 160.798 23.661 160.798 27.8821C160.798 32.1032 158.616 36.3923 153.603 36.3923V36.3767Z"
          fill="#FAFAFA"
        />
        <path
          className="r2"
          d="M193.397 13.1653C193.433 13.0795 193.447 12.9863 193.437 12.894C193.428 12.8017 193.395 12.7131 193.342 12.6361C193.289 12.559 193.218 12.4959 193.134 12.4523C193.05 12.4087 192.956 12.3859 192.861 12.386C192.148 12.386 191.145 12.386 190.432 12.386C186.637 12.386 183.506 13.0502 181.26 15.7492V13.1862C181.26 13.0697 181.236 12.9543 181.19 12.8467C181.144 12.7392 181.077 12.6415 180.993 12.5593C180.908 12.4772 180.807 12.4122 180.697 12.368C180.586 12.3239 180.468 12.3016 180.348 12.3023H173.695C173.569 12.3023 173.443 12.3266 173.326 12.3739C173.209 12.4212 173.103 12.4906 173.013 12.578C172.923 12.6655 172.852 12.7692 172.804 12.8835C172.755 12.9977 172.73 13.1201 172.73 13.2438V43.5291H181.281V26.268C181.281 23.7992 185.436 20.5143 189.682 20.1063C189.928 20.0931 190.165 20.0164 190.371 19.8841C190.576 19.7517 190.742 19.5685 190.851 19.3531L193.397 13.1653Z"
          fill="#FAFAFA"
        />
        <path
          className="oo"
          d="M239.292 12.4708C235.927 11.906 232.465 12.4009 229.407 13.8838C226.349 15.3667 223.854 17.7606 222.284 20.719C220.714 23.6773 220.149 27.0466 220.672 30.3389C221.195 33.6311 222.779 36.6756 225.193 39.0314C227.608 41.3871 230.728 42.932 234.102 43.4423C237.476 43.9525 240.93 43.4017 243.962 41.8695C246.994 40.3373 249.447 37.9034 250.967 34.92C252.487 31.9366 252.994 28.5587 252.416 25.2754C251.852 22.0796 250.288 19.1326 247.938 16.8397C245.588 14.5468 242.567 13.0205 239.292 12.4708ZM238.477 35.8569C236.709 36.2616 234.853 36.0926 233.192 35.3754C231.532 34.6582 230.156 33.432 229.274 31.8828C228.392 30.3337 228.052 28.5461 228.305 26.7913C228.559 25.0365 229.392 23.4103 230.679 22.1594C231.965 20.9085 233.635 20.1011 235.434 19.8599C237.233 19.6186 239.064 19.9565 240.649 20.8224C242.234 21.6883 243.486 23.0349 244.215 24.6579C244.944 26.2809 245.111 28.0917 244.69 29.8155C244.34 31.2899 243.571 32.6383 242.473 33.7066C241.374 34.775 239.989 35.5201 238.477 35.8569Z"
          fill="#FAFAFA"
        />
        <path
          className="oo"
          d="M218.493 27.9292C218.489 24.998 219.33 22.1248 220.922 19.6386C219.075 16.7435 216.312 14.5139 213.051 13.2875C209.79 12.061 206.208 11.9043 202.848 12.8411C199.487 13.778 196.531 15.7574 194.427 18.4797C192.322 21.2021 191.184 24.5192 191.184 27.9292C191.184 31.3391 192.322 34.6563 194.427 37.3786C196.531 40.101 199.487 42.0804 202.848 43.0172C206.208 43.954 209.79 43.7974 213.051 42.5709C216.312 41.3444 219.075 39.1149 220.922 36.2197C219.33 33.7335 218.489 30.8604 218.493 27.9292ZM209.186 35.8536C207.804 36.168 206.362 36.1334 204.997 35.753C203.631 35.3726 202.388 34.6589 201.384 33.6794C200.38 32.7 199.649 31.4869 199.259 30.155C198.869 28.8231 198.834 27.4161 199.156 26.0671C199.502 24.5879 200.268 23.234 201.367 22.1607C202.467 21.0874 203.854 20.3384 205.369 19.9996C206.755 19.6838 208.2 19.7183 209.568 20.1C210.936 20.4816 212.182 21.1977 213.187 22.1803C214.192 23.163 214.923 24.3798 215.311 25.7153C215.699 27.0509 215.732 28.461 215.405 29.8122C215.054 31.2872 214.285 32.6361 213.185 33.7045C212.085 34.7729 210.7 35.5176 209.186 35.8536Z"
          fill="#FAFAFA"
        />
        <path
          className="k2"
          d="M263.584 13.2128V43.5504H255.322V13.286C255.322 13.0294 255.427 12.7833 255.613 12.6018C255.799 12.4203 256.051 12.3184 256.314 12.3184H262.688C262.928 12.3238 263.155 12.4205 263.323 12.5876C263.49 12.7548 263.584 12.9792 263.584 13.2128Z"
          fill="#FAFAFA"
        />
        <path
          className="k2"
          d="M263.584 27.9317L277.045 12.7629C277.165 12.6281 277.314 12.52 277.48 12.446C277.647 12.372 277.827 12.3338 278.01 12.334H285.741C285.852 12.3345 285.96 12.3659 286.054 12.4244C286.147 12.4829 286.221 12.5661 286.268 12.6643C286.315 12.7625 286.332 12.8716 286.317 12.9789C286.303 13.0862 286.257 13.1873 286.186 13.2703L273.486 27.9317L287.001 43.5451H277.426L263.584 27.9317Z"
          fill="#FAFAFA"
        />
      </svg>
    </div>
  );
};
