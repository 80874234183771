import { combineReducers } from "redux";
import auth from "./authReducer";
import profile from "./profileReducer";
import industry from "./industryReducer";
import country from "./countryReducer";
import state from "./stateReducer";
import education from "./educationReducer";
import skill from "./skillReducer";
import job from "./jobReducer";
import invite from "./inviteReducer";
import subscription from "./subscriptionReducer";
import settings from "./settingsReducer";
import recruiter from "./recruiterReducer";
import league from "./leagueReducer";
import insight from "./insightReducer";
import wallet from "./walletReducer";
import business from "./businessReducer";
import currencyRate from "./currencyRateReducer";
import demo from "./demoReducer";
import bank from "./addBankReducer";
import search_colleagues from './recognitionReducer';
import recognition_requests from './recognitionReducer';
import achievement_data from './recognitionReducer';
import get_active_poll from './nominationReducer';
import get_all_polls from './nominationReducer';
import get_poll_result from './nominationReducer';
import get_all_expert_categories from './expertCategoryReducer';
import get_all_expert from './expertCategoryReducer';
import thankyou_data from './thankYouReducer';
import get_feed_result from './feedReducer';
import jobList from './jobReducer';


export default combineReducers({
  auth,
  profile,
  industry,
  country,
  state,
  education,
  skill,
  job,
  invite,
  subscription,
  settings,
  recruiter,
  league,
  insight,
  wallet,
  business,
  currencyRate,
  demo,
  bank,
  search_colleagues,
  recognition_requests,
  achievement_data,
  get_active_poll,
  get_all_polls,
  get_poll_result,
  get_all_expert_categories,
  get_all_expert,
  thankyou_data,
  get_feed_result,
  jobList
});
