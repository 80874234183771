import React from "react";
import Layout from "../../../layout";
import SubMenu from "../../../components/SubMenu";
import Card from "../../../components/Card";
import { useLocation } from "react-router-dom";
import Referrals from "./Referrals";
import Jobs from "./Jobs";
import Applications from "./Applications";
import "./index.scss";
import BackButton from "../../../components/BackButton";

const subMenuOptions = [
  ["jobs", "/insights/jobs"],
  ["referrals", "/insights/referrals"],
  ["applications", "/insights/applications"],
];
const Insights = () => {
  const { pathname } = useLocation();
  const displaySection = () => {
    if (pathname.includes("referrals")) {
      return <Referrals />;
    } else if (pathname.includes("jobs")) {
      return <Jobs />;
    } else {
      return <Applications />;
    }
  };

  return (
    <Layout>
      <div className="insights d-flex flex-column flex-md-row mt-3">
        <div className="sub-menu-container">
          <BackButton />
          <SubMenu menuOptions={subMenuOptions} includeSVG />
        </div>
        <div className="main-content pt-2 pt-md-0 ml-md-4">
          <Card>{displaySection()}</Card>
        </div>
      </div>
    </Layout>
  );
};

export default Insights;
