import httpRequest from '../../ajax';
import { ERROR, GET_INVITES } from "../actionTypes";

export const getInviteAction = (dispatch, page) => httpRequest('get', `/invitees/${page}/10`)
  .then(({ data: { data } }) => {
    const action = { type: GET_INVITES, payload: data };
    dispatch(action);
    return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));