import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import Layout from "../../../layout";
import TabPanel from "../../../components/TabPanel";
import BizRecommended from "./BizRecommended";
import { useSelector } from "react-redux";
import SearchResult from "./SearchResult";
export default function Home() {
  const [value, setValue] = useState(0);
  const { searchedTerm } = useSelector(({ business }) => business);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Layout>
      {searchedTerm && <SearchResult />}
      {!searchedTerm && (
        <>
          <Tabs indicatorColor="primary" value={value} onChange={handleChange}>
            <Tab label="All Jobs" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <BizRecommended />
          </TabPanel>
        </>
      )}
    </Layout>
  );
}
