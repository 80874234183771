import React from "react";
import { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton";
import Card from "../../../../components/Card";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from "react-toastify";
// import { , toast } from "react-toastify";
import { IoPersonSharp } from "react-icons/io5";
import cogoToast from "cogo-toast";
import CustomInput from "../../../../components/CustomInput";
import { BsPeople, BsGraphUp, BsGearWideConnection } from "react-icons/bs"; // Import icons
import CustomTextArea from "../../../../components/CustomTextArea";
// import "../AddPerformance/add.css";
import "../AddPerformance/add.css"
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployee,
  getPerformance,
  postemployeeperformance,
  getEachPerformance,
} from "../../../../redux/actions/jobActions";

function EmployeeApprasial() {
  const [activeTab, setActiveTab] = useState("Leadership");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [newDiv, setNewDivs] = useState([]);
  const dispatch = useDispatch();
  const [newDivv, setNewDivv] = useState([]);
  const [newDivs, setNewDiv] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeeName, setEmployeeName] = useState({});
  const [newDivGoals, setNewGoals] = useState([]);
  const [newDivvGoals, setNewGoal] = useState([]);
  const [newDivsGoals, setNewGoall] = useState([]);
  const [employeeAppraisal, setEmployeeAppraisal] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [leaderText, setLeaderText] = useState({
    appraisal: "",
  });
  const [leaderTexts, setLeaderTexts] = useState({
    appraisal: "",
  });
  const [leaderTextt, setLeaderTextt] = useState({
    appraisal: "",
  });
  const [goalSubmit, setGoalSubmit] = useState(false);

  const [sendEmployee, setSendEmployee] = useState({
    employee_id: "",
    employee_name: "",
    employee_goals: [],
    employee_appraisal: [],
  });
  const userDataString = sessionStorage.getItem("userData");
     
      const userData = JSON.parse(userDataString);
      const profileId = Number(userData.id);

  const handleNewGoall = () => {
    setNewGoals((prevDivGoals) => [...prevDivGoals]);
  };
  const handleNewGoal = () => {
    setNewGoal((prevDivvGoals) => [...prevDivvGoals]);
  };
  const handleNewGoals = () => {
    setNewGoall((prevDivsGoals) => [...prevDivsGoals]);
  };
   const handleEmployeeChange = (e) => {
    const {value } = e.target;
   
      setSendEmployee((prevSendEmployee) => ({
        ...prevSendEmployee,
       
        employee_name: value
      }));
     
    }

  useEffect(() => {
    // handleNewDivs();
    // handleNewDiv();
    handleNewGoal();
    handleNewGoals();
    handleNewGoall();
    // handleNewDivv();
  }, []);

  useEffect(() => {
    getEmployee(dispatch)
      .then((action) => {
        setEmployees(action.payload.data);
        console.log(action.payload.data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      })
      .finally(() => {
        setTimeout(() => {}, 1000);
      });
  }, [dispatch]);
 

  useEffect(() => {
  getPerformance(dispatch, profileId)
    .then((action) => {
       const employee_name = action.payload.data.employee_name; 
      const performanceData = JSON.parse(action.payload.data.employee_goals);
      const employeeAppraisal = JSON.parse(
              action.payload.data.employee_appraisal
            );
      setPerformanceData(performanceData);
      setEmployeeAppraisal(employeeAppraisal)
      setEmployeeName(employee_name)
      
      console.log("Performance Data:", performanceData);
      if (performanceData && performanceData.Leadership) {
        console.log("Leadership Goals:", performanceData.Leadership);
      } else {
        console.log("Leadership data is not available");
      }
    })
    .catch((error) => {
      console.error("Error fetching performance data:", error);
    });
}, []);

 
   
  const handleSubmit = () => {
    const formDatas = [
    {
      Leadership: performanceData.Leadership.map((goal) => {
       return { ...goal[Object.keys(goal)[0]] }; 
      }),
      type: "Leadership",
      employee_appraisal: leaderText.appraisal,
    },
    {
      Performance: performanceData.Performance.map((goal) => {
       return { ...goal[Object.keys(goal)[0]] }; 

      }),
      type: "Performance",
      employee_appraisal: leaderTexts.appraisal,
    },
    {
      Development: performanceData.Development.map((goal) => {
        return { ...goal[Object.keys(goal)[0]] }; 

      }),
      type: "Development",
      employee_appraisal: leaderTextt.appraisal,
    },
  ];

    localStorage.setItem("appraisalData", JSON.stringify(formDatas));
     cogoToast.success("Employee Appraisal for saved successfully")

    
    console.log(formDatas);
  };
  const handleSubmitt = () => {
    const formDatas = [
      {
        type: "Leadership",
        employee_appraisal: leaderText.appraisal,
      },
      {
        type: "Performance",
        employee_appraisal: leaderTexts.appraisal,
      },
      {
        type: "Development",
        employee_appraisal: leaderTextt.appraisal,
      },
    ];

    localStorage.setItem("appraisalData", JSON.stringify(formDatas));
    setGoalSubmit(true);
    cogoToast.success("Employee Appraisal for saved successfully")
    // .success("Employee Appraisal for saved successfully");
    console.log(formDatas);
  };

  const handleAll = async () => {
     const userDataString = sessionStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    const profileId = Number(userData.id);

    const employeeId = profileId;
     const employee_name = employeeName;

    const appraisalData = JSON.parse(localStorage.getItem("appraisalData"));

    const sendData = {
      employee_id: employeeId,
      employee_name: employee_name,
      employee_appraisal: JSON.stringify(appraisalData),
      employee_goals: JSON.stringify(performanceData),
    };

    console.log(sendData);
    try {
    const response = await postemployeeperformance(dispatch, sendData, employeeId);
   
      
      
  } catch (error) {
      console.log(error)
     
  }
   
  };

 
  return (
    <div className="card-height" additionalClass="mt-4 p-4">
      <h5 style={{ fontWeight: "bolder" }} className="co-grayx">
        {" "}
        Employee's Apprasial
      </h5>
      <br />
      <br />
<div>
        <div className=" mt-3">
          <strong>Employee's FullName</strong>
           <CustomInput
                        type="text"
                        placeholder="Enter your name"
                        label=""
                       
                        name="target_measurement"
                        value={employeeName}
                        
                      />
        </div>
      </div>
      <div
        style={{
          padding: "10px",
          maxHeight: "680px",
        }}
        className="col-12 col-md-12 list-achievements"
      >
       

     
          <div className="mt-3">
            <strong>Click on each performance to view your Goals</strong>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Leadership" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTabClick("Leadership");
                  }}
                  style={{
                    backgroundColor:
                      activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                    color: activeTab === "Leadership" ? "#fff" : "#fff",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  <BsPeople className="mb-1" /> Leadership
                </button>
              </li>

              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Performance" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Performance")}
                  style={{
                    backgroundColor:
                      activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                    color: activeTab === "Performance" ? "#fff" : "#fff",
                    marginLeft: "2rem",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  <BsGraphUp /> Performance
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Development" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTabClick("Development");
                  }}
                  style={{
                    backgroundColor:
                      activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                    color: activeTab === "Development" ? "#fff" : "#fff",
                    marginLeft: "2rem",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  <IoPersonSharp /> Development
                </button>
              </li>
            </ul>

            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  activeTab === "Leadership" ? "show active" : ""
                }`}
                id="leadership-tab"
                style={{ marginTop: "7px" }}
              >
                <main>
                  {performanceData && performanceData.Leadership && performanceData.Leadership.length > 0 && (
  <div>
    {performanceData.Leadership[0].map((goal, index) => (
                        <div key={index}>
                          <strong>Leadership Goal/Objective(Title)</strong>
                          <CustomInput
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              height: "2rem",
                              border: "1px solid #e8e9eb",
                              borderRadius: "0.875rem",
                              padding: "1rem",
                              marginBottom: "8px",
                            }}
                            placeholder="Enter your Leadership goal title"
                            name="goal_title"
                            value={goal.goal_title}
                            // value={
                            //   leadershipPerformanceData.goal_title || sendEmployee.goal_title
                            // }
                          />
                          <br />
                          <div>
                            <strong>
                              Write your Leadership Goals/Objectives (Free text)
                            </strong>
                             <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                            {/* <textarea
                              style={{
                                width: "100%",
                                marginTop: "10px",
                                height: "9rem",
                                border: "1px solid #e8e9eb",
                                borderRadius: "0.875rem",
                                padding: "1rem",
                                marginBottom: "8px",
                              }}
                              placeholder="Enter your Leadership goals"
                              name="goal_content"
                              value={goal.goal_content}
                            /> */}
                          </div>
                          <br />

                          <div className="select-container">
                            <strong>Status</strong>
                            <select
                              name="status"
                              value={
                                goal.status 
                              }
                              style={{ border: "1px solid #e8e9eb" }}
                            >
                              <option value="option1">Select an Option</option>
                              <option value="notStarted">Not started</option>
                              <option value="inProgress">In Progress</option>
                              <option value="completed">Completed</option>
                              <option value="cancelled">Cancelled</option>
                            </select>
                          </div>
                          <br />
                          <br />

                          <div>
                            <strong>Target Measurement (%)</strong>
                            <CustomInput
                              type="text"
                              placeholder={`Enter your target measurement for Leadership Goals`}
                              label=""
                              id={`target_measurement_for leadership goals`}
                              name="target_measurement"
                              value={
                                goal.target_measurement 
                              }
                            />
                          </div>
                          <br />

                          <div>
                            <strong>Target Achieved (%)</strong>
                            <CustomInput
                              name="target_achieved"
                              value={
                                goal.target_achieved 
                              }
                              type="text"
                              placeholder={`Enter your target measurement for Leadership Goals`}
                              label=""
                              id={`target_measurement_for leadership goals`}
                            />
                          </div>
                          <br />

                          <div>
                            <strong>Due Date</strong> <br />
                            <input
                              style={{ border: "1px solid #e8e9eb" }}
                              className="select-contain"
                              type="date"
                              name="due_date"
                              value={goal.due_date}
                              id={`due_date_for Leadership goals`}
                            />
                          </div>
                          <br />
                          <br />
                        </div>
                      ))}
                      {employeeAppraisal ? (
                        employeeAppraisal
                          .filter((item) => item.type === "Leadership")
                          .map((item, index) => (
                            <div key={index}>
                              <strong className="mt-5">
                                Summary of {sendEmployee.employee_name} Review
                                Comment (Free text)
                              </strong>
                             <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                            </div>
                          ))
                      ) : (
                        <div>
                          <strong className="mt-5">
                            Summary of {sendEmployee.employee_name} Review
                            Comment (Free text)
                          </strong>
                          <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={leaderText.appraisal || ""}  
  onReady={(editor) => {
   
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
    
    setLeaderText((prev) => ({ ...prev, appraisal: data })); 
  }}
/>

                          <br />
                          <CustomButton
                            backgroundColor="#3A6ED4"
                            color="#fff"
                            text="Save"
                            disabled={!leaderText.appraisal}
                            handleClick={handleSubmit}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {newDiv &&
                    newDiv.map((el, index) => (
                      <div key={index}>
                        {el}

                        <button
                          className="rounded-3 border border-none"
                          style={{
                            width: "10%",
                            color: "#fff",
                            height: "6vh",
                            borderRadius: "5px",
                            backgroundColor: "#3A6ED4",
                          }}
                          color="#fff"
                          text="Submit"
                        >
                          Submit
                        </button>
                      </div>
                    ))}
                </main>

                <br />
                <br />
              </div>

             <div
  className={`tab-pane fade ${
    activeTab === "Performance" ? "show active" : ""
  }`}
  id="performance-tab"
  style={{ marginTop: "7px" }}
>
  <main>
                 {performanceData && performanceData.Performance && performanceData.Performance.length > 0 && (
  <div>
    {performanceData.Performance[0].map((goal, index) => (
                      <div key={index}>
                        <strong>Performance Goal/Objective (Title)</strong>
                        <CustomInput
                          style={{
                            width: "100%",
                            marginTop: "10px",
                            height: "2rem",
                            border: "1px solid #e8e9eb",
                            borderRadius: "0.875rem",
                            padding: "1rem",
                            marginBottom: "8px",
                          }}
                          placeholder="Enter your Performance goal title"
                          name="goal_title"
                          value={goal.goal_title} // Accessing the first element of the array
                        />
                        <br />
                        <div>
                          <strong>Write your Performance Goals/Objectives (Free text)</strong>
                            <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                        </div>
                        <br />

                        <div className="select-container">
                          <strong>Status</strong>
                          <select
                            name="status"
                            value={goal.status} 
                            style={{ border: "1px solid #e8e9eb" }}
                            onChange={() => { }} // Implement your change handling
                          >
                            <option value="option1">Select an Option</option>
                            <option value="notStarted">Not started</option>
                            <option value="inProgress">In Progress</option>
                            <option value="completed">Completed</option>
                            <option value="cancelled">Cancelled</option>
                          </select>
                        </div>
                        <br />

                        <div>
                          <strong>Target Measurement (%)</strong>
                          <CustomInput
                            type="text"
                            placeholder={`Enter your target measurement for Performance Goals`}
                            name="target_measurement"
                            value={goal.target_measurement} // Accessing the first element of the array
                          />
                        </div>
                        <br />

                        <div>
                          <strong>Target Achieved (%)</strong>
                          <CustomInput
                            name="target_achieved"
                            value={goal.target_achieved} // Accessing the first element of the array
                            type="text"
                            placeholder={`Enter your target achieved percentage`}
                          />
                        </div>
                        <br />

                        <div>
                          <strong>Due Date</strong> <br />
                          <input
                            style={{ border: "1px solid #e8e9eb" }}
                            className="select-contain"
                            type="date"
                            name="due_date"
                            value={goal.due_date} // Accessing the first element of the array
                          />
                        </div>
                        <br />
                      </div>
                    ))}
        
                    {employeeAppraisal ? (
                      employeeAppraisal
                        .filter((item) => item.type === "Performance")
                        .map((item, index) => (
                          <div key={index}>
                            <strong className="mt-5">
                              Summary of {sendEmployee.employee_name} Review Comment (Free text)
                            </strong>
                            <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                          </div>
                        ))
                    ) : (
                      <div>
                        <strong className="mt-5">
                          Summary of {sendEmployee.employee_name} Review Comment (Free text)
                        </strong>
                         <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={leaderTexts.appraisal || ""}  
  onReady={(editor) => {
   
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
    
    setLeaderTexts((prev) => ({ ...prev, appraisal: data })); 
  }}
/>
                        <br />
                        <CustomButton
                          className="rounded-3 border border-none"
                          text="Save"
                          backgroundColor="#3A6ED4"
                          disabled={!leaderTexts.appraisal}
                          handleClick={handleSubmit}
                        />
                      </div>
                    )}
                  </div>
                )}

    {newDivvGoals &&
      newDivvGoals.map((el, index) => (
        <div key={index}>
          {el}
          <button
            className="rounded-3 border border-none"
            style={{
              width: "10%",
              color: "#fff",
              height: "6vh",
              borderRadius: "5px",
              backgroundColor: "#3A6ED4",
            }}
            onClick={handleNewGoal} // Assuming handleNewGoal is defined
          >
            Submit
          </button>
        </div>
      ))}
  </main>
  <br />
  <br />
</div>


              <div
                className={`tab-pane fade ${
                  activeTab === "Development" ? "show active" : ""
                }`}
                id="development-tab"
                style={{ marginTop: "7px" }}
              >
                <main>
                   {performanceData && performanceData.Development && performanceData.Development.length > 0 && (
  <div>
    {performanceData.Development[0].map((goal, index) => (
                        <div key={index}>
                          <strong>Development Goal/Objective(Title)</strong>
                          <CustomInput
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              height: "2rem",
                              border: "1px solid #e8e9eb",
                              borderRadius: "0.875rem",
                              padding: "1rem",
                              marginBottom: "8px",
                            }}
                            placeholder="Enter your Development goal title"
                            name="goal_title"
                            value={goal.goal_title}
                            // value={
                            //   leadershipPerformanceData.goal_title || sendEmployee.goal_title
                            // }
                          />
                          <br />
                          <div>
                            <strong>
                              Write your Development Goals/Objectives (Free
                              text)
                            </strong>
                             <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                          </div>
                          <br />

                          <div className="select-container">
                            <strong>Status</strong>
                            <select
                              name="status"
                              value={
                                goal.status // Accessing the first element of the array
                              }
                              style={{ border: "1px solid #e8e9eb" }}
                            >
                              <option value="option1">Select an Option</option>
                              <option value="notStarted">Not started</option>
                              <option value="inProgress">In Progress</option>
                              <option value="completed">Completed</option>
                              <option value="cancelled">Cancelled</option>
                            </select>
                          </div>
                          <br />
                          <br />

                          <div>
                            <strong>Target Measurement (%)</strong>
                            <CustomInput
                              type="text"
                              placeholder={`Enter your target measurement for Development Goals`}
                              label=""
                              id={`target_measurement_for leadership goals`}
                              name="target_measurement"
                              value={
                                goal.target_measurement // Accessing the first element of the array
                              }
                            />
                          </div>
                          <br />

                          <div>
                            <strong>Target Achieved (%)</strong>
                            <CustomInput
                              name="target_achieved"
                              value={
                                goal.target_achieved // Accessing the first element of the array
                              }
                              type="text"
                              placeholder={`Enter your target measurement for Development Goals`}
                              label=""
                              id={`target_measurement_for Development goals`}
                            />
                          </div>
                          <br />

                          <div>
                            <strong>Due Date</strong> <br />
                            <input
                              style={{ border: "1px solid #e8e9eb" }}
                              className="select-contain"
                              type="date"
                              name="due_date"
                              value={goal.due_date}
                              id={`due_date_for Development goals`}
                            />
                          </div>
                          <br />
                        </div>
                      ))}
                      {employeeAppraisal ? (
                        employeeAppraisal
                          .filter((item) => item.type === "Development")
                          .map((item, index) => (
                            <div key={index}>
                              <strong className="mt-5">
                                Summary of {sendEmployee.employee_name} Review
                                Comment (Free text)
                              </strong>
                               <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                              
                            </div>
                          ))
                      ) : (
                        <div>
                          
                          <strong className="mt-5">
                            Summary of {sendEmployee.employee_name} Review
                            Comment (Free text)
                          </strong>
                          <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={leaderTextt.appraisal || ""}  
  onReady={(editor) => {
   
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
    
    setLeaderTextt((prev) => ({ ...prev, appraisal: data })); 
  }}
/>
                          
                          <br />
                          {!goalSubmit ? (
                            <CustomButton
                              className="rounded-3 border border-none"
                              color="#fff"
                              backgroundColor="#3A6ED4"
                              text="Save"
                              disabled={!leaderTextt.appraisal}
                              handleClick={handleSubmitt}
                            />
                          ) : (
                            <CustomButton
                              className="rounded-3 border border-none"
                              backgroundColor="#3A6ED4"
                              color="#fff"
                              text="Submit"
                              handleClick={handleAll}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {newDivs &&
                    newDivs.map((el, index) => (
                      <div key={index}>
                        {el}
                        {/* {index == newDivs.length - 1 && (
                        <button
                          className="rounded-3 border border-none"
                          style={{
                            width: "10%",
                            color: "#fff",
                            height: "6vh",
                            borderRadius: "5px",
                            backgroundColor: "#3A6ED4",
                          }}
                          color="#fff"
                          text="Submit"
                          onClick={handleNewDivv}
                        >
                          Add
                        </button>
                      )} */}
                        <button
                          className="rounded-3 border border-none"
                          style={{
                            width: "10%",
                            color: "#fff",
                            height: "6vh",
                            borderRadius: "5px",
                            backgroundColor: "#3A6ED4",
                          }}
                          color="#fff"
                          text="Submit"
                        >
                          Edit
                        </button>
                      </div>
                    ))}
                </main>
                <br />
                <br />
              </div>
            </div>
          </div>
     

        <br />

        <br />

        {/* </form> */}
      </div>
    </div>
  );
}

export default EmployeeApprasial;
