import React from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import Card from "../../../components/Card";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import About from "./About";
import Account from "./Account";
import Notificationsd from "./Notifications";
import Security from "./Security";
import Support from "./Support";
import Bank from "./Bank";

const subMenuOptions = [
  ["push notifications", "/settings/notifications"],
  ["my account", "/settings/account"],
  ["security", "/settings/security"],
  // ["bank", "/settings/bank"],
  ["help/support", "/settings/help"],
  ["about", "/settings/about"],
];

const Settings = () => {
  const { pathname } = useLocation();
  const displaySection = () => {
    if (pathname.includes("account")) {
      return <Account />;
    } else if (pathname.includes("security")) {
      return <Security />;
    // } else if (pathname.includes("bank")) {
    //   return <Bank />;
    } else if (pathname.includes("help")) {
      return <Support />;
    } else if (pathname.includes("about")) {
      return <About />;
    } else {
      return <Notificationsd />;
    }
  };

  return (
    <Layout>
      <div className="d-flex flex-column flex-md-row mt-3">
        <div className="sub-menu-container">
          <BackButton />
          <SubMenu menuOptions={subMenuOptions} includeSVG />
        </div>
        <div className="main-content ml-md-4">
          <Card additionalClass="p-5">{displaySection()}</Card>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
