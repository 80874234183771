// import React, { useEffect, useState } from "react";
import React from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import { bool, func } from "prop-types";
// import { IconButton } from "@material-ui/core";
// import icons from "../../../../../assets/icons/icon-collection.svg";
// import CustomButton from "../../../../../components/CustomButton";
// import {
//   editJobAction,
//   getJobLevelsAction,
// } from "../../../../../redux/actions/jobActions";
// import { getIndustryAction } from "../../../../../redux/actions/industryActions";
// import { getAllSkillsAction } from "../../../../../redux/actions/skillsAction";
// import JobDetails from "./JobDetails";
// import AddDocuments from "./JobDocuments";

const EditJob = () => {
  return <p></p>;
};
// const EditJob = ({ open, handleClose }) => {
//   const dispatch = useDispatch();
//   const [industryOptions, setIndustryOptions] = useState({});
//   const [section, setSection] = useState("details");
//   const [levelOptions, setLevelOptions] = useState({});
//   const [skillOptions, setSkillOptions] = useState({});
//   const [isJobCreated, setIsJobCreated] = useState(false);
//   const [newJob, setNewJob] = useState({
//     campany_name: "",
//     industry: "0",
//     about_company: "",
//     no_of_staff: "0",
//     job_level: "0",
//     role_title: "",
//     job_type: "0",
//     reports_to: "",
//     applicant_benefit: "salary",
//     skills_required: [],
//     location: "",
//     additional_files: [],
//     isToRefTimeLineOrToHireRecruter: 1,
//     isCompanyRepresentative: "1",
//     salary: "",
//     duration: "",
//   });
//   const { industry, skill, job } = useSelector((store) => store);
//   const { allSkills } = skill;
//   const { industries } = industry;
//   const { jobLevels, jobCreated } = job;

//   useEffect(() => {
//     Promise.all([
//       getIndustryAction(dispatch),
//       getJobLevelsAction(dispatch),
//       getAllSkillsAction(dispatch),
//     ]);
//   }, [dispatch]);

//   useEffect(() => {
//     const options = {};
//     industries.forEach(
//       (industry) => (options[industry.title] = industry.title)
//     );
//     setIndustryOptions(options);
//   }, [industries]);

//   useEffect(() => {
//     const options = {};
//     allSkills.forEach((skill) => (options[skill.title] = skill.title));
//     setSkillOptions(options);
//   }, [allSkills]);

//   useEffect(() => {
//     const options = {};
//     jobLevels &&
//       jobLevels.forEach((level) => (options[level.id] = level.title));
//     setLevelOptions(options);
//   }, [jobLevels]);

//   const handleChange = ({ target: { value, name } }) => {
//     const changedObject = {};
//     changedObject[name] = value;
//     setNewJob({ ...newJob, ...changedObject });
//   };

//   const setSelectedSkills = (skills) => {
//     setNewJob({ ...newJob, ...{ skills_required: skills } });
//   };

//   const addDocLink = (link) => {
//     const links = [...newJob.additional_files];
//     links.push(link);
//     setNewJob({ ...newJob, ...{ additional_files: links } });
//   };
//   const [linksObject, setLinksObject] = useState([]);
//   const addDocLinkObject = (link) => {
//     const links = [...linksObject];
//     links.push(link);
//     setLinksObject(links);
//   };

//   const handleSubmit = async (e) => {
//     const job_level = parseInt(newJob.job_level, 10);
//     const isCompanyRepresentative = parseInt(
//       newJob.isCompanyRepresentative,
//       10
//     );

//     e.preventDefault();
//     // if (section === "documents") {
//     //   setSection("details");
//     // } else {
//     // await createJobAction(dispatch, {
//     //   ...newJob,
//     //   ...{ job_level, isCompanyRepresentative },
//     // });
//     setIsJobCreated(true);
//     // }
//   };

//   const closeDialog = () => {
//     setIsJobCreated(false);
//     handleClose();
//   };

//   return (
//     <div>
//       <Dialog
//         open={open}
//         onClose={closeDialog}
//         aria-labelledby="form-dialog-title"
//         PaperProps={{
//           style: {
//             minWidth: "50rem",
//             borderRadius: "1rem",
//             padding: "0.75rem",
//           },
//         }}
//       >
//         <div className="d-flex justify-content-between align-items-centter">
//           <DialogTitle id="form-dialog-title">
//             {" "}
//             {!jobCreated && <>Post a New Job</>}
//           </DialogTitle>
//           <div className="close-button">
//             <IconButton onClick={handleClose}>
//               <svg width="30" height="30" className="rounded-circle">
//                 <use href={`${icons}#close`}></use>
//               </svg>
//             </IconButton>
//           </div>
//         </div>
//         {isJobCreated && jobCreated && (
//           <DialogContent>
//             <div className="d-flex flex-column justify-content-center align-items-center mx-auto my-5">
//               <svg width="119" height="119" className="rounded-circle">
//                 <use href={`${icons}#checkmark`}></use>
//               </svg>
//               <p className="co-green mt-2 small-text">
//                 Job successfully Published
//               </p>
//             </div>
//           </DialogContent>
//         )}
//         {!jobCreated && (
//           <DialogContent>
//             <form onSubmit={handleSubmit}>
//               {section === "details" && (
//                 <JobDetails
//                   newJob={newJob}
//                   setSelectedSkills={setSelectedSkills}
//                   handleChange={handleChange}
//                   industryOptions={industryOptions}
//                   levelOptions={levelOptions}
//                   skillOptions={skillOptions}
//                 />
//               )}
//               {section === "documents" && (
//                 <AddDocuments
//                   job={newJob}
//                   addDocLinkObject={(link) => addDocLinkObject(link)}
//                   addDocLink={(link) => addDocLink(link)}
//                   linksObject={linksObject}
//                 />
//               )}
//             </form>
//           </DialogContent>
//         )}
//         {!jobCreated && (
//           <DialogActions>
//             <CustomButton
//               type="button"
//               backgroundColor="#3A6ED4"
//               color="#fff"
//               text={section === "documents" ? "Job Details" : "Upload File"}
//               handleClick={() =>
//                 section === "documents"
//                   ? setSection("details")
//                   : setSection("documents")
//               }
//               additionalClass="mx-3 mt-2"
//             />
//             <CustomButton
//               type="submit"
//               backgroundColor="#41B883"
//               handleClick={handleSubmit}
//               color="#fff"
//               text={"Publish"}
//               additionalClass="mx-3 mt-2"
//             />
//           </DialogActions>
//         )}
//       </Dialog>
//     </div>
//   );
// };

// EditJob.propTypes = {
//   open: bool.isRequired,
//   handleClose: func.isRequired,
// };

export default EditJob;
