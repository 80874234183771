/* eslint-disable indent */
import {
  CREATE_RECRUITER,
  GET_MY_RECRUITER_DATA,
  GET_ALL_RECRUITERS,
  GET_RECRUITER,
  GET_RECRUITER_JOBS,
  SEARCH_RECRUITER,
  GET_RECRUITER_INSIGHT,
  GET_RECRUITER_JOBS_APPLICANTS,
  GET_RECRUITER_JOB_APPLICANTS,
  GET_RECRUITER_JOBS_SHORTLISTED,
  SHORTLIST_APPLICANT,
  GET_RECRUITER_APPLICANTS_SHORTLISTED,
  SEND_SHORTLIST,
  RECRUITER_REQUEST_ACCEPTANCE,
  RECRUITER_COMPLETED_JOBS_LEVELS,
  RECRUITER_COMPLETED_BY_INDUSTRY,
  RECRUITER_SHORTLIST_INTERVIEW_RATIO,
  GET_RECRUITER_REVIEW,
} from "../actionTypes";

const initialState = {
  recruiterJobs: [],
  recruiterJobsApplicants: [],
  recruiterJobsShortlisted: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_RECRUITER:
      // console.log(payload);
      return {
        ...state,
      };

    case GET_MY_RECRUITER_DATA:
      return {
        ...state,
        myRecruiterData: payload,
      };

    case SEARCH_RECRUITER:
      return {
        ...state,
        recruiters: payload,
      };

    case GET_ALL_RECRUITERS:
      return {
        ...state,
        recruiters: payload,
      };

    case GET_RECRUITER:
      return {
        ...state,
        currentRecruiter: payload,
      };

    case GET_RECRUITER_JOBS:
      return {
        ...state,
        recruiterJobs: payload,
      };

    case GET_RECRUITER_JOB_APPLICANTS:
      return {
        ...state,
        applicants: payload,
      };

    case GET_RECRUITER_JOBS_APPLICANTS:
      return {
        ...state,
        recruiterJobsApplicants: payload,
      };
    case GET_RECRUITER_JOBS_SHORTLISTED:
      return {
        ...state,
        recruiterJobsShortlisted: payload,
      };
    case GET_RECRUITER_INSIGHT:
      return {
        ...state,
        insight: payload,
      };
    case SHORTLIST_APPLICANT:
      return {
        ...state,
        shortlisted: payload,
      };
    case GET_RECRUITER_APPLICANTS_SHORTLISTED:
      return {
        ...state,
        shortlistedApplicants: payload,
      };
    case SEND_SHORTLIST:
      return {
        ...state,
        sentShortlist: payload,
      };
    case RECRUITER_REQUEST_ACCEPTANCE:
      return {
        ...state,
        totalRequestAcceptance: payload,
      };
    case RECRUITER_COMPLETED_JOBS_LEVELS:
      // console.log(payload);
      return {
        ...state,
        completedJobsLevels: payload,
      };
    case RECRUITER_COMPLETED_BY_INDUSTRY:
      // console.log(payload);
      return {
        ...state,
        completedByIndustry: payload,
      };
    case RECRUITER_SHORTLIST_INTERVIEW_RATIO:
      // console.log(payload);
      return {
        ...state,
        shortlistInterviewRatio: payload,
      };
    case GET_RECRUITER_REVIEW:
      // console.log(payload);
      return {
        ...state,
        reviews: payload,
      };
    default:
      return state;
  }
};
