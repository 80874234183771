import React, { useRef, useState ,useEffect} from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import icons from "../../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import ReactStars from "react-rating-stars-component";
import {getAchievementsDataHR,
        updateRecognitionRequestHrAction
      }from "../../../../redux/actions/recognitionAction";
import { SectionLoader } from "../../../../components/PageLoader";

import { useHistory } from "react-router";
import CustomTextArea from "../../../../components/CustomTextArea";
import CustomInput from "../../../../components/CustomInput";
import cogoToast from "cogo-toast";
import { uploadFile } from "../../../../utils/helpers";
import Grid from '@material-ui/core/Grid';
import './achievement.css';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
 

const MyAchievementHr = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [isAdding, setisAdding] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [id, setID] = useState(1);
  const {achievement_data} = useSelector((store) => store);
  const [stateRecognition, setStateRecognition] = useState({request_id:"",status:""});
  const [sectionLoading, setSectionLoading] = useState(true);

  const uploadInputRef = useRef(0);
  const uniqueURL = `${process.env.REACT_APP_WEB_URL}`;
  const title = ``;
  const hashtags = ["workbrook"];
  const handleChangeUpload = ({ target: { files } }) => {
    if (files && files[0]) {
      setIsUploading(true);

      Promise.resolve(
        uploadFile(
          files[0],
          "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
        )
      )
        .then(({ data, success }) => {
          if (success) {
            //addDocLink(data);

           // addDocLinkObject({ link: data, name: files[0].name });
          } else {
            cogoToast.error("Unsuccessful document upload", {
              hideAfter: 4,
              position: "top-center",
            });
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    id === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getAchievementsDataHR(dispatch, id)).finally(() =>
    {
      setTimeout( ()=>{
        setSectionLoading(false);
      }, 1000)
   
    }
   
    );
  }, [dispatch, id]);

 
  const handleClose = () => {
     setAnchorEl(null);
    setOpen(false);
  };

  const approveAchievementPost = async () => {
     
     setLoading(true)
 
     await updateRecognitionRequestHrAction(dispatch, {
      ...stateRecognition,
    });
    //setLoading(false)
   };
 


  const handleRefClick = () => {
    uploadInputRef.current.click();
  };
  return (
     <div additionalClass="mt-4 p-4 arrpx maxh">
     <h5>Achievements Approval</h5>
 
  
               <div
                style={{
                  padding: "10px",
                  Height: "280px",
                  
                 }}
                className="col-12 col-md-12 list-achievements">
         {sectionLoading && <SectionLoader />}
      
     {
      achievement_data &&  achievement_data.achievement_data
      &&
      achievement_data.achievement_data.map( (item,index)=>{
        return(
          item.recognition_status !=="pending"?
          <></>
          :
          <Card
          additionalClass="mx-md-2 px-4 mb-4"
          additionalStyle={{
            height: "100%",
            display: "flex",
         
            flexFlow: "column",
            alignItems: "stretch",
            justifyContent: "space-between",
          }}>

            <Grid container spacing={0}>
              <Grid item xs={1}>
                      <br/>
                    <img src={item.recognised_user.image_url} 
                  data-demo-src={item.recognised_user.image_url} 
                  data-user-popover="1" 
                  className="md-dd img-circle"
                  alt="" 
                  data-target="webuiPopover67" /> 
                    </Grid>
                    <Grid item xs={11}>
                    <br/>

                    <span className="">{item.recognised_user.first_name} {item.recognised_user.last_name} ({item.recognised_user.department})</span><br/>
                    <span className="co-gray">{item.created_at}</span>

                    </Grid>

            </Grid>
              
              <Divider/>

          <br/> 


      <div className="d-flex mb-2 align-items-center justify-content-between">


      <div className="d-flex align-items-center">


      <span className="d-flex flex-column ml-3x">
      <h6 className="mb-0 fontbold">{item.what_content}</h6> 
      <span className="co-gray">{item.how_content}

      <span style={{color:'#fff'}}>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nibh eros, aliquam in faucibus et, vestibulum quis nisl. Morbi sed ligula dignissim, varius ipsum in, mattis arcu</span>
      </span>
      </span>



                    
      </div>


      </div> 


      <ReactStars
        count={5}
        size={14}
        edit={false}
        activeColor="#ffd700"
        value={item.avgRating}
      />

      <br/>
      
      <Divider/>
      <br/>
  
      <p>Recognised By: </p>
   
      <div class="likers-group">
 
      
      <img src={item.nominated_by_user.image_url}
      data-demo-src={item.nominated_by_user.image_url}
      data-user-popover="5" 
      alt="" 
      data-target="webuiPopover20" />

      </div>
      <div class="likers-text">
      <p>
       
      <span className="name-1">{item.nominated_by_user.first_name} {item.nominated_by_user.last_name}</span>
      </p>

      </div>
      
 
      <Grid container spacing={0}>
      <Grid item xs={2}>
      <button className="btn btn-primary"
      onClick={
        ()=>{
         setStateRecognition({ ...stateRecognition, request_id: item.recognition_id, status:"approved" });
         approveAchievementPost();
        }
      }
      >Approve </button>
      </Grid>
      <Grid item xs={2}>           
      <button className="btn btn-danger">Decline</button>
      </Grid>
      </Grid>


      </Card>

     )
   })
   
 
  }
  
<div>
<br/><br/><br/><br/><br/><br/>
</div>
              

      </div>

 
     </div>
 
   );
};

export default MyAchievementHr;
