import React, { useEffect,useRef,useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import icons from "../../../../assets/icons/icon-collection.svg";
import { useHistory } from "react-router";
import CustomInput from "../../../../components/CustomInput";
import ReactStars from "react-rating-stars-component";
import CustomTextArea from "../../../../components/CustomTextArea";
import cogoToast from "cogo-toast";
import { uploadFile } from "../../../../utils/helpers";
import {
  createExpertCategoryAction
} from "../../../../redux/actions/expertCategoryAction";

import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import TextField from '@material-ui/core/TextField';
 import Autocomplete from '@material-ui/lab/Autocomplete';
 import { makeStyles } from '@material-ui/core/styles';
import './style.css';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

 

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
       fontSize: 18,
    },
  },
});
 

const Create = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [uploadedIcon, setUploadedIcon] = useState();
  const classes = useStyles();
  const uploadInputRef = useRef(0);
  const [loading, setLoading] = useState(false);

  const [stateRecognition, setStateRecognition] = useState({
    title:"",
    desc: "",
    icon: ""
   });


  const handleRefClick = () => {
    uploadInputRef.current.click();
  };


const handleChangeUpload = ({ target: { files } }) => {
  if (files && files[0]) {
    setIsUploading(true);

    Promise.resolve(
      uploadFile(
        files[0],
        "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
      )
    )
      .then(({ data, success }) => {
        if (success) {
          //addDocLink(data);
          setUploadedIcon(data);
          setStateRecognition({ ...stateRecognition, icon: data });

         // addDocLinkObject({ link: data, name: files[0].name });
        } else {
          cogoToast.error("Unsuccessful document upload", {
            hideAfter: 4,
            position: "top-center",
          });
        }
      })
      .finally(() => {
        setIsUploading(false);
      });
  }
};

const handleChange = ({ target: { id, value, checked } }) => {
  setStateRecognition((prevState) => ({ ...prevState, [id]: value }));
};


const handleSubmit = async (e) => {
     
  setLoading(true)
  e.preventDefault();
  
  await createExpertCategoryAction(dispatch, {
    ...stateRecognition,
  });
  //setLoading(false)
 };

 

 
  return (
    <Card additionalClass="mt-4 p-4  maxh">
      
      <h5>Add Expert's Category</h5>

      <div
                
                style={{
                  padding: "10px",
                  maxHeight: "280px",
                }}
                className="col-12 col-md-12 list-achievements">

          <form onSubmit={handleSubmit}> 

                
                <CustomInput
                  type="text"
                  name="title"
                  placeholder=""
                  label="Title"
                  id="title"
                  value={stateRecognition.title}
                  onChange={handleChange}

                />

                <CustomTextArea
                  type="text"
                  name="desc"
                  placeholder=""
                  label="Description"
                  id="desc"

                  value={stateRecognition.desc}
                  onChange={handleChange}
                />

                 
                   <div>
                    <br/>
                    
                      <input
                        ref={uploadInputRef}
                        type="file"
                        onChange={handleChangeUpload}
                        style={{ display: "none" }}
                      />

                      <CustomButton
                        backgroundColor="#fff"
                        color="#000"
                      
                        text="Upload Category Icon"
                        handleClick={handleRefClick}
                        loading={isUploading}
                      />
                    </div>
                    <br/>
                    
 
                    <img src={uploadedIcon}
                    className="image-preview"
                    />
                    
                    <div>
                      <br/><br/>

                    <CustomButton
                      backgroundColor="#3A6ED4"
                      color="#fff"
                         type="submit"
                      text="ADD"
                      loading={isUploading}
                    />

                  
                    </div>
 
            </form> 
    </div>



    </Card>

  );
};


export default Create;
