import httpRequest from "../../ajax";
import axios from "axios";
// import cogoToast from "cogo-toast";
import qs from "qs";
import {
  // LOGIN,
  ERROR,
  GET_BANKS,
  CREATE_BANK_ACCOUNT,
  GET_ACCOUNT_BALANCE,
  GET_BANK_ACCOUNT,
  GET_MY_ACCOUNTS,
  RESET_MY_ACCOUNTS,
  GET_TRANSACTIONS,
  // CARD_TOKEN_SUCCESS,
  CARD_CREATE_SUCCESS,
  GET_CARDS,
  RESET_CARDS,
  DELETE_BANK_ACCOUNT,
  SET_DEFAULT_CARD,
  FUND_WALLET,
  WITHDRAW_FROM_WALLET,
  TRANSFER_FROM_AVAILABLE,
} from "../actionTypes";

export const getBanksList = (dispatch) => {
  httpRequest("get", "/wallet/withrawal/banks")
    .then(({ data: { data } }) => {
      const action = { type: GET_BANKS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const resetCards = (dispatch, page) => {
  const action = { type: RESET_CARDS, payload: [] };
  dispatch(action);
  return action;
};

// export const getAccountName = (dispatch, accountNo, bankCode) => {
//   httpRequest("get", "/wallet/withrawal/banks")
//     .then(({ data: { data } }) => {
//       const action = { type: GET_BANKS, payload: data };
//       dispatch(action);
//       return action;
//     })
//     .catch(({ response }) => ({
//       payload: response?.data?.message,
//       type: ERROR,
//     }));
// };

export const createBankAccount = (dispatch, accountDetails) => {
  httpRequest("post", "/wallet/account/create", accountDetails)
    .then(({ data: { data } }) => {
      const action = { type: CREATE_BANK_ACCOUNT, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const getBankAccount = (dispatch, accountDetails) => {
 return  httpRequest("get", "/wallet/account/get", accountDetails)
    .then(({ data: { data } }) => {
      const action = { type: GET_BANK_ACCOUNT, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const getAccountBalance = (dispatch) => {
  httpRequest("get", "/wallet/balance")
    .then(({ data: { data } }) => {
      const action = { type: GET_ACCOUNT_BALANCE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const getMyAccounts = (dispatch, page, limit) => {
  httpRequest("get", `/wallet/account/${page}/${limit}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_MY_ACCOUNTS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const resetMyAccounts = (dispatch) => {
  const action = { type: RESET_MY_ACCOUNTS, payload: [] };
  dispatch(action);
  return action;
};

export const getTransactions = (dispatch, page) => {
  httpRequest("get", `/wallet/payment/${page}/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_TRANSACTIONS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.message,
      type: ERROR,
    }));
};

export const createCardToken = (dispatch, data) => {
  try {
    const url = "https://api.stripe.com/v1/tokens";
    axios.defaults.headers.common.Authorization = `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`;
    axios({
      method: "post",
      url: url,
      data: qs.stringify(data),
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
      .then(({ data }) => {
        const action = { type: "CARD_TOKEN_SUCCESS", payload: data };
        createCard(dispatch, data.id);
        dispatch(action);
        return data;
      })
      .catch(({ response }) => {
        // console.log(
        //   "Error in creating token: ",
        //   response?.data?.error?.message
        // );
        dispatch({
          payload: response?.data?.error?.message,
          type: ERROR,
        });
      });
  } catch (err) {
    // console.log("error", err);
  }
};

export const createCard = (dispatch, token) => {
  const tokenData = {
    stripeToken: token,
  };
  httpRequest("post", "/wallet/card/create", tokenData)
    .then(({ data: { data } }) => {
      const action = { type: CARD_CREATE_SUCCESS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const getCards = (dispatch, page) => {
  httpRequest("get", `/wallet/card/1/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_CARDS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.message,
      type: ERROR,
    }));
};

export const deleteBankAccount = async (dispatch, id) => {
  return await httpRequest("post", `/wallet/account/${id}/delete`, {})
    .then(({ data: { data } }) => {
      const action = { type: DELETE_BANK_ACCOUNT, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const setDefaultCard = (dispatch, cardId) => {
  httpRequest("post", `/wallet/card/setDefault/${cardId}`)
    .then(({ data: { data } }) => {
      const action = { type: SET_DEFAULT_CARD, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const fundWallet = (dispatch, fundData) => {
  httpRequest("post", `/wallet/payment/fundWallet`, fundData)
    .then(({ data: { data } }) => {
      const action = { type: FUND_WALLET, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const fundWalletWithPaystack = (dispatch, fundData) => {
  httpRequest("post", `/wallet/payment/fundWalletWithPaystack`, fundData)
    .then(({ data: { data } }) => {
      const action = { type: FUND_WALLET, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const fundWalletWithGPAY = (dispatch, fundData) => {
  httpRequest("post", `/wallet/payment/fundWalletWithGPAY`, fundData)
    .then(({ data: { data } }) => {
      const action = { type: FUND_WALLET, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const addCardPaystack = (dispatch, reference) => {
  httpRequest(
    "post",
    `/wallet/payment/verifyTransaction/${reference.reference}`,
    reference
  )
    .then(({ data: { data } }) => {
      const action = { type: CARD_CREATE_SUCCESS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const transferFromAvailable = async (dispatch, payload) => {
  return await httpRequest("post", `/wallet/available/transfer`, payload)
    .then(({ data: { data } }) => {
      const action = { type: TRANSFER_FROM_AVAILABLE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });
};

export const withdrawFromWallet = async (dispatch, payload) => {
  return await httpRequest("post", `/wallet/withrawal/withdraw`, payload)
    .then(({ data: { data } }) => {
      const action = { type: WITHDRAW_FROM_WALLET, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });
};
