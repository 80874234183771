import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { bool, object as objectPropType } from "prop-types";
import EditCard from "../EditCard";
import CustomTextArea from "../../../../../components/CustomTextArea";
import Counter from "../../../../../components/Counter";
import {
  EDIT_PROFILE,
  PROFILE_LOADING,
  STOP_PROFILE_LOADING,
} from "../../../../../redux/actionTypes";
import { updateProfileAction } from "../../../../../redux/actions/profileActions";

const About = ({ profileData, profileLoading }) => {
  const MAX_WORDS = 200;

  const [text, setText] = useState(profileData.about || "");
  const [count, setCount] = useState(MAX_WORDS - (text.trim().split(/\s+/).length || 0));

  const dispatch = useDispatch();

  const handleChange = ({ target: { id, value } }) => {
    const words = value.trim().split(/\s+/);
    const wordCount = words.length;

    if (wordCount >= MAX_WORDS && value.endsWith(" ")) {
      return; 
    }

  
    if (wordCount <= MAX_WORDS) {
      setText(value);
      setCount(MAX_WORDS - wordCount);
      dispatch({ type: EDIT_PROFILE, payload: { name: id, value } });
    }
  };

  useEffect(() => {
    
    const aboutText = Array.isArray(profileData.about) ? profileData.about.join(" ") : profileData.about || "";
    setText(aboutText);
    setCount(MAX_WORDS - (aboutText.trim().split(/\s+/).length || 0));
  }, [profileData.about]);
const handleSubmit = async () => {
  const payload = {
    first_name: profileData.first_name,
    last_name: profileData.last_name,
    gender: profileData.gender,
    dob: profileData.dob,
    state: profileData.state,
    country: profileData.country,
    industry: profileData.industry,
    image_url: profileData.image_url,
    resume: profileData.resume,
    phone: profileData.phone,
    links: profileData.links,
    isShowOnProfile: 1,
    about: text, 
  };

  try {
   
    dispatch({ type: PROFILE_LOADING });

    
    await updateProfileAction(payload, dispatch);

  } catch (error) {
   
    console.error("Error updating profile:", error);
   
  } finally {
   
    dispatch({ type: STOP_PROFILE_LOADING });
  }
};


  return (
    <EditCard
      handleSubmit={handleSubmit}
      allRequired
      btnText="Update"
      fields
      loading={profileLoading}
    >
      <div className="w-100 position-relative">
        <Counter count={count} MAX_WORDS={MAX_WORDS} />
        <CustomTextArea
          classes="pt-4 pr-5"
          value={text}
          onChange={handleChange}
          rows="15"
          id="about"
          placeholder="About you (200 words)"
          maxLength={MAX_WORDS * 6}
        />
      </div>
    </EditCard>
  );
};

About.propTypes = {
  profileData: objectPropType.isRequired,
  profileLoading: bool.isRequired,
};

export default About;
