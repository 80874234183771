/* eslint-disable indent */
import {
    CREATE_FEED_POST,
    GET_FEEDS_RESULTS
  } from "../actionTypes";  

  const initialState = {
 
   };
 
export default (state = initialState, { type, payload }) => 
{
    switch (type) {
      
         case CREATE_FEED_POST: {
          return {
            ...state,
            feedsCreated: true,
          };
        };
        case GET_FEEDS_RESULTS:
          return {
            get_feed_result : payload,
          };

      default:
         return state;
    }
    
  };

   