import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton";
import { IoPersonSharp } from "react-icons/io5";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ButtonLoader } from "../../../../components/ButtonLoader";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { Toast, toast } from "react-toastify";
import CustomTextArea from "../../../../components/CustomTextArea";
import { BsPeople, BsGraphUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import {
  getEmployee,
  getPerformance,
  postperformances,
  postsendback,
} from "../../../../redux/actions/jobActions";
import cogoToast from "cogo-toast";

function SupervisorReward() {
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [activeTab, setActiveTab] = useState("Leadership");
  const [checkedall, setChecked] = useState(false);
   const { userData } = useSelector((store) => store.auth);
  const { id } = userData;
  const [checkedPlan, setCheckedPlan] = useState(false);
  const [employees, setEmployees] = useState([]);
   const[employeeDisplayApprasial, setemployeeDisplayApprasial] = useState([])
  const [goalSubmit, setGoalSubmit] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);

  const [employeeAppraisal, setEmployeeAppraisal] = useState([]);
  const dispatch = useDispatch();
  const [sendEmployee, setSendEmployee] = useState({
    employee_id: "",
    employee_name: "",
    employee_goals: [],
  });
  const [Succession, setSuccession] = useState({
    Leadership: "",
  });
  const [Successionn, setSuccessionn] = useState({
    Performance: "",
  });
  const [Successions, setSuccessions] = useState({
    Development: "",
  });
  const [leadershipImprove, setLeadershipImprove] = useState({
    Leadership: "",
  });
  const [performanceImprove, setPerformanceImprove] = useState({
    Performance: "",
  });
  const [developmentImprove, setDevelopmentImprove] = useState({
    Development: "",
  });
   useEffect(() => {
     
    console.log(id)
    const getAllSubordinates =async () => {
      try {
        const response = await getEmployee(dispatch, id)
        console.log(response.payload)
        setEmployees(response.payload.data)
      } catch (error) {
        console.log(error)
      }
    }
    getAllSubordinates()
  }, [id]);

 const handleEmployeeChange = (e) => {
    const value = e.target.value;
    const employee = employees.find((all) => all.username === value);
    if (employee) {
      setSelectedEmployee(employee);
      // setSelectedEmployees({ id: employee.id, username: employee.username });
      setSendEmployee((prevSendEmployee) => ({
        ...prevSendEmployee,
        employee_id: employee.id,
        employee_name: employee.username,
      }));
      setTimeout(() => {
        getPerformance(dispatch, employee.id)
          .then((action) => {
            console.log(action.payload.data);

            const performanceData = JSON.parse(
              action.payload.data.employee_goals
            );

            const employeeAppraisal = JSON.parse(
              action.payload.data.employee_appraisal
            );
            console.log(employeeAppraisal);
            console.log(employeeAppraisal.type);

            setEmployeeAppraisal(employeeAppraisal);
            setPerformanceData(performanceData);
            console.log(performanceData.Leadership);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000);
    }
  };
  const handleSubmit = () => {
    
    const formDataa = {
      Leadership:[(performanceData.Leadership|| []).map((goal) => {
        const goalKey = Object.keys(goal)[0];
         return {
          
          ...goal[goalKey], 
         Succession: Succession.Leadership,
        Improve_Performance: leadershipImprove.Leadership,
        };
       
      }),
    ],
      Performance:[ (performanceData.Performance || []).map((goal) => {
        const goalKey = Object.keys(goal)[0];
         return {
          
          ...goal[goalKey], 
         Succession: Successionn.Performance,
        Improve_Performance: performanceImprove.Performance,
        };
       
      }),
    ],

      Development: [(performanceData.Development || []).map((goal) => {
        const goalKey = Object.keys(goal)[0];
         return {
          
          ...goal[goalKey],
         Succession: Successions.Development,
        Improve_Performance: developmentImprove.Development,
        };
        
      }),
    ],
       

    };
  

    localStorage.setItem("formDataa", JSON.stringify(formDataa));
     cogoToast.success("Saved successfully ")
   

    console.log(formDataa);
  };
    const handleSubmitt = () => {
    
    const formDataa = {
      Leadership:[(performanceData.Leadership|| []).map((goal) => {
        const goalKey = Object.keys(goal)[0];
         return {
          
          ...goal[goalKey], 
         Succession: Succession.Leadership,
        Improve_Performance: leadershipImprove.Leadership,
        };
       
      }),
    ],
      Performance:[ (performanceData.Performance || []).map((goal) => {
        const goalKey = Object.keys(goal)[0];
         return {
          
          ...goal[goalKey], 
         Succession: Successionn.Performance,
        Improve_Performance: performanceImprove.Performance,
        };
       
      }),
    ],

      Development: [(performanceData.Development || []).map((goal) => {
        const goalKey = Object.keys(goal)[0];
         return {
          
          ...goal[goalKey],
         Succession: Successions.Development,
        Improve_Performance: developmentImprove.Development,
        };
        
      }),
    ],
       

    };
  
    setGoalSubmit(true);
      localStorage.setItem("formDataa", JSON.stringify(formDataa));
      cogoToast.success("Saved successfully ")
      setGoalSubmit(true)
    // toast.success("Saved Successfully");

    console.log(formDataa);
  };
  
  const handleSendBack = () => {
    const sendData = {
      employee_id: sendEmployee.employee_id,
      // employee_name: sendEmployee.employee_name,
    };

    console.log(sendData);

    postsendback(dispatch, sendData);
  };

  const handleAll = () => {
    const superv = JSON.parse(localStorage.getItem("formDataa")) || {};
    const appraisalData = JSON.parse(localStorage.getItem("appraisalData"));
    console.log(appraisalData)
    const sendData = {
      employee_id: sendEmployee.employee_id,
      employee_name: sendEmployee.employee_name,
     employee_appraisal: JSON.stringify(appraisalData),

      employee_goals: JSON.stringify(superv),
    };
    console.log(superv);
    console.log(sendData);

    postperformances(dispatch, sendData);
  };
 const handleChange = (event, editor) => {
  const data = editor.getData(); 
  setSuccession((prevSuccession) => ({
    ...prevSuccession,
    Leadership: data, 
  }));
};
  const handleChanges = (event, editor) => {
    const data = editor.getData(); 
  setSuccessions((prevSuccessions) => ({
    ...prevSuccessions,
    Development: data,
  }));
  };
  const handleChangee = (event, editor) => {
    const data = editor.getData(); 
  setSuccessionn((prevSuccessionn) => ({
    ...prevSuccessionn,
    Performance: data, 
  }));
  };
  const handleChangesImprove = (event, editor) => {
     const data = editor.getData(); 
  setDevelopmentImprove((prevDevelopmentImprove) => ({
    ...prevDevelopmentImprove,
    Development: data, 
  }));
  };
 const handleChangeImprove = (event, editor) => {
  const data = editor.getData();
  setLeadershipImprove((prevLeadershipImprove) => ({
    ...prevLeadershipImprove,
    Leadership: data,
  }));
};
  const handleChangeeImprove = (event, editor) => {
    const data = editor.getData(); 
  setPerformanceImprove((prevPerformanceImprove) => ({
    ...prevPerformanceImprove,
    Performance: data, 
  }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="card-height" additionalClass="mt-4 p-4">
      <h5 style={{ fontWeight: "bold" }} className="co-grayx">
        Supervisor Reward of Performance
      </h5>

      <div
        style={{ padding: "10px", maxHeight: "680px" }}
        className="col-12 col-md-12 list-achievements"
      >
         <div className="select-container mt-3">
          <strong>Select Employee to view</strong>
          <select
            style={{ border: "1px solid #e8e9eb" }}
            onChange={handleEmployeeChange}
          >
            <option value="">Select an Option</option>
            {Array.isArray(employees) && employees.length > 0 && (
               employees.map((employee) => (
                <option key={employee.id} value={employee.username}>
                  {employee.username}
                </option>
               ))
            )}
            
             
          </select>
        </div>

        <br />

        {selectedEmployee && (
          <>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Leadership" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Leadership")}
                  style={{
                    backgroundColor:
                      activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                  }}
                >
                  <BsPeople className="mb-1" /> Leadership
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Performance" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Performance")}
                  style={{
                    backgroundColor:
                      activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <BsGraphUp className="mb-1" /> Performance
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Development" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Development")}
                  style={{
                    backgroundColor:
                      activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <IoPersonSharp className="mb-1" /> Development
                </button>
              </li>
            </ul>
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  activeTab === "Leadership" ? "show active" : ""
                }`}
                id="leadership-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData &&  (
                  <div>
                    {Array.isArray(performanceData.Leadership) &&performanceData.Leadership.flat().map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Leadership
                          Goals/Objectives
                        </strong>
                       <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Leadership")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                    </div>
                  ))}
                <>
                  <br />
                  <br />
                  {!performanceData?.Leadership?.some(arr => arr.some(item => item.Succession || item.Improve_Performance)) && (
                     <div>
                    <strong>
                      Would you like to improve or reward Performance?
                    </strong>
                    <br />
                    <br />

                    <div>
                      <input
                        onChange={() => {
                          setChecked(true);
                          setCheckedPlan(false);
                        }}
                        style={{ marginTop: "3px" }}
                        type="checkbox"
                        checked={checkedall}
                      />
                      <strong>Improve Performance</strong>
                    </div>
                    <br />

                    <div>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setCheckedPlan(true);
                          setChecked(false);
                        }}
                        checked={checkedPlan}
                      />
                      <strong>Reward Performance</strong>
                    </div>
                  </div>
                  )}
                 
                  <br />

                  {checkedall&&  (
                    <div>
                      <strong className="mt-5">
                        Performance Plan for {sendEmployee.employee_name}'s
                        Goals
                      </strong>
                     <CKEditor
  editor={ClassicEditor}
  data={leadershipImprove.Leadership || ''} 
  onChange={(event, editor) => handleChangeImprove(event, editor)} // Pass editor to handleChangeImprove
/>
                    </div>
                  )}
                  <br />
                  {performanceData && performanceData.Leadership && performanceData.Leadership.map((performanceItemArray, index) => (
  performanceItemArray.map((item, subIndex) => (
    (item.Improve_Performance || item.Succession) && ( 
      <div key={`${index}-${subIndex}`}>
        {item.Improve_Performance && ( 
          <>
            <strong>Supervisor Comment for {item.goal_title}</strong>
            <CKEditor
              editor={ClassicEditor}
              config={{}}
              data={item.Improve_Performance || ""} 
              onReady={(editor) => {
               
              }}
             
            />
          </>
        )}
       
      </div>
    )
  ))
))}



                  {checkedPlan && (
                    <div>
                      <strong className="mt-5">
                        Succession Plan for {sendEmployee.employee_name}'s Goals
                      </strong>
                     <CKEditor
  editor={ClassicEditor}
  data={Succession.Leadership || ''} // Load existing data or set to empty
  onChange={(event, editor) => handleChange(event, editor)}
/>
                    </div>
                  )}
                  {performanceData && performanceData.Leadership && performanceData.Leadership.map((performanceItemArray, index) => (
  performanceItemArray.map((item, subIndex) => (
    (item.Improve_Performance || item.Succession) && ( 
      <div key={`${index}-${subIndex}`}>
        {item.Succession && ( 
          <>
            <strong>Supervisor Comment for {item.goal_title}</strong>
            <CKEditor
              editor={ClassicEditor}
              config={{}}
              data={item.Succession|| ""} 
              onReady={(editor) => {
               
              }}
              onChange={(event, editor) => {
                const updatedContent = editor.getData();
                handleChange(updatedContent, index, subIndex);
              }}
            />
          </>
        )}
        
      </div>
    )
  ))
))}


                  <br />
                  <br />
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "red",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSendBack}
                  >
                    SendBack
                  </button>
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    disabled={
                      !leadershipImprove.Leadership && !Succession.Leadership
                    }
                    text="Submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Performance" ? "show active" : ""
                }`}
                id="performance-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData &&  (
                  <div>
                    {Array.isArray(performanceData.Performance) &&performanceData.Performance.flat().map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Perfomance
                          Goals/Objectives
                        </strong>
                        <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Performance")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                       <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                    </div>
                  ))}
                <>
                  <br />
                  <br />
                  {!performanceData?.Performance?.some(arr => arr.some(item => item.Succession || item.Improve_Performance)) && (
                    <div>
                      <strong>
                        Would you like to improve or reward Performance?
                      </strong>
                      <br />
                      <br />

                      <div>
                        <input
                          onChange={() => {
                            setChecked(true);
                            setCheckedPlan(false);
                          }}
                          style={{ marginTop: "3px" }}
                          type="checkbox"
                          checked={checkedall}
                        />
                        <strong>Improve Performance</strong>
                      </div>
                      <br />

                      <div>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setCheckedPlan(true);
                            setChecked(false);
                          }}
                          checked={checkedPlan}
                        />
                        <strong>Reward Performance</strong>
                      </div>
                    </div>
                  )}
                  <br />
                  <br />
                  {checkedall &&(
                    <div>
                      <strong className="mt-5">
                        Performance Plan for {sendEmployee.employee_name}'s
                        Goals
                      </strong>
                      <CKEditor
  editor={ClassicEditor}
  data={performanceImprove.Performance || ''} 
  onChange={(event, editor) => handleChangeeImprove(event, editor)} 
/>
                      
                    </div>
                  )}
                  <br />
                  {performanceData && performanceData.Performance && performanceData.Performance.map((performanceItemArray, index) => (
                    performanceItemArray.map((item, subIndex) => (
        (item.Improve_Performance || item.Succession) && (
                        <div key={`${index}-${subIndex}`}>
                          {item.Improve_Performance && ( // Render only if Improve_Performance has data
          <>
          <strong>Supervisor Comment for {item.goal_title}</strong>
          <CKEditor
            editor={ClassicEditor}
            config={{}}
            data={item.Improve_Performance|| ""} 
            onReady={(editor) => {
            
            }}
            
           />
                </>
                          )}
          
                        </div>
        )
      ))
    ))}
                  <br />

                  {checkedPlan &&(
                    <div>
                      <strong className="mt-5">
                        Succession Plan for {sendEmployee.employee_name}'s Goals
                      </strong>
                        <CKEditor
  editor={ClassicEditor}
  data={Successionn.Performance || ''} // Load existing data or set to empty
  onChange={(event, editor) => handleChangee(event, editor)}
/>
                      
                    </div>
                  )}

                  <br />
                   {performanceData && performanceData.Performance && performanceData.Performance.map((performanceItemArray, index) => (
                     performanceItemArray.map((item, subIndex) => (
          (item.Improve_Performance || item.Succession) && ( 
        <div key={`${index}-${subIndex}`}>
        {item.Succession && ( 
          <>
          <strong>Supervisor Comment for {item.goal_title}</strong>
          <CKEditor
            editor={ClassicEditor}
            config={{}}
            data={item.Succession || ""} // Set initial data to `Succession` or empty string
            onReady={(editor) => {
              // Editor is ready
            }}
            
            />
          </>
          )}
          
         
        </div>
      )
      ))
    ))}

                  <br />
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "red",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSendBack}
                  >
                    SendBack
                  </button>
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                    disabled={
                      !performanceImprove.Performance &&
                      !Successionn.Performance
                    }
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Development" ? "show active" : ""
                }`}
                id="development-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData &&  (
                  <div>
                    {Array.isArray(performanceData.Development) &&performanceData.Development.flat().map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Development
                          Goals/Objectives
                        </strong>
                      <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={goal.goal_content}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Development")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                    </div>
                  ))}
                <>
                  
                  <br />
                  {!performanceData?.Development?.some(arr => arr.some(item => item.Succession || item.Improve_Performance)) && (
                    <div>
                      <strong>
                        Would you like to improve or reward Performance?
                      </strong>
                      <br />
                      <br />

                      <div>
                        <input
                          onChange={() => {
                            setChecked(true);
                            setCheckedPlan(false);
                          }}
                          style={{ marginTop: "3px" }}
                          type="checkbox"
                          checked={checkedall}
                        />
                        <strong>Improve Performance</strong>
                      </div>
                      <br />

                      <div>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setCheckedPlan(true);
                            setChecked(false);
                          }}
                          checked={checkedPlan}
                        />
                        <strong>Reward Performance</strong>
                      </div>
                    </div>
                  )}
                  
                  
                  {checkedall && (
                    <div>
                      <strong className="mt-5">
                        Performance Plan for {sendEmployee.employee_name}'s
                        Goals
                      </strong>
                      <CKEditor
  editor={ClassicEditor}
  data={developmentImprove.Development || ''} 
  onChange={(event, editor) => handleChangesImprove(event, editor)} 
/>
                  
                      
                     
                    </div>
                  )}
                  <br />
                   {performanceData && performanceData.Development && performanceData.Development.map((performanceItemArray, index) => (
                    performanceItemArray.map((item, subIndex) => (
        (item.Improve_Performance || item.Succession) && (
                        <div key={`${index}-${subIndex}`}>
                          {item.Improve_Performance && ( 
          <>
          <strong>Supervisor Comment for {item.goal_title}</strong>
          <CKEditor
            editor={ClassicEditor}
            config={{}}
            data={item.Improve_Performance|| ""} 
            onReady={(editor) => {
            
            }}
            
           />
                </>
                          )}
         
                        </div>
        )
      ))
    ))}
                  <br />

                  {checkedPlan && (
                    <div>
                      <strong className="mt-5">
                        Succession Plan for {sendEmployee.employee_name}'s Goals
                      </strong>
                       <CKEditor
  editor={ClassicEditor}
  data={Successions.Development || ''} 
  onChange={(event, editor) => handleChanges(event, editor)}
/>
                     
                    </div>
                  )}

                  <br />
                   {performanceData && performanceData.Development && performanceData.Development.map((performanceItemArray, index) => (
                     performanceItemArray.map((item, subIndex) => (
          (item.Improve_Performance || item.Succession) && ( 
        <div key={`${index}-${subIndex}`}>
        {item.Succession && ( 
          <>
          <strong>Supervisor Comment for {item.goal_title}</strong>
          <CKEditor
            editor={ClassicEditor}
            config={{}}
            data={item.Succession || ""} 
            onReady={(editor) => {
              // Editor is ready
            }}
            
            />
          </>
          )}
          
        
        </div>
      )
      ))
    ))}

                  <br />
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "red",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSendBack}
                  >
                    SendBack
                  </button>
                  {!goalSubmit ? (
                    <button
                      className="rounded-3 border border-none"
                      style={{
                        width: "10%",
                        color: "#fff",
                        height: "6vh",
                        borderRadius: "5px",
                        backgroundColor: "#3A6ED4",
                      }}
                      color="#fff"
                      text="Submit"
                      disabled={
                        !developmentImprove.Development &&
                        !Successions.Development
                      }
                      onClick={handleSubmitt}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="rounded-3 border border-none"
                      style={{
                        width: "10%",
                        color: "#fff",
                        height: "6vh",
                        borderRadius: "5px",
                        backgroundColor: "#3A6ED4",
                      }}
                      color="#fff"
                      text="Submit"
                      onClick={handleAll}
                    >
                      Submit
                    </button>
                  )}
                </>
              </div>
            </div>
          </>
        )}

        <br />
        <br />
      </div>
    </div>
  );
}

export default SupervisorReward;
