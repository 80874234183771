import { node } from "prop-types";
import React, {useEffect, useState} from "react";
import WorkImage from "../../../../assets/images/workbrook-bg-new.png";
import WBtext from "../../../../assets/icons/WBtext.svg";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";

import { getJobListAction } from "../../../../redux/actions/jobActions";


const AuthWrapper = ({ children }) => {

  const dispatch = useDispatch();
  const {jobList} = useSelector((store) => store);

  const [sectionLoading, setSectionLoading] = useState(true);

  useEffect(() => {
    Promise.resolve(getJobListAction(dispatch)).finally(() => 
    {
      setTimeout( ()=>{
        setSectionLoading(false);
      }, 1000)
    }
    
    );
  }, [dispatch, 1]);

  return (
    <div
      className="auth signin"
      style={{
        background: "#3A6ED4",
      }}
    >
     {/* <br/><br/>
        <a href="job-lists" className="job-board-btn custom-button" >Go to Job Board</a>
      */}
      <div className="wrapper  d-flex align-items-center">
        <div className="d-none d-md-flex flex-column justify-content-center w-50 p-3">
          <img
            style={{ width: "60%", display: "block", margin: "0 auto" }}
            src={WorkImage}
            alt="logo"
          />

          <img
            style={{ width: "100%", display: "block", margin: "0 auto" }}
            src={WBtext}
            alt="logo"
          />
          <h4
            className="co-white text-center mt-2"
            style={{
              fontSize: "40px",
            }}
          >
            Market Place
          </h4>
         
        </div>
        {children}
      </div>

{/* Job listing starts */}
      <div  style={{
        background: "#fff",
        padding:"10px",
      }}>

    {/* <div 
    
    className="grid"
    style={{
            background: "#fff",
            padding:"10px",
            width:"50%"

          }}>
      {   jobList.jobList && jobList.jobList[0] && jobList.jobList[0].map( (item, index)=>{
                   return(
                    <div className="card   mb-2x mgtio" key={index}>
                        
                    <div className="card-body p-2 p-sm-3">
                        <div className="media forum-item">
                       

                            <div className="media-body">
                                <h6><a href={`${process.env.REACT_APP_WEB_URL}/job-lists-details/${item.id}/apply/no-ref`} target="_blank" data-toggle="collapse" data-target=".forum-content" className="text-body bolded links">{item.role_title}</a></h6>
                              <p className="text-muted"> 
                              <i className="fa fa-building"></i> {item.campany_name} &nbsp;
                              <i className="fa fa-certificate"></i>  {item.industry} &nbsp;
                              <i className="fa fa-calendar"></i>  {item.job_type} 
                              </p> 
                                <p className="text-secondary">
                                     <span dangerouslySetInnerHTML={{ __html: item.about_company.substring(0, 600)+" ..." }} className="inline-block"></span>
                                     <a href={`${process.env.REACT_APP_WEB_URL}/job-lists-details/${item.id}/apply/no-ref`} target="_blank"  className="inline-block links">Apply Now</a>
                                </p>
                                <p className="text-muted"><a href="javascript:void(0)"></a> {item.location} - <span className="text-secondary font-weight-bold">Date Posted: {item.date_created}</span></p>

                            <ul className="d-flex flex-wrap mt-3">
                
                                {
                              JSON.parse(item.skills_required).map( (skill,index)=>{
                                    return(
                                        <li
                                        key={index.toString()}
                                        className="mr-2 py-1 px-2 mt-1 outlined"
                                        >
                                        {skill}
                                        </li>
                                    )
                                })
                             }
                                 
                            
                            </ul>

                            </div>
                            <div className="text-muted small text-center align-self-center">
                            
                         
                            </div>
                        </div>
                    </div>
                    </div>
                   )
                         
               })  
                       
               }
</div> */}
        
      </div>
 {/* Job listing ends*/}
 
 
    </div>
  );
};

AuthWrapper.propTypes = {
  children: node.isRequired,
};

export default AuthWrapper;
