import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  HomeIcon,
  InsightsIcon,
  SavedIcon,
  LeagueIcon,
  InviteIcon,
  SettingsIcon,
  WBIcon,
  SubIcon,
} from "../../../components/CustomIcons";
import WBtext from "../../../assets/icons/WBtext.svg";
import WBlogo from "../../../assets/icons/WBlogo.svg";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { bool, string } from "prop-types";
import { NavLink } from "react-router-dom";

import iconExpertsImage from "./icon-experts.png";
import iconOrg from "./icon-org.png"
import iconRecognitionImage from "./icon-recognition.png";
import iconPerformanceManagement from "./icon-Performance.png";

import iconNominationImage from "./icon-nomination.png";
import iconThankYouImage from "./icons-thankyou-card.png";
import "./menu-style.css";

const Menu = ({ toolbar, open }) => {
  const dispatch = useDispatch();
  const [canPost, setCanPost] = useState(true);

  const {
    userData: { user_type },
  } = useSelector((store) => store.auth);

  // console.log("account_type", account_type);
  // console.log("company_id", company ? company.id : "NULL");

  // const company_id = company ? company.id : 0;

  useEffect(() => {
    if (user_type === "1") {
      setCanPost(true);
    } else {
      setCanPost(false);
    }
  }, [dispatch]);

  return (
   <div className="menu">
      <div className={toolbar}>
        <img className="w-75 pl-2" src={open ? WBtext : WBlogo} alt="logo" />
      </div>
      <List>
        <NavLink to="/home">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <HomeIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </NavLink>
        {!canPost && (
          <NavLink to="/referrals">
            <ListItem button>
              <ListItemIcon className="ml-1">
                <InsightsIcon viewBox="0 0 20 17" />
              </ListItemIcon>
              <ListItemText primary="My Referrals" />
            </ListItem>
          </NavLink>
        )}
        {canPost && (
          <NavLink to="/admin">
            <ListItem button>
              <ListItemIcon className="ml-1">
                <WBIcon viewBox="0 0 20 17" />
              </ListItemIcon>
              <ListItemText primary="Admin (Recruiters)" />
            </ListItem>
          </NavLink>
        )}
        
        <NavLink to="/saved">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <SavedIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Saved" />
          </ListItem>
        </NavLink>
        <NavLink to="/league">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <LeagueIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Community Feeds" />
          </ListItem>
        </NavLink>
        <NavLink to="/recognition/my_achievement">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <img src={iconRecognitionImage} className="img-icon-sidebar" />
            </ListItemIcon>
            <ListItemText primary="Recognition" />
          </ListItem>
        </NavLink>
        <NavLink to="/invite">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <InviteIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Invite a colleague" />
          </ListItem>
        </NavLink>
         {!canPost && (
          <NavLink to= "/employee_performance_management/addperformance">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <img src={iconPerformanceManagement} className="img-icon-sidebar" />
            </ListItemIcon>
            <ListItemText primary="Employee Performance Management" />
          </ListItem>
        </NavLink>
        )}
         
         {canPost && (
          <NavLink to="/organization/assignroles">
            <ListItem button>
              <ListItemIcon className="ml-1">
               <img src={iconOrg} className="img-icon-sidebar" />
              </ListItemIcon>
              <ListItemText primary="Organogram Development" />
            </ListItem>
          </NavLink>
        )}
       
        {canPost && (
          <NavLink to="/performance/supervisorreview">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <img src={iconPerformanceManagement} className="img-icon-sidebar" />
            </ListItemIcon>
            <ListItemText primary="Performance Management" />
          </ListItem>
        </NavLink>
        )}
        
      
 
        <NavLink to="/nominations/vote">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <img src={iconNominationImage} className="img-icon-sidebar" />
            </ListItemIcon>
            <ListItemText primary="Polling" />
          </ListItem>
        </NavLink>
        

        <NavLink to="/thankyou/cards">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <img src={iconThankYouImage} className="img-icon-sidebar" />
            </ListItemIcon>
            <ListItemText primary="Thank You Cards" />
          </ListItem>
        </NavLink>

        {canPost && (
          <NavLink to="/experts/list">
            <ListItem button>
              <ListItemIcon className="ml-1">
                <img src={iconExpertsImage} className="img-icon-sidebar" />
              </ListItemIcon>
              <ListItemText primary="Experts" />
            </ListItem>
          </NavLink>
        )}

        <NavLink to="/settings">
          <ListItem button>
            <ListItemIcon className="ml-1">
              <SettingsIcon viewBox="0 0 20 17" />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </NavLink>
      </List>
    </div>
  );
};

Menu.propTypes = {
  toolbar: string,
  open: bool,
};

Menu.defaultProps = {
  open: false,
};

export default Menu;
