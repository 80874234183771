/* eslint-disable indent */
import {
  GET_MY_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_CONFIG,
  SUBSCRIBE_CREATE,
  GET_MY_SUBSCRIPTIONS_STATUS,
} from "../actionTypes";

const initialState = {
  states: [],
  isActive: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SUBSCRIPTIONS_CONFIG:
      return {
        ...state,
        availableSubsreiptions: payload,
      };

    case GET_MY_SUBSCRIPTIONS_STATUS:
      return {
        ...state,
        isActive: true,
      };
    case SUBSCRIBE_CREATE:
      return {
        ...state,
        subscription: payload,
      };
    case GET_MY_SUBSCRIPTIONS:
      return {
        ...state,
        mySubscriptions: payload,
      };
    default:
      return state;
  }
};
