import httpRequest from '../../ajax';
import { ERROR, GET_LEAGUE, GET_LEAGUE_POINTS, GET_LEAGUE_POSITION } from "../actionTypes";

export const getLeagueAction = (dispatch) => httpRequest('get', '/wbLeague')
  .then(({ data: { data } }) => {
    const action = { type: GET_LEAGUE, payload: data };
    dispatch(action);
    return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

export const getMyPosition = (dispatch) => httpRequest('get', '/wbLeague/myPosition')
  .then(({ data: { data } }) => {
    const action = { type: GET_LEAGUE_POSITION, payload: data[0] };
    dispatch(action);
    return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

export const getLeaguePoints = (dispatch) => httpRequest('get', '/wbLeague/pointAggregation')
  .then(({ data: { data } }) => {
    const action = { type: GET_LEAGUE_POINTS, payload: data };
    dispatch(action);
    return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));