import React, { useEffect } from 'react';
import { object as objectPropType } from 'prop-types';
import { useLocation } from 'react-router-dom';

/**
 * Auto Scroll
 * @function AutoScroll
 * @description Scroll page to top when pathname changes
 * @param {object} props - component props
 * @returns {JSX} - returns JSX
 */
const AutoScroll = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {children}
    </>
  );
};

AutoScroll.propTypes = {
  children: objectPropType.isRequired,
  location: objectPropType.isRequired
};

export default AutoScroll;
