import httpRequest from "../../ajax";
import {
  CLEAR_JOB_UPDATED,
  DECLINE_APPLICATION,
  ERROR,
  GET_APPLICATION_INSIGHTS,
  GET_APPLICATION_STATS,
  GET_JOB_APPLICANTS,
  GET_BUSINESS_JOB_APPLICANTS,
  GET_JOB_OFFER,
  GET_REFERRAL_STATS,
  GET_REFERRERS,
  UPDATE_APPLICATION,
  HIRE_APPLICANT,
  GET_RECRUITERS_LIST,
  WITHDRAW_APPLICATION,
} from "../actionTypes";

export const getMyApplicationInsight = (dispatch) =>
  httpRequest("get", "/insiight/applications/1/10")
    .then(({ data: { data } }) => {
      const action = { type: GET_APPLICATION_INSIGHTS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getApplicationStats = (dispatch) =>
  httpRequest("get", "/insiight/applications/stats")
    .then(({ data: { data } }) => {
      const action = { type: GET_APPLICATION_STATS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getReferralStats = (dispatch) =>
  httpRequest("get", "/insiight/referrals/stats")
    .then(({ data: { data } }) => {
      const action = { type: GET_REFERRAL_STATS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const getReferrers = (dispatch) =>
  httpRequest("get", "/insiight/referrals/1/10")
    .then(({ data: { data } }) => {
      const action = { type: GET_REFERRERS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const getJobOffersAction = (dispatch) =>
  httpRequest("get", "/insiight/jobOffers/1/100")
    .then(({ data: { data } }) => {
      const action = { type: GET_JOB_OFFER, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getJobApplicants = (dispatch, id, stage) =>
  httpRequest("get", `/insiight/jobOffers/${id}/applicants/${stage}/1/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_JOB_APPLICANTS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      const action = { type: GET_JOB_APPLICANTS, payload: [[]] };
      dispatch(action);
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const getRecruiterJobApplicants = (dispatch, id) =>
  httpRequest("get", `/insiight/jobOffers/${id}/shortlisted/1/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_JOB_APPLICANTS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      const action = { type: GET_JOB_APPLICANTS, payload: [[]] };
      dispatch(action);
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const updateApplication = (dispatch, data) =>
  httpRequest("post", `/insiight/jobOffers/recieversMoveApplicantAction`, data)
    .then(({ data: { data } }) => {
      const action = { type: UPDATE_APPLICATION, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const moveBusinessApplicant = (dispatch, data) =>
  httpRequest("post", `/insiight/jobOffers/businessMoveApplicantAction`, data)
    .then(({ data: { data } }) => {
      const action = { type: UPDATE_APPLICATION, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const declineApplication = (dispatch, jobId, applicantId) =>
  httpRequest(
    "post",
    `/insiight/jobOffers/${jobId}/applicant/${applicantId}/reject`,
    {}
  )
    .then(({ data: { data } }) => {
      const action = { type: DECLINE_APPLICATION, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const clearUpdated = (dispatch) => () => {
  dispatch({
    type: CLEAR_JOB_UPDATED,
  });
};

export const logProfileVisits = (id) =>
  httpRequest("post", `/insiight/applications/${id}/profileVisit`).catch(
    ({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    })
  );

export const acceptOrRejectShortlist = (dispatch, data) =>
  httpRequest(
    "post",
    `/recruiter/insight/applicants/acceptOrRejectShortlist`,
    data
  )
    .then(({ data: { data } }) => {
      const action = { type: UPDATE_APPLICATION, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getBusinessJobApplicants = (dispatch, id, company_id, stage) =>
  httpRequest(
    "get",
    `/insiight/jobOffers/${id}/business/applicants/${stage}/${company_id}/1/100`
  )
    .then(({ data: { data } }) => {
      const action = { type: GET_BUSINESS_JOB_APPLICANTS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const hireApplicant = (dispatch, data) =>
  httpRequest("post", `/recruiter/insight/applicants/hireApplicant`, data)
    .then(({ data: { data } }) => {
      const action = { type: HIRE_APPLICANT, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const hireBusinessApplicant = (dispatch, data) =>
  httpRequest(
    "post",
    `/recruiter/insight/applicants/hireBusinessApplicant`,
    data
  )
    .then(({ data: { data } }) => {
      const action = { type: HIRE_APPLICANT, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruitersList = (dispatch, jobId) => {
  httpRequest("get", `/insiight/jobOffers/${jobId}/recruiters/1/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_RECRUITERS_LIST, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const withdrawApplication = (dispatch, jobId, message) =>
  httpRequest("post", `/insiight/applications/${jobId}/wthdraw`, message)
    .then(({ data: { data } }) => {
      const action = { type: WITHDRAW_APPLICATION, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
