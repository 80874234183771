import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import {
  saveBusinessRef,
  getBusinessRef,
} from "../../../../redux/actions/businessActions";
import HomeModal from "../Modal";
import JobCard from "./JobCard";
const Referrals = () => {
  const [page] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const [section, setSection] = useState("details");
  const { jobId, refLink } = useParams();

  const [loading, setLoading] = useState(false);
  const [, setLoadingMore] = useState(false);

  const { refs } = useSelector((state) => state.business);
  const { pathname } = useLocation();
  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getBusinessRef(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, []);

  const saveForLater = () => {
    const payload = {
      job_id: jobId,
      status: 1,
      refCode: refLink ? refLink : null,
    };
    const saveLater = async () => {
      await saveBusinessRef(dispatch, page, payload);
    };
    saveLater();
  };
  return (
    <div className="row position-relative mt-3 align-items-stretch">
      {jobId && (
        <HomeModal
          setSection={setSection}
          section={section}
          open={true}
          handleClose={() => history.push("/home")}
          isBusiness={
            pathname.includes("biz") || pathname.includes("coy") == true
              ? true
              : false
          }
          isFromCompany={pathname.includes("coy") == true ? true : false}
          saveForLater={saveForLater}
        />
      )}
      {loading && !refs && <SectionLoader />}
      {!loading && !refs && <EmptyState text="There are no recommended refs" />}
      {refs && refs && (
        <>
          {refs.map((job, index) => (
            <div
              key={index.toString()}
              className="col-12 col-md-6"
              style={{
                padding: "10px",
                maxHeight: "230px",
              }}
            >
              <JobCard job={{ ...job, about_company: "" }} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Referrals;
