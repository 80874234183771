import React, { useEffect,useRef,useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import icons from "../../../../assets/icons/icon-collection.svg";
import { useHistory } from "react-router";
import CustomInput from "../../../../components/CustomInput";
import ReactStars from "react-rating-stars-component";
import CustomTextArea from "../../../../components/CustomTextArea";
import cogoToast from "cogo-toast";
import { uploadFile } from "../../../../utils/helpers";
import {
  getColleagues,
  createRecognitionAction
} from "../../../../redux/actions/recognitionAction";
import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import './assigncolleague.css';
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

// Imagine you have a list of languages that you'd like to autosuggest.
 

 

 


const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
       fontSize: 18,
    },
  },
});
 

const AssignColleague = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [colleague, setColleague] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [newVSearchalue, setSearchValue] = useState();
  const [newInputSearchValue, setInputSearchValue] = useState();
  const [input, setInput] = useState('');
  const [fileselectedname, setFileSelectedName] = useState('');

  const [searchColleagueInputData, setSearchColleagueInputData] = useState([])
  const classes = useStyles();
  const uploadInputRef = useRef(0);
  //JSON.stringify(search_colleagues)
 
  const {search_colleagues} = useSelector((store) => store);
  const [stateRecognition, setStateRecognition] = useState({
    colleague_id:"",
    what_content: "",
    how_content: "",
    attachment_url:"",
    invitees_id:""
   });

  useEffect(() => {
    colleague === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getColleagues(dispatch, colleague)).finally(() =>
    colleague === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, colleague]);


  useEffect(()=>{
    setSearchColleagueInputData(search_colleagues)
  },[dispatch,search_colleagues])
  
  const handleChangeUpload = ({ target: { files } }) => {
  if (files && files[0]) {
      setIsUploading(true);
      
      Promise.resolve(
        uploadFile(
          files[0],
          "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
        )
      )
      .then(({ data, success }) => {

        if (success) {
          //addDocLink(data);
          setStateRecognition({ ...stateRecognition, attachment_url: data });
          setFileSelectedName(files[0].name);
         // addDocLinkObject({ link: data, name: files[0].name });
        } else {
          cogoToast.error("Unsuccessful document upload", {
            hideAfter: 4,
            position: "top-center",
          });
        }
      })
      .finally(() => {
        setIsUploading(false);
      });
  }
};

  const handleChange = ({ target: { id, value, checked } }) => {
    setStateRecognition((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleRefClick = () => {
    uploadInputRef.current.click();
  };


  const handleSubmit = async (e) => {
     
    setLoading(true)
    e.preventDefault();
    

      
  const promise =  createRecognitionAction(dispatch, {
      ...stateRecognition,
    });
    Promise.resolve(promise).finally(() => {
          setLoading(false)
       //   console.log(promise);
          setTimeout( ()=>{
 
             window.location.reload(false);
          },8000)
        
    });



   };

   
 
  

 
  return (
    <Card additionalClass="mt-4 p-4  maxh">
      
      <h5 className="co-grayx">Recognise a colleague</h5>
 
     
    
      <div
         style={{
                  padding: "10px",
                  maxHeight: "680px",
                }}
                className="col-12 col-md-12 list-achievements">
      <form onSubmit={handleSubmit}> 
      
          <strong>Select Colleague to Recognise</strong>
          <Autocomplete
              
        
          style={{ width: '100%' }}
          options={searchColleagueInputData.search_colleagues}
          classes={{
            option: classes.option,
          }}
          onChange={(event, newVSearchalue) => {
            //setSearchValue(newVSearchalue);
            //alert(newVSearchalue.id);
            setStateRecognition({ ...stateRecognition, colleague_id: newVSearchalue.id });
          }}
          
          autoHighlight
          getOptionLabel={(option) => option.email }
          renderOption={(option) => (
            <React.Fragment>
          
              {option.first_name == null ?"":option.first_name+" "} 
              {option.last_name == null ?"":option.last_name+" ("} 
              {option.email == null ?"":option.email+")"} 

    
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              variant="outlined"
              style={{
                backgroundColor:'#fff'
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
          <br/>

          <strong> What did the employee do?</strong>
                 <CustomInput
                  type="text"
                  onChange={handleChange}
                  value={stateRecognition.what_content}
                  name="what_content"
                  placeholder=""
                  label=""
                  id="what_content"
                />

            <br/>

            <strong> How did the employee do it?</strong>

            <CustomTextArea
                  label=""
                  onChange={handleChange}
                  value={stateRecognition.how_content}
                  name="how_content"
                  id="how_content"
                 />

 
            <div>
                    <br/>
                      <input
                        ref={uploadInputRef}
                        type="file"
                        onChange={handleChangeUpload}
                        style={{ display: "none" }}
                      />

                      <CustomButton
                        backgroundColor="#fff"
                        color="#000"
                        text={ fileselectedname ==""? "Add Attachments (Any of Video, Audo, Image or Docs if available)":
                          ""+fileselectedname+" (Change)"
                          }
                        handleClick={handleRefClick}
                        loading={isUploading}
                      />
                      {
                        isUploading ? "Uploading file please wait....":<></>
                      }
                    </div>
 


                    <br/><br/>
   
         
          <strong>Select Colleagues to Support Recognition</strong>
      
    

          <Autocomplete

         
            style={{ width: '100%'}}
            options={searchColleagueInputData.search_colleagues}
            classes={{
              option: classes.option,
            }}
            onChange={(event, newVSearchalue) => {
              // alert(newVSearchalue[0].id)
              setStateRecognition({ ...stateRecognition, invitees_id: newVSearchalue.id });
            }}
          
            getOptionLabel={(option) => option.email }
            renderOption={(option) => (
              <React.Fragment>
                {option.first_name == null ?"":option.first_name+" "} 
                {option.last_name == null ?"":option.last_name+" ("} 
                {option.email == null ?"":option.email+")"}     
                </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                variant="outlined"
                style={{
                  backgroundColor:'#fff'
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
       />  
  

                    <div>
                      <br/><br/>

                    <CustomButton
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text="Submit"
                      type="submit"
                      loading={loading}
                    />

                    
                    </div>

  

    </form>
    </div>



    </Card>

  );
};


export default AssignColleague;
