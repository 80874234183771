import { bool, string } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Void from "../../../assets/icons/void.svg";
import CustomButton from '../../CustomButton';

const ErrorComponent = ({ heading, errorEncountered }) => {
  const history = useHistory();
  return (
    <div className="d-flex mt-5 flex-column align-items-center">
      <h1 className="my-4 co-darkgray"><b>{heading}</b></h1>
      <img className="my-4" width="350" src={Void} alt="not found" />
      <div className="d-flex mt-4 justify-content-around w-50">
        {errorEncountered && <div className="w-50">
          <CustomButton
            handleClick={() => window.location.reload()}
            text="Reload"
            backgroundColor="#3A6ED4"
            color="#fff"
          />
        </div>}
        <div className="w-50">
          <CustomButton
            text="Home"
            handleClick={() => history.push("/")}
            backgroundColor="#3A6ED4"
            color="#fff"
          />
        </div>

      </div>
    </div>
  );
};

ErrorComponent.propTypes = {
  heading: string.isRequired, errorEncountered: bool
};

ErrorComponent.defaultProps = {
  errorEncountered: false
};

export default ErrorComponent;
