import React, { useState, useEffect } from "react";
import RecruiterWrapper from "../RecruiterWrapper";
import Hire from "../../../../assets/icons/hire.svg";
import CustomButton from "../../../../components/CustomButton";
import { useHistory } from "react-router-dom";
import SearchRecruiter from "./SearchRecruiters";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../../../../components/EmptyState";
import { SectionLoader } from "../../../../components/PageLoader";
import { getAllRecruiters } from "../../../../redux/actions/recruiterAction";
import { checkRecruiterActive } from "../../../../redux/actions/subscriptionActions";

const HireRecruiter = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [job, setJob] = useState({});
  const [viewAll, setViewAll] = useState(false);
  const [stage, setStage] = useState(
    sessionStorage.getItem("recruiter-hire-agreement") === "accepted" ? 2 : 1
  );
  const [loading, setLoading] = useState(false);
  const { recruiters } = useSelector(({ recruiter }) => recruiter);

  const { isActive } = useSelector((store) => store.subscription);

  useEffect(() => {
    checkRecruiterActive(dispatch);
  }, [dispatch]);

  const getRecruiters = () => {
    setViewAll(true);
    setLoading(true);
    Promise.resolve(getAllRecruiters(dispatch)).finally(() =>
      setLoading(false)
    );
  };
  useEffect(() => {
    if (history.location?.state?.job) {
      setJob(history.location?.state?.job);
    }
  }, [history.location.state]);

  const shortenName = (name) => {
    let recruit = name.split(" ");
    // console.log("recruit", recruit);
    let newName = `${recruit[0]}`;
    if (recruit.length > 1) {
      newName += " " + recruit[1].charAt(0) + ".";
    }

    return newName;
  };

  useEffect(() => {
    setLoading(false);
  }, []);
  const recruiterCards = () => {
    return recruiters && recruiters[0] ? (
      recruiters.map((recruiter) => (
        <div
          key={recruiter.id}
          className="w-100 mt-4 d-flex align-items-center justify-content-between"
        >
          <div className="d-flex align-items-center">
            <img
              width="30"
              className="rounded-circle"
              src={recruiter.profilePicture}
              alt="avatar"
            />

            <span className="d-flex flex-column ml-3">
              <h6 className="mb-0" style={{ textTransform: "capitalize" }}>
                {shortenName(recruiter.fullname)}
              </h6>
              <span className="co-gray">{recruiter.industry[0]}</span>
            </span>
          </div>
          <div className="view">
            <CustomButton
              handleClick={() =>
                history.push({
                  pathname: `/recruiter/hire/profile/${recruiter.id}/${job.id}`,
                  state: {
                    job,
                    jobToSend: history.location?.state?.jobToSend,
                  },
                })
              }
              backgroundColor="#fff"
              text="view"
              color="#3A6ED4"
              borderColor="#3A6ED4"
              additionalClass="px-3"
            />
          </div>
        </div>
      ))
    ) : (
      <EmptyState text="No recruiter found" />
    );
  };

  return (
    <>
      <RecruiterWrapper>
        <div className=" pt-4 mx-5">
          {stage === 1 && (
            <div className="d-flex flex-column align-items-center">
              <h1>Hire a recruiter</h1>
              <h6>Make The Recruitment Process Easier</h6>
              <img src={Hire} alt="hire" />
              <p>
                By {isActive ? "clicking on “I Agree”" : "paying recruiter fee"}
                , you confirm that you have read and understand the{" "}
                <a href="https://workbrook.com/clients-agreement/">
                  Terms & Conditions
                </a>{" "}
                to hire a recruiter on workbrook.
              </p>
              {isActive && (
                <CustomButton
                  handleClick={() => {
                    sessionStorage.setItem(
                      "recruiter-hire-agreement",
                      "accepted"
                    );
                    setStage(2);
                  }}
                  text="I Agree"
                  backgroundColor="#000"
                  color="#fff"
                  additionalClass="p-3 mb-5"
                />
              )}

              {!isActive && (
                <CustomButton
                  handleClick={() => {
                    history.push("/subscriptions/recruiters");
                  }}
                  text="Pay"
                  backgroundColor="#000"
                  color="#fff"
                  additionalClass="p-3 mb-5"
                />
              )}
            </div>
          )}
          {stage === 2 && (
            <div>
              <SearchRecruiter setStage={setStage} />
            </div>
          )}
          {stage === 3 && (
            <div className="position-relative">
              <h3 className="my-4">
                {viewAll ? "Search Result" : "Available Recruiters"}
              </h3>
              {/* <SearchInput /> */}
              {loading && <SectionLoader />}
              {recruiterCards()}
              {!viewAll && (
                <CustomButton
                  handleClick={getRecruiters}
                  backgroundColor="#000"
                  text="View All Recruiters"
                  color="#fff"
                  borderColor="#000"
                  additionalClass="px-3 my-4"
                />
              )}
            </div>
          )}
        </div>
      </RecruiterWrapper>
    </>
  );
};

export default HireRecruiter;
