import React, { useEffect, useState } from 'react'
import Organogram from "../../../../components/Organogram"
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../../components/CustomButton';
import { createOrganogram, getRole, getCompanyStaff, assignRoles, unAssignRoles, editRole } from '../../../../redux/actions/businessActions';
import { Modal, Button, Form } from 'react-bootstrap';
import "../organization.css"
function Organization() {
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const [parentRoleId, setParentRoleId] = useState(null);
    const [selectedStaffId, setSelectedStaffId] = useState({});
  const [lastRole, setLastRole] = useState(null)
  const [editRole, setEditRole] = useState({
    name: "",
    company_id: "",
    parent_role_id: "",
  })
  const [assignedRoles, setAssignedRoles] = useState({});
  const [openEdit, setOpenEdit] = useState(false)
  const[editRoleId, setEditRoleId] = useState("")

  const [companyStaff, setCompanyStaff] = useState([])
  
  const [createRole, setCreateRole] = useState({
    name: "",
    parent_role_id: ""
  })
  const[selectedRoleId, setSelectedRoleId] = useState({})
  const {
    userData: {  company },
  } = useSelector((store) => store.auth);

  const company_id = company ? company.id : "";
 


 
  useEffect(() => {
    const getAll = async () => {
        const parsedCompanyId = parseFloat(company_id); 
        console.log("company_id:", company_id, "Type:", typeof parsedCompanyId);
        
        const data = {
            company_id: Math.floor(parsedCompanyId) 
        };
        
        try {
            console.log("Making request with company_id:", data);
            const response = await getRole(dispatch, data);
            console.log(response.payload.id);
          setRoles(response.payload);
          if (response.payload.length > 0) {
            setLastRole(response.payload[response.payload.length -1].id)
          }
        } catch (error) {
            console.log(error);
        }
    };
    getAll();
  }, [company_id]);
 
  useEffect(() => {
    const getCompany = async () => {
        const parsedCompanyId = parseFloat(company_id); 
        console.log("company_id:", company_id, "Type:", typeof parsedCompanyId);
        
        const data = {
            company_id: Math.floor(parsedCompanyId) 
        };
        
        try {
            console.log("Making request with company_id:", data);
            const response = await getCompanyStaff(dispatch, data);
            console.log(response.payload.id);
          setCompanyStaff(response.payload);
          
        } catch (error) {
            console.log(error);
        }
    };
    getCompany();
  }, [company_id]);


 
  return (
    <div>
      
      
    
      <Organogram roles={ roles} companyStaff={companyStaff} />
      
      
    

    </div>
  )
}

export default Organization;
