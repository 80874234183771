import cogoToast from "cogo-toast";
import httpRequest from "../../ajax";

import { 
  CREATE_THANK_YOU,
  GET_THANK_YOU_CARDS,
  ERROR
} from "../actionTypes";

  
export const CreateThankYouCardAction = (dispatch, data) =>
{

  console.log("thankYouAction"+JSON.stringify(data));
  httpRequest("post", "/thankyoucard/create", data)
  .then(({ data: { data } }) => {
    const action = { type: CREATE_THANK_YOU, payload: data };
    dispatch(action);
     return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

}


 
export const getThankYouCardsAction = (dispatch, page) =>{
  httpRequest("get", `/thankyoucard`)
   .then(({ data: { data } }) => {
      console.log("<<<getThankYouCardsAction>>>"+JSON.stringify(data));
       const action = { type: GET_THANK_YOU_CARDS, payload: data };
     dispatch(action);
     return action;
   })
   .catch(({ response }) => ({
     payload: response?.data?.message,
     type: ERROR,
   }));
 }


 


 

 
 
 