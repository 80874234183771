/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "../../../../../components/Card";
import EditJob from "../EditJob";
import icons from "../../../../../assets/icons/icon-collection.svg";
import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Checkbox,
  DialogActions,
  FormControlLabel,
  DialogContent,
  DialogTitle,
  Dialog,
  FormGroup,
} from "@material-ui/core";
import CustomButton from "../../../../../components/CustomButton";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SmallPlaceholder from "../../../../../assets/icons/small-placeholder.svg";
import { acceptOrRejectShortlist } from "../../../../../redux/actions/insightActions";

const RecruiterJobCard = ({
  job,
  handleClick,
  anchorEl,
  handleClose,
  openDialog,
  closeDialog,
  openEditDialog,
  closeEditDialog,
  tooLong,
  handleChange,
  noApplication,
  notAccepting,
  other,
  open,
  openEdit,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openShortlist, setOpenShortlist] = useState(false);
  const [applicants, setApplicants] = useState([]);

  // console.log("job", job);

  const applicantShortlist = (jobId, applicants) => {
    // console.log("isShorlistAccepted", job.isShorlistAccepted);
    if (job.isShorlistAccepted && job.isShorlistAccepted) {
      history.push(`/insights/jobs/applicants/${jobId}`);
    } else {
      setApplicants(applicants);
      setOpenShortlist(true);
    }
  };

  const handleAcceptOrRejectShortlist = (jobId, act) => {
    const data = {
      job_id: jobId,
      acceptOrDecline: act,
    };

    const takeActionShortlist = async () => {
      setLoading(true);
      await acceptOrRejectShortlist(dispatch, data);
      setLoading(false);
    };

    takeActionShortlist();
  };
  return (
    <Card key={job.id} noPadding additionalClass="p-0 mb-4">
      <div
        className="d-flex align-items-center status-sent px-3 py-2"
        style={{ backgroundColor: "black" }}
      >
        <span className="mr-2">
          <svg width="13" height="13">
            <use href={`${icons}#elipse`}></use>
          </svg>
        </span>
        {job.job_applicants} Applicants
      </div>
      <div className="mx-4 py-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-column">
            {job.role_title}
            <span className="co-gray small-text">{job.role_title}</span>
          </div>
          <IconButton onClick={handleClick}>
            <svg width="20" height="15">
              <use href={`${icons}#dots`}></use>
            </svg>
          </IconButton>
        </div>

        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom" }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "15rem",
              marginTop: "0.2rem",
              borderRadius: "1rem",
              boxShadow: "0px 0px 5px -3px rgba(0,0,0,0.2)",
            },
          }}
        >
          <MenuItem className="px-4 py-2 medium-text" onClick={openEditDialog}>
            Edit this Job
          </MenuItem>
          <MenuItem
            className="px-4 py-2 medium-text co-lightred"
            onClick={openDialog}
          >
            Delete this job
          </MenuItem>
          <Divider />
          <MenuItem
            className="px-4 py-3 co-lightred medium-text"
            onClick={handleClose}
          >
            Cancel
          </MenuItem>
        </Menu>
        <Dialog
          open={open}
          onClose={closeDialog}
          aria-labelledby="close-job"
          PaperProps={{
            style: {
              width: "30rem",
              borderRadius: "1rem",
              padding: "0.75rem",
            },
          }}
        >
          <div className="d-flex justify-content-between align-items-centter">
            <DialogTitle id="form-dialog-title">
              <span className="co-red">Delete this job</span>
            </DialogTitle>
            <div className="close-button">
              <IconButton onClick={closeDialog}>
                <svg width="30" height="30" className="rounded-circle">
                  <use href={`${icons}#close`}></use>
                </svg>
              </IconButton>
            </div>
          </div>
          <DialogContent>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={tooLong}
                    onChange={handleChange}
                    name="tooLong"
                  />
                }
                label="The process is taking too long"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={noApplication}
                    onChange={handleChange}
                    name="noApplication"
                  />
                }
                label="I haven't received any application"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={notAccepting}
                    onChange={handleChange}
                    name="notAccepting"
                  />
                }
                label="No longer accepting applications"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={other}
                    onChange={handleChange}
                    name="other"
                  />
                }
                label="other"
              />
            </FormGroup>
          </DialogContent>
          <DialogActions className="mt-5">
            <span className="w-25 mr-4">
              <button className="w-40 co-green borderless bg-white">
                <b>Submit</b>
              </button>
            </span>
          </DialogActions>
        </Dialog>

        <div className="w-100 mt-3 d-flex flex-column flex-sm-row align-items-md-center justify-content-md-between">
          {/* <Link to={`jobs/applicants/${job.id}`} className="w-50 co-primary">
            Active Recruiter
          </Link> */}
          <div className="d-flex flex-column text-muted">
            <span className="mb-2">Active Recruiter</span>
            <span>
              {job.activeRecruiter ? (
                <img
                  src={`${job.activeRecruiter.profilePicture}`}
                  width="30px"
                />
              ) : (
                <img src={SmallPlaceholder} width="30px" />
              )}
            </span>
          </div>
          <div className="w-50">
            <CustomButton
              backgroundColor="black"
              text={
                job.isShortlistSent === "0"
                  ? "Shortlist in progress"
                  : "Shortlisted Applicants"
              }
              color="#fff"
              borderColor="#3A6ED4"
              additionalClass="w-100"
              // handleClick={() =>
              //   history.push(`/insights/jobs/applicants/${job.id}`)
              // }
              handleClick={() => applicantShortlist(job.id, job.applicants)}
              disabled={job.isShortlistSent === "0"}
            />
          </div>
        </div>
      </div>

      {/* DIALOG FOR ACCEPT/REJECT SHORTLIST */}
      <Dialog
        open={openShortlist}
        onClose={() => setOpenShortlist(false)}
        aria-labelledby="close-job"
        PaperProps={{
          style: {
            width: "30rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-centter">
          <DialogTitle id="form-dialog-title">
            <span className="co-red">Accept or Reject Shortlist</span>
          </DialogTitle>
          <div className="close-button">
            <IconButton onClick={() => setOpenShortlist(false)}>
              <svg width="30" height="30" className="rounded-circle">
                <use href={`${icons}#close`}></use>
              </svg>
            </IconButton>
          </div>
        </div>
        <DialogContent>
          {applicants.length > 0 &&
            applicants.map((applicant) => (
              <div
                key={applicant.id}
                // onClick={() => handleOpen(applicant)}
                className="d-flex align-items-center cursor-pointer justify-content-between mb-3"
              >
                <span className="d-flex align-items-center">
                  <img width="50" src={applicant.image_url} alt="avatar" />
                  <span className="d-flex flex-column ml-3">
                    <h6 className="mb-0">
                      {applicant.first_name} {applicant.last_name}
                    </h6>
                    <span className="co-gray small-text">
                      @{applicant.username}
                    </span>
                  </span>
                </span>
                <span>
                  {/* <CustomButton
                    text="Move"
                    alt="Move"
                    type="button"
                    backgroundColor="white"
                    borderColor="var(--primary-color)"
                    additionalClass={"px-4"}
                  ></CustomButton> */}
                </span>
              </div>
            ))}
        </DialogContent>
        <DialogActions className="mt-5">
          <span className="w-50 mr-4">
            <CustomButton
              text="Reject Shortlist"
              alt="Reject Shortlist"
              type="button"
              backgroundColor="white"
              loading={loading}
              borderColor="var(--primary-color)"
              handleClick={() => handleAcceptOrRejectShortlist(job.id, 0)}
            ></CustomButton>
          </span>
          <span className="w-50 mr-4">
            <CustomButton
              text="Accept Shortlist"
              alt="Accept Shortlist"
              type="button"
              backgroundColor="var(--primary-color)"
              borderColor="white"
              color="white"
              loading={loading}
              handleClick={() => handleAcceptOrRejectShortlist(job.id, 1)}
            ></CustomButton>
          </span>
        </DialogActions>
      </Dialog>

      {/* Dialog for edit job */}
      <>
        <EditJob open={openEdit} handleClose={closeEditDialog} />
      </>
    </Card>
  );
};

export default RecruiterJobCard;
