import React from "react";
import clsx from "clsx";
import { string } from "prop-types";
import PropTypes from "prop-types";
import "./index.scss";

const Counter = ({ count, MAX_WORDS }) => (
  <span
    className={clsx(
      "counter small-text d-flex align-items-center justify-content-center",
      {
        warning: count <= 50 && count > 10,
        danger: count <= 10,
      }
    )}
  >
    {isNaN(count) ? MAX_WORDS - 1 : count}
  </span>
);

Counter.propTypes = {
  count: PropTypes.number.isRequired,
};


export default Counter;
