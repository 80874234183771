import React from "react";
import { useLocation } from "react-router-dom";
import General from "./General";
import Applicants from "./Applicants";

function Jobs() {
  const { pathname } = useLocation();
  return (
    <div className="row my-4 mx-1 mx-md-4">
      <div className="w-100 my-4">
        {pathname.includes("applicants") ? <Applicants /> : <General />}
      </div>
    </div>
  );
}

export default Jobs;
