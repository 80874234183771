import React from "react";
import { string, bool, func } from "prop-types";
import "../CustomInput/index.scss";

const CustomTextArea = ({
  classes,
  rows,
  value,
  label,
  readOnly,
  id,
  defaultValue,
  name,
  isRequired,
  onChange,
  placeholder,
  isDisabled,
   style,
}) => {
  return (
    <div className="custom-input mt-3">
      <label className="d-block" htmlFor={id}>
        {label}
      </label>
      <textarea
        rows={rows}
        defaultValue={defaultValue}
        className={`${classes} w-100`}
        value={value}
        readOnly={readOnly}
        id={id}
        name={name}
        required={isRequired}
        disabled={isDisabled}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

CustomTextArea.propTypes = {
  value: string.isRequired,
  label: string.isRequired,
  id: string.isRequired,
  isRequired: bool,
  onChange: func,
  placeholder: string,
  isDisabled: bool,
  rows: string,
  classes: string,
  name: string,
};

CustomTextArea.defaultProps = {
  isRequired: false,
  onChange: () => {},
  placeholder: "",
  isDisabled: false,
  rows: "4",
  classes: "",
};

export default CustomTextArea;
