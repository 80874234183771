import React, { useEffect } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import { Divider } from "@material-ui/core";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

const Insights = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { referralStats } = useSelector(({ insight }) => insight);

  useEffect(() => {
    Promise.resolve([getReferralStats(dispatch)]).finally(() => {});
  }, [dispatch]);
  return (
    <Card additionalClass="mt-4 p-4">
      <h5>Referral Insights</h5>
      <div className="d-flex border-primary">
        <span className="w-50 py-2 text-center co-white bg-primary">
          <b>Total Referrals</b>
        </span>
        <span className="w-50 py-2 text-center co-primary">
          <b>{referralStats?.clicksByReferral}</b>
        </span>
      </div>
      <div className="mt-4 position-relative d-flex justify-content-center">
        <img src={Chart} alt="chart" />
        <div className="figure position-absolute">
          <span className="d-flex flex-column align-items-center justify-content-center">
            <b>{referralStats?.clicksByReferral}</b>
            <span className="co-darkgray smalltext">Total Viewed</span>
          </span>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <span className="d-flex flex-column align-items-center p-3">
          <span
            style={{
              background: "#304FFD",
              width: "0.5rem",
              padding: "0.25rem",
              borderRadius: "0.1rem",
            }}
          ></span>
          <b className="mt-1">{referralStats?.appliedForJob}</b>
          <span className="co-darkgray smalltext">Applied</span>
        </span>
        <Divider orientation="vertical" flexItem />
        <span className="d-flex flex-column align-items-center p-3">
          <span
            style={{
              background: "#FFD240",
              width: "0.5rem",
              padding: "0.25rem",
              borderRadius: "0.1rem",
            }}
            className="px-1"
          ></span>
          <b className="mt-1">
            {referralStats?.clicksByReferral - referralStats?.appliedForJob}
          </b>
          <span className="co-darkgray smalltext">Did-Not-Apply</span>
        </span>
      </div>
      <div className="p-5">
        <CustomButton
          backgroundColor="#3461B8"
          color="#fff"
          handleClick={() => {
            history.push("/referrals/sent");
          }}
          additionalClass="p-2"
          text="View All Referrals"
        />
      </div>
    </Card>
  );
};

export default Insights;
