// Error
export const ERROR = "ERROR";

// Auth
export const AUTH_LOADING = "AUTH_LOADING";
export const STOP_AUTH_LOADING = "STOP_AUTH_LOADING";
export const LOGIN = "LOGIN";
export const CREATE_USER = "CREATE_USER";
export const RESEND_OTP = "RESEND_OTP";
export const SOCIAL_MEDIA_REGISTER = "SOCIAL_MEDIA_REGISTER";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const CLEAR_CREATE_USER = "CLEAR_CREATE_USER";

// Profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_OTHER_USER = "GET_OTHER_USER";
export const SET_RESUME = "SET_RESUME";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const STOP_PROFILE_LOADING = "STOP_PROFILE_LOADING";
export const GET_ADMIN_REQUESTS = "GET_ADMIN_REQUESTS";
export const APPROVE_ADMIN_REQUEST = "APPROVE_ADMIN_REQUEST";
export const DECLINE_ADMIN_REQUEST = "DECLINE_ADMIN_REQUEST";

// Education
export const GET_EDUCATIONS = "GET_EDUCATIONS";
export const GET_EDUCATION = "GET_EDUCATION";
export const CREATE_EDUCATION = "CREATE_EDUCATION";
export const EDIT_EDUCATION = "EDIT_EDUCATION";
export const UPDATE_EDUCATION = "UPDATE_EDUCATION";
export const EDUCATION_LOADING = "EDUCATION_LOADING";
export const STOP_EDUCATION_LOADING = "STOP_EDUCATION_LOADING";
export const RESET_EDUCATION_FORM = "RESET_EDUCATION_FORM";

// Industry
export const GET_INDUSTRIES = "GET_INDUSTRIES";

//Experience

export const GET_EXPERIENCE = "GET_EXPERIENCE";
export const GET_EXPERIENCES = "GET_EXPERIENCES";
export const CREATE_EXPERIENCE = "CREATE_EXPERIENCE";
export const EDIT_EXPERIENCE = "EDIT_EXPERIENCE";
export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";

// Country
export const GET_COUNTRIES = "GET_COUNTRIES";

// State
export const GET_STATES = "GET_STATES";

// Skill
export const GET_ALL_SKILLS = "GET_ALL_SKILLS";
export const GET_SKILL_BY_ID = "GET_SKILL_BY_ID";
export const GET_MY_SKILLS = "GET_MY_SKILLS";
export const CREATE_SKILL = "CREATE_SKILL";
export const UPDATE_SKILL = "UPDATE_SKILL";
export const SKILL_LOADING = "SKILL_LOADING";
export const STOP_SKILL_LOADING = "STOP_SKILL_LOADING";

// Jobs
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_ALL_RECOMMENDATION_JOBS = "GET_ALL_RECOMMENDATION_JOBS";
export const GET_OFFERS_JOBS = "GET_OFFERS_JOBS";
export const GET_JOB = "GET_JOB";
export const GET_STATUS = "GET_STATUS";
export const GET_JOB_LIST = "GET_JOB_LIST";
export const GET_MY_JOBS = "GET_MY_JOBS";
export const SAVE_JOB = "SAVE_JOB";
export const REPORT_JOB = "REPORT_JOB";
export const GET_SAVED_JOBS = "GET_SAVED_JOBS";
export const DELETE_SAVED_JOBS = "DELETE_SAVED_JOBS";
export const DELETE_JOBS = "DELETE_JOBS";
export const APPLY_TO_JOB = "APPLY_TO_JOB";
export const GET_APPLIED = "GET_APPLIED";
export const SEARCH_JOBS = "SEARCH_JOBS";
export const POST_JOB_VALIDATION = "POST_JOB_VALIDATION";
export const GET_JOB_LEVELS = "GET_JOB_LEVELS";
export const CREATE_JOB = "CREATE_JOB";
export const CREATE_JOB_UPLOAD_EXCEL = "CREATE_JOB_UPLOAD_EXCEL";
export const EDIT_JOB = "EDIT_JOB";
export const RESET_JOB_CREATED = "RESET_JOB_CREATED";
export const LIKE_UNLIKE_JOB = "LIKE_UNLIKE_JOB";
export const GET_REFERRAL_JOBS = "GET_REFERRAL_JOBS";
export const RESET_JOBS = "RESET_JOBS";
export const GET_ADMIN_JOBS = "GET_ADMIN_JOBS";
export const APPLY_TO_RECRUITER_JOB = "APPLY_TO_RECRUITER_JOB";
export const GET_JOB_NO_AUTH = "GET_JOB_NO_AUTH";
// Invite
export const GET_INVITES = "GET_INVITES";

// Subscriptions
export const GET_MY_SUBSCRIPTIONS = "GET_MY_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_CONFIG = "GET_SUBSCRIPTIONS_CONFIG";
export const SUBSCRIBE_CREATE = "SUBSCRIBE_CREATE";

//Organogram
export const CREATE_ORGANOGRAM = "CREATE_ORGANOGRAM";
export const EDIT_ORGANOGRAM = "EDIT_ORGANOGRAM";
export const GET_ROLES = "GET_ROLES";
export const GET_COMPANY_STAFF = "GET_COMPANY_STAFF";
export const POST_ASSIGN_ROLES = "POST_ASSIGN_ROLES";
export const POST_UNASSIGN_ROLES = "POST_UNASSIGN_ROLES";

// Settings
export const GET_NOTIFICATION_SETTINGS = "GET_NOTIFICATION_SETTINGS";
export const UPDATE_NOTIFICATION_SETTINGS = "UPDATE_NOTIFICATION_SETTINGS";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// Recruiter
export const CREATE_RECRUITER = "CREATE_RECRUITER";
export const UPDATE_RECRUITER = "UPDATE_RECRUITER";
export const GET_MY_RECRUITER_DATA = "GET_MY_RECRUITER_DATA";
export const SEARCH_RECRUITER = "SEARCH_RECRUITER";
export const GET_ALL_RECRUITERS = "GET_ALL_RECRUITERS";
export const GET_RECRUITER = "GET_RECRUITER";
export const GET_RECRUITER_JOBS = "GET_RECRUITER_JOBS";
export const SEND_JOB = "SEND_JOB";
export const ACCEPT_DECLINE_JOB = "ACCEPT_DECLINE_JOB";
export const GET_RECRUITER_INSIGHT = "GET_RECRUITER_INSIGHT";
export const GET_RECRUITER_JOBS_APPLICANTS = "GET_RECRUITER_JOBS_APPLICANTS";
export const GET_RECRUITER_JOB_APPLICANTS = "GET_RECRUITER_JOB_APPLICANTS";
export const GET_RECRUITER_JOBS_SHORTLISTED = "GET_RECRUITER_JOBS_SHORTLISTED";
export const SHORTLIST_APPLICANT = "SHORTLIST_APPLICANT";
export const GET_COST_OF_HIRE_ANALYTICS = "GET_COST_OF_HIRE_ANALYTICS";
export const GET_EMPLOYEE_ENGAGEMENT_ANALYTICS =
  "GET_EMPLOYEE_ENGAGEMENT_ANALYTICS";
export const GET_REFERAL_RATIO_ANALYTICS = "GET_REFERAL_RATIO_ANALYTICS";
export const GET_PIE_CHART_ANALYTICS = "GET_PIE_CHART_ANALYTICS";
export const GET_AVERAGE_TIME_TO_HIRE_ANALYTICS =
  "GET_AVERAGE_TIME_TO_HIRE_ANALYTICS";

export const GET_RECRUITER_APPLICANTS_SHORTLISTED =
  "GET_RECRUITER_APPLICANTS_SHORTLISTED";
export const SEND_SHORTLIST = "SEND_SHORTLIST";
export const RECRUITER_REQUEST_ACCEPTANCE = "RECRUITER_REQUEST_ACCEPTANCE";
export const RECRUITER_COMPLETED_JOBS_LEVELS =
  "RECRUITER_COMPLETED_JOBS_LEVELS";
export const RECRUITER_COMPLETED_BY_INDUSTRY =
  "RECRUITER_COMPLETED_BY_INDUSTRY";
export const RECRUITER_SHORTLIST_INTERVIEW_RATIO =
  "RECRUITER_SHORTLIST_INTERVIEW_RATIO";
export const GET_RECRUITER_REVIEW = "GET_RECRUITER_REVIEW";

// WBLeague
export const GET_LEAGUE = "GET_LEAGUE";
export const GET_LEAGUE_POSITION = "GET_LEAGUE_POSITION";
export const GET_LEAGUE_POINTS = "GET_LEAGUE_POINTS";

// Insights
export const GET_APPLICATION_INSIGHTS = "GET_APPLICATION_INSIGHTS";
export const GET_APPLICATION_STATS = "GET_APPLICATION_STATS";
export const GET_REFERRAL_STATS = "GET_REFERRAL_STATS";
export const GET_REFERRERS = "GET_REFERRERS";
export const GET_JOB_OFFER = "GET_JOB_OFFER";
export const GET_JOB_APPLICANTS = "GET_JOB_APPLICANTS";
export const GET_BUSINESS_JOB_APPLICANTS = "GET_BUSINESS_JOB_APPLICANTS";
export const DECLINE_APPLICATION = "DECLINE_APPLICATION";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const CLEAR_JOB_UPDATED = "CLEAR_JOB_UPDATED";
export const HIRE_APPLICANT = "HIRE_APPLICANT";
export const GET_RECRUITERS_LIST = "GET_RECRUITERS_LIST";
export const WITHDRAW_APPLICATION = "WITHDRAW_APPLICATION";

// Wallet
export const GET_BANKS = "GET_BANKS";
export const CREATE_BANK_ACCOUNT = "CREATE_BANK_ACCOUNT";
export const GET_BANK_ACCOUNT = "GET_BANK_ACCOUNT";
export const GET_ACCOUNT_BALANCE = "GET_ACCOUNT_BALANCE";
export const GET_MY_ACCOUNTS = "GET_MY_ACCOUNTS";
export const RESET_MY_ACCOUNTS = "RESET_MY_ACCOUNTS";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const CARD_TOKEN_SUCCESS = "CARD_TOKEN_SUCCESS";
export const CARD_CREATE_SUCCESS = "CARD_CREATE_SUCCESS";
export const GET_CARDS = "GET_CARDS";
export const RESET_CARDS = "RESET_CARDS";
export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
export const SET_DEFAULT_CARD = "SET_DEFAULT_CARD";
export const FUND_WALLET = "FUND_WALLET";
export const WITHDRAW_FROM_WALLET = "WITHDRAW_FROM_WALLET";
export const TRANSFER_FROM_AVAILABLE = "TRANSFER_FROM_AVAILABLE";
export const GET_MY_SUBSCRIPTIONS_STATUS = "GET_MY_SUBSCRIPTIONS_STATUS";
export const RECRUIT_REQUESTS = "RECRUIT_REQUESTS";
export const RECRUIT_REQUESTS_BY_JOBS = "RECRUIT_REQUESTS_BY_JOBS";
// Business
export const CREATE_BUSINESS_JOB = "CREATE_BUSINESS_JOB";
export const GET_SUBSIDIARIES = "GET_SUBSIDIARIES";
export const GET_BUSINESS_JOBS = "GET_BUSINESS_JOBS";
export const GET_BUSINESS_JOB = "GET_BUSINESS_JOB";
export const RESET_JOB = "RESET_JOB";
export const GET_MY_ADMIN_BUSINESS_JOBS = "GET_MY_ADMIN_BUSINESS_JOBS";
export const SAVE_BUSINESS_REF = "SAVE_BUSINESS_REF";
export const GET_BUSINESS_REFS = "GET_BUSINESS_REFS";
export const RESET_BUSINESS_APPLICANTS = "RESET_BUSINESS_APPLICANTS";
export const GET_AVG_TIME_TO_HIRE = "GET_AVG_TIME_TO_HIRE";
export const GET_ANNUAL_EMPLOYEES = "GET_ANNUAL_EMPLOYEES";
export const PROCESS_QUALITY_OF_HIRE = "PROCESS_QUALITY_OF_HIRE";
export const GET_QUALITY_OF_HIRE = "GET_QUALITY_OF_HIRE";
export const GET_CURRENCY_RATES = "GET_CURRENCY_RATES";
export const GET_EMPLOYEE_ENGAGEMENT = "GET_EMPLOYEE_ENGAGEMENT";
export const BUSINESS_SEARCH_JOBS = "BUSINESS_SEARCH_JOBS";
export const GET_EMPLOYEE_REFERRAL_RATIO = "GET_EMPLOYEE_REFERRAL_RATIO";
export const GET_COST_OF_HIRE = "GET_COST_OF_HIRE";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const POST_HIRING = "POST_HIRING";
export const POST_PERFORMANCE = "POST_PERFORMANCE";
export const POST_SENDBACK = "POST_SENDBACK";
export const POST_EMPLOYEEPERFORMANCE = " POST_EMPLOYEEPERFORMANCE";
export const GET_PERFORMANCE = "GET_PERFORMANCE";
export const GET_EACHPERFORMANCE = "GET_EACHPERFORMANCE";
export const  POST_EDITED_PERFORMANCE = " POST_EDITED_PERFORMANCE,"

//BANK
export const POST_ADD_BANK = "POST_ADD_BANK";

//RECOGNITION
export const SEARCH_COLLEAGUE = "SEARCH_COLLEAGUE";
export const CREATE_RECOGNITION = "CREATE_RECOGNITION";
export const GET_RECOGNITION_REQUEST = "GET_RECOGNITION_REQUEST";
export const SUBMIT_RECOGNITOIN_REQUEST = "SUBMIT_RECOGNITOIN_REQUEST";
export const GET_ACHIEVMENT_DATA = "GET_ACHIEVMENT_DATA";
export const GET_ACHIEVMENT_DATA_HR = "GET_ACHIEVMENT_DATA_HR";
export const UPDATE_RECOGNITOIN_REQUEST_APPROVAL_HR =
  "UPDATE_RECOGNITOIN_REQUEST_APPROVAL_HR";

//NOMINATION
export const CREATE_NOMINATION = "CREATE_NOMINATION";
export const GET_POLLS = "GET_POLLS";
export const GET_ACTIVE_POLLS = "GET_ACTIVE_POLLS";
export const GET_ALL_POLLS = "GET_ALL_POLLS";
export const VOTE_NOMINATOIN = "VOTE_NOMINATOIN";
export const GET_POLL_RESULTS = "GET_POLL_RESULTS";

//EXPERTISE
export const CREATE_EXPERT_CATEGORY = "CREATE_EXPERT_CATEGORY";
export const GET_ALL_EXPERT_CATEGORY = "GET_ALL_EXPERT_CATEGORY";
export const CREATE_EXPERT = "CREATE_EXPERT";
export const GET_ALL_EXPERT = "GET_ALL_EXPERT";
export const UPDATE_ALL_EXPERT_CATEGORY = "UPDATE_ALL_EXPERT_CATEGORY";
export const DELETE_ALL_EXPERT_CATEGORY = "DELETE_ALL_EXPERT_CATEGORY";

//THANK YOU
export const CREATE_THANK_YOU = "CREATE_THANK_YOU";
export const GET_THANK_YOU_CARDS = "GET_THANK_YOU_CARDS";

//Feeds
export const CREATE_FEED_POST = "CREATE_FEED_POST";
export const GET_FEEDS_RESULTS = "GET_FEEDS_RESULTS";
export const CREATE_COMMENT_POST = "CREATE_COMMENT_POST";
export const DELETE_FEEDS_CATEGORY = "DELETE_FEEDS_CATEGORY";

//Verification
export const GET_VERIFICATION = "GET_VERIFICATION";
