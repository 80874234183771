import { ternaryResolver } from '../../utils/helpers';
import {
  GET_ALL_SKILLS,
  SKILL_LOADING,
  STOP_SKILL_LOADING,
  CREATE_SKILL,
  UPDATE_SKILL,
  GET_SKILL_BY_ID,
  GET_MY_SKILLS,
} from '../actionTypes';

const initialState = {
  mySkills: {
    skills: [],
  },
  skillById: {
    skills: [],
  },
  allSkills: [],
  skillLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case CREATE_SKILL:
  case UPDATE_SKILL:
  case GET_MY_SKILLS:
    return {
      ...state,
      mySkills: {
        ...state.mySkills,
        ...ternaryResolver((typeof payload === 'object'), payload, {}),
      },
    };
  case GET_SKILL_BY_ID:
    return {
      ...state,
      skillById: {
        ...state.skillById,
        ...ternaryResolver((typeof payload === 'object'), payload, {}),
      },
    };
  case GET_ALL_SKILLS:
    return {
      ...state,
      allSkills: ternaryResolver(Array.isArray(payload), payload, []),
    };
  case SKILL_LOADING:
    return {
      ...state,
      skillLoading: true,
    };
  case STOP_SKILL_LOADING:
    return {
      ...state,
      skillLoading: false,
    };
  default:
    return state;
  }
};
