import React, { useState } from "react";
import SearchInput from "../../../../components/CustomSearchInput";
import { useLocation } from "react-router-dom";
import General from "./General";
import Applicants from "./Applicants.js";
import Requests from "./Requests";

const Jobs = () => {
  const { pathname } = useLocation();
  const [search, setSearch] = useState("");
  return (
    <div className="my-4 mx-1 mx-md-4">
      <SearchInput
        handleChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <div className="w-100 my-4">
        {pathname.includes("applicants") ? (
          <Applicants />
        ) : pathname.includes("requests") ? (
          <Requests />
        ) : (
          <General search={search} />
        )}
      </div>
    </div>
  );
};

export default Jobs;
