/* eslint-disable react/prop-types */
import React from "react";
import clsx from "clsx";
import { anonymousFunc } from "../../utils/helpers";
import { node, string, bool } from "prop-types";
import "./index.scss";

const Card = ({
  children,
  backgroundColor,
  color,
  loading,
  handleClick,
  additionalClass,
  noPadding,
  additionalStyle,
}) => {
  return (
    <div
    onClick={loading ? anonymousFunc : handleClick}
      style={{
        background: backgroundColor,
        color: color,
        ...(additionalStyle || {}),
      }}
      className={clsx("customcard w-100", additionalClass, {
        "p-3": !noPadding,
      })}
    >
      {children}
    </div>
  );
};

Card.propTypes = {
  children: node.isRequired,
  backgroundColor: string,
  color: string,
  additionalClass: string,
  noPadding: bool,
};

Card.defaultProps = {
  backgroundColor: "#ffffff",
  color: "#000",
  additionalClass: "",
  noPadding: false,
};

export default Card;
