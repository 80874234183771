import httpRequest from '../../ajax';
import {
  GET_STATES,
  ERROR,
} from '../actionTypes';

/**
 * @description get state action
 * @function getStateAction
 * @param {string} countryId - countryId
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const getStateAction = (countryId, dispatch) => {
  return httpRequest('get',`/country/${countryId}/states`)
    .then(({ data: { data }}) => {
      const action = { type: GET_STATES, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};
