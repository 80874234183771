import React, { useEffect,useRef,useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import icons from "../../../../assets/icons/icon-collection.svg";
import { useHistory } from "react-router";
import CustomInput from "../../../../components/CustomInput";
import ReactStars from "react-rating-stars-component";
import CustomTextArea from "../../../../components/CustomTextArea";
import cogoToast from "cogo-toast";
import {
  getColleagues} from "../../../../redux/actions/recognitionAction";
import {CreateThankYouCardAction} from "../../../../redux/actions/thankYouAction";   
import { uploadFile } from "../../../../utils/helpers";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import './thankacolleague.css';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
       fontSize: 18,
    },
  },
});
 

const ThankAColleague = () => {
  
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const {search_colleagues} = useSelector((store) => store);
  const [searchColleagueInputData, setSearchColleagueInputData] = useState([])
  const classes = useStyles();
  const [stateRecognition, setStateRecognition] = useState({
    colleague_id:"",
    comment: ""
   });
 
   useEffect(() => {
    Promise.resolve(getColleagues(dispatch, 1)).finally(() =>  setLoading(false) 
    );
  }, [dispatch, 1]);



  const handleChange = ({ target: { id, value, checked } }) => {
    setStateRecognition((prevState) => ({ ...prevState, [id]: value }));
  };


  useEffect(()=>{
    setSearchColleagueInputData(search_colleagues)
  },[dispatch,search_colleagues]);
  
  const handleSubmit = async (e) => {
     
    setLoading(true)
    e.preventDefault();

    
    const promise =  CreateThankYouCardAction(dispatch, {
      ...stateRecognition,
    });
    Promise.resolve(promise).finally(() => {
          setLoading(false)
          setTimeout( ()=>{
            window.location.reload(false);
             
          },2000)
         
    });



  setStateRecognition({
      colleague_id:"",
      comment: ""
     });

   };
 

  return (
    <Card additionalClass="mt-4 p-4  maxh">
      
      <h5>Thank a colleague</h5>
  
      <div
                
                style={{
                  padding: "10px",
                  maxHeight: "280px",
                }}
                className="col-12 col-md-12 list-achievements">
         

         <form onSubmit={handleSubmit}>
         <Autocomplete

                        
            style={{ width: '100%'}}
            options={searchColleagueInputData.search_colleagues}

            classes={{
              option: classes.option,
            }}

            onChange={(event, newVSearchalue) => {
              setStateRecognition({ ...stateRecognition, colleague_id: newVSearchalue.id});
            }}

            getOptionLabel={(option) => option.email }
            renderOption={(option) => (
              <React.Fragment>
                {option.first_name == null ?"":option.first_name+" "} 
                {option.last_name == null ?"":option.last_name+" ("} 
                {option.email == null ?"":option.email+")"}     
                </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                variant="outlined"
                style={{
                  backgroundColor:'#fff'
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
            />


          <br/>

        

            <p>Your Comment</p>

                    <CustomTextArea
                         
                          label=""
                          onChange={handleChange}
                          value={stateRecognition.comment}
                          name="comment"
                          id="comment"

                        />

                     <div>
                      <br/><br/>

                    <CustomButton
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text="Send"
                      type="submit"
                      loading={loading}
                    />

                    </div>
 
    </form>
    </div>
                
    </Card>

  );
};


export default ThankAColleague;
