import {
  LOGIN,
  AUTH_LOADING,
  STOP_AUTH_LOADING,
  GET_PROFILE,
  UPDATE_PROFILE,
  CREATE_USER,
  RESEND_OTP,
  CLEAR_CREATE_USER,
  ACTIVATE_USER,
} from "../actionTypes";

const initialState = {
  userData:
    JSON.parse(localStorage.getItem("userData")) ||
    JSON.parse(sessionStorage.getItem("userData")),
  isAuthenticated:
    (!!localStorage.token || !!sessionStorage.token) &&
    (!!localStorage.userData || !!sessionStorage.userData),
  rememberMe: !!localStorage.rememberMe,
  loading: false,
};

//alert("<<<<initialState>>"+sessionStorage.getItem("userData"))

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_USER:
      return {
        ...state,
        userCreated: true,
      };

      case RESEND_OTP:
        return {
          ...state,
        };

    case CLEAR_CREATE_USER:
      return {
        ...state,
        userCreated: false,
        activated: false,
      };
    case ACTIVATE_USER:
      return {
        ...state,
        activated: true,
      };
    case LOGIN:
      return {
        ...state,
        userData: payload,
        isAuthenticated: true,
        rememberMe: !!localStorage.rememberMe,
      };
    case GET_PROFILE:
    case UPDATE_PROFILE:
      return {
        ...state,
        userData: payload,
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_AUTH_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
