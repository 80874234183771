/* eslint-disable indent */
import {
  CREATE_BUSINESS_JOB,
  GET_SUBSIDIARIES,
  GET_BUSINESS_JOBS,
  GET_BUSINESS_JOB,
  RESET_JOB,
  GET_MY_ADMIN_BUSINESS_JOBS,
  SAVE_BUSINESS_REF,
  GET_BUSINESS_REFS,
  GET_AVG_TIME_TO_HIRE,
  GET_COST_OF_HIRE_ANALYTICS,
  GET_EMPLOYEE_ENGAGEMENT_ANALYTICS,
  GET_ANNUAL_EMPLOYEES,
  PROCESS_QUALITY_OF_HIRE,
  GET_QUALITY_OF_HIRE,
  BUSINESS_SEARCH_JOBS,
  RESET_JOBS,
  GET_EMPLOYEE_ENGAGEMENT,
  GET_EMPLOYEE_REFERRAL_RATIO,
  GET_COST_OF_HIRE,
  GET_PIE_CHART_ANALYTICS,
  GET_AVERAGE_TIME_TO_HIRE_ANALYTICS
} from "../actionTypes";

const initialState = {
  jobsData: {
    jobs: [],
    count: 0,
  },
  savedData: {
    saved: [],
    count: 0,
  },
  jobApplications: [],
  jobOffers: {
    jobs: [],
    total_applicants: 0,
  },
  jobLevels: [],
  myJobs: [],
  referralJobs: [],
  jobApplied: {},
  searchedTerm: "",
  jobs: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BUSINESS_JOBS:
      return {
        ...state,
        jobsData: {
          jobs: [...state.jobsData.jobs, ...payload["0"]],
          count: payload["total_item_count"],
        },
      };
    case GET_SUBSIDIARIES:
      return {
        ...state,
        subsidiaries: payload,
      };
    case BUSINESS_SEARCH_JOBS:
      return {
        ...state,
        searchedTerm: payload.term,
        jobs: payload.data,
      };
    case GET_BUSINESS_JOB:
      return {
        ...state,
        job: payload,
      };
    case RESET_JOB:
      return {
        ...state,
        job: {},
      };
    case GET_MY_ADMIN_BUSINESS_JOBS:
      return {
        ...state,
        myJobs: payload["0"],
      };
    case SAVE_BUSINESS_REF:
      return {
        ...state,
        savedRef: payload,
      };
    case GET_BUSINESS_REFS:
      return {
        ...state,
        refs: payload[0],
      };
    case GET_AVG_TIME_TO_HIRE:
      return {
        ...state,
        avg_tth: payload,
      };
    case GET_COST_OF_HIRE_ANALYTICS:
        return {
          ...state,
          costOfHireAnalyticsData: payload,
        };
    case GET_EMPLOYEE_ENGAGEMENT_ANALYTICS:
        return {
          ...state,
          employeeEngagementAnalyticsData: payload,
        };
    case GET_EMPLOYEE_REFERRAL_RATIO:
          return {
            ...state,
            employeeReferalRatioAnalyticsData: payload,
          };
    case GET_PIE_CHART_ANALYTICS:
          return {
            ...state,
            pieChartAnalyticsData: payload,
          };
    case GET_AVERAGE_TIME_TO_HIRE_ANALYTICS:
            return {
              ...state,
              averageTimeToHireAnalyticsData: payload,
            };
    case CREATE_BUSINESS_JOB: {
          const jobArr = [...[payload], ...state.myJobs];
          return {
            ...state,
            myJobs: jobArr,
            jobCreated: true,
          };
        }

    case GET_EMPLOYEE_ENGAGEMENT:
      return {
        ...state,
        emp_engt: payload,
      };
    case GET_ANNUAL_EMPLOYEES:
      return {
        ...state,
        annualEmployees: payload,
      };
    case PROCESS_QUALITY_OF_HIRE:
      return {
        ...state,
        qualityOfHire: payload,
      };
    case GET_QUALITY_OF_HIRE:
      return {
        ...state,
        qualityOfHirePercent: payload,
      };

    case GET_EMPLOYEE_REFERRAL_RATIO:
      return {
        ...state,
        employeeReferralRatio: payload,
      };
    case GET_COST_OF_HIRE:
      return {
        ...state,
        costOfHire: payload,
      };
    // case GET_JOB_LEVELS:
    //   return {
    //     ...state,
    //     jobLevels: payload,
    //   };
    // case SEARCH_JOBS:
    //   return {
    //     ...state,
    //     jobs: payload,
    //   };
    // case GET_APPLIED:
    //   return {
    //     ...state,
    //     jobApplications: payload,
    //   };
    // case SAVE_JOB: {
    //   const newJobsArray = state.jobs.map((job) => {
    //     if (job.id === payload.job_id) {
    //       job.isSaved = 1;
    //     }
    //     return job;
    //   });
    //   return {
    //     ...state,
    //     jobs: newJobsArray,
    //   };
    // }
    // case GET_SAVED_JOBS:
    //   return {
    //     ...state,
    //     savedData: {
    //       // saved: [...state.savedData.saved, ...payload["0"]],
    //       saved: [...payload["0"]],
    //       count: payload["total_item_count"],
    //     },
    //   };
    // case DELETE_SAVED_JOBS: {
    //   console.log("SavedData", state.savedData);
    //   const jobsArray = state.savedData.saved.filter(
    //     (job) => job.id !== payload.job_id
    //   );
    //   return {
    //     ...state,
    //     saved: jobsArray,
    //   };
    // }
    case CREATE_BUSINESS_JOB: {
      const jobArr = [...[payload], ...state.myJobs];
      return {
        ...state,
        myJobs: jobArr,
        jobCreated: true,
      };
    }
    // case LIKE_UNLIKE_JOB: {
    //   const jobArr = [...[payload], ...state.myJobs];
    //   return {
    //     ...state,
    //     jobLiked: true,
    //     jobId: payload.job_id,
    //   };
    // }
    // case GET_REFERRAL_JOBS:
    //   return {
    //     ...state,
    //     referralJobs: payload.jobs,
    //   };
    // case RESET_JOB_CREATED:
    //   return {
    //     ...state,
    //     jobCreated: false,
    //   };
    case RESET_JOBS:
      return {
        ...state,
        jobsData: {
          jobs: [],
          count: 0,
        },
      };
    // case GET_ADMIN_JOBS:
    //   return {
    //     ...state,
    //     adminJobs: payload,
    //   };
    // case APPLY_TO_RECRUITER_JOB: {
    //   // const jobArr = [...[payload], ...state.myJobs];
    //   return {
    //     ...state,
    //     jobApplied: payload,
    //     jobCreated: true,
    //   };
    // }
    default:
      return state;
  }
};
