/* eslint-disable indent */
import {
  
    CREATE_THANK_YOU,
    GET_THANK_YOU_CARDS,
 
  } from "../actionTypes";  

  const initialState = {
 
   };
 
export default (state = initialState, { type, payload }) => 
{
    switch (type) {
         case CREATE_THANK_YOU: {
          return {
            ...state,
            thankyouCreated: true,
          };
        };
        case GET_THANK_YOU_CARDS:
          return {
              thankyou_data: payload,
            };
      default:
         return state;
    }
    
  };

   