/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { resetAllJobs } from "../../../redux/actions/jobActions";
import {
  getBusinessJobsAction,
  // getAdminJobs,
} from "../../../redux/actions/businessActions";
import HomeModal from "../../General/Home/Modal";
import { SectionLoader } from "../../../components/PageLoader";
import EmptyState from "../../../components/EmptyState";
import CustomButton from "../../../components/CustomButton";
import { ButtonLoader } from "../../../components/ButtonLoader";
import AdminJobCard from "../AdminJobCard";
import "../../General/Home/index.scss";
import { saveBusinessRef } from "../../../redux/actions/businessActions";
// import JobCard from "./JobCard";

export default function BizRecommended() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [section, setSection] = useState("details");
  const { jobId, refLink } = useParams();

  const {
    jobsData,
    // ,
    // myJobs
  } = useSelector(({ business }) => business);
  const { jobs, count } = jobsData;

  const { userData } = useSelector(({ auth }) => auth);
  const refCode = userData.refCode;
  const company_id = userData.company_id ? userData.company.id : "";

  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    resetAllJobs(dispatch);
    // Promise.resolve(getAdminJobs(dispatch, page)).finally(() =>
    //   page === 1 ? setLoading(false) : setLoadingMore(false)
    // );
    Promise.resolve(getBusinessJobsAction(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);

  const handleClose = () => {
    setSection("details");
    setAnchorEl(null);
  };

  const saveForLater = () => {
    const payload = {
      job_id: jobId,
      status: 1,
      refCode: refLink ? refLink : null,
    };
    const saveLater = async () => {
      await saveBusinessRef(dispatch, page, payload);
    };
    saveLater();
  };

  return (
    <div className="row mt-3 d-flex">
      {jobId && (
        <HomeModal
          setSection={setSection}
          section={section}
          open={jobId}
          handleClose={() => history.push("/home")}
          isBusiness={true}
          saveForLater={saveForLater}
        />
      )}
      {loading && <SectionLoader />}
      {!loading && !jobs[0] && (
        <div className="d-flex align-items-center justify-content-center">
          <EmptyState text="There are no jobs available" />
        </div>
      )}
      {jobs && jobs[0] && (
        <>
          {jobs.map((job, index) => (
            // <JobCard
            //   key={job.id}
            //   job={job}
            //   // handleClose={handleClose}
            //   // anchorEl={anchorEl}
            //   setSection={setSection}
            // />
            <div className="col-12 col-md-6">
              <AdminJobCard
                key={index}
                job={job}
                handleClose={handleClose}
                anchorEl={anchorEl}
                setSection={setSection}
                companyId={company_id}
                refCode={refCode}
                isBusiness={true}
              />
            </div>
          ))}
          {count > 10 * page && (
            <div className="mx-auto mt-3 w-75">
              <CustomButton
                handleClick={() => setPage(page + 1)}
                text={loadingMore ? <ButtonLoader /> : "Load More"}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
