import React, { useEffect,useRef,useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import { Divider } from "@material-ui/core";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import { SectionLoader } from "../../../../components/PageLoader";

import {
  getRecognitionRequests,
  submitRecognitionRequestAction
} from "../../../../redux/actions/recognitionAction";
import ReactStars from "react-rating-stars-component";
import CustomTextArea from "../../../../components/CustomTextArea";
import { useHistory } from "react-router";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const Requests = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [id, setID] = useState(1);
  const recognition_support_idRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [stateRecognition, setStateRecognition] = useState({
    decision:"endorse",
    rejection_reason: "",
    recog_id:"",
    rating:0
   });
  const {recognition_requests} = useSelector((store) => store);
   
  useEffect(() => {
    id === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getRecognitionRequests(dispatch, id)).finally(() =>
    id === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, id]);
  
  const ratingChanged = (newRating) => {
    console.log(newRating);
    setStateRecognition({ ...stateRecognition, rating: newRating });

  };

  const handleChange = ({ target: { id, value, checked } }) => {
    setStateRecognition((prevState) => ({ ...prevState, [id]: value }));
  };

 


  const handleChangeDropdown = ()=>{

  }
  const handleSubmit = async (e) => {
     
    setLoading(true)
    e.preventDefault();

   
  

    const promise =  submitRecognitionRequestAction(dispatch, {
      ...stateRecognition,
    });
    Promise.resolve(promise).finally(() => {
          setLoading(false)
          setTimeout( ()=>{
           // window.location.reload(false);
          },2000)
        
    });


   };
 

 
  return (
    <Card additionalClass="mt-4 p-4">
      <h5>Recognition Request</h5>

      {/*loading && <SectionLoader />*/}

      { recognition_requests &&  recognition_requests.recognition_requests && recognition_requests.recognition_requests.map( (item, index)=>{
        return( 
          <Accordion>

            {
             item.recognition_status =="pending"?
          <AccordionItem>
              <AccordionItemHeading>
                  <AccordionItemButton>
                  <img src={item.recognised_user.image_url} 
                    className="search-img"
                  data-demo-src={item.recognised_user.image_url}  data-user-popover="5" alt="" data-target="webuiPopover20"
                   /> 
                  
                    {item.recognised_user.first_name} {item.recognised_user.last_name} ({item.recognised_user.department})  | Recognised by  {item.nominated_by_user.first_name} {item.nominated_by_user.last_name} 
                    
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                 <p><b>What:</b> <span className="co-gray">{item.what_content}</span> </p>
                 <p><b>How:</b> <span className="co-gray"> {item.how_content} </span> </p>
  
               <br/>
              <form onSubmit={handleSubmit}>
              <div>


               {/* <input type="text" 
                onChange={()=>{
                 setStateRecognition({ ...stateRecognition, recog_id: item.recognition_support_id });
                }} 
                ref={recognition_support_idRef}
                value={item.recognition_support_id}
                name="recognition_support_id"
                id="recognition_support_id"
               />
                 */}
              <p>How are you connected to this Achievement?</p>
              <CustomInput
                type="text"
                name="supported_by"
                onChange={()=>{
                  setStateRecognition({ ...stateRecognition, recog_id: item.recognition_support_id });
                }}
                placeholder="E.g I was his line manager ..."
                label=""
                id="supported_by"
              />

            
          {/*<CustomSelect
            handleChanges={handleChangeDropdown}
            options={{
              0: "endorse",
              1:"decline"
            }}
            id="decision"
            name="decision"
            value={stateRecognition.decision}
            label="Do you Endorse/Decline this Recognition's Achievement"
           /> */}
          <br/>

              <p>Rate  the Applicant</p>
              <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={24}
                  activeColor="#ffd700"
                />

              <CustomTextArea
                  label=""
                  onChange={handleChange}
                  value={stateRecognition.rejection_reason}
                  name="rejection_reason"
                  id="rejection_reason"
                 />

                <br/>
                <CustomButton
                backgroundColor="#3A6ED4"
                color="#fff"
                text="Submit"
                type="submit"
                loading={loading}
                />
  
              </div>
              </form>
              </AccordionItemPanel>
          </AccordionItem>
              :
              <></>
        }
          </Accordion>
        )
      })
      

        }

     </Card>
  );
};

export default Requests;
