import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import EditCard from "../EditCard";
import CustomButton from "../../../../../components/CustomButton";
import icons from "../../../../../assets/icons/icon-collection.svg";
import AddLink from "./AddLink";
import { uploadFile } from "../../../../../utils/helpers";
import { updateProfileAction } from "../../../../../redux/actions/profileActions";

const Resume = () => {
  const { profileData } = useSelector((store) => store.profile);
  const [state, setState] = useState({
    resume: profileData?.resume,
    resumeName: profileData?.resumeName,
    facebook: profileData?.links ? profileData?.links[0]?.facebook : "",
    linkdn: profileData?.links ? profileData?.links[0]?.linkdn : "",
    twitter: profileData?.links ? profileData?.links[0]?.twitter : "",
    potfolio: profileData?.links ? profileData?.links[0]?.potfolio : "",
    resumePreview: "",
  });
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpen] = useState(false);

  const uploadInputRef = useRef(null);

  const handleRemove = ({ target: { name } }) => {
    if (name === "resume") {
      setState((prevState) => ({
        ...prevState,
        [name]: "",
        resumePreview: "",
      }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: "" }));
    }
  };

  const handleChangeUpload = ({ target: { files } }) => {
    if (files && files[0]) {
      setIsUploading(true);

      Promise.resolve(
        uploadFile(
          files[0],
          "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
        )
      )
        .then(({ data, success }) => {
          if (success) {
            setState((prevState) => ({
              ...prevState,
              resumePreview: data,
              resumeName: files[0].name,
            }));
          } else {
            cogoToast.error("Unsuccessful file upload", {
              hideAfter: 4,
              position: "top-center",
            });
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  const handleSave = () => {
    setIsSaving(true);

    const payload = {
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      gender: profileData.gender,
      dob: profileData.dob,
      state: profileData.state,
      country: profileData.country,
      industry: profileData.industry,
      image_url: profileData.image_url,
      resume: state.resumePreview || state.resume,
      resumeName: state.resumeName,
      phone: profileData.phone,
      links: [
        {
          facebook: state.facebook,
          linkdn: state.linkdn,
          twitter: state.twitter,
          potfolio: state.potfolio,
        },
      ],
      isShowOnProfile: 1,
      about: profileData.about,
    };

    Promise.resolve(updateProfileAction(payload, dispatch)).finally(() => {
      setIsSaving(false);
    });
  };

  const handleRefClick = () => {
    uploadInputRef.current.click();
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      facebook: profileData.links ? profileData?.links[0]?.facebook : "",
      linkdn: profileData.links ? profileData?.links[0]?.linkdn : "",
      twitter: profileData.links ? profileData?.links[0]?.twitter : "",
      potfolio: profileData.links ? profileData?.links[0]?.potfolio : "",
    }));
  }, [open, profileData]);

  return (
    <EditCard
      loading={isSaving}
      handleBtnClick={handleSave}
      btnType="button"
      disable={isUploading}
      allRequired={false}
    >
      <div className="w-100">
        <h6>Resume/Attached Documents</h6>
        <p>
          Add Files or links to external documents, photos, sites, videos and
          presentations.{" "}
        </p>
        <p
          className="co-lightred medium-text mb-1 d-block"
          style={{ paddingBottom: "10px" }}
        >
          Resume should be in pdf or doc format.
        </p>
        <div className="my-4 d-flex">
          <div className="w-50 mr-1">
            <input
              ref={uploadInputRef}
              type="file"
              onChange={handleChangeUpload}
              style={{ display: "none" }}
              accept="application/pdf, .docx"
            />

            <CustomButton
              backgroundColor="#3A6ED4"
              color="#fff"
              text="Upload Resume"
              additionalClass="rounded"
              handleClick={handleRefClick}
              loading={isUploading}
            />
          </div>
          <div className="w-50 ml-1">
            <CustomButton
              type="button"
              handleClick={() => setOpen(true)}
              backgroundColor="#fff"
              color="#3A6ED4"
              text="Add Links"
              additionalClass="rounded border-primary"
              disabled={isUploading}
            />
          </div>
          <AddLink open={open} handleClose={() => setOpen(false)} />
        </div>

        {/* <div className="link-count my-5 w-100 d-flex justify-content-end">
          <span className="blob rounded px-2 py-1 bg-green co-white"><b>4/20</b></span>
        </div> */}

        <div className="d-flex flex-column mt-4">
          {state.resumeName && (
            <span className="d-flex justify-content-between my-2">
              <a
                className="d-flex"
                href={state.resumePreview || state.resume}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <svg width="20" height="24" className="mr-2">
                  <use href={`${icons}#file`}></use>
                </svg>
                {state.resumeName}
                <span className="flag mx-3 co-primary small-text px-1">
                  Default
                </span>
              </a>
              <button
                name="resume"
                onClick={handleRemove}
                className="px-1 delete rounded-circle bg-pink"
              >
                <svg width="10" height="8" style={{ pointerEvents: "none" }}>
                  <use href={`${icons}#close-blue`}></use>
                </svg>
              </button>
            </span>
          )}

          {/* <span className="d-flex justify-content-between my-2">
            <div className="d-flex">
              <svg width="20" height="24" className="mr-2">
                <use href={`${icons}#file`}></use>
              </svg>
              Jane_Doe_Cover_Letter.pdf
            </div>
            <button className="px-1 delete rounded-circle bg-pink">
              <svg width="10" height="8" >
                <use href={`${icons}#close-blue`}></use>
              </svg>
            </button>
          </span> */}
          {state?.facebook && (
            <span className="d-flex justify-content-between my-2">
              <div className="d-flex">
                <svg width="22" height="22" className="mr-2">
                  <use href={`${icons}#link`}></use>
                </svg>
                <a
                  className="underlined co-primary mr-2"
                  href={state.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </div>
              <button
                name="facebook"
                onClick={handleRemove}
                className="px-1 delete rounded-circle bg-pink"
              >
                <svg width="10" height="8" style={{ pointerEvents: "none" }}>
                  <use href={`${icons}#close-blue`}></use>
                </svg>
              </button>
            </span>
          )}

          {state.potfolio && (
            <span className="d-flex justify-content-between my-2">
              <div className="d-flex">
                <svg width="22" height="22" className="mr-2">
                  <use href={`${icons}#link`}></use>
                </svg>
                <a
                  className="underlined co-primary mr-2"
                  href={state.potfolio}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Portfolio
                </a>
              </div>
              <button
                name="potfolio"
                onClick={handleRemove}
                className="px-1 delete rounded-circle bg-pink"
              >
                <svg width="10" height="8" style={{ pointerEvents: "none" }}>
                  <use href={`${icons}#close-blue`}></use>
                </svg>
              </button>
            </span>
          )}

          {state.twitter && (
            <span className="d-flex justify-content-between my-2">
              <div className="d-flex">
                <svg width="22" height="22" className="mr-2">
                  <use href={`${icons}#link`}></use>
                </svg>
                <a
                  className="underlined co-primary mr-2"
                  href={state.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </div>
              <button
                name="twitter"
                onClick={handleRemove}
                className="px-1 delete rounded-circle bg-pink"
              >
                <svg width="10" height="8" style={{ pointerEvents: "none" }}>
                  <use href={`${icons}#close-blue`}></use>
                </svg>
              </button>
            </span>
          )}

          {state.linkdn && (
            <span className="d-flex justify-content-between my-2">
              <div className="d-flex">
                <svg width="22" height="22" className="mr-2">
                  <use href={`${icons}#link`}></use>
                </svg>
                <a
                  className="underlined co-primary mr-2"
                  href={state.linkdn}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </div>
              <button
                name="linkdn"
                onClick={handleRemove}
                className="px-1 delete rounded-circle bg-pink"
              >
                <svg width="10" height="8" style={{ pointerEvents: "none" }}>
                  <use href={`${icons}#close-blue`}></use>
                </svg>
              </button>
            </span>
          )}

          {/* {[
            !state.resume,
            !state.facebook,
            !state.linkdn,
            !state.twitter,
            !state.potfolio,
          ].every((val) => val === true) && (
            <p className="mr-2 py-1 px-2 co-darkgray">Nothing to display</p>
          )} */}
        </div>
      </div>
    </EditCard>
  );
};

export default Resume;
