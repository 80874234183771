/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import demoImg from "../assets/images/t.jpeg";
import { closeRecruiterModal } from "../redux/actions/demoActions";

export const RecruiterHold = () => {
  const dispatch = useDispatch();
  const demoState = useSelector(({ demo }) => demo);
  const closeModal = () => {
    closeRecruiterModal(dispatch);
  };
  return (
    <>
      {demoState.recruiterModal && (
        <div
          className="recruiter-modal"
          id="recruiterModal"
          onClick={(event) => {
            const modal = document.querySelector("#recruiterModal");
            if (event.target == modal) {
              closeModal();
            }
          }}
        >
          <div>
            <img
              src={demoImg}
              alt="demo"
              style={{ width: "80%", maxWidth: "300px" }}
            />
            <p>
              We are building the largest network of recruiters in the world. we
              will open this up in no time.
              <br />
              In the main time, please explore the referral market place
            </p>
          </div>
        </div>
      )}
    </>
  );
};
