import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import CustomButton from "../../../../components/CustomButton";
import { getLeaguePoints } from "../../../../redux/actions/leagueAction";
import Wrapper from "../Wrapper";
import './points.css';

const Points = () => {
  const dispatch = useDispatch();
  const { myPoints } = useSelector(({ league }) => league);

  useEffect(() => {
    getLeaguePoints(dispatch);
  }, [dispatch]);

  return (
    <Wrapper>
      {myPoints && (
        <div>
       


          <div className="d-flex finerx justify-content-between my-3">
            Sign Up
            <span className="co-primary-x">{myPoints.signUP.signUpPoint}pts</span>
          </div>
          <div className="d-flex finerx-second justify-content-between my-3">
            Completed Profile
            <span className="co-primary-x">
              {myPoints.profilePoint.profileCompletedPoint || 0}pts
            </span>
          </div>
          <div className="d-flex finerx-third justify-content-between my-3">
            Invited a friend
            <span className="co-primary-x">
              {myPoints.invitedAFriend.invitedAFriendPoint || 0}pts
            </span>
          </div>
          <div className="d-flex finerx-fourth justify-content-between my-3">
            Successful referrals (Executive)
            <span className="co-primary-x">
              {myPoints.execRef.execRefPoint || 0}pts
            </span>
          </div>
          <div className="d-flex  finerx-fifth justify-content-between my-3">
            Successful referrals (Senior Management)
            <span className="co-primary-x">
              {myPoints.sMRef.sMRefPoint || 0}pts
            </span>
          </div>
          <div className="d-flex finerx-sixth justify-content-between my-3">
            Successful referrals (Management)
            <span className="co-primary-x">
              {myPoints.mGRef.mGRefPoint || 0}pts
            </span>
          </div>
          <div className="d-flex  finerx-seventh justify-content-between my-3">
            Successful referrals (Non Management)
            <span className="co-primary-x">
              {myPoints.nMRef.nMGRefPoint || 0}pts
            </span>
          </div>
          <div className="d-flex   finerx-eight justify-content-between my-3">
            Successful referrals (Graduate)
            <span className="co-primary-x">
              {myPoints.graduateRef.graduateRefPoint || 0}pts
            </span>
          </div>
          <div className="d-flex finerx-nineth justify-content-between my-3">
            Total Points Earned
            <button className="co-white p-2 mb-3 bg-danger borderless rounded" 
            
            >
              {myPoints.total.point}pts
            </button>
          </div>
        </div>
      )}
      {/* <CustomButton
        disabled={myPoints?.total?.amount < 50 || 0}
        color="#1E93E2"
        borderColor="#1E93E2"
        backgroundColor="#fff"
        text={`Withdraw $${myPoints?.total?.amount || 0}`}
      /> */}
    </Wrapper>
  );
};

export default Points;
