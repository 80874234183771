import {
  GET_STATES,
} from '../actionTypes';
    
const initialState = {
  states: [],
};
    
export default (state = initialState, { type, payload }) => {
  switch (type) {
  case GET_STATES:
    return {
      ...state,
      states: payload,
    };
  default:
    return state;
  }
};
    