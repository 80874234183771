import React, { useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import { Link } from "react-router-dom";
import CustomInput from "../../../../components/CustomInput";
import Recover from "../../../../assets/icons/recover.svg";
import Lock from "../../../../assets/icons/Lock.svg";

const ForgotPassword = () => {
  const [sent, setSent] = useState(false);

  const handleSubmit = () => {
    setSent(true);
  };

  return (
    <div
      className="auth"
      style={{
        background: "rgba(0,0,0,0.85)",
      }}
    >
      <div className="wrapper h-100 d-flex align-items-center">
        <div className="d-flex w-50 justify-content-center">
          <img src={Recover} alt="logo" />
        </div>
        <div className="w-50">
          <div className="auth-form mx-auto">
            <div className="d-flex justify-content-center mb-5">
              <img className="w-50" src={Lock} alt="lock" />
            </div>
            <h4 className="text-center mt-4">Recover Your Password</h4>
            {sent && (
              <div className={sent ? "pb-5" : ""}>
                <CustomButton
                  backgroundColor="#41B883"
                  color="#fff"
                  text="Sent"
                  additionalClass="mt-3 mb-2"
                />
                <p className="pb-5 small-text text-center">
                  Check your email for confirmation{" "}
                </p>
              </div>
            )}
            {!sent && (
              <div>
                <form>
                  <CustomInput type="text" label="Email" id="email" />
                  <CustomButton
                    backgroundColor="#3A6ED4"
                    color="#fff"
                    text="Recover Password"
                    additionalClass="mt-3 mb-5"
                    handleClick={handleSubmit}
                  />
                </form>
                <p className="pt-4 pb-3 small-text text-center">
                  Go back to <Link to="/signin">Login</Link>{" "}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
