import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../../../components/Card';
import CustomBadge from '../../../../components/CustomBadge';
import { getLeagueAction } from '../../../../redux/actions/leagueAction';
import Wrapper from '../Wrapper';
import "../Wrapper/wrapper.css";
import './leaderlist.css';

const LeaderList = () => {
  const dispatch = useDispatch();
  const { league } = useSelector(({ league }) => league);

  useEffect(() => {
    getLeagueAction(dispatch);
  }, [dispatch]);
  return (
    <Wrapper list>

          
          
          
       <div class="container">
        <table id="socreboard" class="table striped">
        <thead>
        <tr>
            <td className="white-text boldened-text">Avatar</td>
            <td className="white-text boldened-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User</td>       
            <td className="white-text boldened-text">Position</td>
        </tr>

        </thead>
        
        <tbody>

        {league && league.map((person, index) => (
        <tr>
        <td>
        <img className="rounded-circle" width="50" src={person.image_url} alt="avatar" />
        </td>
        <td>
        <span className="d-flex align-items-center">
               <span className="d-flex flex-column ml-3 small-text">
                <h6 className="mb-0 boldened-text">{person.first_name} {person.last_name}</h6>
                <span className="co-gray">@{person.username}</span>
              </span>
         </span>
        </td>
        <td style={{width:50}}>
        
        <CustomBadge figure={index + 1} />
        </td>
        </tr>
         )
        )
      }

        {/*league && <div>
        {league.map((person, index) => (
          <div key={person.id} className="d-flex justify-content-between align-items-center mt-3">
            <span className="d-flex align-items-center">
               <span className="d-flex flex-column ml-3 small-text">
                <h6 className="mb-0">{person.first_name} {person.last_name}</h6>
                <span className="co-gray">@{person.username}</span>
              </span>
            </span>

            <CustomBadge figure={index + 1} />
          </div>
        ))}
      </div> */}

        </tbody>
      </table>
      </div>

      
     </Wrapper>
  );
};

export default LeaderList;
