/* eslint-disable indent */
import {
  CLEAR_JOB_UPDATED,
  DECLINE_APPLICATION,
  GET_APPLICATION_INSIGHTS,
  GET_APPLICATION_STATS,
  GET_JOB_APPLICANTS,
  GET_BUSINESS_JOB_APPLICANTS,
  GET_JOB_OFFER,
  GET_REFERRAL_STATS,
  GET_REFERRERS,
  UPDATE_APPLICATION,
  RESET_BUSINESS_APPLICANTS,
  HIRE_APPLICANT,
  GET_RECRUITERS_LIST,
} from "../actionTypes";

const initialState = {
  jobOffers: {},
  applicants: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_APPLICATION:
      return {
        ...state,
        updated: true,
      };

    case DECLINE_APPLICATION:
      return {
        ...state,
        declined: true,
      };

    case CLEAR_JOB_UPDATED:
      return {
        ...state,
        updated: false,
        declined: false,
      };

    case GET_APPLICATION_INSIGHTS:
      return {
        ...state,
        applications: payload.applications,
      };

    case GET_JOB_OFFER:
      return {
        ...state,
        jobOffers: payload,
      };

    case GET_JOB_APPLICANTS:
      return {
        ...state,
        applicants: payload,
      };

    case GET_BUSINESS_JOB_APPLICANTS:
      return {
        ...state,
        businessApplicants: payload,
      };

    case RESET_BUSINESS_APPLICANTS:
      return {
        ...state,
        businessApplicants: [],
      };

    case GET_REFERRAL_STATS:
      return {
        ...state,
        referralStats: payload,
      };

    case GET_REFERRERS:
      return {
        ...state,
        referrers: payload,
      };

    case GET_APPLICATION_STATS:
      return {
        ...state,
        applicationStats: payload,
      };
    case HIRE_APPLICANT:
      return {
        ...state,
        hiredApplicant: payload,
      };
    case GET_RECRUITERS_LIST:
      return {
        ...state,
        recruitersLIst: payload,
      };
    default:
      return state;
  }
};
