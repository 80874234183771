import React, { useEffect,useRef,useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import icons from "../../../../assets/icons/icon-collection.svg";
import { useHistory } from "react-router";
import CustomInput from "../../../../components/CustomInput";
import ReactStars from "react-rating-stars-component";
import CustomTextArea from "../../../../components/CustomTextArea";
import cogoToast from "cogo-toast";
import { uploadFile } from "../../../../utils/helpers";
import {
  createExpertCategoryAction,
  getAllExpertCategoryAction,
  updateExpertCategoryAction,
  deleteExpertCategoryAction
} from "../../../../redux/actions/expertCategoryAction";
import { SectionLoader } from "../../../../components/PageLoader";
 
import TextField from '@material-ui/core/TextField';
 import Autocomplete from '@material-ui/lab/Autocomplete';
 import { makeStyles } from '@material-ui/core/styles';
import './style.css';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

 

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
       fontSize: 18,
    },
  },
});
 

const CategoryList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sectionLoading, setSectionLoading] = useState(true);
  const [editcontentArea, setEditContentArea ] = useState(false);
  const [uploadedIcon, setUploadedIcon] = useState();
  const classes = useStyles();
  const uploadInputRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const {get_all_expert_categories} = useSelector((store) => store);

  const [stateRecognition, setStateRecognition] = useState({
    title:"",
    desc: "",
    icon: "",
    category_id:""
   });


  
 
   const handleRefClick = () => {
    uploadInputRef.current.click();
  };


const handleChangeUpload = ({ target: { files } }) => {
  if (files && files[0]) {
    setIsUploading(true);

    Promise.resolve(
      uploadFile(
        files[0],
        "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
      )
    )
      .then(({ data, success }) => {
        if (success) {
          //addDocLink(data);
          setUploadedIcon(data);
          setStateRecognition({ ...stateRecognition, icon: data });

         // addDocLinkObject({ link: data, name: files[0].name });
        } else {
          cogoToast.error("Unsuccessful document upload", {
            hideAfter: 4,
            position: "top-center",
          });
        }
      })
      .finally(() => {
        setIsUploading(false);
      });
  }
};

const handleChange = ({ target: { id, value, checked } }) => {
  setStateRecognition((prevState) => ({ ...prevState, [id]: value }));
};

 

useEffect(() => {
  Promise.resolve(getAllExpertCategoryAction(dispatch, null)).finally(() => 
 {
  setTimeout( ()=>{
    setSectionLoading(false);
  }, 1000)
 }
  );
}, [dispatch, 1]);

    const handleSubmit = async (e) => {
        
      setLoading(true)
      e.preventDefault();
      
     let  resp = await updateExpertCategoryAction(dispatch, {
        ...stateRecognition,
      });
         setLoading(false)
         setTimeout(()=>{
          window.location.reload(false);
         },5000);
    };

const  deleteCategorySubmit  = async (_id)=>{
  if(window.confirm("Kindly confirm removal of the category")){
   await deleteExpertCategoryAction(dispatch, {
    item_id:_id
    });
       setLoading(false)
       setTimeout(()=>{
        window.location.reload(false);
       },5000);



  }
}
 
  return (
    <Card additionalClass="mt-4 p-4  maxh">
      

        {
          editcontentArea ?
          <div>
             <h5 className="gray-co">Edit Category</h5>
            <form onSubmit={handleSubmit}> 

             <CustomInput
              type="text"
              name="title"
              placeholder=""
              label="Title"
              id="title"
              value={stateRecognition.title}
              onChange={handleChange}

            />

            <CustomTextArea
              type="text"
              name="desc"
              placeholder=""
              label="Description"
              id="desc"

              value={stateRecognition.desc}
              onChange={handleChange}
            />

 
            <div>
              <br/>
              
              <img src={uploadedIcon}
            className="image-preview"
            />  
                <input
                  ref={uploadInputRef}
                  type="file"
                  onChange={handleChangeUpload}
                  style={{ display: "none" }}
                />

              <CustomButton
                backgroundColor="#fff"
                color="#000"
              
                text="Change Category Icon"
                handleClick={handleRefClick}
                loading={isUploading}
              />
            </div>
            <br/>
          
        
            <div>
              <br/><br/>

            <CustomButton
              backgroundColor="#3A6ED4"
              color="#fff"
              type="submit"
              text="Save Update"
              loading={loading}
            />  
            <div className="text-center">
              <br/>
            <button class="btn btn-sm text-danger"
            onClick={()=>{
              setEditContentArea(false);
            }}
            >Cancel</button>
            </div>
           
  
            </div>

            </form> 

          </div>
          :
          <div>

        <h5> List Categories</h5>

        <div style={{
                    padding: "10px",
                    maxHeight: "280px",
                  }}
        className="col-12 col-md-12 list-achievements">
        <table   className="col-md-12 table-striped table-bordered-padding">
          <thead>
            <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Icon</th>
            <th>Action</th>
            </tr>
          </thead>
          
          <tbody>
          {sectionLoading && <SectionLoader />}
            
            {
              get_all_expert_categories &&

              get_all_expert_categories.get_all_expert_categories
              &&
              get_all_expert_categories.get_all_expert_categories.map( (item, index)=>{
                return(
                  <tr>
                  <td style={{width:130}}>{item.title}</td>
                  <td style={{width:230}}>{item.desc}</td>
                  <td style={{width:90}}>
                    <img src={item.icon} className="img_class img-circle" />
                  </td>
                <td style={{width:100}}>
                  
                  <button className="btn btn-sm btn-success"
                  onClick={()=>{
                    setStateRecognition({ ...stateRecognition, 
                      title:item.title,
                      desc:item.desc, 
                      icon:item.icon,
                      category_id: item.id
                    });
                    setUploadedIcon(item.icon)
                    setEditContentArea(true);
                  }}
                  >Edit</button>
                  &nbsp;
                  <button className="btn btn-sm btn-danger"
                  onClick={()=>{
                   
                    deleteCategorySubmit(item.id);
                  }}
                  >Delete</button>
                
                </td>
                </tr>
            
                )
              })
            
            }

          </tbody>
        </table>
          

        </div>

          </div>

        }
     


    </Card>

  );
};


export default CategoryList;
