import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Card from "../../../../../components/Card";
import ProgressBar from "../../../../../components/ProgressBar";
import RecruiterWrapper from "../../RecruiterWrapper";
import icons from "../../../../../assets/icons/icon-collection.svg";
import { SectionLoader } from "../../../../../components/PageLoader";
import {
  getTotalRequestAndAcceptance,
  getRecruiterJobsLevels,
  getRecruiterJobsCompletedByIndustry,
} from "../../../../../redux/actions/recruiterAction";

const ActivityTracker = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { totalRequestAcceptance, completedJobsLevels, completedByIndustry } =
    useSelector((state) => state.recruiter);

  // console.log("Total", totalRequestAcceptance);
  // console.log("Jobs Levels", completedJobsLevels);
  // console.log("Industry", completedByIndustry);

  const industries =
    completedByIndustry && completedByIndustry["data"]
      ? completedByIndustry.data.filter(
          (industry) => industry.industry_count != 0
        )
      : [];

  // console.log("All INdustryies", industries);

  useEffect(() => {
    setLoading(true);
    Promise.resolve([
      getTotalRequestAndAcceptance(dispatch, id),
      getRecruiterJobsLevels(dispatch, id),
      getRecruiterJobsCompletedByIndustry(dispatch, id),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <RecruiterWrapper cardColor="#f7f7f7">
      {loading && <SectionLoader />}
      {!loading && (
        <>
          {totalRequestAcceptance && (
            <div className="d-flex justify-content-between mb-2">
              <Card additionalClass="w-50 mr-1 px-5 d-flex flex-column">
                <div style={{ color: "#304FFD", margin: "auto" }}>
                  <svg width="75" height="36">
                    <use href={`${icons}#chart`}></use>
                  </svg>
                </div>
                <p className="mt-2 co-darkgray text-center">Total Requests</p>
                <h3 className="text-center">
                  {totalRequestAcceptance.total_request}
                </h3>
              </Card>
              <Card additionalClass="w-50 ml-1 px-5 d-flex flex-column">
                <div className="" style={{ color: "#FF965D", margin: "auto" }}>
                  <svg width="75" height="36">
                    <use href={`${icons}#chart`}></use>
                  </svg>
                </div>
                <p className="mt-2 co-darkgray text-center">Acceptance Rate</p>
                <h3 className="text-center">
                  {totalRequestAcceptance.acceptance_rate.toFixed(1)}%
                </h3>
              </Card>
            </div>
          )}

          {!loading && completedJobsLevels && (
            <Card additionalClass="p-5 mb-3">
              <h5>Completed Jobs (Level)</h5>
              <div className="mt-3">
                <p className="d-flex justify-content-between align-items-center">
                  Graduate <span>{completedJobsLevels.completed_graduate}</span>
                </p>
                <ProgressBar
                  percent={`${completedJobsLevels.completed_graduate}%`}
                  color="#304FFD"
                />
              </div>
              <div className="mt-3">
                <p className="d-flex justify-content-between align-items-center">
                  Non-Manager{" "}
                  <span>{completedJobsLevels.completed_nonManager}</span>
                </p>
                <ProgressBar
                  percent={`${completedJobsLevels.completed_nonManager}%`}
                  color="#FF965D"
                />
              </div>
              <div className="mt-3">
                <p className="d-flex justify-content-between align-items-center">
                  Manager <span>{completedJobsLevels.completed_manager}</span>
                </p>
                <ProgressBar
                  percent={`${completedJobsLevels.completed_manager}%`}
                  color="#FFD240"
                />
              </div>
              <div className="mt-3">
                <p className="d-flex justify-content-between align-items-center">
                  Senior Manager{" "}
                  <span>{completedJobsLevels.completed_seniorManager}</span>
                </p>
                <ProgressBar
                  percent={`${completedJobsLevels.completed_seniorManager}%`}
                  color="#49C96D"
                />
              </div>
              <div className="mt-3">
                <p className="d-flex justify-content-between align-items-center">
                  Executive{" "}
                  <span>{completedJobsLevels.completed_executiive}</span>
                </p>
                <ProgressBar
                  percent={`${completedJobsLevels.completed_executiive}%`}
                  color="#FF5964"
                />
              </div>
            </Card>
          )}

          {!loading && industries && (
            <Card additionalClass="p-5">
              <h5>Completed Jobs (Industry)</h5>
              {industries.map((industry, index) => (
                <div className="mt-3" key={index}>
                  <p className="d-flex justify-content-between align-items-center">
                    {industry.title} <span>{industry.industry_count}</span>
                  </p>
                  <ProgressBar
                    percent={`${industry.industry_count}%`}
                    color="#304FFD"
                  />
                </div>
              ))}
            </Card>
          )}
        </>
      )}
    </RecruiterWrapper>
  );
};

export default ActivityTracker;
