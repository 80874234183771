/* eslint-disable react/prop-types */
import React, { useState } from "react";
import icons from "../../../../assets/icons/icon-collection.svg";
import { useHistory } from "react-router-dom";
import { Divider, IconButton } from "@material-ui/core";
import Card from "../../../../components/Card";

const JobCard = ({ job }) => {
  const history = useHistory();
  const [, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  console.log(job);

  return (
    <>
      <Card
        additionalClass="mx-md-2 px-4 mb-4"
        additionalStyle={{
          height: "100%",
          display: "flex",
          flexFlow: "column",
          alignItems: "stretch",
          justifyContent: "space-between",
        }}
      >
        <div className="d-flex mb-2 align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              width="30"
              className="rounded-circle"
              src={job.created_by_profile.image_url}
              alt="avatar"
            />
            <span className="d-flex flex-column ml-3">
              <h6 className="mb-0">{job.company?.company_name}</h6>
              <span className="co-gray">
                {job.company?.campany_name}{" "}
                {/* {job.created_by_profile.last_name} */}
              </span>
            </span>
          </div>
          <IconButton onClick={(e) => handleClick(e)}>
            <svg width="20" height="15">
              <use href={`${icons}#dots`}></use>
            </svg>
          </IconButton>
        </div>
        <div className="d-flex justify-content-start my-3">
          <span className="mr-2">
            <svg width="14" height="14" className="mr-1">
              <use href={`${icons}#mappin`}></use>
            </svg>
            {job.location}
          </span>
        </div>
        <Divider />
        <div className="pb-3">
          <b className="d-block my-3 co-primary">{job.role_title}</b>
          <span>
            {job.about_company?.substring(0, 25)}...
            <button
              type="button"
              onClick={() => history.push(`/home/${job.id}/recommended`)}
              className="co-primary bg-white borderless"
            >
              <b>See More</b>
            </button>
          </span>
        </div>
      </Card>
    </>
  );
};

export default JobCard;
