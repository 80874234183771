/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { SectionLoader } from "../../../../../components/PageLoader";
import icons from "../../../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../../../components/CustomButton";
import EmptyState from "../../../../../components/EmptyState";
import Card from "../../../../../components/Card";
import {
  changeRequestStatus,
  getRecruitRequestsByJobs,
} from "../../../../../redux/actions/jobActions";
import Placeholder from "../../../../../assets/icons/placeholder.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";

const Request = ({ search }) => {
  const dispatch = useDispatch();
  const { recruitRequestsByJobs } = useSelector(({ job }) => job);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    Promise.resolve(getRecruitRequestsByJobs(dispatch, id)).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);

  const { state } = useLocation();
  const changeStatus = (request, option) => {
    changeRequestStatus(request, option, () => {
      getRecruitRequestsByJobs(dispatch, id);
    });
  };

  const jobCards = () => {
    return recruitRequestsByJobs && recruitRequestsByJobs[0] ? (
      recruitRequestsByJobs
        .filter((job) => {
          if (
            search &&
            !JSON.stringify(job).toLowerCase().includes(search.toLowerCase())
          ) {
            return false;
          }
          return true;
        })
        .map((request) => {
          return (
            <Card key={request.id} noPadding additionalClass="w-100 p-0 mb-4">
              <div className="d-flex align-items-center status-recruiter px-3 py-2">
                <span className="mr-2">
                  <svg width="13" height="13">
                    <use href={`${icons}#elipse`}></use>
                  </svg>
                </span>
              </div>
              <div className="d-flex flex-row align-items-center mt-2 mx-4">
                <div className="mx-4 py-3">
                  <div>
                    {request.recruiter.profilePicture && (
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                        src={request.recruiter.profilePicture}
                        alt="avatar"
                      />
                    )}

                    {!request.recruiter.profilePicture && (
                      <img src={Placeholder} alt="avatar" />
                    )}
                  </div>
                </div>
                <span className="smalltext co-darkgray mt-1">
                  {request.recruiter.fullname}
                </span>
              </div>

              <div className="w-100 d-flex justify-content-end">
                {request.action_taken === "0" && (
                  <div className="w-50 pb-3 d-flex align-items-center">
                    <button
                      onClick={() => changeStatus(request.id, "reject")}
                      className="co-lightred borderless bg-white mx-3"
                    >
                      Reject
                    </button>
                    <span className="w-100 mx-3">
                      <CustomButton
                        handleClick={() => changeStatus(request.id, "accept")}
                        text="Accept"
                        color="#fff"
                        backgroundColor="#3A6ED4"
                      />
                    </span>
                  </div>
                )}
                {request.action_taken === "1" && (
                  <div className="w-50 pb-3 d-flex align-items-center">
                    {request.isAccepted === "0" && (
                      <button
                        onClick={() => {}}
                        className="co-lightred borderless bg-white mx-3"
                      >
                        Rejected
                      </button>
                    )}
                    {request.isAccepted === "1" && (
                      <span className="w-100 mx-3">
                        <CustomButton
                          handleClick={() =>
                            history.push({
                              pathname: `/recruiter/hire/profile/${request.recruiter.id}/${id}`,
                              state: {
                                job: state.job,
                              },
                            })
                          }
                          text="View Recruiter"
                          color="#fff"
                          backgroundColor="#3A6ED4"
                        />
                      </span>
                    )}
                  </div>
                )}
              </div>
            </Card>
          );
        })
    ) : (
      <EmptyState text="you have no request for this job" />
    );
  };

  return (
    <div className="position-relative w-100">
      {loading && <SectionLoader />}
      {jobCards()}
    </div>
  );
};

export default Request;
