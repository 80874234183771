import React from "react";
import { useSelector } from "react-redux";
import BackButton from "../../../../components/BackButton";
import Card from "../../../../components/Card";
import SubMenu from "../../../../components/SubMenu";
import Layout from "../../../../layout";
import { node, string } from "prop-types";

const subMenuOptions = [
  ["Become-A-Recruiter", "/recruiter/become-recruiter"],
  ["Hire-A-Recruiter", "/recruiter/hire"],
];

const recruiterSubMenuOptions = [
  ["Hire-A-Recruiter", "/recruiter/hire"],
  // ["Dashboard", "/recruiter", "down"],
  ["requests received", "/recruiter/requests"],
  ["insights", "/recruiter/insights"],
  ["shortlists", "/recruiter/shortlist"],
  ["Requests Sent", "/recruiter/sent"],
];

const RecruiterWrapper = ({ children, cardColor }) => {
  const { userData } = useSelector(({ auth }) => auth);

  return (
    <Layout recruiter>
      <div className="d-flex flex-column flex-md-row mt-3">
        <div className="sub-menu-container">
          <BackButton color="#000" />
          <SubMenu
            menuOptions={
              userData.isRecruiter ? recruiterSubMenuOptions : subMenuOptions
            }
            recruiter
          />
        </div>
        <div className="main-content ml-md-4">
          <Card backgroundColor={cardColor} className="mt-5">
            {children}
          </Card>
        </div>
      </div>
    </Layout>
  );
};

RecruiterWrapper.propTypes = {
  children: node.isRequired,
  cardColor: string,
};

RecruiterWrapper.defaultProps = {
  cardColor: "#fff",
};

export default RecruiterWrapper;
