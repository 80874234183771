import React from 'react';
import { NavLink } from 'react-router-dom';
import icons from "../../assets/icons/icon-collection.svg";
import { array, bool } from 'prop-types';
import './index.scss';


const SubMenu = ({ menuOptions, includeSVG, recruiter }) => {
  return (
    <div className={recruiter ? "d-flex flex-md-column recruiter-menu" : "d-flex flex-md-column sub-menu"}>
      {menuOptions.map(option => {
        return (
          <NavLink className="d-flex align-items-center justify-content-between medium-text px-2 py-3 my-1 mr-2 mr-md-0" key={option[0]} to={option[1]}>
            <span className="pl-md-2 d-flex align-items-center">
              {includeSVG && (
                <svg width="22" height="20" className="d-none d-md-block mr-3">
                  <use href={`${icons}#${option[0]}`}></use>
                </svg>
              )}
              <span className="">{option[0]}</span>
            </span>
            <span className="d-none d-md-block">
              <svg width="12" height="20" className={option[2] ? "down mr-2" : "mr-2"}>
                <use href={`${icons}#arrow`}></use>
              </svg>
            </span>

          </NavLink>);
      })}
    </div>
  );
};

SubMenu.propTypes = {
  menuOptions: array.isRequired,
  includeSVG: bool,
  recruiter: bool
};

SubMenu.defaultProps = {
  includeSVG: false,
  recruiter: false
};

export default SubMenu;
