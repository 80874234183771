import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RecruiterWrapper from "../../RecruiterWrapper";
import Dough from "../../../../../assets/images/dough.png";
import { Divider } from "@material-ui/core";
import Card from "../../../../../components/Card";
import CustomButton from "../../../../../components/CustomButton";
import {
  shortlistAndInterviewRatio,
  getRecruiterReview,
} from "../../../../../redux/actions/recruiterAction";

const Performance = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [, setLoading] = useState(false);
  const { shortlistInterviewRatio, reviews } = useSelector(
    (state) => state.recruiter
  );

  // console.log("Reviews", reviews);

  const ratio = shortlistInterviewRatio ? shortlistInterviewRatio[0] : {};
  const rReviews = reviews && reviews.rating ? reviews.rating : [];

  // console.log("All INdustryies", industries);

  useEffect(() => {
    setLoading(true);
    Promise.resolve([
      shortlistAndInterviewRatio(dispatch, id),
      getRecruiterReview(dispatch, id),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <RecruiterWrapper cardColor="#f7f7f7">
      <Card additionalClass="d-flex flex-column justify-content-center m-2 p-3">
        <h5>Shortlist/Interview Ratio</h5>
        <img className="mx-auto mt-4" src={Dough} alt="chart" />
        <div className="d-flex mt-4 justify-content-center">
          <span className="d-flex flex-column justify-content-center align-items-center px-2">
            {ratio && ratio.interviewsCount ? ratio.interviewsCount : 0}
            <p className="co-darkgray smalltest mb-0 p-2">Shortlists</p>
          </span>
          <Divider orientation="vertical" flexItem />
          <span className="d-flex flex-column justify-content-center align-items-center px-2">
            {ratio && ratio.shorlistedCount ? ratio.shorlistedCount : 0}
            <p className="co-darkgray smalltest mb-0 p-2">Interviews</p>
          </span>
          <Divider orientation="vertical" flexItem />
          <span className="d-flex flex-column justify-content-center px-2">
            <h5>
              {ratio && ratio.shortlistAndInterviewPercentage
                ? ratio.shortlistAndInterviewPercentage
                : 0}
              %
            </h5>
          </span>
        </div>
      </Card>
      <Card additionalClass="m-2 p-3">
        <h5>Reviews</h5>
        {rReviews && rReviews.length === 0 && (
          <div className="d-flex mb-3">No Review Yet</div>
        )}
        {rReviews.length > 0 &&
          rReviews.map((review, index) => (
            <div className="d-flex mb-3" key={index}>
              <img
                className="mr-3"
                src={`${review.image_url}`}
                width="40px"
                height="40px"
                alt="avatar"
              />
              <div>
                <b>{review.first_name}</b>
                <p>
                  {review.review_message}
                  {/* <button className="co-primary borderless smalltext bg-white">
                    See More...
                  </button> */}
                </p>
              </div>
            </div>
          ))}

        {/* <div className="d-flex mb-3">
          <img className="mr-3" src={Placeholder} alt="avatar" />
          <div>
            <b>Devon Williamson</b>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi.
              <button className="co-primary borderless smalltext bg-white">
                See More...
              </button>
            </p>
          </div>
        </div> */}
      </Card>
      <div className="m-2">
        <CustomButton
          backgroundColor="#3A6ED4"
          color="#fff"
          text="VIew All Reviews"
        />
      </div>
    </RecruiterWrapper>
  );
};

export default Performance;
