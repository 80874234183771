import React, { useRef,useEffect, useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import icons from "../../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
  import Grid from '@material-ui/core/Grid';
  import { SectionLoader } from "../../../../components/PageLoader";

import { useHistory } from "react-router"; 
import './vote.css';
import ProgressBar from "@ramonak/react-progress-bar";

import {
  getPollResultAction,
} from "../../../../redux/actions/nominationAction";

const VoteResult = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {get_poll_result} = useSelector((store) => store);
  const [sectionLoading, setSectionLoading] = useState(true);


  useEffect(() => {
    Promise.resolve(getPollResultAction(dispatch, 1)).finally(() => 
    {
      setTimeout( ()=>{
        setSectionLoading(false);
      }, 1000)
    }
 
    );
  }, [dispatch, 1]);

  
  return (
     <div additionalclassName="mt-4 p-4 maxh">
     <h5>Poll Results</h5>
      
     {sectionLoading && <SectionLoader />}

    {
      get_poll_result 
      &&
      get_poll_result.get_poll_result
      &&
      get_poll_result.get_poll_result.map( (item, index)=>{
        return(

          <div
          key={index}
          style={{
            padding: "5px",
            maxHeight: "280px",
          }}
          className="col-12 col-md-12 list-achievements">
          <Card
          additionalClass="col-md-12"
          additionalStyle={{
          height: "100%",
          display: "flex",
          marginBottom:'5%',
          flexFlow: "column",
          alignItems: "stretch",
          justifyContent: "space-between",
          }}>

                  
        <div className="d-flex mb-2 align-items-center justify-content-between">
        <div className="d-flex align-items-center">
        <div className="panel panel-primary">
        <div className="panel-heading">
            <h6 className="panel-title fontbold">
                <span className="glyphicon glyphicon-hand-right"></span>
             {item.title}
            
              <br/>

                </h6>
        </div>
        <div className="panel-body">
            <ul className="list-group">
             {
         item.options.map((item2, index2)=>{
                   return(         
            <li className="list-group-item">
                    <div className="checkbox">
                        <label className="co-gray">
                        
                        <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={4}>
                     
                            &nbsp;&nbsp; &nbsp;
                            {
                                item2.option_type !== "others" ?
                            <img src={item2.image_url} 
                              className="md-dd img-circle"
                            data-demo-src={item2.image_url}  
                            data-user-popover="5" alt="" 
                            data-target="webuiPopover20" />
                            :
                            <></>

                                }
                            &nbsp; &nbsp;
                            {
                                item2.option_type == "others" ?
                              <span className="co-gray">{item2.option} </span> 
                                :
                            <span>  {item2.last_name} {item2.first_name} </span> 

                              }
                          </Grid>
                    
                          <Grid item xs={12} sm={12} md={8}>
                            <div style={{marginTop:'2.5%'}}>
                            
                            <ProgressBar completed={item2.score*10}  bgColor={'#32CD32'}/>
                            </div>

                          </Grid>
                          </Grid>
                          

                
                        </label>
                    </div>
                </li>

            )
            })
                    
            }
  
       
       
              
            </ul>
        </div>
        <div className="panel-footer text-center">
          
        </div>
        </div>

                  
          </div>
          </div> 
          
          
        </Card>

</div>

        )
      })
   
 
    }




     
     </div>
 
   );
};

export default VoteResult;
