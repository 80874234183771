import React, { useState,useEffect } from "react"; 
// import Applications from "./Applications";
import { useDispatch, useSelector } from "react-redux";
import { getJobNoAuthAction } from "../../../redux/actions/jobActions";
//import {Helmet} from "react-helmet";
//import MetaTags from 'react-meta-tags';
//import DocumentMeta from 'react-document-meta';
//import ShareLink from 'react-linkedin-share-link'
 
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
// import MyJobs from './MyJobs';
import "./details.scss"; 

const JobListPublicDetails = () => 
{
    
  const [applyLink, setApplyLink] = useState(null);
  const [jobID, setJobID] = useState(null);
  const [originalURL, setOriginalURL] = useState(null);

  const [hasUpdatedMetaTag, setHasUpdatedMetaTag] = useState(false);
   

  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { jobreadonly } = useSelector(({ job }) => job);
  const hashtags = ["workbrook", "job", "workbrookjobs"];
    
  const structuredJSON =         JSON.stringify({
    "@context" : "https://schema.org/",
    "@type" : "JobPosting",
    "title" : jobreadonly.role_title,
    "description" :jobreadonly.about_company ,
    "identifier": {
      "@type": "PropertyValue",
      "name": "Google",
      "value": "1234567"
    },
    "datePosted" : jobreadonly.date_created,
    //"validThrough" : "2017-03-18T00:00",
    "employmentType" : "CONTRACTOR",
    "hiringOrganization" : {
      "@type" : "Organization",
      "name" : "Workbrook",
      // "sameAs" : "http://www.google.com",
      "logo" : "https://web.workbrook.com/favicon.ico"
    },
    "jobLocation": {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": jobreadonly.location,
        "addressLocality": jobreadonly.location,
        "addressRegion": "NG",
        "postalCode": "10002",
        "addressCountry": "NG"
      }
    },
    "baseSalary": {
      "@type": "MonetaryAmount",
      "currency": null,
      "value": {
        "@type": "QuantitativeValue",
        "value": jobreadonly.salary,
        "unitText": null
      }
    }
          
  });
    
  //for SEO
  useEffect( ()=>{

    if(jobreadonly.about_company !==undefined)
    {
      let descMeta = jobreadonly.about_company?.substring(0, 160).replace(/<[^>]+>/g, '');
      let titleMeta=  jobreadonly.role_title; //"Job Vacancy @ "+jobreadonly.campany_name+" -"+ jobreadonly.role_title;
      document.title =  titleMeta;

      //set meta tags 
      //metaAdder('property="description"', descMeta);
      //metaAdder('property="og:description"', descMeta);
      //metaAdder('property="og:title"', jobreadonly.role_title);
      //metaAdder('property="twitter:title"', jobreadonly.role_title);
      //metaAdder('property="twitter:description"', descMeta);
      if(metaAdder('property="og:description"', descMeta) &&
           metaAdder('property="og:title"', jobreadonly.role_title) &&
           metaAdder('property="twitter:title"', jobreadonly.role_title) &&
           metaAdder('property="twitter:description"', descMeta)
      ){
        console.log("Set setHasUpdatedMetaTag to TRUE ");
        setHasUpdatedMetaTag(true);
      }
      
    }

  });

  useEffect(()=>{
    let path = window.location.pathname;
    let currenturl = window.location.href;
    let totalURL = currenturl.replace("job-lists-details","home");
    let jobID = path.split("/")[2];
    setJobID(jobID);
    setApplyLink(totalURL);
    setOriginalURL(currenturl);


    // alert(totalURL);
    Promise.resolve(getJobNoAuthAction(dispatch, jobID)).finally(() =>
    {}
    );
  },[dispatch, 1]);
    
  
  const metaAdder = (queryProperty, value) => {
    // Get an element if it exists already
    let element = document.querySelector(`meta[${queryProperty}]`);
    
    // Check if the element exists
    if (element) {
      // If it does just change the content of the element
      element.setAttribute("content", value);
      return false;
    } else { 
      // It doesn't exist so lets make a HTML element string with the info we want
      element = `<meta ${queryProperty} content="${value}" />`;

      // And insert it into the head
      document.head.insertAdjacentHTML("beforeend", element);
      console.log("Added Meta for "+queryProperty);

      return true;
    }
  };

    
  return (
    <div>
      {
        hasUpdatedMetaTag ? 
          <div>
            <div id="main-content" class="blog-page">
              <div class="container">
                <div class="row clearfix">
                  <div class="col-lg-8 col-md-12 left-box">
                    <div class="card single_post">
                      <div class="body">
                        <div class="img-post">
                          {/* 
                                <img class="d-block img-fluid" src="https://via.placeholder.com/800x280/87CEFA/000000" alt="First slide" />
                          */}  </div>
                        <h3>{jobreadonly.role_title}</h3>
                        {/** Details Content Starts */}
                        <div class="mt-5x pt-5">
                          <div>
                            <a href={applyLink} className="applynowClass">Apply Now</a>
                           
                          </div>
                          <div className="mt-5">
                            <div>
                              <h5><strong>Reports To</strong></h5>
                              <p>{jobreadonly.reports_to}</p>
                            </div>
       
 
                            <div>
                              <br/>
                              <h5><strong>About the company</strong></h5>
                              <br/>
                              <p>
                                <span dangerouslySetInnerHTML={{ __html: jobreadonly.about_company }}></span>
                              </p>
                            </div>

                            <div>
                              <br/>
                              <h5>Applicants Benefits</h5>
                              <p>salary</p>
                            </div>
                            <div>
                              <h5>Salary</h5>
                              <p>{jobreadonly.salary}</p>
                            </div>
                            <div>
                              <h5>Skills Required</h5>
                              <ul class="d-flex flex-wrap mt-3">
                                {
                                  jobreadonly && 
               jobreadonly.skills_required &&    
               jobreadonly.skills_required.map( (item)=>{
                 return(
                   <li class="mr-2 py-1 px-2 mt-1 outlined">{item}</li> 
                 )
               })
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                    
                        {/** End Details Contents */}
 
                      </div>                        
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 right-box">
                 
                   
                    <div class="card">
                      <div class="header text-center">
                        <a href={applyLink} className="applynowClass">Apply to this Job</a>
                      </div>
                        
                      <div
                        className="mx-auto w-75 w-100 mt-4"
                        style={{ textAlign: "center" }}
                      >
                        <h6>Share to Social Network</h6>
                        <div>
                          <FacebookShareButton
                            url={originalURL}
                            quote={jobreadonly.role_title}
                            className="mr-2"
                            hashtag="#workbrook #job"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          <TwitterShareButton
                            url={originalURL}
                            title={jobreadonly.role_title}
                            className="mr-2"
                            hashtags={hashtags}
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>

                          <LinkedinShareButton url={originalURL} className="mr-2">
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>
                   
                          <WhatsappShareButton url={`${jobreadonly.role_title}  ${originalURL}`} className="mr-2">
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                        
                          <div style={{marginTop:'5%'}}></div>
                        </div>
                      </div>
                          
                    </div>
                
                  </div>
                </div>

              </div>
            </div>
    
 
            <script type="application/ld+json">
              {structuredJSON}
            </script>
          </div>
          :
          <div>
       Loading...
          </div>
      }
    </div>
  )
}
export default JobListPublicDetails;