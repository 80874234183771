/* eslint-disable indent */
import {
  
    CREATE_NOMINATION,
    GET_ACTIVE_POLLS,
    GET_ALL_POLLS,
    GET_POLL_RESULTS,
    VOTE_NOMINATOIN
  } from "../actionTypes";  

  const initialState = {
 
   };
 
export default (state = initialState, { type, payload }) => 
{
    switch (type) {
      
         case CREATE_NOMINATION: {
          return {
            ...state,
            nominationCreated: true,
          };
        };
        case VOTE_NOMINATOIN: {
          return {
            ...state,
            nominationCreated: true,
          };
        };
        case GET_ACTIVE_POLLS:
          return {
            get_active_poll : payload,
          };
          case GET_POLL_RESULTS:
            return {
              get_poll_result : payload,
            };

          case GET_ALL_POLLS:
            return {
              get_all_polls : payload,
            };
   
      default:
         return state;
    }
    
  };

   