import { func, string, boolean } from "prop-types";
import React, { useState } from "react";
import CustomInput from "../CustomInput";
import axios from "axios";
function SearchLocationInput({ handleLocationChange, inputStyle, isRequired, value }) {
  const [locations, setLocations] = useState({});
  const searchLocations = async (city) => {
    try {
      const {
        data: { cities },
      } = await axios.get(`https://workbrook.us/anonymous/cities/${city}`);
      setLocations(cities);
    } catch (error) {
      //
    }
  };
  return (
    <div className="search-location-input">
      <div className="custom-text-field mt-3 w-100">
        <label className="d-block" htmlFor="autoCompleteLocations">
          Location
          {isRequired && <span className="co-lightred"> * </span>}
        </label>
        <div className="input-wrapper">
          <input
            className="w-100"
            type="text"
            id="autoCompleteLocations"
            required={isRequired}
            onChange={(e) => {
              if (e.target.value.length > 3) {
                searchLocations(e.target.value);
              }
              handleLocationChange(e.target.value);
            }}
            placeholder="Enter a City"
            inputStyle={inputStyle}
            value={value}
            list="locationsResult"
          />
          
          <datalist id="locationsResult">
            {Object.values(locations).length > 0 &&
              Object.values(locations).map((item) => (
                <option
                  key={`${item.name}, ${item.subcountry}, ${item.country}`}
                  value={`${item.name}, ${item.subcountry}, ${item.country}`}
                />
              ))}
          </datalist>
        </div>
      </div>
    </div>
  );
}

SearchLocationInput.propTypes = {
  handleLocationChange: func.isRequired,
  inputStyle: string,
  isRequired: boolean,
};

CustomInput.defaultProps = {
  inputStyle: "",
};
export default SearchLocationInput;
