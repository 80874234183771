import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import numeral from "numeral";
import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import CustomButton from "../../../../components/CustomButton";
import icons from "../../../../assets/icons/icon-collection.svg";
import Checked from "../../../../assets/icons/Checked.svg";
import { bool, node } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getMyPosition } from "../../../../redux/actions/leagueAction";
import { SectionLoader } from "../../../../components/PageLoader";
import './wrapper.css';

const Wrapper = ({ children, list }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState();
  const league = useSelector(({ league }) => league);

  // console.log("myPosition", league);

  useEffect(() => {
    setLoading(true);
    Promise.resolve(getMyPosition(dispatch))
      .catch((err) => console.log("Error:::"))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="mt-3 px-2 position-relative">
    
      {loading && <SectionLoader />}
      {league.myPosition && (
        <>
          <div className="profile-header  wb-league-header d-flex flex-column flex-md-row">
          
         
            
            <div className="w-100 d-flex flex-column mt-4">
             
              <div className="d-flex mt-3 mb-4">

              <div>
                {/*
            <img
              height="150"
              className="rounded-circle mr-5 text-center"
              src={league.myPosition.image_url}
              alt=""
            />

              <h4 className="black-text text-center">
                {league.myPosition.first_name}{" "}
                <b>{league.myPosition.last_name}</b>
            
        
              </h4>
          
             
         
               <div>
                  {list ? (
                    <CustomButton
                      additionalClass="px-2"
                      handleClick={() => history.push("/league/points")}
                      color="#fff"
                      backgroundColor="#fff"
                      text="View My Points"
                    />
                  ) : (
                    <CustomButton
                      additionalClass="px-2"
                      handleClick={() => history.push("/league/list")}
                      color="#fff"
                      backgroundColor="#fff"
                      text="View Ranking"
                    />
                  )}
                </div>
   */}

            </div>
          

               {/* <div className="mr-2 ">
                  <CustomButton
                    additionalClass="px-2 min-width-2 small-text"
                    color="#fff"
                    backgroundColor="#FD7972"
                    text={
                      league.myPostion
                        ? numeral(
                            league.myPosition.positionInmyState &&
                              league.myPosition.positionInmyState.rank
                          ).format("0o")
                        : "Complete Your Profile to view League"
                    }
                  />
                </div> */}
          <div className="text-center w-100">

          <img 
          className="trophy-icon text-center"
          src="https://www.pinclipart.com/picdir/big/355-3556122_4-succeed-vector-trophy-icon-png-clipart.png" 
          
          />
  
          </div>

                
              </div>
              
             
              <div className="my-1">
                
                {children}
                <div className="d-flex justify-content-end my-5">
                  <button
                    onClick={() => setOpen(true)}
                    className="co-primary borderless bg-white d-flex align-items-center"
                  >
                    About WB League
                    <svg width="22" height="22" className="ml-2">
                      <use href={`${icons}#info`}></use>
                    </svg>
                  </button>
                </div>

                <div className="d-flex justify-content-end my-5">
                  <button
                    onClick={() => history.push("/league/points")}
                    className="co-primary borderless bg-white d-flex align-items-center"
                  >
                    View My Points
                    <svg width="22" height="22" className="ml-2">
                      <use href={`${icons}#info`}></use>
                    </svg>
                  </button>
                </div>

               


                <Dialog
                  open={open}
                  onClose={() => setOpen(false)}
                  aria-labelledby="View details"
                  PaperProps={{
                    style: {
                      width: "30rem",
                      borderRadius: "1rem",
                      padding: "0.75rem",
                    },
                  }}
                >
                  <div className="d-flex justify-content-between align-items-centter">
                    <DialogTitle id="details-dialog-title">
                      About WB League
                    </DialogTitle>
                    <div className="close-button">
                      <IconButton onClick={() => setOpen(false)}>
                        <svg width="30" height="30" className="rounded-circle">
                          <use href={`${icons}#close`}></use>
                        </svg>
                      </IconButton>
                    </div>
                  </div>

                  <DialogContent>
                    {/*<Divider /> */}
                    <div className="d-flex co-darkgray justify-content-between align-items-center my-3">
                      Sign Up
                      <div className="d-flex co-green justify-content-between">
                        <span>5 pts</span>
                        {/* <span className="ml-4">$5</span> */}
                      </div>
                    </div>
                    <div className="d-flex co-darkgray justify-content-between align-items-center my-3">
                      Complete Profile
                      <div className="d-flex co-green justify-content-between">
                        <span>5 pts</span>
                        {/* <span className="ml-4">$5</span> */}
                      </div>
                    </div>
                    <div className="d-flex co-darkgray justify-content-between align-items-center my-3">
                      Executive Referral
                      <div className="d-flex co-green justify-content-between">
                        <span>30 pts</span>
                        {/* <span className="ml-4">$30</span> */}
                      </div>
                    </div>
                    <div className="d-flex co-darkgray justify-content-between align-items-center my-3">
                      Senior Manager Referral
                      <div className="d-flex co-green justify-content-between">
                        <span>20 pts</span>
                        {/* <span className="ml-4">$20</span> */}
                      </div>
                    </div>
                    <div className="d-flex co-darkgray justify-content-between align-items-center my-3">
                      Manager Referral
                      <div className="d-flex co-green justify-content-between">
                        <span>15 pts</span>
                        {/* <span className="ml-4">$15</span> */}
                      </div>
                    </div>
                    <div className="d-flex co-darkgray justify-content-between align-items-center my-3">
                      Non-Manager Referral
                      <div className="d-flex co-green justify-content-between">
                        <span>10 pts</span>
                        {/* <span className="ml-4">$10</span> */}
                      </div>
                    </div>
                    <div className="d-flex co-darkgray justify-content-between align-items-center my-3">
                      Graduate Referral
                      <div className="d-flex co-green justify-content-between">
                        <span>10 pts</span>
                        {/* <span className="ml-4">$10</span> */}
                      </div>
                    </div>
                    <div className="d-flex co-darkgray justify-content-between align-items-center my-3">
                      Invite a friend
                      <div className="d-flex co-green justify-content-between">
                        <span>5 pts</span>
                        {/* <span className="ml-4">$5</span> */}
                      </div>
                    </div>
                    {/* <Divider />
                    <p className="d-flex mt-5">
                      <img src={Checked} alt="checked" />
                      You have to complete your profile before cash out is
                      possible.
                    </p>
                    <p className="d-flex mt-4">
                      <img src={Checked} alt="checked" />
                      Earn minimum of 50 points before you can cash out
                    </p>
                    <p className="d-flex my-4">
                      <img src={Checked} alt="checked" />
                      You cant earn points on &quot;invite a friend&quot; until
                      the friend posts a job or applies for a job and is
                      successful or makes a successful referral.
                    </p> */}
                  </DialogContent>
                </Dialog>
              </div>
          
          
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Wrapper.propTypes = {
  children: node.isRequired,
  list: bool,
};

Wrapper.defaultProps = {
  list: false,
};

export default Wrapper;
