import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import BackButton from "../../../components/BackButton";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import Vote from "./Vote";
import AssignColleague from "./AssignColleague";
import ManageNomination from "./ManageNomination";
import VoteResult from "./VoteResult";

import "./index.scss";
import { getReferralJobs } from "../../../redux/actions/jobActions";
 
 
const subMenuOptions = [
  ["Ongoing Polls", "/nominations/vote"],
  ["Polls Results", "/nominations/result"],
  ];

 const subMenuOptionsAdmin = [
  ["Ongoing Polls", "/nominations/vote"],
  ["Poll Results", "/nominations/result"],
  ["Manage Polls", "/nominations/manage_nomination"]
];

  const Recognition = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const {
    userData: { user_type },
  } = useSelector((store) => store.auth);

  useEffect(() => {
    
    if (user_type === "1") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [dispatch]);

  const displaySection = () => {
    if (pathname.includes("vote")) {
      return <Vote />;
    } 
   else if (pathname.includes("result")) {
        return <VoteResult />;
      } 
      else if (pathname.includes("assign_a_colleague")) {
        return <AssignColleague />;
      } 
    else {
      return <ManageNomination />;
    }
  };
  return (
    <Layout>
      <div className="referrals d-flex mt-3">
        <div className="w-25">
          <BackButton />
          {
            isAdmin? 
            <SubMenu menuOptions={subMenuOptionsAdmin} />
            :
            <SubMenu menuOptions={subMenuOptions} />
          }
         </div>
        <div className="w-75 ml-4 d-flex align-items-center justify-content-center">
          {displaySection()}
        </div>
      </div>
    </Layout>
  );
};

export default Recognition;
