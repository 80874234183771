import React from "react";
import { useDispatch } from "react-redux";
import { Divider } from "@material-ui/core";
import Card from "../../../../components/Card";
import CustomButton from "../../../../components/CustomButton";
import Placeholder from "../../../../assets/icons/placeholder.svg";
import { processQualityOfHire } from "../../../../redux/actions/businessActions";
import moment from "moment";

const QualityCard = ({ user, refreshList, company_id, company_name }) => {
  const dispatch = useDispatch();

  const processRequest = (answer) => {
    const data = {
      company_id,
      answer,
      applicant_id: user.applicant_id,
    };
    Promise.resolve(processQualityOfHire(dispatch, data)).finally(() => {
      refreshList();
    });
  };

  return (
    <Card noPadding additionalClass="pt-3 mt-3">
      <div className="d-flex justify-content-between w-100 px-4">
        <img src={Placeholder} alt="placeholder" />
        <div className="w-100 d-flex flex-column mt-3 ml-3 mb-4">
          <span>
            Is{" "}
            <strong>
              {user.first_name} {user.last_name}
            </strong>{" "}
            still employed in {company_name}?
          </span>
          <span>
            <strong>Role:</strong> {user.role_title}
          </span>
          <span>
            <strong>Hired on:</strong>{" "}
            {moment(user.date_created).format("Do MMM YYYY")}
          </span>
        </div>
      </div>
      <Divider />
      <div className="d-flex">
        <CustomButton
          additionalClass="py-3"
          text="Yes"
          borderColor="#fff"
          backgroundColor="#fff"
          color="#3a6ed4"
          handleClick={() => processRequest("yes")}
        />
        <Divider orientation="vertical" flexItem />
        <CustomButton
          additionalClass="py-3"
          text="No"
          borderColor="#fff"
          backgroundColor="#fff"
          color="#E8323E"
          handleClick={() => processRequest("no")}
        />
      </div>
    </Card>
  );
};

export default QualityCard;
