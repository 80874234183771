import React, { useEffect,useRef,useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import icons from "../../../../assets/icons/icon-collection.svg";
import { useHistory } from "react-router";
import CustomInput from "../../../../components/CustomInput";
import ReactStars from "react-rating-stars-component";
import CustomTextArea from "../../../../components/CustomTextArea";
import cogoToast from "cogo-toast";
import { uploadFile } from "../../../../utils/helpers";

import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import TextField from '@material-ui/core/TextField';
 import Autocomplete from '@material-ui/lab/Autocomplete';
 import { makeStyles } from '@material-ui/core/styles';
import './assigncolleague.css';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

// Imagine you have a list of languages that you'd like to autosuggest.
const languages = [
  {
    id:50,
    name: 'Peter Tymon',
    year: 'Snr Backend Developer'
  },
  {
    id:24,
    name: 'Asekun Kufofiji',
    year: 'CEO Green Harbour'
  },
  { id:100,
    name: 'Jimi Edet',
    year: 'H.R Expert at Facebook'
  },
   
];

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
       fontSize: 18,
    },
  },
});
 

const AssignColleague = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const uploadInputRef = useRef(0);
 

const handleChangeUpload = ({ target: { files } }) => {
  if (files && files[0]) {
    setIsUploading(true);

    Promise.resolve(
      uploadFile(
        files[0],
        "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
      )
    )
      .then(({ data, success }) => {
        if (success) {
          //addDocLink(data);

         // addDocLinkObject({ link: data, name: files[0].name });
        } else {
          cogoToast.error("Unsuccessful document upload", {
            hideAfter: 4,
            position: "top-center",
          });
        }
      })
      .finally(() => {
        setIsUploading(false);
      });
  }
};

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};


  const ratingChanged = (newRating) => {
    console.log(newRating);
  };


  const handleRefClick = () => {
    uploadInputRef.current.click();
  };
 
  return (
    <Card additionalClass="mt-4 p-4  maxh">
      
      <h5>Recognise a colleague</h5>
  
      <div
                
                style={{
                  padding: "10px",
                  maxHeight: "280px",
                }}
                className="col-12 col-md-12 list-achievements">
                
                <Autocomplete
                    id="country-select-demo"
                    style={{ width: '100%' }}
                    options={languages}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <React.Fragment>
                      <img src={"https://i.pravatar.cc/"+option.id} 
                      data-demo-src={"https://i.pravatar.cc/"+option.id} 
                      className="search-img"
                      data-user-popover="5" alt="" data-target="webuiPopover20" />

                        {option.name} ({option.year})  
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search  Colleague Name, Email or Position"
                        variant="outlined"
                        style={{
                          backgroundColor:'#fff'
                        }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

              <br/>
              
              <p> What did the employee do?</p>
                    <CustomInput
                      type="text"
                      name="supported_by"
                      placeholder=""
                      label=""
                      id="supported_by"
                    />

                <br/>

                <p> How did the employee do it?</p>

                <CustomTextArea
                      name="comment"
                      label=""
                      id="comment"
                    />
            

                {/*  <CustomInput
                    type="text"
                    name="supported_by"
                    placeholder=""
                    label=""
                    id="supported_by"
                  /> */}

                <div>
                <br/>
                      <input
                        ref={uploadInputRef}
                        type="file"
                        onChange={handleChangeUpload}
                        style={{ display: "none" }}
                      />

                      <CustomButton
                        backgroundColor="#fff"
                        color="#000"
                        text="Add Attachments (Any of Video, Audo, Image or Docs if available)"
                        handleClick={handleRefClick}
                        loading={isUploading}
                      />
                    </div>


                    <div>
                      <br/><br/>

                    <CustomButton
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text="Invite Colleague to Confirm"
                      loading={isUploading}
                    />

                    
                    </div>

  {/*       
           <Accordion>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Revenue or sales you increased for the company. - David Oluyale
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent finibus, nunc et mollis dignissim, massa erat pretium sem, quis sodales arcu turpis pharetra lorem.
                    </p>
                 <p>
                   Requested By: David Oluyale
                 </p>

                 <Divider/>
                <br/>
                 <div>
                  <p>How are you recommended to the applicant ?</p>
                 <CustomInput
                  type="text"
                  name="supported_by"
                  placeholder=""
                  label=""
                  id="supported_by"
                />

            <p>Rate  the Applicant</p>
            <ReactStars
                count={5}
                onChange={ratingChanged}
                size={24}
                activeColor="#ffd700"
              />

          <CustomTextArea
                  name="comment"
                  label="Comment"
                  id="comment"
                />

        <CustomButton
            backgroundColor="#3A6ED4"
            color="#fff"
            text="Submit"
             
          />

                 </div>
                </AccordionItemPanel>
            </AccordionItem>

              <div>
                <br/>
              </div>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                   Won the best Award in Movie production. - Ajayi Olaide
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent finibus, nunc et mollis dignissim, massa erat pretium sem, quis sodales arcu turpis pharetra lorem.
                    </p>
                 <p>
                   Requested By: David Oluyale
                 </p>

                 <Divider/>
                <br/>
                 <div>
                  <p>How are you recommended to the applicant ?</p>
                 <CustomInput
                  type="text"
                  name="supported_by"
                  placeholder=""
                  label=""
                  id="supported_by"
                />

            <p>Rate  the Applicant</p>
            <ReactStars
                count={5}
                onChange={ratingChanged}
                size={24}
                activeColor="#ffd700"
              />

          <CustomTextArea
                  name="comment"
                  label="Comment"
                  id="comment"
                />

        <CustomButton
            backgroundColor="#3A6ED4"
            color="#fff"
            text="Submit"
             
          />

                 </div>
                </AccordionItemPanel>
            </AccordionItem>


            
          


        </Accordion>
*/}

    </div>



    </Card>

  );
};


export default AssignColleague;
