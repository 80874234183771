import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_SETTINGS, UPDATE_NOTIFICATION_SETTINGS,
} from '../actionTypes';
    
const initialState = {
  notificationSettings: {},
  notifications: [],
};
    
export default (state = initialState, { type, payload }) => {
  switch (type) {
  case UPDATE_NOTIFICATION_SETTINGS:
  case GET_NOTIFICATION_SETTINGS:
    return {
      ...state,
      notificationSettings: payload,
    };
  case GET_NOTIFICATIONS:
    return {
      ...state,
      notifications: payload,
    };
  default:
    return state;
  }
};