import React from "react";
import { useLocation } from "react-router-dom";
// import SearchInput from "../../../../components/CustomSearchInput";
import RecruiterWrapper from "../RecruiterWrapper";
import General from "./General";
import ShortlistedApplicants from "./ShortlistedApplicants";

const ShortLists = () => {
  const { pathname } = useLocation();

  const getView = () => {
    if (pathname.includes("applicants")) {
      return (
        <div className="w-100 mt-3">
          <ShortlistedApplicants />
        </div>
      );
    } else {
      return <General />;
    }
  };

  return (
    <RecruiterWrapper>
      <div className="row m-4">
        <h3 className="mb-5">
          <b>Shortlists</b>
        </h3>
        {/* <SearchInput /> */}
        {getView()}
      </div>
    </RecruiterWrapper>
  );
};

export default ShortLists;
