import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import Visa from "../../../../assets/icons/visa.svg";
// import Mastercard from "../../../../assets/icons/mastercard.svg";
import Check from "../../../../assets/icons/check1.svg";
import { getAllEducationAction } from "../../../../redux/actions/educationActions";
import { getOtherUserAction, getProfileAction } from "../../../../redux/actions/profileActions";

import Uncheck from "../../../../assets/icons/uncheck.svg";
import { logProfileVisits } from "../../../../redux/actions/insightActions";
import {
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "../../../../components/CustomButton";
import {
  deleteBankAccount,
  withdrawFromWallet,
  transferFromAvailable,
} from "../../../../redux/actions/walletActions";
import CustomInput from "../../../../components/CustomInput";
import { getAccountBalance } from "../../../../redux/actions/walletActions";
import cogoToast from "cogo-toast";

const Account = ({ account, refreshList, transfer, type }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const { profile } = useSelector((store) => store);
  const { profileData } = profile;
  const { id, stage } = useParams();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    amount: "",
    password: "",
  });
  // const bankCode = account.bankCode;

  // const { accounts } = useSelector((state) => state.wallet);

  // console.log("Account::", account);
 
  
  useEffect(() => {
    
    Promise.all([
      getProfileAction(dispatch),
      getAllEducationAction(dispatch),
     
    ])
  }, [dispatch]);
  useEffect(() => {
   
   console.log(id)
    if (id) {
   
      getOtherUserAction(dispatch, id);
      logProfileVisits(id);
    }
 }, [dispatch, id]);
  const handleClick = () => {
    setChecked(!checked);
  };

  const handleDelete = (id) => {
    // console.log("ID", id);
    Promise.resolve(deleteBankAccount(dispatch, id)).finally(() => {
      refreshList();
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChange = ({ target: { id, value } }) => {
    const newValue = {};
    newValue[id] = value;
    // console.log(newValue);
    setState({ ...state, ...newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    
    if (
      !profileData?.first_name ||
      !profileData?.about ||
      !profileData?.resume ||
      !profileData?.last_name ||
      !profileData?.gender ||
      !profileData?.dob ||
      !profileData.country ||
      !profileData.location ||
      !profileData.experience
    ) {
      cogoToast.error("Please complete your profile");
      return;
    }
    // console.log("State", state);

    setLoading(true);
    if (transfer) {
      const payload = {
        amount: parseFloat(state.amount * 100),
        password: state.password,
      };
      Promise.resolve(transferFromAvailable(dispatch, payload)).finally(() => {
        setOpenDialog(false);
        setLoading(false);
        getAccountBalance(dispatch);
      });
    } else {
      let payload;
      if (type === "paystack") {
        payload = {
          account_number: account.account_number,
          bank_code: account.bankCode,
          type: "nuban",
          currency: "NGN",
          amount: parseFloat(state.amount * 100),
        };
      } else {
        payload = {
          account_number: account.account_number,
          bank_code: account.bankCode,
          type: "stripe",
          currency: "USD",
          amount: parseFloat(state.amount * 100),
        };
      }

      Promise.resolve(withdrawFromWallet(dispatch, payload)).finally(() => {
        setOpenDialog(false);
        setLoading(false);
      });
    }
  };

  return (
    <Card className={classes.card} style={{ backgroundColor: "#F7F7F7" }}>
      <CardContent>
        <div
          variant="body2"
          className="d-flex flex-row justify-content-between"
        >
          {transfer ? null : (
            <>
              <div className={classes.intext}>
                <div className="mb-2">{account.bank}</div>
                <div className="mb-2">
                  {account.account_number.substring(0, 6).replace(/\d/g, "*")}{" "}
                  {account.account_number.substring(4)}
                </div>
              </div>
              {checked ? (
                <img
                  src={Check}
                  alt="check"
                  style={{ width: "20px" }}
                  className={classes.cardlogo}
                  onClick={handleClick}
                />
              ) : (
                <img
                  src={Uncheck}
                  alt="check"
                  style={{ width: "20px" }}
                  className={classes.cardlogo}
                  onClick={handleClick}
                />
              )}
            </>
          )}
        </div>
        <Typography variant="body2" component="p" className={classes.intext}>
          {transfer
            ? "Transfer from available balance to book balance"
            : account.account_name}
        </Typography>
        {transfer && (
          <p
            className="pt-2"
            style={{
              color: "red",
              fontSize: "12px",
            }}
          >
            Amount transferred to from available balance to book balance can no
            longer be withdrawn
          </p>
        )}
        <CustomButton
          backgroundColor="#3A6ED4"
          color="#fff"
          text={transfer ? "Transfer" : "Withdraw"}
          additionalClass="mt-5 mb-2"
          handleClick={() => setOpenDialog(true)}
        />
        {transfer ? null : (
          <div className="d-flex justify-content-center">
            <Link to="#" onClick={() => handleDelete(account.id)}>
              Delete Account
            </Link>
          </div>
        )}

        {/* Confirm Password Dialog */}
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              width: "35rem",
              borderRadius: "1rem",
              padding: "0.75rem",
            },
          }}
        >
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <h4>
                Enter amount to {transfer ? "transfer" : "withdraw"} and your
                password
              </h4>
              <CustomInput
                name="amount"
                type="number"
                id="amount"
                label="Enter Amount"
                isRequired={true}
                placeholder="amount"
                value={state.amount}
                onChange={handleChange}
              />
              <CustomInput
                name="password"
                type="password"
                id="password"
                label="Enter Your Password"
                isRequired="true"
                placeholder="password"
                value={state.password}
                onChange={handleChange}
              />
              <CustomButton
                backgroundColor="#3A6ED4"
                color="#fff"
                type="submit"
                text="CONFIRM WITHDRAWAL"
                additionalClass="mt-5 mb-2"
                loading={loading}
                disabled={[!state.amount, !state.password].includes(true)}
              />
            </form>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles({
  card: {
    background: "#F7F7F7",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "0 20px 0 20px",
    fontSize: "14px",
    marginBottom: "20px",
  },
  intext: {
    fontSize: "14px",
    // marginBottom: "10px",
  },
  cardlogo: {
    width: "45px",
    marginRight: "20px",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default Account;
