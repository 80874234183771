import cogoToast from "cogo-toast";
import httpRequest from "../../ajax";

import { 
  CREATE_NOMINATION,
  GET_ACTIVE_POLLS,
  GET_ALL_POLLS,
  VOTE_NOMINATOIN,
  GET_POLL_RESULTS,
  ERROR
} from "../actionTypes";

  
export const createNominationAction = (dispatch, data) =>
{
 
  console.log("createNominationAction"+JSON.stringify(data));
  httpRequest("post", "/nomination/create", data)
  .then(({ data: { data } }) => {
    const action = { type: CREATE_NOMINATION, payload: data };
    dispatch(action);
     return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

}


export const getActivePollsAction = (dispatch, page) =>{
 
  httpRequest("get", `/nomination/activepolls`)
   .then(({ data: { data } }) => {
    
    console.log("<<<<getActivePollsAction>>>"+JSON.stringify(data));

       const action = { type: GET_ACTIVE_POLLS, payload: data };
     dispatch(action);
     return action;
   })
   .catch(({ response }) => ({
     payload: response?.data?.message,
     type: ERROR,
   }));
 }


 export const getPollResultAction = (dispatch, page) =>{
 
  httpRequest("get", `/nomination/results`)
   .then(({ data: { data } }) => {
    
      console.log("<<<<<<getPollResultAction>>>>>"+JSON.stringify(data));

      const action = { type: GET_POLL_RESULTS, payload: data };
     dispatch(action);
     return action;
   })
   .catch(({ response }) => ({
     payload: response?.data?.message,
     type: ERROR,
   }));
 }




 export const getAllPollsAction = (dispatch, page) =>{
 
  httpRequest("get", `/nomination/allpolls`)
   .then(({ data: { data } }) => {
    console.log("getActivePollsHR "+JSON.stringify(data));

       const action = { type: GET_ALL_POLLS, payload: data };
     dispatch(action);
     return action;
   })
   .catch(({ response }) => ({
     payload: response?.data?.message,
     type: ERROR,
   }));
 }



export const voteNominationAction = (dispatch, data) =>
{
  console.log("voteNominationAction"+JSON.stringify(data));

  httpRequest("post", "/nomination/vote", data)
  .then(({ data: { data } }) => {
    const action = { type: VOTE_NOMINATOIN, payload: data };
    dispatch(action);
     return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

}

 
 