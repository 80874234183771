import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { bool, func } from "prop-types";
import { TextField, Divider } from "@material-ui/core";
import icons from "../../../../../../assets/icons/icon-collection.svg";
import { updateProfileAction } from "../../../../../../redux/actions/profileActions";
import { ButtonLoader } from "../../../../../../components/ButtonLoader";
import {
  ternaryResolver,
  anonymousFunc,
} from "../../../../../../utils/helpers";

const AddLink = ({ open, handleClose }) => {
  const [isSaving, setIsSaving] = useState(false);
  const { profileData } = useSelector((store) => store.profile);
  const [state, setState] = useState({
    linkdn: profileData.links ? profileData.links[0]?.linkdn : "",
    twitter: profileData.links ? profileData.links[0]?.twitter : "",
    facebook: profileData.links ? profileData.links[0]?.facebook : "",
    potfolio: profileData.links ? profileData.links[0]?.potfolio : "",
  });

  const dispatch = useDispatch();

  const handleChange = ({ target: { name, value } }) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSaving(true);
    const payload = {
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      gender: profileData.gender,
      dob: profileData.dob,
      state: profileData.state,
      country: profileData.country,
      industry: profileData.industry,
      image_url: profileData.image_url,
      resume: profileData.resume,
      phone: profileData.phone,
      links: [state],
      isShowOnProfile: 1,
      about: profileData.about,
    };

    const promise = updateProfileAction(payload, dispatch);
    Promise.resolve(promise).finally(() => {
      setIsSaving(false);
      handleClose();
    });
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      linkdn: profileData.links ? profileData.links[0]?.linkdn : "",
      twitter: profileData.links ? profileData.links[0]?.twitter : "",
      facebook: profileData.links ? profileData.links[0]?.facebook : "",
      potfolio: profileData.links ? profileData.links[0]?.potfolio : "",
    }));
  }, [open, profileData]);

  const shouldDisableButton = !Object.values(state)
    .map((val) => !!val && val.includes("http"))
    .includes(true);

  return (
    <div>
      <Dialog
        open={open}
        onClose={ternaryResolver(isSaving, anonymousFunc, handleClose)}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "20rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        <DialogTitle id="form-dialog-title">
          <svg width="22" height="22" className="mr-2">
            <use href={`${icons}#link`}></use>
          </svg>
          <span className="medium-text co-primary">Add New Link</span>
        </DialogTitle>
        <Divider />
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <div className="mt-4">
              <TextField
                onChange={handleChange}
                name="linkdn"
                value={state.linkdn}
                className="w-100 mb-4"
                variant="outlined"
                label="LinkedIn URL"
              />
              <TextField
                onChange={handleChange}
                name="potfolio"
                value={state.potfolio}
                className="w-100 mb-4"
                variant="outlined"
                label="Portfolio URL"
              />
              <TextField
                onChange={handleChange}
                name="twitter"
                value={state.twitter}
                className="w-100 mb-4"
                variant="outlined"
                label="Twitter URL"
              />
              <TextField
                onChange={handleChange}
                name="facebook"
                value={state.facebook}
                className="w-100 mb-4"
                variant="outlined"
                label="Facebook URL"
              />
            </div>
          </DialogContent>
          <Divider />
          <DialogActions className="d-flex justify-content-start">
            <button
              disabled={isSaving || shouldDisableButton}
              type="submit"
              className={`ml-3 mt-2 bg-white transparent-border dialog-save ${ternaryResolver(
                shouldDisableButton,
                "co-darkgray",
                "co-primary"
              )}`}
            >
              {isSaving ? (
                <ButtonLoader color="#3a6ed4" width="40" height="40" />
              ) : (
                <b>Save</b>
              )}
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

AddLink.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  success: bool.isRequired,
  setSuccess: func.isRequired,
};

export default AddLink;
