import { node } from "prop-types";
import React, { Component } from "react";
import ErrorComponent from "./ErrorComponent";

export default class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorComponent heading="Oops! An Error Has Occured" />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
};
