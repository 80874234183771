import cogoToast from "cogo-toast";
import httpRequest from "../../ajax";
import {
  APPLY_TO_JOB,
  CREATE_JOB,
  CREATE_JOB_UPLOAD_EXCEL,
  EDIT_JOB,
  DELETE_SAVED_JOBS,
  DELETE_JOBS,
  POST_JOB_VALIDATION,
  POST_HIRING,
  POST_PERFORMANCE,
  POST_EMPLOYEEPERFORMANCE,
  POST_SENDBACK,
  ERROR,
  GET_STATUS,
  GET_VERIFIED,
   POST_EDITED_PERFORMANCE,
  GET_ALL_JOBS,
  GET_PERFORMANCE,
  GET_EACHPERFORMANCE,
  GET_JOB_NO_AUTH,
  GET_ALL_RECOMMENDATION_JOBS,
  GET_OFFERS_JOBS,
  GET_APPLIED,
  GET_JOB,
  GET_JOB_LIST,
  GET_JOB_LEVELS,
  GET_MY_JOBS,
  GET_SAVED_JOBS,
  RESET_JOB_CREATED,
  SAVE_JOB,
  REPORT_JOB,
  SEARCH_JOBS,
  // LIKE_UNLIKE_JOB,
  GET_REFERRAL_JOBS,
  RESET_JOBS,
  GET_ADMIN_JOBS,
  APPLY_TO_RECRUITER_JOB,
  RECRUIT_REQUESTS,
  RECRUIT_REQUESTS_BY_JOBS,
  GET_BUSINESS_JOB,
  GET_VERIFICATION, //
  GET_EMPLOYEE,
} from "../actionTypes";

export const createJobAction = (dispatch, data) => {
  httpRequest("post", "/jobs/create", data)
    .then(({ data: { data } }) => {
      const action = { type: CREATE_JOB, payload: data };
      dispatch(action);
      getJobsAction(dispatch, 1);
      window.location.href = "myjobs";
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};
export const getStatus = (dispatch, user_id) => {
  return httpRequest("post", "/jobs/check-status", {user_id})
    .then(({ data }) => {
      
      const action = { type: GET_STATUS, payload: data};
      dispatch(action);
      
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};
export const postJobValidation = (dispatch, data) => {
  return httpRequest("post", "/jobs/validate", data)
    .then(({ data }) => {
      
      const action = { type: POST_JOB_VALIDATION, payload: data};
      dispatch(action);
      
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const createJobFromExcelAction = (dispatch, data) => {
  httpRequest("post", "/jobs/create/xls", data)
    .then(({ data: { data } }) => {
      const action = { type: CREATE_JOB_UPLOAD_EXCEL, payload: data };
      dispatch(action);
      getJobsAction(dispatch, 1);
      window.location.href = "myjobs";
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const editJobAction = (dispatch, data) => {
  // alert("**###=====editJobAction===="+JSON.stringify(data));

  httpRequest("post", `/jobs/${data.company_id}/update`, data)
    .then(({ data: { data } }) => {
      const action = { type: EDIT_JOB, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const getEitherJob = (dispatch, jobId, isBusiness = false) => {
  if (isBusiness) {
    // console.log("I am isBusiness");
    getBusinessJob(dispatch, jobId);
  } else {
    getJob(dispatch, jobId);
  }
};

export const getJobListAction = (dispatch) => {
  httpRequest("get", `/all-job-list`)
    .then(({ data: { data } }) => {
      console.log(
        "############==getJobListAction==############" + JSON.stringify(data)
      );

      const action = { type: GET_JOB_LIST, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const getJob = (dispatch, id) => {
  httpRequest("get", `/jobs/${id}`)
    .then(({ data: { data } }) => {
      //console.log("############==getJob==############"+JSON.stringify(data));
      const action = { type: GET_JOB, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const getJobNoAuthAction = (dispatch, id) => {
  httpRequest("get", `/jobs/noauth/${id}`)
    .then(({ data: { data } }) => {
      console.log("############==getJob==############" + JSON.stringify(data));
      const action = { type: GET_JOB_NO_AUTH, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const clearJobCreated = (dispatch) =>
  dispatch({ type: RESET_JOB_CREATED });

export const getJobsAction = (dispatch, page) =>
  httpRequest("get", `/jobs/${page}/20`)
    .then(({ data: { data } }) => {
      //  console.log("############==getJobsAction==############"+JSON.stringify(data));

      const action = { type: GET_ALL_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));


export const getJobsRecommendationAction = (dispatch, page) =>
  httpRequest("get", `/jobs/suggesstions/${page}/20`)
    .then(({ data: { data } }) => {
      const action = { type: GET_ALL_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getOffersAction = (
  dispatch
  //,page
) =>
  httpRequest("get", `/insiight/jobOffers`)
    .then(({ data: { data } }) => {
      // console.log("Offers", data);
      const action = { type: GET_OFFERS_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const resetAllJobs = (dispatch) => dispatch({ type: RESET_JOBS });

export const getMyJobsAction = (dispatch) =>
  httpRequest("get", "/jobs/byMe/1/50")
    .then(({ data: { data } }) => {
      const action = { type: GET_MY_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getJobLevelsAction = (dispatch) =>
  httpRequest("get", "/jobs/levels")
    .then(({ data: { data } }) => {
      const action = { type: GET_JOB_LEVELS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getApplications = (dispatch) =>
  httpRequest("get", "/jobs/application")
    .then(({ data: { data } }) => {
      const action = { type: GET_APPLIED, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getSavedJobs = (dispatch, page) =>
  httpRequest("get", `/jobs/saved/${page}/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_SAVED_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getRecruiterRequestsJobs = (dispatch) =>
  httpRequest("get", `/jobs/requestToRecruit/recruiter`)
    .then(({ data: { data } }) => {
      const action = { type: RECRUIT_REQUESTS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const changeRequestStatus = (id, option, action) =>
  httpRequest("put", `/jobs/requestToRecruit/${id}/${option}`)
    .then(() => {
      return action();
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const getRecruitRequestsByJobs = (dispatch, id) =>
  httpRequest("get", `/jobs/requestToRecruit/${id}`)
    .then(({ data: { data } }) => {
      const action = { type: RECRUIT_REQUESTS_BY_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });
export const recruitRequest = (id) =>
  httpRequest("post", `/jobs/requestToRecruit/${id}`)
    .then(() => {
      return;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const saveJobAction = (dispatch, job_id) =>
  httpRequest("post", "/jobs/saved/create", { job_id })
    .then(({ data: { data } }) => {
      const action = { type: SAVE_JOB, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

// report job
export const reportJobAction = (dispatch, job_id, reason) => {
  const data = {
    job_id: job_id,
    reason: reason,
  };

  httpRequest("post", "/jobs/reportPost", data)
    .then(({ data: { data } }) => {
      const action = { type: REPORT_JOB, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const applyAction = (dispatch, job_id, refCode, c_id, func) => {
  const url = c_id
    ? `/jobs/application/create/${c_id}`
    : "/jobs/application/create";
  return httpRequest("post", url, { job_id, refCode })
    .then(({ data: { data } }) => {
      const action = { type: APPLY_TO_JOB, payload: data };
      dispatch(action);
      func();
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

// Apply to recuiter's jobs
export const applyToRecruiterJob = (dispatch, job_id, recruiterId, func) => {
  // console.log("Applying to Recruiters Jobs");
  const rData = {
    job_id: parseInt(job_id, 10),
    recruiterId: parseInt(recruiterId, 10),
  };

  return httpRequest("post", "/jobs/application/applyToRercruiterJob", rData)
    .then(({ data: { data } }) => {
      const action = { type: APPLY_TO_RECRUITER_JOB, payload: data };
      dispatch(action);
      func();
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const searchJobAction = (dispatch, term, navigate = () => {}) =>
  httpRequest("get", `/jobs/${term}/search`)
    .then(({ data: { data } }) => {
      navigate();
      const action = { type: SEARCH_JOBS, payload: { data, term } };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      cogoToast.error(`No result for ${term}`);
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const deleteSavedJob = (dispatch, job_id) =>
  httpRequest("post", `/jobs/saved/${job_id}/delete`, {})
    .then(({ data: { data } }) => {
      const action = { type: DELETE_SAVED_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const deleteJob = (dispatch, jobid) => {
  //alert("deleting "+job.company_id);
  httpRequest("post", `/jobs/${jobid}/delete`, {})
    .then(({ data: { data } }) => {
      const action = { type: DELETE_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};
export const likeUnlikeJob = (dispatch, data) =>
  httpRequest("post", "/jobs/like/likeUnLike", data)
    .then
    // ({ data: { data } }) => {
    // const action = { type: LIKE_UNLIKE_JOB, payload: data };
    // console.log("data payload", data);
    // dispatch(action);
    // return action;
    // }
    ()
    .catch(({ response }) => {
      // console.log("error res", response);
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const getReferralJobs = (dispatch) =>
  httpRequest("get", `/insiight/referrals`)
    .then(({ data: { data } }) => {
      // console.log("in action");
      const action = { type: GET_REFERRAL_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getAdminJobs = (dispatch, page) =>
  httpRequest("get", `/admin/jobs/${page}/10`)
    .then(({ data: { data } }) => {
      // console.log("admin jobs", data);
      const action = { type: GET_ADMIN_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

// Get Single business job...
export const getBusinessJob = (dispatch, id) =>
  httpRequest("get", `/business/jobs/${id}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_BUSINESS_JOB, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getVerification = (dispatch, user_id) =>
  httpRequest("post", "/jobs/verify-user", { user_id })
    .then(({ data }) => {
      const isSuccess = data.success === true;
      const payload = { success: isSuccess };
      const action = { type: GET_VERIFICATION, payload };

      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
export const getPerformance = (dispatch, employee_id) =>
  httpRequest("get", `/employee-performance/${employee_id}`)
    .then(({ data }) => {
      const action = { type: GET_PERFORMANCE, payload: data };

      dispatch(action);

      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
export const getEachPerformance = (dispatch) =>
  httpRequest("get", "/employee-performance/")
    .then(({ data: { data } }) => {
      const action = { type: GET_EACHPERFORMANCE, data };

      dispatch(action);
      console.log(data);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getEmployee = (dispatch, id) =>
  httpRequest("get", `/employee-performance/supervisor-subordinates/${id}`)
    .then(({ data }) => {
      const action = { type: GET_EMPLOYEE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const postperformances = (dispatch, data) => {
  httpRequest("post", "/employee-performance/", data)
    .then(({ data }) => {
      const action = { type: POST_PERFORMANCE, payload: data };
      dispatch(action);
      getJobsAction(dispatch, 1);

      return action;
    })
    .catch(({ response }) => {
      // Dispatch an error action
      dispatch({ type: ERROR, payload: response?.data?.message });
    });
};
export const postemployeeperformance = (dispatch, data, employee_id) => {
  httpRequest("put", `/employee-performance/${employee_id}`, data)
    .then(({ data }) => {
      const action = { type: POST_EMPLOYEEPERFORMANCE, payload: data };
      dispatch(action);
      getJobsAction(dispatch, 1);

      return action;
    })
    .catch(({ response }) => {
      // Dispatch an error action
      dispatch({ type: ERROR, payload: response?.data?.message });
    });
};
export const editEmployee = (dispatch, profileId, data) => {
  httpRequest("put", `/employee-performance/${profileId}`, data)
    .then(({ data }) => {
      const action = { type: POST_EDITED_PERFORMANCE, payload: data };
      dispatch(action);
      getJobsAction(dispatch, 1);

      return action;
    })
    .catch(({ response }) => {
      // Dispatch an error action
      dispatch({ type: ERROR, payload: response?.data?.message });
    });
};
export const postsendback = (dispatch, data) => {
  httpRequest("post", "/employee-performance/send-back", data)
    .then(({ data }) => {
      const action = { type: POST_SENDBACK, payload: data };
      dispatch(action);

      return action;
    })
    .catch(({ response }) => {
      // Dispatch an error action
      dispatch({ type: ERROR, payload: response?.data?.message });
    });
};
export const postHiring = (dispatch, datas) =>
  httpRequest("post", "/jobs/candidate_recruitment", datas)
    .then(({ data }) => {
      const action = { type: POST_HIRING, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
