import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton";
import { IoPersonSharp } from "react-icons/io5";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ButtonLoader } from "../../../../components/ButtonLoader";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { Toast, toast } from "react-toastify";
import cogoToast from "cogo-toast";
import CustomTextArea from "../../../../components/CustomTextArea";
import { BsPeople, BsGraphUp } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import {
  getEmployee,
  getPerformance,
  editEmployee,
  postperformances,
  postsendback,
} from "../../../../redux/actions/jobActions";

function SupervisorReview() {
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [activeTab, setActiveTab] = useState("Leadership");
   const [loading, setLoading] = useState(false);
   
  const [checkedall, setChecked] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [checkedPlan, setCheckedPlan] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState({ id: '', username: '' });
  const dispatch = useDispatch();
  const [goalSubmit, setGoalSubmit] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);
  const [editable, seteditable] = useState(false);
  const [editablee, seteditablee] = useState(false);
  const [editables, seteditables] = useState(false);
  const { userData } = useSelector((store) => store.auth);
  const { id } = userData;
  const [editableGoal, setEditableGoal] = useState(
    performanceData?.Leadership || []
  );
  const [editableGoall, setEditableGoall] = useState(
    performanceData?.Performance || []
  );
  const [editableGoals, setEditableGoals] = useState(
    performanceData?.Development || []
  );
  const [employeeAppraisal, setEmployeeAppraisal] = useState([]);
  const [supervisorComment, setSupervisorComment] = useState({
    Leadership: "",
  });
  const [supervisorCommentt, setSupervisorCommentt] = useState({
    Performance: "",
  });
  const [supervisorComments, setSupervisorComments] = useState({
    Development: "",
  });
  const [sendEmployee, setSendEmployee] = useState({
    employee_id: "",
    employee_name: "",
    employee_goals: [],
    employee_appraisal: [],
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
 
  useEffect(() => {
    if (performanceData) {
      setEditableGoal({
        Leadership: performanceData.Leadership || [],
      });
      setEditableGoall({
        Performance: performanceData.Performance || [],
      });
      setEditableGoals({
        Development: performanceData.Development || [],
      });
    }
  }, [performanceData]);
  useEffect(() => {
     
    console.log(id)
    const getAllSubordinates =async () => {
      try {
        const response = await getEmployee(dispatch, id)
        console.log(response.payload)
        setEmployees(response.payload.data)
      } catch (error) {
        console.log(error)
      }
    }
    getAllSubordinates()
  }, [id]);
  const handleEmployeeChange = (e) => {
    const value = e.target.value;
    const employee = employees.find((all) => all.username === value);
    if (employee) {
      setSelectedEmployee(employee);
      setSelectedEmployees({ id: employee.id, username: employee.username });
      setSendEmployee((prevSendEmployee) => ({
        ...prevSendEmployee,
        employee_id: employee.id,
        employee_name: employee.username,
      }));
      setTimeout(() => {
        getPerformance(dispatch, employee.id)
          .then((action) => {
            console.log(action.payload.data);

            const performanceData = JSON.parse(
              action.payload.data.employee_goals
            );

            const employeeAppraisal = JSON.parse(
              action.payload.data.employee_appraisal
            );
            console.log(employeeAppraisal);
            console.log(employeeAppraisal.type);

            setEmployeeAppraisal(employeeAppraisal);
            setPerformanceData(performanceData);
            console.log(performanceData.Leadership);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000);
    }
  };

 

  const handleSubmitEdit = async () => {
    if (!selectedEmployee.id) {
    cogoToast.error("Please select an employee and edit the goals.");
    return;
  }


  const originalGoals = performanceData; 

  const editedLeadershipGoals = performanceData.Leadership.flat().map((goal, index) => ({
    goal_title: goal.goal_title,
    goal_type: goal.goal_type,
    goal_content: editableGoal.Leadership[index]?.goal_content || goal.goal_content,
    status: goal.status,
    target_measurement: goal.target_measurement,
    target_achieved: goal.target_achieved,
    due_date: goal.due_date,
  }));

  const editedPerformanceGoals = performanceData.Performance.flat().map((goal, index) => ({
    goal_title: goal.goal_title,
    goal_type: goal.goal_type,
    goal_content: editableGoall.Performance[index]?.goal_content || goal.goal_content, 
    status: goal.status,
    target_measurement: goal.target_measurement,
    target_achieved: goal.target_achieved,
    due_date: goal.due_date,
  }));

 
  const editedDevelopmentGoals = performanceData.Development.flat().map((goal, index) => ({
    goal_title: goal.goal_title,
    goal_type: goal.goal_type,
    goal_content: editableGoals.Development[index]?.goal_content || goal.goal_content, 
    status: goal.status,
    target_measurement: goal.target_measurement,
    target_achieved: goal.target_achieved,
    due_date: goal.due_date,
  }));

    
    const dataToSend = {
    employee_id: selectedEmployee.id,
    employee_name: String(selectedEmployee.username),
    employee_goals: JSON.stringify({
        Leadership: [editedLeadershipGoals], 
        Performance: [editedPerformanceGoals],
        Development: [editedDevelopmentGoals], 
    }),
};


console.log(dataToSend);



  


  try {
    setLoading(true);
   const response = await editEmployee(dispatch, selectedEmployee.id, dataToSend); 
    cogoToast.success("Goals submitted successfully!");
  } catch (error) {
    console.error("Error submitting goals:", error); 
    cogoToast.error("Failed to submit goals."); 
  } finally {
    setLoading(false); 
  }
};


  const handleSubmits = () => {
  console.log(editableGoall);

  const updatedPerformanceData = { ...performanceData };

  if (editableGoall && Array.isArray(editableGoall.Performance)) {
    updatedPerformanceData.Performance.forEach((goal, index) => {
      if (editableGoall.Performance[index]) {
        goal.goal_content = editableGoall.Performance[index].goal_content; 
      }
    });
    setPerformanceData(updatedPerformanceData);

    let formDatas = {
      Performance: [ updatedPerformanceData.Performance.map((goal, index) => {
         const goalKey = Object.keys(goal)[0];
        return {
          ...goal[goalKey],
          supervisor_Comment: supervisorCommentt.Performance, 
          supervisor_EditGoal: editableGoall.Performance[index].goal_content || goal.goal_content, 
        };
      }),
    ],
    };

    localStorage.setItem("perform", JSON.stringify(formDatas));
    cogoToast.success("Performance review saved successfully");
    console.log(formDatas);
  } 
};
 const handleSubmit = () => {
  if (!supervisorComment.Leadership) {
    cogoToast.error("Please fill your comments");
    return;
  }

  const updatedPerformanceData = { ...performanceData };


  updatedPerformanceData.Leadership.forEach((goal, index) => {
    if (editableGoal.Leadership[index]) {
      goal.goal_content = editableGoal.Leadership[index].goal_content;
    }
    goal.supervisor_EditGoal = editableGoal.Leadership[index].goal_content || goal.goal_content;
    goal.supervisor_Comment = supervisorComment.Leadership;
  });

  setPerformanceData(updatedPerformanceData);

   let formDatas = {
     Leadership: [
       updatedPerformanceData.Leadership.map((goal, index) => {
         const goalKey = Object.keys(goal)[0];
         return {
           
             ...goal[goalKey],
             supervisor_Comment: supervisorComment.Leadership,
             supervisor_EditGoal: editableGoal.Leadership[index]?.goal_content || goal.goal_content,
           
         };
       })
     ],
   }
  localStorage.setItem("Leader", JSON.stringify(formDatas));
  cogoToast.success("Leadership review saved successfully");
};

 const handleSubmitt = () => {
  if (!supervisorComments.Development) {
    cogoToast.error("Please fill your comments");
    return;
  }

  const updatedPerformanceData = { ...performanceData };

  if (editableGoals && Array.isArray(editableGoals.Development)) {
    updatedPerformanceData.Development.forEach((goal, index) => {
      if (editableGoals.Development[index]) {
        goal.goal_content = editableGoals.Development[index].goal_content; 
      }
    });

    setPerformanceData(updatedPerformanceData);

    let formDatas = {
      Development: [ updatedPerformanceData.Development.map((goal, index) => {
        const goalKey = Object.keys(goal)[0];
        return{
        ...goal[goalKey],
        supervisor_Comment: supervisorComments.Development,
        supervisor_EditGoal: editableGoals.Development[index].goal_content || goal.goal_content,
        }
       
      }),
    ],
    };

    localStorage.setItem("Develop", JSON.stringify(formDatas));
    
    cogoToast.success("Supervisor's review for Development saved successfully");
    console.log(formDatas);
    setGoalSubmit(true);

  } else {
    console.error("Editable goal or Development array is undefined or not an array.");
  }
};

  const handleSendBack = () => {
    const sendData = {
      employee_id: sendEmployee.employee_id,
      // employee_name: sendEmployee.employee_name,
    };

    console.log(sendData);

    postsendback(dispatch, sendData);
  };
const handleSupervisorLeadershipComment = (data) => {
  setSupervisorComment((prevSupervisorComment) => ({
    ...prevSupervisorComment,
    Leadership: data,
  }));
  };
  const handleSupervisorPerformanceComment = (data) => {
  setSupervisorCommentt((prevSupervisorCommentt) => ({
    ...prevSupervisorCommentt,
    Performance: data,
  }));
};
const handleSupervisorDevelopmentComment= (data) => {
  setSupervisorComments((prevSupervisorComments) => ({
    ...prevSupervisorComments,
    Development: data,
  }));
};
  
 
const handleEditGoals = (index, updatedContent) => {
  setPerformanceData((prevData) => {
    const updatedLeadershipGoals = [...prevData.Leadership];
updatedLeadershipGoals[index].goal_content = updatedContent;
    return {
      ...prevData,
      Leadership: updatedLeadershipGoals,
    };
  });
};

  const handleEditPerformance =(index, updatedGoalContent) => {
  
  const updatedPerformanceData = { ...performanceData };


  if (Array.isArray(updatedPerformanceData.Performance)) {
    updatedPerformanceData.Performance[index].goal_content = updatedGoalContent;
  }

  
  setPerformanceData(updatedPerformanceData);
  
 
  
};
  const handleEditDevelopment = (index, updatedGoalContent) => {
  
  const updatedPerformanceData = { ...performanceData };


  if (Array.isArray(updatedPerformanceData.Development)) {
    updatedPerformanceData.Development[index].goal_content = updatedGoalContent;
  }

  
  setPerformanceData(updatedPerformanceData);
  
 
  
};

  const handleAll = () => {
    
  const leadership = JSON.parse(localStorage.getItem("Leader")) || {};
  const performance = JSON.parse(localStorage.getItem("perform")) || {};
  const development = JSON.parse(localStorage.getItem("Develop")) || {};

    const appraisalData = JSON.parse(localStorage.getItem("appraisalData"));
    const combinedData = {
      Leadership: [(leadership.Leadership || []).map((goal) => {
        const goalKey = Object.keys(goal)[0]; 
        return {
          
          ...goal[goalKey], 
          supervisor_Comment: supervisorComment.Leadership, 
        };
      }),
    ],
      Performance: [ (performance.Performance || []).map((goal) => {
        const goalKey = Object.keys(goal)[0];
        return {
        
          ...goal[goalKey],
          supervisor_Comment:  supervisorCommentt.Performance,
        };
    
      }),
    ],
      Development:[ (development.Development || []).map((goal) => {
        const goalKey = Object.keys(goal)[0];
        return {
          ...goal[goalKey],
          supervisor_Comment: supervisorComments.Development,
        };
      
  
      }),
    ],
      
    }
  

    console.log(combinedData);
    
     const sendData = {
      employee_id: sendEmployee.employee_id,
      employee_name: sendEmployee.employee_name,
      employee_appraisal: JSON.stringify(appraisalData),
      employee_goals: JSON.stringify(combinedData),
    };

  console.log(sendData)

  try {
    const response = postperformances(dispatch, sendData);
    
  } catch (error) {
    console.log(error);
  }
};


  return (
    <div className="card-height" additionalClass="mt-4 p-4">
      <h5 style={{ fontWeight: "bold" }} className="co-grayx">
        Supervisor's Review of Performance
      </h5>

      <div
        style={{ padding: "10px", maxHeight: "680px" }}
        className="col-12 col-md-12 list-achievements"
      >
        <div className="select-container mt-3">
          <strong>Select Employee to view</strong>
          <select
            style={{ border: "1px solid #e8e9eb" }}
            onChange={handleEmployeeChange}
          >
            <option value="">Select an Option</option>
            {Array.isArray(employees) && employees.length > 0 && (
               employees.map((employee) => (
                <option key={employee.id} value={employee.username}>
                  {employee.username}
                </option>
               ))
            )}
            
             
          </select>
        </div>
        <br />

        {selectedEmployee && performanceData &&(
          <>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Leadership" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Leadership")}
                  style={{
                    backgroundColor:
                      activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                  }}
                >
                  <BsPeople className="mb-1" /> Leadership
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Performance" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Performance")}
                  style={{
                    backgroundColor:
                      activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <BsGraphUp className="mb-1" /> Performance
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Development" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Development")}
                  style={{
                    backgroundColor:
                      activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <IoPersonSharp className="mb-1" /> Development
                </button>
              </li>
            </ul>
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  activeTab === "Leadership" ? "show active" : ""
                }`}
                id="leadership-tab"
                style={{ marginTop: "7px" }}
              >
              {performanceData && (
  <div>
    {Array.isArray(performanceData.Leadership) && performanceData.Leadership.length > 0 && (
      <div>
        {performanceData.Leadership.map((leadershipGoals, leadershipIndex) => (
          <div key={leadershipIndex}>
            <strong>
              {sendEmployee.employee_name} Leadership Goals/Objectives
            </strong>
            {leadershipGoals.map((goal, index) => (
              <div key={index}>
                <CKEditor
                  editor={ClassicEditor}
                  data={goal.goal_content}
                  onChange={(event, editor) => {
                    const updatedContent = editor.getData();
                    handleEditGoals( index, updatedContent); 
                  }}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    )}
  </div>
)}
                <br />
                <br />
               <button
        style={{
          width: "14%",
          color: "#fff",
          height: "6vh",
          borderRadius: "5px",
          backgroundColor: "#3A6ED4",
          border: "none",
          cursor: loading ? "not-allowed" : "pointer",
        }}
        onClick={handleSubmitEdit}
        disabled={loading} 
      >
        {loading ? <ButtonLoader /> : "Submit Edited Version"}
      </button>
  
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Leadership")
                  .map((item, index) => (
                    <div key={index}>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                    </div>
                  ))}

                <br />
                <br />
                {!performanceData?.Leadership?.some(arr => arr.some(item => item.supervisor_Comment)) && (
                  <div>
                      <strong>Supervisor's Review</strong>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{}}
                    data={
                      performanceData &&
                        performanceData.Leadership &&
                        performanceData.Leadership.length > 0
                        ? performanceData.Leadership[0].supervisor_Comment || ""
                        : supervisorComments.Leadership || ""
                    }
 
                    onReady={(editor) => {
 
                    }}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      handleSupervisorLeadershipComment(updatedContent);
                    }}
                  />
                  </div>
                )}
                <div>
                  
                  {performanceData &&
  performanceData.Leadership &&
  performanceData.Leadership.map((performanceItemArray, index) => (
    performanceItemArray.map((item, subIndex) => (
       (item.supervisor_Comment) && (
        <div key={`${index}-${subIndex}`}>
          {item.supervisor_Comment && (
            <>
        <strong>Supervisor Comment for {sendEmployee.employee_name}'s {item.goal_title}</strong>
        <CKEditor
          editor={ClassicEditor}
          config={{}}
          data={item.supervisor_Comment || ""}
          onReady={(editor) => {
            // Editor is ready
          }}
         
        />
    
     </>
                          )}
          {!item.supervisor_Comment && (
            <div>No Comment data available.</div>
          )}
                        </div>
        )
      ))
    ))}

               

                </div>
               
                <br />

                <br />

                <br />
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "red",
                  }}
                  color="#fff"
                  text="Submit"
                  onClick={handleSendBack}
                >
                  SendBack
                </button>
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#3A6ED4",
                  }}
                  color="#fff"
                  text="Submit"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Performance" ? "show active" : ""
                }`}
                id="performance-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && (
                  <div>
                    {Array.isArray(performanceData.Performance) && performanceData.Performance.length > 0 && (

                    
                      <div>

                    
                        {performanceData.Performance.map((PerformanceGoals, index) => (
                          <div key={index}>
                            <strong>
                              {" "}
                              {sendEmployee.employee_name} Performance
                              Goals/Objectives
                            </strong>
                            {PerformanceGoals.map((goal, index) => (
                              <div key={index}>
                                <CKEditor
                              editor={ClassicEditor}
                              config={{}}
                              data={goal.goal_content}
                        
                              onReady={(editor) => {
          
                              }}
                              onChange={(event, editor) => {
                                const updatedContent = editor.getData();
                                handleEditPerformance(index,updatedContent);
                              }}
                            />
                              </div>
                            ))}
                            <br />
                          </div>
                        ))}
                      </div>
                
                    )}
                  </div>
                )}
                <br />
              
                 <button
        style={{
          width: "14%",
          color: "#fff",
          height: "6vh",
          borderRadius: "5px",
          backgroundColor: "#3A6ED4",
          border: "none",
          cursor: loading ? "not-allowed" : "pointer",
        }}
        onClick={handleSubmitEdit}
        disabled={loading} 
      >
        {loading ? <ButtonLoader /> : "Submit Edited Version"}
      </button>
  
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Performance")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                     <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                    </div>
                  ))}

                <br />
                <br />
                 {!performanceData?.Performance?.some(arr => arr.some(item => item.supervisor_Comment)) && (
                  <div>
                      <strong>Supervisor's Review</strong>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{}}
                    data={
                      performanceData &&
                        performanceData.Performance  &&
                        performanceData.Performance .length > 0
                        ? performanceData.Performance [0].supervisor_Comment || ""
                        : supervisorCommentt.Performance  || ""
                    }
 
                    onReady={(editor) => {
 
                    }}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      handleSupervisorPerformanceComment(updatedContent);
                    }}
                  />
                  </div>
                )}
                <div>
                  
                 {performanceData &&
  performanceData.Performance &&
  performanceData.Performance.map((performanceItemArray, index) => (
    performanceItemArray.map((item, subIndex) => (
       (item.supervisor_Comment) && (
        <div key={`${index}-${subIndex}`}>
          {item.supervisor_Comment && (
            <>
        <strong>Supervisor Comment for  {sendEmployee.employee_name}'s  {item.goal_title}</strong>
        <CKEditor
          editor={ClassicEditor}
          config={{}}
          data={item.supervisor_Comment || ""}
          onReady={(editor) => {
            // Editor is ready
          }}
         
        />
        </>
                          )}
          {!item.supervisor_Comment && (
            <div>No Comment data available.</div>
          )}
                        </div>
        )
      ))
    ))}


                  

                 
                </div>
                {/* {performanceData && performanceData.Performance ? (
                  <div>
                    {performanceData.Performance.map((goal, index) => (
                      <div key={index}>
                        <strong>Supervisor's Review</strong>
                        <CustomTextArea
                          name="Performance"
                          value={goal.supervisor_Comment}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <strong>Supervisor's Review</strong>
                    <CustomTextArea
                      name="Performance"
                      value={supervisorCommentt.Performance}
                      onChange={handleChangee}
                    />
                  </div>
                )} */}

                <br />

                <br />
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "red",
                  }}
                  color="#fff"
                  text="Submit"
                  onClick={handleSendBack}
                >
                  SendBack
                </button>
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#3A6ED4",
                  }}
                  color="#fff"
                  onClick={handleSubmits}
                  text="Submit"
                >
                  Save
                </button>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Development" ? "show active" : ""
                }`}
                id="development-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && (
                  <div>
                    {Array.isArray(performanceData.Development) && performanceData.Development.length > 0 && (
                      
                   
                  <div>
                  {performanceData.Development.map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Development
                          Goals/Objectives
                        </strong>
                      {goal.map((goal, index) => (
                         <div key={index}>
                           <CKEditor
          editor={ClassicEditor}
          config={{}}
          data={goal.goal_content} 
         
          onReady={(editor) => {
          
          }}
          onChange={(event, editor) => {
            const updatedContent = editor.getData(); 
            handleEditDevelopment(index, updatedContent); 
          }}
        />
                         </div>
                       ))}
                        <br />
                      </div>
                  ))}
                      </div>
                
                )}
                      </div>
                )}
                <br />
                
                <button
        style={{
          width: "14%",
          color: "#fff",
          height: "6vh",
          borderRadius: "5px",
          backgroundColor: "#3A6ED4",
          border: "none",
          cursor: loading ? "not-allowed" : "pointer",
        }}
        onClick={handleSubmitEdit}
        disabled={loading} 
      >
        {loading ? <ButtonLoader /> : "Submit Edited Version"}
      </button>
  
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Development")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={item.employee_appraisal}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
     
  }}
/>
                    </div>
                  ))}

                <br />
                <br />
                {!performanceData?.Development?.some(arr => arr.some(item => item.supervisor_Comment)) && (
                  <div>
                      <strong>Supervisor's Review</strong>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{}}
                    data={
                      performanceData &&
                        performanceData.Development  &&
                        performanceData.Development.length > 0
                        ? performanceData.Development[0].supervisor_Comment || ""
                        : supervisorComments.Development || ""
                    }
 
                    onReady={(editor) => {
 
                    }}
                    onChange={(event, editor) => {
                      const updatedContent = editor.getData();
                      handleSupervisorDevelopmentComment(updatedContent);
                    }}
                  />
                  </div>
                )}
                
                 {performanceData &&
  performanceData.Development&&
  performanceData.Development.map((performanceItemArray, index) => (
    performanceItemArray.map((item, subIndex) => (
      (item.supervisor_Comment) && (
        
        <div key={`${index}-${subIndex}`}>
          {item.supervisor_Comment && (
            <>
        <strong>Supervisor Comment for  {sendEmployee.employee_name}'s  {item.goal_title}</strong>
        <CKEditor
          editor={ClassicEditor}
          config={{}}
          data={item.supervisor_Comment || ""}
          onReady={(editor) => {
            // Editor is ready
          }}
         
        />
    </>
                          )}
          {!item.supervisor_Comment && (
            <div>No Comment data available.</div>
          )}
                        </div>
        )
      ))
    ))}

                 {/* <CKEditor
  editor={ClassicEditor}
  config={{}}
  data={
    performanceData &&
                    performanceData.Development &&
                    performanceData.Development.length > 0
                      ? performanceData.Development[0].supervisor_Comment || ""
                      : supervisorComments.Development || ""
  }
 
  onReady={(editor) => {
 
  }}
  onChange={(event, editor) => {
    const updatedContent = editor.getData(); 
   handleSupervisorDevelopmentComment(updatedContent); 
                  }}
                /> */}
               

               

                <br />

                <br />
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "red",
                  }}
                  onClick={handleSendBack}
                  color="#fff"
                  text="Submit"
                >
                  SendBack
                </button>
                {!goalSubmit ? (
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "20%",
                      color: "#fff",
                      height: "6vh",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSubmitt}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "20%",
                      color: "#fff",
                      height: "6vh",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleAll}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </>
        )}

        <br />
        <br />
      </div>
    </div>
  );
}

export default SupervisorReview;