import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import BackButton from "../../../components/BackButton";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import Insights from "./Insights";
import Sent from "./Sent";
import "./index.scss";
import { getReferralJobs } from "../../../redux/actions/jobActions";

const subMenuOptions = [
  ["Sent Referrals", "/referrals/sent"],
  ["Insights", "/referrals/insights"],
];
const Referrals = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const displaySection = () => {
    if (pathname.includes("insight")) {
      return <Insights />;
    } else {
      return <Sent />;
    }
  };
  return (
    <Layout>
      <div className="referrals d-flex mt-3">
        <div className="w-25">
          <BackButton />
          <SubMenu menuOptions={subMenuOptions} />
        </div>
        <div className="w-75 ml-4 d-flex align-items-center justify-content-center">
          {displaySection()}
        </div>
      </div>
    </Layout>
  );
};

export default Referrals;
