import httpRequest from "../../ajax";
import {
  CHANGE_PASSWORD,
  ERROR,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
} from "../actionTypes";
import { logoutAction } from "./authActions";

export const getNotificationSettings = (dispatch) =>
  httpRequest("get", "/settings/profile/notification")
    .then(({ data: { data } }) => {
      const action = { type: GET_NOTIFICATION_SETTINGS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getNotifications = (dispatch) =>
  httpRequest("get", "/settings/profile/notification/log/1/10")
    .then(({ data: { data } }) => {
      const action = { type: GET_NOTIFICATIONS, payload: data.notifications };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const updateNotificationSettings = (dispatch, data) =>
  httpRequest("post", "/settings/profile/notification/update", data)
    .then(({ data: { data } }) => {
      const action = { type: UPDATE_NOTIFICATION_SETTINGS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const changePassword = (dispatch, data) =>
  httpRequest("post", "/user/changePassword", data)
    .then(({ data: { data } }) => {
      const action = { type: CHANGE_PASSWORD, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const deleteAccount = (data) =>
  httpRequest("post", "/settings/profile/deleteAccount", data)
    .then(() => {
      return logoutAction();
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
