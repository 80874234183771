import httpRequest from '../../ajax';
import {
  GET_INDUSTRIES,
  ERROR,
} from '../actionTypes';

/**
 * @description get industry action
 * @function getIndustryAction
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const getIndustryAction = (dispatch) => httpRequest('get','/industry')
  .then(({ data: { data }}) => {
    const action = { type: GET_INDUSTRIES, payload: data };
    dispatch(action);
    return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));
