import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Layout from "../../../../layout";
import BackButton from "../../../../components/BackButton";
import { logProfileVisits } from "../../../../redux/actions/insightActions";
import { getOtherUserAction } from "../../../../redux/actions/profileActions";
import { ternaryResolver } from "../../../../utils/helpers";
import { getAllExperienceAction, getSingleExperienceAction } from "../../../../redux/actions/experienceAction";
import icons from "../../../../assets/icons/icon-collection.svg";
import "../index.scss";

const OtherUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    handleGetExperience();
  }, []);
  const handleGetExperience = async () => {
    try {
      const response = await getSingleExperienceAction(id, dispatch);
      if (response && response.payload) {
        setExperiences(response.payload);
      } else {
        setExperiences([]);
      }
    } catch (error) {
      console.error(error);
      setExperiences([]);
    }
  };

  const { user } = useSelector(({ profile }) => profile);
  useEffect(() => {
    Promise.all([getOtherUserAction(dispatch, id), logProfileVisits(id)]);
  }, [dispatch]);

  return (
    <Layout>
      <>
        <BackButton/>
        {user && (
          <div className="mt-3 px-2">
            <div className="profile-header d-flex">
              <img height="150" className="mr-5" src={user.image_url} />
              <div className="d-flex flex-column mt-2">
                <h4 className="text-dark">
                  {user.first_name} <b>{user.last_name}</b>
                </h4>
                {/* <span className="co-gray">Project Manager</span> */}
              </div>
            </div>
          </div>
        )}
        <div className="profile-about">
          <div className="section-header mt-5">
            <p className="mb-0">About</p>
          </div>
          <p className="co-darkgray mt-4">
            {user?.about || "Nothing to display"}
          </p>
        </div>
        <div className="profile-education">
          <div className="section-header mt-5">
            <p className="mb-0">EDUCATION/CERTIFICATIONS</p>
          </div>
          {user?.education.length ? (
            user.education.map((data, index) => (
              <div className={`education edu_link${index + 1}`} key={data.id}>
                <p className="co-darkgray medium-text">{data.school}</p>
                <p>{`${data.degree} ${data.field_of_study} (${ternaryResolver(
                  `${data.end_date}`.toLowerCase() === "present",
                  data.end_date,
                  new Date(data?.end_date).getFullYear()
                )})`}</p>
              </div>
            ))
          ) : (
            <div className="mt-4">
              <p className="co-darkgray">Nothing to display</p>
            </div>
          )}
        </div>
        <div className="profile-skills">
          <div className="section-header mt-5">
            <p className="mb-0">SKILLS/INTERESTS</p>
          </div>
          <ul className="d-flex flex-wrap mt-3">
            {user?.skills?.length ? (
              user?.skills?.map((skill) => (
                <li
                  key={skill}
                  className="mr-2 py-1 px-2 outlined"
                  style={{ marginTop: "10px" }}
                >
                  {skill}
                </li>
              ))
            ) : (
              <li className="mr-2 py-1 px-2 co-darkgray">No skill added</li>
            )}
          </ul>
        </div>
        <div className="profile-documents">
          <div className="section-header mt-5">
            <p className="mb-0">RESUME/DOCUMENTS</p>
          </div>
          <div className="d-flex flex-column mt-3">
            {user?.resume && (
              <a className="d-flex my-2" href={user.resume} download>
                <svg width="20" height="24" className="mr-2">
                  <use href={`${icons}#file`}></use>
                </svg>
                Rèsumé
                <span className="flag mx-3 co-primary small-text px-1">
                  Default
                </span>
              </a>
            )}
            {user?.links && (
              <span className="d-flex my-2">
                <svg width="22" height="22" className="mr-2">
                  <use href={`${icons}#link`}></use>
                </svg>
                {user?.links[0]?.facebook && (
                  <a
                    className="underlined co-primary mr-2"
                    href={user.links[0].facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                )}
                {user?.links[0]?.linkdn && (
                  <a
                    className="underlined co-primary mr-2"
                    href={user.links[0].linkdn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                )}
                {user?.links[0]?.twitter && (
                  <a
                    className="underlined co-primary mr-2"
                    href={user.links[0].twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                )}
                {user?.links[0]?.potfolio && (
                  <a
                    className="underlined co-primary"
                    href={user.links[0].potfolio}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Portfolio
                  </a>
                )}
                {[
                  !user?.resume,
                  !user?.links[0]?.facebook,
                  !user?.links[0]?.linkdn,
                  !user?.links[0]?.twitter,
                  !user?.links[0]?.potfolio,
                ].every((val) => val === true) && (
                  <p className="mr-2 py-1 px-2 co-darkgray">
                    Nothing to display
                  </p>
                )}
              </span>
            )}
          </div>
        </div>
        <div className="profile-education">
          <div className="section-header mt-5">
            <p className="mb-0">EXPERIENCES</p>
          </div>
          {experiences?.length ? (
            experiences.map((data, index) => (
              <div className={`education edu_link${index + 1}`} key={data.id}>
                <p className="co-darkgray medium-text">{data.campany_name}</p>
                <p>{data.title}</p>
                <p>{data.achievements}</p>
                <p>{data.start_date}</p>
                <p>{data.end_date}</p>
              </div>
            ))
          ) : (
            <div className="mt-4">
              <p className="co-darkgray">Nothing to display</p>
            </div>
          )}
        </div>
      </>
    </Layout>
  );
};

export default OtherUser;
